import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

export const CompanyTag = ({ tag }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.tagText}>{tag.name}</Typography>
    </div>
  );
};


const useStyles = makeStyles(({ palette }) => ({
  root: {
    textDecoration: "none",
    backgroundColor: palette.primary.main,
    padding: "0 16px",
    margin: "12px 3px 0 3px",
    fontSize: "18px",
    fontWeight: 700,
    borderRadius: 16,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  tagText: {
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 14,
    fontStyle: "normal",
    color: "#FFFFFF"
  }
}));

