import React, { useContext, useEffect, useState } from "react";
import { CompanyLogo } from "../../../components/Overview/CompanyLogo/CompanyLogo";
import { CompanyInfo } from "../../../components/Overview/CompanyInfo";
import { Typography, makeStyles } from "@material-ui/core";
import { getZettleAuth, zettleAuth } from "../../../services/ZettleServices";
import { MSButton } from "../../../components/MSButton";
import LanguageContext from "../../../store/LanguageContext";

export const CompanyIntroSection = ({ companyData }) => {
  const [disabled, setDisabled] = useState(false);
  const [isZettleConnected, setIsZettleConnected] = useState(true);
  const strings = useContext(LanguageContext);
  const searchParams = new URLSearchParams(window.location.search);
  const classes = useStyles();

  useEffect(() => {
    if (companyData?.zettle_webhook_id) {
      setIsZettleConnected(true);
    } else {
      setIsZettleConnected(false);
    }
  }, [companyData]);

  useEffect(() => {
    const zettleCallback = async () => {
      if (searchParams.has("code") && searchParams.has("state")) {
        try {
          setDisabled(true);
          let formData = new FormData();
          formData.append("code", searchParams.get("code"));
          formData.append("state", searchParams.get("state"));
          await zettleAuth(formData);
          setIsZettleConnected(true);
        } catch (error) {
          console.error(error);
        } finally {
          setDisabled(false);
        }
      }
    };
    zettleCallback();
  }, []);

  const zettleInit = async () => {
    try {
      setDisabled(true);
      const result = await getZettleAuth();
      window.open(result.url);
    } catch (error) {
      console.error(error);
    } finally {
      setDisabled(false);
    }
  };

  return (
    companyData !== null && (
      <section
        className={classes.root}
        style={{
          backgroundImage: `linear-gradient(to top, rgba(251, 251, 251, 1), rgba(251, 251, 251, 0.8)), url(${companyData.background_url})`,
        }}
      >
        <CompanyLogo logoURL={companyData.logo_url} />
        <CompanyInfo companyData={companyData} />
        {!isZettleConnected ? (
          <MSButton disabled={disabled} className={classes.button} type={"secondary"} onClick={zettleInit}>
            Integrate with Zettle
          </MSButton>
        ) : (
          <Typography>{strings.zettleSuccess}</Typography>
        )}
      </section>
    )
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginBottom: 50,
  },
  button: {
    margin: 12,
    height: 50,
    width: "100%",
    maxWidth: 500,
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 32,
  },
}));
