import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

export const InputWraper = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <Typography className={classes.header}>{title}</Typography>
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    border: "1px solid #C4C4C440",
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: 20,
    marginTop: 30,
    marginBottom: 30,
  },

  header: {
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 24,
    color: "#333333",
    textAlign: "start",
    borderBottom: "2px solid #EDB5A3",
    padding: "25px 0px 10px 30px",
  },
});

