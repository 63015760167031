import React from "react";
import { useFooterStyles } from "./FooterStyles";
import Copyright  from "../../pages/SignInCompanyPage/Copyright/Copyright";

export const Footer = () => {
  const classes = useFooterStyles();

  return (
    <footer className={classes.root}>
      <Copyright isFooter={true} />
    </footer>
  );
};
