export const NavigationRoutes = {
  OVERVIEW: "/overview",
  STAMP_CARD: "/stamp_card",
  LOYALTY_CARD: "/loyalty_card",
  COUPONS: "/coupons",
  GIFT_CARDS: "/gift_cards",
  DEALS: "/deals",
  STORE: "/store",
  RETURNS: "/returns",
};

export const SansNavigationRoutes = {
  ROOT: "/",
  DASHBOARD: "/dashboard",
  SIGN_IN: "/sign_in",
  SIGN_IN_COMPANY: "/sign_in_company",
  DEMO: "/demo",
};
