import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { ToggleButtonGroup } from "@material-ui/lab";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Bar } from "react-chartjs-2";
import Dropdown from "../Shared/Dropdown";
import { Months } from "../../lib/constants";
import { useContext } from "react";
import LanguageContext from "../../store/LanguageContext";

const options = {
  indexAxis: "x",
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  type: "line",
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      grid: {
        display: false,
      },
      ticks: {
        precision: 0,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "right",
    },
    title: {
      display: true,
    },
  },
};

const useStyles = makeStyles(theme => ({
  chartPaper: {
    backgroundColor: "#E4E4E4",
    padding: "24px",
    borderRadius: "16px",
  },
  toggleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    minWidth: 330,
    flexWrap: "wrap",
  },
  buttonSelected: {
    backgroundColor: "#EDB5A3!important",
    color: "#FFFFFF!important",
  },
  buttonRoot: {
    backgroundColor: "#FFF",
    fontWeight: "500",
    borderRadius: "60px!important",
    border: "0px solid",
    margin: "4px 8px",
    padding: "0px 25px",
    alignItems: "center",
    textTransform: "none",
    flex: "none",
    color: "#000",
  },
  dropdownsContainer: {
    display: "flex",
    flexDirection: "row",
    justifySelf: "flex-end",
  },
}));

const StampCardsGraph = props => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);

  let months = Months();
  const currentYear = new Date().getFullYear();
  const years = [
    { value: 1, label: currentYear },
    { value: 2, label: currentYear - 1 },
    { value: 3, label: currentYear - 2 },
  ];
  const [filterMonth, setFilterMonth] = useState(months.find(el => el.value === new Date().getMonth() + 1));
  const [filterYear, setFilterYear] = useState(years[0]);
  const [filterStampCards, setFilterStampCards] = useState("day");

  const handleFilterStampCards = (event, newState) => {
    setFilterStampCards(newState);
  };
  const handleMonthChnage = value => {
    setFilterMonth(value);
  };

  const handleYearChange = value => {
    setFilterYear(value);
  };

  const filterLabels = (labels, values, filteredLabels, filteredValues) => {
    labels.forEach((element, index) => {
      let date = new Date(element);
      if (date.getMonth() + 1 === filterMonth.value && date.getFullYear() === filterYear.label) {
        filteredLabels.push(date.getDate());
        filteredValues.push(values[index]);
      }
    });
  };

  let claimLabels;
  let claimData;
  if (filterStampCards === "day") {
    let labels = Object.keys(props.data.claims_per_day);
    let values = Object.values(props.data.claims_per_day);
    let filteredLabels = [];
    let filteredValues = [];
    filterLabels(labels, values, filteredLabels, filteredValues);
    claimLabels = filteredLabels;
    claimData = filteredValues;
  } else if (filterStampCards === "month") {
    claimLabels = Object.keys(props.data.claims_per_month);
    claimData = Object.values(props.data.claims_per_month);
  } else {
    claimLabels = Object.keys(props.data.claims_total);
    claimData = Object.values(props.data.claims_total);
  }

  let data_claims = {
    labels: claimLabels,
    datasets: [
      {
        label: strings.stampCardPage.claimedStampCards,
        data: claimData,
        backgroundColor: ["#EDB5A3"],
        borderColor: "#EDB5A3",
        borderRadius: 30,
        fill: false,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
    ],
  };

  return (
    <Paper elevation={0} className={classes.chartPaper}>
      <div className={classes.toggleContainer}>
        <ToggleButtonGroup
          value={filterStampCards}
          exclusive
          onChange={handleFilterStampCards}
          style={{
            backgroundColor: "#FFF",
            borderRadius: 60,
            border: "1px solid rgba(196, 196, 196, 0.25)",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
            display: "flex",
            alignItems: "start",
            width: "fit-content",
            height: 32,
            overflowX: "auto",
            flexWrap: "nowrap",
            overflowY: "hidden",
            maxWidth: "100%",
            marginRight: filterStampCards === "day" ? "10px" : 0,
            marginBottom: 5,
          }}
        >
          <ToggleButton
            value="day"
            classes={{
              selected: classes.buttonSelected,
              root: classes.buttonRoot,
            }}
          >
            {strings.stampCardPage.day}
          </ToggleButton>
          <ToggleButton
            value="month"
            classes={{
              selected: classes.buttonSelected,
              root: classes.buttonRoot,
            }}
          >
            {strings.stampCardPage.month}
          </ToggleButton>
          <ToggleButton
            value="total"
            classes={{
              selected: classes.buttonSelected,
              root: classes.buttonRoot,
            }}
          >
            {strings.stampCardPage.total}
          </ToggleButton>
        </ToggleButtonGroup>
        {filterStampCards === "day" && (
          <div className={classes.dropdownsContainer}>
            <Dropdown
              data={months}
              selectedItem={filterMonth}
              handleChange={handleMonthChnage}
              dropdownKey={"filter"}
            />
            <div style={{ marginLeft: 10 }}>
              <Dropdown data={years} selectedItem={filterYear} handleChange={handleYearChange} dropdownKey={"graph"} />
            </div>
          </div>
        )}
      </div>
      <Bar data={data_claims} options={options} />
    </Paper>
  );
};

export default StampCardsGraph;
