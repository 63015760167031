import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { MSButton } from "../MSButton";

const DemoStep = ({ title, body, buttonText, onClick = undefined, disabled = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.body}>{body}</Typography>
      {onClick && (
        <MSButton disabled={disabled} className={classes.button} type={"secondary"} onClick={onClick}>
          {buttonText}
        </MSButton>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    margin: 32,
  },
  title: {
    fontFamily: "DMSans",
    fontSize: 32,
    fontWeight: 700,
  },
  body: {
    fontFamily: "DMSans",
    fontSize: 24,
  },
  button: {
    margin: 12,
    height: 50,
    width: "100%",
    maxWidth: 500,
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 32,
  },
}));

export default DemoStep;
