import { makeStyles, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";

const DateTimePicker = ({ value, isDatePickerOpen, setIsDatePickerOpen, handleDateChange, includeTime }) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        value={value}
        open={isDatePickerOpen}
        onOpen={() => setIsDatePickerOpen(true)}
        onClose={() => setIsDatePickerOpen(false)}
        onChange={date => handleDateChange(date)}
        disablePast
        ampm={false}
        error={false}
        helperText={null}
        format={includeTime ? "dd/MM/yyyy   HH:mm" : "dd/MM/yyyy"}
        views={includeTime ? ["year", "month", "date", "hours", "minutes"] : [("year", "month", "date")]}
        animateYearScrolling
        className={classes.datePickerContainer}
        TextFieldComponent={props => <TextField {...props} onClick={() => setIsDatePickerOpen(true)} />}
        keyboardIcon={<Calendar height={26} width={26} />}
        InputProps={{
          disableUnderline: true,
          readOnly: true,
          fullWidth: true,
          className: classes.dateInput,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles({
  datePickerContainer: {
    display: "flex",
    minWidth: 150,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 59,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    borderRadius: 8,
    border: "0.5px solid #C4C4C4",
    resize: "none",
    padding: "5px 0px 5px 10px",
    backgroundColor: "#FFFFFF",
    "& *": {
      cursor: "pointer",
    },
  },

  dateInput: {
    color: "#333333",
    fontFamily: "DMSans",
    fontSize: 20,
    fontWeight: 400,
  },
});

export default DateTimePicker;
