const locale = {
  en: {
    zettleSuccess: "Your company is succesfully connected to Zettle",
    demoStep: "Step",
    demoStepBody1:
      "Once the cashier has scanned all the products, the product details together with a QR code will be shown on the tablet. The tablet is locatated at the checkout desk in the store. Press the 'Checkout' button below to emulate this process (this will happend automatically in the real process)",
    demoStepBody2:
      "If the user hasn't connected a payment card to mystamp yet. He/she should scan the qr code before paying or right after. If the user has already connected their payment card, he/she can pay as usual and the points will be sent to their account. Press the 'Transaction' button below to emulate that the user has successfully payed with their card (this will happend automatically in the real process)",
    demoStepButton1: "Checkout",
    demoStepButton2: "Transaction",
    startedCardIntegration: "Pay with your card at the checkout to start collecting points automatically",
    newPoints: "New points",
    pointsCallToAction: "Scan the QR code to connect your bank card and start collecting points for every purchase",
    pointsCallToAction1: "Collect points and take advantage of incredible offers",
    pointsCallToAction2: "Your order details and a QR code will show up here",
    offer: {
      total: "Total",
      receipt: "Receipt",
      scanTo: "Scan to connect your card",
    },
    B1001: "The offer has expired",
    B1002: "The QR code has expired",
    instructions:
      "An email has been sent with instructions on how to proceed. Sometimes emails ends up in the spam folder. Good luck!",
    couldNotFindEmail: "Sorry, we could find any company with that email address. Try again",
    reset: "Reset",
    resetPassword: "Reset password",
    signOut: "Sign out",
    contactUs: "Contact us",
    whatDoYouWantToDo: "What would you like to do?",
    roger: "Roger!",
    loginAgain: "You need to log in again ...",
    sessionExpired: "Your session has expired",
    serverNotResponding: "The server is not responding ...",
    wrongUserCredentials: "Wrong email or password",
    noEmailProvided: "No email provided",
    selectLocation: "Select your location",
    getOffersError: "We could not fetch your offers.",
    noInternet: "Please connect to the Internet",
    checkInternet: "Do you have internet connection?",
    qrOfferError:
      "We could not fetch the qr code for the offer, this might occur if you lost your internet connection.",
    qrOfferExpired: "Offer expired",
    qrOfferExpiredLong: "The offer has regrettably expired.",
    tryAgainNote: "Would the error persist after trying again please contact MyStamp, dev@mystamp.se",
    tryAgainButton: "Try again",
    noAssociatedCompany: "This account does not have an associated company",
    contactAdministrator: "Kindly contact an administrator at kontakt@mystamp.se",
    scanToGetStamp: "Scan to get stamp",
    scanToGetPointsPre: "Scan to get ",
    scanToGetPointsPost: " points",
    tryAgain: "Try again...",
    signIn: "Sign in",
    forgotPassword: "Forgot password?",
    somethingWrong: "Something went wrong",
    selectOffer: "Select offer",
    welcome: "Welcome",
    location: "Location",
    none: "None",
    all: "All",
    months: {
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
    },
    navigationBar: {
      overview: "Overview",
      stampCard: "Stamp Card",
      loyaltyCard: "Loyalty Card",
      coupons: "Coupons",
      store: "Store",
      returns: "Returns",
    },
    tabletLoginPage: {
      tablet: "Tablet",
      goToCompanyPortal: "Go to company portal",
    },
    companyPortalLoginPage: {
      welcomeTo: "Welcome to",
      companyPortal: "Company portal",
      goToTablet: "Go to tablet login",
    },
    and: "and",
    loyaltyCardsPage: {
      invited: "Invited",
      interactions: "Interactions",
      activeLoyaltyCards: "Active loyalty cards",
      activeTitle: "Active",
      inviteUsersTitle: "Invite users",
      companyNameDefault: "Company name",
      shortDescriptionDefault: "Short description",
      verifiedBy: "VERIFIED BY",
      inviteEmailTitle: "Invite by email",
      email: "Email",
      emails: "Emails",
      emailsDescriptonExample: "person1@mystamp.se\nperson2@mystamp.se\nperson3@mystamp.se",
      dateFrom: "From",
      dateTo: "To",
      tagUsers: "Tag on user (optional)",
      invite: "Invite",
      inviteCSVTitle: "Invite multiple people in bulk",
      selectCSV: "Choose file (CSV)",
      addNewTag: "Add a new tag",
      tag: "Tag",
      add: "Add",
      tableTitle: "Customers with loyalty cards",
      inviteVia: "Invite with:",
      telephone: "telephone",
      filter: "Filter / Search",
      id: "ID",
      start: "Start",
      end: "End",
      invitedAt: "Invited",
      invitedLink: "Invite link",
      retrievedCard: "Retrieved",
      oneMonth: "One Month",
      selectDate: "Select date",
      extend: "Extend",
      save: "Save",
      remove: "Remove",
      dateFromValidation: "Date from must be less than Date to.",
      requiredFile: "File is required!",
      invitationSuccessful: "Invitation sent successfully!",
      invitationUnsuccessful: "Invitation is not sent!",
      emailRequired: "Email is required!",
      emailsRequired: "A list of emails or a CSV file is required!",
      invalidEmail: "Invalid email data!",
      tagTitle: "TAG",
      selectPlaceholder: "Select...",
      tagRequired: "Tag is required!",
      taggingSuccessful: "Tag created successfully!",
      taggingUnsuccessful: "Tag is not created!",
      tagExists: "Tag already exists!",
      emailTitle: "E-MAIL",
      applyButton: "Apply",
      resetButton: "Clear filter",
      cancel: "Cancel",
      displayUsers: "Showing user",
      of: "of",
      or: "or",
      total: "",
      previous: "Previous",
      next: "Next",
      last: "Last",
      saveAll: "Save all changes",
      designedCards: "Designed loyalty cards",
      simpleInvitation: "Simple invite system",
      manageCustomers: "Customer management",
      accessInstruction: "Want access? Send an email to kontakt@mystamp.se and we will help you set it up.",
      tagRemovedMessage: "Tag removed successfully!",
      removeDialogContent: "Are you sure you want to permanently remove this tag?",
      statistics: "Statistics",
    },
    overviewPage: {
      myUsers: "My users",
    },
    couponsPage: {
      points: "Points to give",
      createCoupons: "Create unique coupons",
      reward: "Reward loyal customers",
      pushNotifications: "Push Notifications",
      numberOfGuests: "Invited",
      activeCoupons: "Active coupons",
      redeemedCoupons: "Claimed coupons",
      comingSoon: "Coming soon...",
      yourCoupons: "Your Coupons",
      clickOnCoupons: "Press on a coupon to see metrics for that coupon",
      addNewCoupon: "Create new coupon",
      editCoupon: "Edit your coupon",
      views: "Views",
      aboutTitle: "1. About coupon",
      addImage: "Add image (optional)",
      png: "(PNG)",
      validityOptions: "Fill in values ​​for events*",
      validityPeriod: "Validity period*",
      validityPeriodHelp: "How long from the activation date should the coupon be valid?",
      scheduledAt: "Scheduled at",
      totalRedemptions: "Total number of redemptions (optional)",
      max72: "(max 72 characters)",
      shortDescriptonExample: "For example. Buy candy for 60 SEK receive an Ahlgren Bilar bag for free",
      longDescription: "Long description",
      max125: "(max 125 characters)",
      longDescriptonExample: "",
      specifications: "Specifications (optional)",
      activate: "Active (optional)",
      activateText: "Activate the event coupon",
      max80: "(max 80 characters)",
      specificationsExample: 'For example. "Pick up between 08-10 on weekdays"',
      preview: "Preview",
      previewHelp:
        "This preview provides a general idea of how your message will appear on a mobile device. Actual message rendering will vary depending on the device.",
      longDescriptionCouponExample:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu, sit molestie commodo elit pretium. Sed        lectus purus tempus vitae.",
      bullet1: "Lorem ipsum dolor sit amet, cons ectetur adipiscing elit.",
      bullet2: "Maecenas feugiat male suada inter dum rhoncus proin.",
      bullet3: "In tincidunt et iaculis.",
      totalRedemptionsMessage_1: "Valid for the first ",
      totalRedemptionsMessage_2: " customers",
      pickUp: "Claim",
      valid: "Valid ",
      to: " to ",
      for: " for ",
      dateToExample: "00/00/00",
      inviteViaList: "Invite via list",
      allUsers: "All users",
      stampCardUsers: "Users that have a stamp card",
      loyaltyCardUsers: "Users with loyalty card",
      inviteViaCSV: "Invite via email and CSV-file",
      enterEmails: "Enter email address",
      separateWithCommas: " (separate by comma)",
      inviteMessagesTitle: "You will invite",
      allUsersMessage_1: "All users that have a stamp card or a loyalty card",
      allUsersMessage_2: "All users that have a stamp card",
      allStampsMessage_1: "All users",
      allStampsMessage_2: " that have a",
      allStampsMessage_3: " stamp card",
      selectedStampMessage_1: "All users that have used their",
      selectedStampMessage_2: " stamp card in ",
      allLoyaltyCardsMessage_1: " with a",
      allLoyaltyCardsMessage_2: " loyalty card",
      selectedLoyaltyCardsMessage_1: "All users",
      selectedLoyaltyCardsMessage_2: " loyalty card ",
      loyaltyWithTagsMessage_1: " and tagged with ",
      emailsMessage_1: " users via",
      emailsMessage_2: " email",
      csvMessage_1: "All users in the attached",
      csvMessage_2: " CSV-file",
      chooseStores: "Filter on store for stamp card (optional)",
      chooseLoyaltyCards: "Choose loyalty card",
      chooseTags: "Filter on tag for loyalty card (optional)",
      pushNotificationsHeader: "Push notification",
      sendNotifications: "Send push notification (optional)",
      sendNotificationsToInvited: "Send push notification to the invited",
      users: "users",
      notificationTitle: "Title",
      notificationMessage: "Message",
      time: "Time",
      balance: "Push notification balance",
      saveHeader: "Save and publish",
      publishedCouponText_v1: "Your coupon has been published!",
      publishedCouponText_v2: "Your coupon is ready to be used! Below you will find link and QR-code.",
      scheduledAtPast_v1: "This coupon was scheduled for ",
      scheduledAtPast_v2: " but was not sent out.\nPlease contact MyStamp.",
      share: "Share",
      couponsPerPerson: "Coupon per person*",
      link: "Link",
      qr: "QR",
      saveAsPNG: "Save as PNG",
      notificationsMessage_1: "You have",
      notificationsMessage_2: " push notification left ",
      notificationsMessage_3: " out of ",
      notificationsMessage_4: " this month",
      notificationsMessage_5: "You are out of push notifications. Email us if you would like to have some more!",
      notificationsMessage_6: "Your push notifications are starting to run out. Email us if you want more!",
      notificationError: "Notifications can only be schedueled between 8:00 - 19:59",
      insufficientNotificationFunds: "Insufficient notification funds",
      maximumNumberOfCharacters: "You have reached a maximum number of characters!",
      removeDialogTitle: "Remove selected coupon",
      removeDialogContent: "Are you sure you want to remove this coupon?",
      eventTitle: "Event that dispatches coupon*",
      days: "days",
      weeks: "weeks",
      monthsLiteral: "months",
      eventOptionLabel_11: "User stamp for the first time",
      eventOptionLabel_21: "User have not stamped for ",
      eventOptionLabel_22: " days",
      eventOptionLabel_31: "User have stamped ",
      eventOptionLabel_32: " times in ",
      eventOptionLabel_33: " days",
      eventOptionLabel_41: "User have stamped ",
      eventOptionLabel_42: " times per stamp card",
      eventOptionsText_2: "Not stamped for ",
      eventOptionsText_3: "Stamped  ",
      eventOptionsText_4: "Stamped ",
      campaignCoupon: "Campaign coupon",
      eventCoupon: "Event coupon",
      campaignCoupons: "Campaign coupons",
      eventCoupons: "Event coupons",
      show: "Show:",
      activeValidation: "You already have an activated coupon with this event type.",
    },
    stampCardPage: {
      createStamps: "Create",
      seeStatistics: "See statistics",
      claims: "Claims",
      stamps: "Stamps",
      activeStamps: "Active stamp cards",
      offer: "Offer",
      date: "Date",
      showingClaims: "Showing claimed stamp cards",
      location: "Location",
      allLocations: "All locations",
      stampsPerLocation: "Stamps per location",
      claimedStampCards: "Claimed stamp cards",
      exportList: "Export list",
      day: "Day",
      month: "Month",
      total: "Total",
      stampsNumber: "Number of stamps",
      createStamp: "Create stamp for",
      active: "Active",
      copyLink: "Copy link",
      copiedLink: "Copied to clipboard",
      copiedLinkError: "We don't have your browsers permission to copy text to the clipboard",
      downloadQRImage: "Download QR code",
    },
    storePage: {
      storeImage: "Store image",
      settings: "Store settings",
      createdSubscriptions: "Created subscriptions",
      activeSubscribers: "Active subscribers",
      colors: "Colors",
      background: "Background",
      text: "Text",
      buttonBackground: "Button background",
      buttonText: "Button text",
      yourCreatedSubs: "Your created subscriptions",
      clickOnSub: "Click on a subscription to see key figures for that subscription",
      paymentSolutionTitle: "Set up payment solution",
      paymentSolutionSubtitle:
        "To create a subscription, you first need to establish a connection with the Stripe payment service",
      connectWithStripe: "Connect with Stripe",
      startDate: "Start date",
      email: "Email",
      paymentStatus: "Status",
      redemptionsToday: "Redemptions today",
      redemptionsMonth: "Redemptions per month",
      remove: "Remove",
      saveSettings: "Save settings",
      wrongFormat: "Wrong format",
      success: "Success",
      krPerMonth: "kr per month",
    },
    returnsPage: {
      title: "Process a Return",
      description: "1. About the purchase",
      input_label_1: "Receipt ID",
      input_help_1: "Enter the Receipt ID from the receipt",
      input_label_2: "Amount of Points Left",
      input_help_2: "Enter the Amount of Points the customer should have after the return",
      submit: "Submit",
      status_1: "The return was successful.",
      status_2: "coupons were already redeemed.",
      status_3: "coupons have been removed.",
    },
    pointsDashboard: {
      title_1: "Scan your receipt &",
      title_2: "get rewards",
      scan_qr: "Scan the QR code to get",
      scan_receipt: "Scan your receipt to get",
      points: "points",
      at: "Buy for",
      and_get: "and get",
      offer: {
        title: "Congratulations!",
        on_next_purchase: "on your next purchase",
        footer_disclaimer: "Get this offer by scanning the code on your MyStamp receipt",
      },
      purchase_card: {
        title: "Don't forget!",
        total_paid: "Total paid: ",
        to_claim: "To claim",
        points: "points",
      },
    },
  },
  sv: {
    zettleSuccess: "Ditt företag är kopplat till Zettle",
    demoStep: "Steg",
    demoStepBody1:
      "När kassörskan har skannat alla produkter kommer produktinformationen tillsammans med en QR-kod att visas på surfplattan. Surfplattan finns vid kassadisken i butiken. Tryck på 'Kassa'-knappen nedan för att emulera denna process (detta kommer att ske automatiskt i den verkliga processen)",
    demoStepBody2:
      "Om användaren ännu inte har anslutit ett betalkort till mystamp, kan han/hon skanna qr-koden före betalning eller direkt efter. Om användaren redan har anslutit sitt betalkort kan han/hon betala som vanligt och poängen skickas till dennes konto. Tryck på 'Transaktion'-knappen nedan för att emulera att användaren har betalat med sitt kort (detta kommer att ske automatiskt i den verkliga processen)",
    demoStepButton1: "Kassa",
    demoStepButton2: "Transaktion",
    startedCardIntegration: "Betala med ditt kort i kassan för att börja samla poäng automatiskt",
    newPoints: "Nya poäng",
    pointsCallToAction: "Skanna QR-koden för att koppla ditt bankkort och börja samla in poäng för varje köp",
    pointsCallToAction1: "Samla poäng och ta del av otroliga erbjudanden",
    pointsCallToAction2: "Dina produkter och en QR-kod kommer att visas här",
    offer: {
      total: "Totalt",
      receipt: "Kvitto",
      scanTo: "Skanna för att ansluta ditt kort",
    },
    B1001: "Erbjudandet har gått ut",
    B1002: "QR-koden har gått ut",
    instructions: "Ett email har skickats med instruktioner hur du går vidare. Lycka till!",
    couldNotFindEmail: "Ursäkta, men vi kunde hitta något företag med den mailadressen. Försök igen",
    reset: "Återställ",
    resetPassword: "Återställ lösenord",
    signOut: "Logga ut",
    contactUs: "Kontakta oss",
    whatDoYouWantToDo: "Vad vill du göra?",
    roger: "Uppfattat!",
    loginAgain: "Du behöver logga in på nytt...",
    sessionExpired: "Din session har gått ut",
    serverNotResponding: "Servern svarar inte...",
    wrongUserCredentials: "Fel email eller lösenord",
    noEmailProvided: "Du måste ange en email",
    selectLocation: "Välj plats",
    getOffersError: "Vi kunde inte hämta dina erbjudanden.",
    noInternet: "Internet anslutning funkar inte, var god anslut till internet.",
    checkInternet: "Har du anslutning till internet?",
    qrOfferError:
      "Vi kunde inte hämta qr koden för erbjudnandet, detta kan bero på att du inte har någon internet anslutning.",
    qrOfferExpired: "Erbjudandet utgånget",
    qrOfferExpiredLong: "Tyvärr har erbjudandet gått ut.",
    tryAgainNote: "Skulle felet kvarstå var god kontakta MyStamp dev@mystamp.se",
    tryAgainButton: "Försök igen",
    noAssociatedCompany: "Detta konto har inget företag",
    contactAdministrator: "Vänligen kontakta en administratör via kontakt@mystamp.se",
    scanToGetStamp: "Skanna för att få stämpel",
    scanToGetPointsPre: "Skanna för att få ",
    scanToGetPointsPost: " poäng",
    tryAgain: "Försök igen...",
    signIn: "Logga In",
    forgotPassword: "Glömt lösenord?",
    somethingWrong: "Något gick fel",
    selectOffer: "Välj erbjudande",
    welcome: "Välkommen",
    location: "Plats",
    none: "Ingen",
    all: "Alla",
    months: {
      january: "januari",
      february: "februari",
      march: "mars",
      april: "april",
      may: "maj",
      june: "juni",
      july: "juli",
      august: "augusti",
      september: "september",
      october: "oktober",
      november: "november",
      december: "december",
    },
    tabletLoginPage: {
      tablet: "Tablet",
      goToCompanyPortal: "Gå till företagsportalen",
    },
    companyPortalLoginPage: {
      welcomeTo: "Välkommen till",
      companyPortal: "Företagsportal",
      goToTablet: "Gå till surfplattan",
    },
    navigationBar: {
      overview: "Översikt",
      stampCard: "Stämpelkort",
      loyaltyCard: "Lojalitetskort",
      coupons: "Kuponger",
      returns: "Returer",
    },
    and: "och",
    loyaltyCardsPage: {
      invited: "Antal inbjudna",
      interactions: "Interaktioner",
      activeLoyaltyCards: "Aktiva lojalitetskort",
      activeTitle: "Aktiva",
      inviteUsersTitle: "Bjud in användare",
      companyNameDefault: "Företagsnamn",
      shortDescriptionDefault: "Kort beskrivning",
      verifiedBy: "VERIFIED BY",
      inviteEmailTitle: "Bjud in via e-post",
      email: "E-post",
      emails: "E-postadresser",
      emailsDescriptonExample: "person1@mystamp.se\nperson2@mystamp.se\nperson3@mystamp.se",
      dateFrom: "Från",
      dateTo: "Till",
      tagUsers: "Tag på användare (valfritt)",
      invite: "Bjud in",
      inviteCSVTitle: "Bjud in flera personer samtidigt",
      selectCSV: "Välj fil (CSV)",
      addNewTag: "Lägg till en ny tag",
      tag: "Tag",
      add: "Lägg till",
      tableTitle: "Kunder med lojalitetskort",
      inviteVia: "Bjud in via:",
      telephone: "Telefon",
      filter: "Filtrera / Sök",
      id: "ID",
      start: "Start",
      end: "Slut",
      invitedAt: "Inbjuden",
      invitedLink: "inbjudningslänk",
      retrievedCard: "Hämtat kort",
      oneMonth: "En månad",
      selectDate: "Välj datum",
      extend: "Förläng",
      save: "Spara",
      remove: "Ta bort",
      dateFromValidation: "Från datum måste vara mindre än till datumet.",
      requiredFile: "Fil är obligatoriskt",
      invitationSuccessful: "Inbjudan skickades",
      invitationUnsuccessful: "Inbjudan misslyckades",
      emailRequired: "Email är obligatoriskt",
      emailsRequired: "En lista med e-postadresset eller en CSV fil är obligatoriskt",
      invalidEmail: "Ogiltig email",
      tagTitle: "TAG",
      selectPlaceholder: "Välj...",
      tagRequired: "Tag är obligatorisk!",
      taggingSuccessful: "Tag skapad",
      taggingUnsuccessful: "Taggning misslyckades",
      tagExists: "Tag existerar redan",
      emailTitle: "EMAIL",
      applyButton: "Tillämpa",
      resetButton: "Rensa filter",
      cancel: "Avbryt",
      displayUsers: "Visar användare",
      of: "av",
      or: "eller",
      total: "totalt",
      previous: "Föregående",
      next: "Nästa",
      last: "Sista",
      saveAll: "Spara alla ändringar",
      designedCards: "Designade lojalitetskort",
      simpleInvitation: "Enkel inbjudan",
      manageCustomers: "Administrera dina kunder",
      accessInstruction: "Vill du ha tillgång? Skicka ett email till kontakt@mystamp.se så hjälper vi dig igång.",
      tagRemovedMessage: "Tag borttagen!",
      removeDialogContent: "Är du säker på att du vill ta bort taggen?",
      statistics: "Statistik",
    },
    overviewPage: {
      myUsers: "Mina användare",
    },
    couponsPage: {
      points: "Poäng att ge",
      createCoupons: "Skapa unika kuponger",
      reward: "Belöna era trogna kunder",
      pushNotifications: "Push notifikationer",
      numberOfGuests: "Antal inbjudna",
      activeCoupons: "Aktiva kuponger",
      redeemedCoupons: "Inlösta kuponger",
      comingSoon: "Coming soon...",
      yourCoupons: "Dina kuponger",
      clickOnCoupons: "Klicka på en kupong för att se nyckeltal för den kupongen",
      addNewCoupon: "Skapa ny kupong",
      editCoupon: "Editera din kupong",
      views: "Visningar",
      aboutTitle: "1. Om kupongen",
      addImage: "Lägg till bild (valfritt)",
      png: "(PNG)",
      validityOptions: "Fyll i värden för event*",
      validityPeriod: "Giltighetstid*",
      validityPeriodHelp: "Hur länge från aktiveringsdatum ska kupongen gälla?",
      scheduledAt: "Schemalagd",
      totalRedemptions: "Totalt antal inlösningar (valfritt)",
      max72: "(max 72 tecken)",
      shortDescriptonExample: "Ex. Köp lösgodis för 60 kr få en Ahlgrens Bilar på köpet",
      longDescripton: "Längre beskrivning",
      max125: "(max 125 tecken)",
      longDescriptonExample: "",
      specifications: "Specifikationer (valfritt)",
      activate: "Aktiv (valfritt)",
      activateText: "Aktivera eventkupongen",
      max80: "(max 80 tecken)",
      specificationsExample: 'Ex. "Hämtas ut mellan 08-10 på vardagar"',
      preview: "Förhandsvisning",
      previewHelp:
        "Den här förhandsvisningen ger en allmän uppfattning om hur ditt meddelande kommer att visas på en mobil enhet. Den faktiska meddelanderenderingen varierar beroende på enheten.",
      longDescription: "Lång beskrivning",
      longDescriptionCouponExample:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu, sit molestie commodo elit pretium. Sed        lectus purus tempus vitae.",
      bullet1: "Lorem ipsum dolor sit amet, cons ectetur adipiscing elit.",
      bullet2: "Maecenas feugiat male suada inter dum rhoncus proin.",
      bullet3: "In tincidunt et iaculis.",
      totalRedemptionsMessage_1: "Gäller de ",
      totalRedemptionsMessage_2: " första uthämtningarna",
      pickUp: "Hämta ut",
      valid: "Giltig ",
      to: " till ",
      for: " i ",
      dateToExample: "00/00/00",
      inviteViaList: "Bjud in via färdiga listor",
      allUsers: "Samtliga användare",
      stampCardUsers: "Användare som använt sitt stämpelkort",
      loyaltyCardUsers: "Användare med lojalitetskort",
      inviteViaCSV: "Bjud in via e-post och CSV-fil",
      enterEmails: "Ange e-postadress",
      separateWithCommas: " (separera med kommatecken)",
      inviteMessagesTitle: "Du kommer bjuda in",
      allUsersMessage_1: "Alla användare som har ett stämpelkort eller lojalitetskort",
      allUsersMessage_2: "Alla användare som har ett stämpelkort",
      allStampsMessage_1: "Alla användare",
      allStampsMessage_2: " som använt sitt",
      allStampsMessage_3: " stämpelkort",
      selectedStampMessage_1: "Användare som använt sitt",
      selectedStampMessage_2: " stämpelkort i ",
      allLoyaltyCardsMessage_1: " med",
      allLoyaltyCardsMessage_2: " lojalitetskort",
      selectedLoyaltyCardsMessage_1: "Användare",
      selectedLoyaltyCardsMessage_2: " lojalitetskortet ",
      loyaltyWithTagsMessage_1: " taggat med ",
      emailsMessage_1: " användare via",
      emailsMessage_2: " e-post",
      csvMessage_1: "Alla användare i bifogad",
      csvMessage_2: " CSV-fil",
      chooseStores: "Filtrera på butik för stämpelkort (valfritt)",
      chooseLoyaltyCards: "Välj lojalitetskort",
      chooseTags: "Välj tagg för lojalitetskort (valfritt)",
      pushNotificationsHeader: "Pushnotiser",
      sendNotifications: "Skicka pushnotis (valfritt)",
      sendNotificationsToInvited: "Skicka pushnotis till de inbjudna",
      users: "användare",
      notificationTitle: "Titel",
      notificationMessage: "Meddelande",
      time: "Tid",
      balance: "Pushnotiser saldo",
      saveHeader: "Spara och publicera",
      publishedCouponText_v1: "Din kupong har publiserats!",
      publishedCouponText_v2: "Din kupong är redo att användas! Nedanför hittar du länk och QR-kod.",
      scheduledAtPast_v1: "Kupongen var schemalagd ",
      scheduledAtPast_v2: " men blev aldrig utskickad.\nVar god kontakta MyStamp",
      share: "Dela",
      couponsPerPerson: "Kuponger per person*",
      link: "Länk",
      qr: "QR",
      saveAsPNG: "Spara som PNG",
      notificationsMessage_1: "Ni har",
      notificationsMessage_2: " pushnotiser kvar",
      notificationsMessage_3: " av ",
      notificationsMessage_4: " denna månad",
      notificationsMessage_5: "Era pushnotiser är slut. Maila oss om ni vill ha fler!\n",
      notificationsMessage_6: "Era pushnotiser börjar ta slut. Maila oss om ni vill ha fler!\n",
      notificationError: "Aviseringar kan endast schemaläggas mellan 8:00 - 19:59",
      insufficientNotificationFunds: "Du har tyvärr inga push notifikationer kvar",
      maximumNumberOfCharacters: "Du har nåt maximalt antal tecken",
      removeDialogTitle: "Ta bort vald kupon",
      removeDialogContent: "Är du säker på att du vill ta bort kupongen?",
      eventTitle: "Event som genererar utskick*",
      days: "dagar",
      weeks: "veckor",
      monthsLiteral: "månader",
      eventOptionLabel_11: "Användare stämplar för första gången",
      eventOptionLabel_21: "Användare har inte stämplat på ",
      eventOptionLabel_22: " dagar",
      eventOptionLabel_31: "Användare har stämplat ",
      eventOptionLabel_32: " gånger på ",
      eventOptionLabel_33: " dagar",
      eventOptionLabel_41: "Användare har stämplat ",
      eventOptionLabel_42: " gånger per stämpelkort",
      eventOptionsText_2: "Inte stämplat på ",
      eventOptionsText_3: "Stämplat ",
      eventOptionsText_4: "Stämplat ",
      campaignCoupon: "Kampanjkupong",
      eventCoupon: "Eventkupong",
      campaignCoupons: "Kampanjkuponger",
      eventCoupons: "Eventkuponger",
      show: "Visa:",
      activeValidation: "Du har redan en aktiverad kupong med den här typen av event.",
    },
    stampCardPage: {
      createStamps: "Skapa",
      seeStatistics: "Se statistik",
      claims: "Se uthämtningar",
      stamps: "Stämplingar",
      activeStamps: "Aktiva stämpelkort",
      offer: "Erbjudande",
      date: "Datum",
      showingClaims: "Visar inlösta stämpelkort",
      location: "Plats",
      allLocations: "Alla butiker",
      stampsPerLocation: "Stämplingar per butik",
      claimedStampCards: "Inlösta stämpelkort",
      exportList: "Exportera lista",
      day: "Dag",
      month: "Månad",
      total: "Totalt",
      stampsNumber: "antal stämplingar",
      createStamp: "Skapa stämpel för",
      active: "Aktiva",
      copyLink: "Kopiera länk",
      copiedLink: "Kopierad till urklipp",
      copiedLinkError: "Vi har inte din webbläsares tillåtelse att kopiera text till urklipp",
      downloadQRImage: "Ladda ner QR-kod",
    },
    storePage: {
      storeImage: "Butiksbild",
      settings: "Butiksinställningar",
      createdSubscriptions: "Skapade prenumerationer",
      activeSubscribers: "Aktiva prenumeranter",
      colors: "Färger",
      background: "Bakgrund",
      text: "Text",
      buttonBackground: "Knappbakgrund",
      buttonText: "Knapptext",
      yourCreatedSubs: "Dina skapade prenumerationer",
      clickOnSub: "Klicka på en prenumeration för att se nyckeltal för den prenumerationen",
      paymentSolutionTitle: "Sätt upp betalningslösning",
      paymentSolutionSubtitle:
        "För att skapa en prenumeration behöver du först upprätta en koppling med betalningstjänsten Stripe ",
      connectWithStripe: "Anslut med Stripe",
      startDate: "Startdatum",
      email: "E-post",
      paymentStatus: "Status",
      redemptionsToday: "Utnyttjanden idag",
      redemptionsMonth: "Utnyttjanden per månad",
      remove: "Ta bort",
      saveSettings: "Spara inställningar",
      wrongFormat: "Fel format",
      success: "Sparat",
      krPerMonth: "kr per månad",
    },
    returnsPage: {
      title: "Behandla en retur",
      description: "1. Om köpet",
      input_label_1: "Kvitto-id",
      input_help_1: "Ange kvitto-id från kvittot",
      input_label_2: "Antal poäng kvar",
      input_help_2: "Ange antalet poäng kunden ska ha efter returen",
      submit: "Skicka in",
      status_1: "Returen har genomförts.",
      status_2: "kuponger var redan utnyttjade.",
      status_3: "kuponger har tagits bort.",
    },
    pointsDashboard: {
      title_1: "Skanna ditt kvitto &",
      title_2: "få belöningar",
      scan_qr: "Skanna QR-koden för att få",
      scan_receipt: "Skanna ditt kvitto för att få",
      points: "poäng",
      at: "Handla för",
      and_get: "och få",
      offer: {
        title: "Grattis!",
        on_next_purchase: "på ditt nästa köp",
        footer_disclaimer: "Få detta erbjudande genom att skanna koden på ditt MyStamp-kvitto",
      },
      purchase_card: {
        title: "Missa inte!",
        total_paid: "Totalt: ",
        to_claim: "Att tilldelas:",
        points: "poäng",
      },
    },
  },
};
export { locale };
