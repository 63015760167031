import fetchHelper from "../lib/fetchHelper";

export const getActiveOffers = async () => {
  return fetchHelper("/api/dashboard/offers/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPointLevels = async offerId => {
  return fetchHelper(`/api/v1/offers/${offerId}/point_level_coupons`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
