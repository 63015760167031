import { Grid } from "@material-ui/core";
import React from "react";
import Metric from "./Metric";

const CountGrid = ({ labelTotal, total, labelActive, active, labelDone, done, gridKey }) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={4} md={2}>
        <Metric label={labelTotal} value={total} gridKey={gridKey} />
      </Grid>
      <Grid item xs={4} md={2}>
        <Metric label={labelActive} value={active} gridKey={gridKey} />
      </Grid>
      <Grid item xs={4} md={2}>
        <Metric label={labelDone} value={done} gridKey={gridKey} />
      </Grid>
    </Grid>
  );
};

export default CountGrid;
