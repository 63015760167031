import { makeStyles } from "@material-ui/core/styles";

export const useFooterStyles = makeStyles(({ palette }) => ({
  root: {
    zIndex: 2,
    width: "100%",
    height: 60,
    background: palette.primary.main,
    display: "flex",
    paddingLeft: 30,
    paddingRight: 30,
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
}));
