import React from "react";
import { makeStyles } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const InputErrorBox = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.errorBox}>
      <ErrorOutlineIcon className={classes.errorIcon} />
      <span className={classes.errorText}>{message}</span>
    </div>
  );
};

const useStyles = makeStyles({
  errorBox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 5,
    marginBottom: -20,
    alignItems: "center",
  },

  errorIcon: {
    fill: "red",
    width: 15,
    height: 15,
  },

  errorText: {
    marginLeft: 2,
    color: "red",
    textAlign: "start",
    fontFamily: "DMSans",
    fontWeight: 400,
  },
});

export default InputErrorBox;
