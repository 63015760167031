import { makeStyles } from "@material-ui/core";

export const useStampCardStyles = makeStyles(() => ({
  root: {
    backgroundImage: ({ image }) => `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: 94,
    width: 308,
    border: "4px solid #fafafa",
    boxShadow: ({ selected }) => (selected ? "rgb(0 0 0 / 82%) 2px 3px 10px 6px" : "0px 2px 6px #888888"),
  },
  shadow: {
    height: "100%",
    width: "100%",
    background: "linear-gradient(to right, #00000077, #00000006)",
  },
  container: {
    marginLeft: 20,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  stampsAndTitleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    width: 135,
    fontSize: 13,
    lineHeight: 1,
    fontWeight: 700,
    color: "#fff",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  stampsContainer: {
    width: 135,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  stampContainer: {
    height: 22,
    width: 22,
    display: "flex",
    alignItems: "flex-end",
  },
}));
