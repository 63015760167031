import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Button, TextField, Typography, Link, Container } from "@material-ui/core";
import useStyles from "./styles";
import { UserContext } from "../../store/UserContext";
import Fade from "@material-ui/core/Fade";
import { errorToast } from "../../components/Notifications/Notifications";
import LanguageContext from "../../store/LanguageContext";
import Layout from "../../Layout/Layout";
import { signIn } from "../../services/SignInServices";
import { roles } from "../../lib/constants";

export const SignIn = () => {
  const classes = useStyles();
  const { state, setState } = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);
  const [fade, setFade] = useState(true);
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();

  const strings = useContext(LanguageContext);

  const handleChange = (value, target) => {
    switch (target) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    let formData = new FormData();
    if (email) {
      formData.append("email", email);
    }
    if (password) {
      formData.append("password", password);
    }
    try {
      let result = await signIn(formData);
      if (window.hj != null) {
        window.hj("tagRecording", [email.toString()]);
      }
      setFade(false);
      setTimeout(() => {
        setState({ authenticated: true, user: result, role: roles.TABLET });
        setRedirect(true);
      }, 600);
    } catch (error) {
      if(error.message === "No email provided") {
        errorToast({
          title: strings.noEmailProvided,
          text: strings.tryAgain,
        });
      } else if(error.message === "Wrong email or password") {
        errorToast({
          title: strings.wrongUserCredentials,
          text: strings.tryAgain,
        });
      } else{
        errorToast({
          title: strings.somethingWrong,
          text: strings.tryAgainNote,
        });
      }

    }
  };

  const signInPage = (
    <Fade in={fade} timeout={600} mountOnEnter unmountOnExit>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" className={classes.welcomeTo}>
            {strings.companyPortalLoginPage.welcomeTo}
          </Typography>
          <Typography component="h1" variant="h5" className={classes.tabletText}>
            {strings.tabletLoginPage.tablet}
          </Typography>
          <form className={classes.form} noValidate onSubmit={event => handleSubmit(event)}>
            <TextField
              onChange={event => handleChange(event.target.value, "email")}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
            <TextField
              onChange={event => handleChange(event.target.value, "password")}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              style={{
                backgroundColor: "#FFF",
                color: "#000",
                borderRadius: 16,
                padding: "10px 20px 10px 20px",
              }}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {strings.signIn}
            </Button>
          </form>
          <Typography style={{ display: "flex", flexDirection: "column" }}>
            <Link className={classes.link} onClick={() => window.location.replace("/password_resets/new")}>
              {strings.forgotPassword}
            </Link>
            <Link className={classes.link} onClick={() => window.location.replace("/sign_in_company")}>
              {strings.tabletLoginPage.goToCompanyPortal}
            </Link>
          </Typography>
        </div>
      </Container>
    </Fade>
  );

  return redirect || ((typeof state.role === 'undefined' || state.role === roles.TABLET) && state.authenticated) ? <Redirect to="/dashboard" /> : <Layout isSignIn={true}>{signInPage}</Layout>;
};
