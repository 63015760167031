import fetchHelper from "../lib/fetchHelper";

export const getZettleAuth = async () => {
  return fetchHelper("/api/dashboard/zettle/auth", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const zettleAuth = async formData => {
  return fetchHelper("api/dashboard/zettle/auth", {
    method: "POST",
    body: formData,
  });
};
