import React, { useContext, useEffect, useState } from "react";
import { generateDropdownData, mapData, mapDataOfSelectedCoupon } from "../../lib/helper";
import {
  addNewCoupon,
  getLocations,
  updateCoupon,
  getAudienceCount,
  getPushNotificationAccess,
} from "../../services/CouponsServices";
import { getActiveCards, getTags } from "../../services/LoyaltyCardsServices";
import LanguageContext from "../../store/LanguageContext";
import { CompanyContext } from "../../store/CompanyContext";
import { errorToast } from "../Notifications/Notifications";
import AboutSection from "./AboutSection";
import InviteSection from "./InviteSection";
import NotificationsSection from "./NotificationsSection";
import SaveSection from "./SaveSection";

const NewCouponSection = ({ activeCoupon, handleCouponsUpdate, handleCouponsRemove }) => {
  //about section
  const strings = useContext(LanguageContext);
  const { company } = useContext(CompanyContext);
  let today = new Date();
  let dateToHelper = new Date(today.setMonth(today.getMonth() + 1));
  const [tags, setTags] = useState([]);
  const [cards, setCards] = useState([]);
  const [locations, setLocations] = useState([]);
  const [pushNotificationAccess, setPushNotificationAccess] = useState(false);
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(dateToHelper);
  const [datesValidation, setDatesValidation] = useState(true);
  const [shortDescriptionErrorMessage, setShortDescriptionErrorMessage] = useState("");
  const [longDescriptionErrorMessage, setLongDescriptionErrorMessage] = useState("");
  const [specificationsErrorMessage, setSpecificationsErrorMessage] = useState("");
  const [scheduledAt, setScheduledAt] = useState(new Date());
  const [totalRedemptions, setTotalRedemptions] = useState(0);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [sendPushNotification, setSendPushNotification] = useState(false);
  const [pushNotificationTitle, setPushNotificationTitle] = useState(company.company.name ?? "");
  const [pushNotificationBody, setPushNotificationBody] = useState("");
  const [audienceCount, setAudienceCount] = useState(0);

  //invite section
  const [allUsersSelected, setAllUsersSelected] = useState(true);
  const [loyaltyCardUsersSelected, setLoyaltyCardUsersSelected] = useState(false);
  const [stampCardUsersSelected, setStampCardUsersSelected] = useState(false);
  const [selectedStampLocations, setSelectedStampLocations] = useState([]);
  const [selectedLoyaltyCard, setSelectedLoyaltyCard] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoyaltyAvailable, setIsLoyaltyAvailable] = useState(true);

  //save section
  const [isSaveEnabled, setIsSaveEnable] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isRemoveVisible, setIsRemoveVisible] = useState(false);

  const createCoupon = async () => {
    setIsSaving(true);
    const loyaltyCardsArray = generateDropdownData(
      allUsersSelected,
      selectedLoyaltyCard,
      cards,
      "loyalty_card_program_id",
    );
    const locationsArray = generateDropdownData(allUsersSelected, selectedStampLocations, locations, "location_id");
    const tagsArray = generateDropdownData(allUsersSelected, selectedTags, tags, "tag_id");
    let loyaltyParameterName =
      "coupon_campaign_loyalty_card_programs_attributes" +
      (loyaltyCardsArray.length === 0 ? "" : "[][loyalty_card_program_id]");
    let tagsParameterName = "coupon_campaign_tags_attributes" + (tagsArray.length === 0 ? "" : "[][tag_id]");
    let locationsParameterName =
      "coupon_campaign_locations_attributes" +
      (locationsArray.length === 0 || allUsersSelected ? "" : "[][location_id]");

    let formData = new FormData();
    formData.append("short_description", shortDescription);
    formData.append("long_description", longDescription);
    formData.append("specifications", specifications);
    formData.append("redemption_limit", totalRedemptions);
    formData.append("valid_from", dateFrom);
    formData.append("valid_to", dateTo);
    formData.append("publish_at", scheduledAt);
    formData.append("stamp_card_audience", allUsersSelected || stampCardUsersSelected);
    if (loyaltyCardsArray.length > 1) {
      loyaltyCardsArray.forEach(el => {
        formData.append(loyaltyParameterName, el);
      });
    } else {
      formData.append(loyaltyParameterName, loyaltyCardsArray);
    }

    if (tagsArray.length > 1) {
      tagsArray.forEach(el => {
        formData.append(tagsParameterName, el);
      });
    } else {
      formData.append(tagsParameterName, tagsArray);
    }

    if (locationsArray.length > 1) {
      locationsArray.forEach(el => {
        formData.append(locationsParameterName, el);
      });
    } else {
      formData.append(locationsParameterName, locationsArray);
    }
    if (imageFile !== null) {
      formData.append("image", imageFile);
    }

    if (sendPushNotification) {
      formData.append("send_push_notification", sendPushNotification);
      formData.append("push_notification_title", pushNotificationTitle);
      formData.append("push_notification_body", pushNotificationBody);
    }

    try {
      if (activeCoupon !== null) {
        let result = await updateCoupon(activeCoupon.id, formData);
        handleCouponsUpdate(result, true);
      } else {
        let result = await addNewCoupon(formData);
        handleCouponsUpdate(result);
      }
    } catch (error) {
      if (error.code === 400) {
        return;
      }
      errorToast({
        title: "Error",
        text: error.message,
      });
    }
    setTimeout(() => {
      setIsSaving(false);
    }, 1000);
  };

  const handleImageChange = image => {
    setImage(image !== null ? image.base64 : null);
    setImageFile(image !== null ? image.fileList[0] : null);
  };

  const handleShortDescriptionChange = event => {
    event.target.value.length <= 72 && setShortDescription(event.target.value);
    if (event.target.value.length === 73) {
      setShortDescriptionErrorMessage(strings.couponsPage.maximumNumberOfCharacters);
      setTimeout(() => {
        setShortDescriptionErrorMessage("");
      }, 3000);
    }
  };

  const handleLongDescriptionChange = event => {
    event.target.value.length <= 125 && setLongDescription(event.target.value);
    if (event.target.value.length === 126) {
      setLongDescriptionErrorMessage(strings.couponsPage.maximumNumberOfCharacters);
      setTimeout(() => {
        setLongDescriptionErrorMessage("");
      }, 3000);
    }
  };

  const handleSpecificationsChange = event => {
    event.target.value.length <= 80 && setSpecifications(event.target.value);
    if (event.target.value.length === 81) {
      setSpecificationsErrorMessage(strings.couponsPage.maximumNumberOfCharacters);
      setTimeout(() => {
        setSpecificationsErrorMessage("");
      }, 3000);
    }
  };

  const handleDateFromChange = date => {
    setDateFrom(date);
    setDatesValidation(dateTo > date);
  };

  const handleDateToChange = date => {
    setDateTo(date);
    setDatesValidation(date > dateFrom);
  };

  const handleScheduledAtChange = date => {
    setScheduledAt(date);
  };

  const handleTotalRedemptionsChange = event => {
    setTotalRedemptions(event.target.value);
  };

  const handleSendPushNotification = toggle => {
    setSendPushNotification(toggle);
  };

  const handleAudienceCount = async () => {
    if (sendPushNotification) {
      let stampQueryName = "stamp_card_audience=";
      let loyaltyQueryName = "loyalty_card_program_ids[]=";
      let tagsQueryName = "tag_ids[]=";
      let locationsQueryName = "location_ids[]=";
      const loyaltyCardsArray = generateDropdownData(
        allUsersSelected,
        selectedLoyaltyCard,
        cards,
        "loyalty_card_program_id",
      );
      const locationsArray = generateDropdownData(allUsersSelected, selectedStampLocations, locations, "location_id");
      const tagsArray = generateDropdownData(allUsersSelected, selectedTags, tags, "tag_id");

      let query = "";
      query += stampQueryName + (allUsersSelected || stampCardUsersSelected);

      loyaltyCardsArray.forEach(el => {
        query += "&" + loyaltyQueryName + el;
      });

      tagsArray.forEach(el => {
        query += "&" + tagsQueryName + el;
      });

      locationsArray.forEach(el => {
        query += "&" + locationsQueryName + el;
      });

      try {
        const audienceCountTemp = await getAudienceCount(query);
        setAudienceCount(audienceCountTemp?.invite_count ?? 0);
      } catch (error) {
        errorToast({
          title: "Error",
          text: error.message,
        });
      }
    }
  };

  const handlePushNotificationTitle = event => {
    setPushNotificationTitle(event.target.value);
  };

  const handlePushNotificationBody = event => {
    setPushNotificationBody(event.target.value);
  };

  useEffect(() => {
    let couponNotNull = activeCoupon !== null;
    let stampCardAudienceActive = couponNotNull && activeCoupon.stamp_card_audience;
    let allLoyaltyDataSelected =
      couponNotNull &&
      activeCoupon.loyalty_card_program_audiences.length === cards.length - 1 &&
      activeCoupon.tag_audiences.length === tags.length;

    let loyaltyUsersSelected =
      couponNotNull &&
      (activeCoupon.loyalty_card_program_audiences.length > 0 || activeCoupon.tag_audiences.length > 0);
    let allSelected = couponNotNull
      ? stampCardAudienceActive && allLoyaltyDataSelected && activeCoupon.stamp_card_location_audiences.length === 0
      : true;
    let stampsLocationsSelected = couponNotNull ? !allSelected && stampCardAudienceActive : false;
    let loyaltySelected = couponNotNull ? (!allSelected ? loyaltyUsersSelected : false) : false;
    setImage(couponNotNull ? activeCoupon.coupon_image : null);
    setDateFrom(couponNotNull ? new Date(activeCoupon.valid_from) : new Date());
    setDateTo(couponNotNull ? new Date(activeCoupon.valid_to) : dateToHelper);
    setScheduledAt(couponNotNull ? new Date(activeCoupon.publish_at) : new Date());
    setTotalRedemptions(
      couponNotNull ? (activeCoupon.redemption_limit !== null ? activeCoupon.redemption_limit : "") : "",
    );
    setShortDescription(couponNotNull ? activeCoupon.short_description : "");
    setLongDescription(couponNotNull ? activeCoupon.long_description : "");
    setSpecifications(couponNotNull ? activeCoupon.specifications : "");
    setSendPushNotification(couponNotNull ? activeCoupon.send_push_notification : false);
    setPushNotificationTitle(couponNotNull ? activeCoupon.push_notification_title ?? "" : company.company.name ?? "");
    setPushNotificationBody(couponNotNull ? activeCoupon.push_notification_body : "");
    setSelectedLoyaltyCard(
      couponNotNull
        ? allSelected
          ? []
          : mapDataOfSelectedCoupon(activeCoupon.loyalty_card_program_audiences, cards, "loyalty")
        : [],
    );
    setSelectedTags(
      couponNotNull ? (allSelected ? [] : mapDataOfSelectedCoupon(activeCoupon.tag_audiences, tags, "tag")) : [],
    );
    setSelectedStampLocations(
      couponNotNull
        ? allSelected
          ? []
          : mapDataOfSelectedCoupon(
              activeCoupon.stamp_card_location_audiences,
              locations,
              "location",
              stampsLocationsSelected,
            )
        : [],
    );
    setLoyaltyCardUsersSelected(loyaltySelected);
    setStampCardUsersSelected(stampsLocationsSelected);
    setAllUsersSelected(allSelected);
    setIsRemoveVisible(couponNotNull);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCoupon]);

  useEffect(() => {
    let loyaltyUsersSelected =
      loyaltyCardUsersSelected && (selectedTags?.length > 0 || selectedLoyaltyCard?.length > 0);
    let stampUsersSelected = stampCardUsersSelected;
    let pushNotificationSelected = sendPushNotification
      ? scheduledAt.getHours() >= 8 && scheduledAt.getHours() < 20
      : sendPushNotification;

    let isSaveButtonEnabled =
      dateFrom !== null &&
      dateTo !== null &&
      scheduledAt !== null &&
      shortDescription.length > 0 &&
      longDescription.length > 0;

    // Check notification requirements
    isSaveButtonEnabled =
      pushNotificationSelected && (allUsersSelected || loyaltyUsersSelected || stampUsersSelected)
        ? pushNotificationTitle.length > 0 && pushNotificationBody.length > 0
        : isSaveButtonEnabled;

    setIsSaveEnable(isSaveButtonEnabled);
    handleAudienceCount();
  }, [
    dateFrom,
    dateTo,
    scheduledAt,
    shortDescription,
    longDescription,
    allUsersSelected,
    loyaltyCardUsersSelected,
    sendPushNotification,
    stampCardUsersSelected,
    selectedStampLocations,
    selectedLoyaltyCard,
    selectedTags,
    pushNotificationTitle,
    pushNotificationBody,
  ]);

  useEffect(() => {
    const ac = new AbortController();
    async function fetchTags() {
      try {
        let tags = await getTags();
        setTags(mapData(tags, "tag"));
      } catch (error) {
        errorToast({
          title: "Error",
          text: error.message,
        });
      }
    }
    async function fetchLoyaltyCards() {
      try {
        let cards = await getActiveCards();
        setCards(mapData(cards, "loyalty"));
      } catch (error) {
        if (error.code === 403) {
          setIsLoyaltyAvailable(false);
          return;
        }
        errorToast({
          title: "Error",
          text: error.message,
        });
      }
    }
    async function fetchLocations() {
      try {
        let locations = await getLocations();
        setLocations(mapData(locations, "location"));
      } catch (error) {
        errorToast({
          title: "Error",
          text: error.message,
        });
      }
    }
    const fetchgetpushNotificationAccess = async () => {
      try {
        const pushNotificationAccess = await getPushNotificationAccess();
        setPushNotificationAccess(pushNotificationAccess["push_notification_access"]);
      } catch (error) {
        errorToast({
          title: "Error",
          text: error.message,
        });
      }
    };
    fetchLoyaltyCards();
    fetchTags();
    fetchLocations();
    fetchgetpushNotificationAccess();
    return () => ac.abort();
  }, []);

  return (
    <>
      <AboutSection
        shortDescription={shortDescription}
        handleShortDescriptionChange={handleShortDescriptionChange}
        longDescription={longDescription}
        handleLongDescriptionChange={handleLongDescriptionChange}
        specifications={specifications}
        handleSpecificationsChange={handleSpecificationsChange}
        image={image}
        handleImageChange={handleImageChange}
        dateTo={dateTo}
        handleDateToChange={handleDateToChange}
        dateFrom={dateFrom}
        handleDateFromChange={handleDateFromChange}
        scheduledAt={scheduledAt}
        handleScheduledAtChange={handleScheduledAtChange}
        totalRedemptions={totalRedemptions}
        handleTotalRedemptionsChange={handleTotalRedemptionsChange}
        datesValidation={datesValidation}
        shortDescriptionErrorMessage={shortDescriptionErrorMessage}
        longDescriptionErrorMessage={longDescriptionErrorMessage}
        specificationsErrorMessage={specificationsErrorMessage}
      />
      <InviteSection
        tags={tags}
        cards={cards}
        locations={locations}
        selectedStampLocations={selectedStampLocations}
        setSelectedStampLocations={setSelectedStampLocations}
        selectedLoyaltyCard={selectedLoyaltyCard}
        setSelectedLoyaltyCard={setSelectedLoyaltyCard}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        stampCardUsersSelected={stampCardUsersSelected}
        setStampCardUsersSelected={setStampCardUsersSelected}
        allUsersSelected={allUsersSelected}
        setAllUsersSelected={setAllUsersSelected}
        loyaltyCardUsersSelected={loyaltyCardUsersSelected}
        setLoyaltyCardUsersSelected={setLoyaltyCardUsersSelected}
        isLoyaltyAvailable={isLoyaltyAvailable}
      />
      {pushNotificationAccess && (
        <NotificationsSection
          scheduledAt={scheduledAt}
          sendPushNotification={sendPushNotification}
          handleSendPushNotification={handleSendPushNotification}
          pushNotificationTitle={pushNotificationTitle}
          handlePushNotificationTitle={handlePushNotificationTitle}
          pushNotificationBody={pushNotificationBody}
          handlePushNotificationBody={handlePushNotificationBody}
          audienceCount={audienceCount}
        />
      )}
      <SaveSection
        pushNotificationAccess={pushNotificationAccess}
        isSaveEnabled={isSaveEnabled}
        createCoupon={createCoupon}
        isSaving={isSaving}
        isRemoveVisible={isRemoveVisible}
        activeCoupon={activeCoupon}
        handleCouponsUpdate={handleCouponsUpdate}
        handleCouponsRemove={handleCouponsRemove}
      />
    </>
  );
};
export default NewCouponSection;
