import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import CursorAnimation from "./CursorAnimation";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}));

const numberOfKeyframes = 60;

export default function TypewriterAnimation({ delay = 0, duration, text, className, cursorSize, onDone, color }) {
  const classes = useStyles();
  const count = useMotionValue(0);
  const [done, setDone] = useState(false);
  const rounded = useTransform(count, latest => Math.round((text.length * latest) / numberOfKeyframes));
  const displayText = useTransform(rounded, latest => text.slice(0, latest));
  const [latest, setLatest] = useState(0);

  useEffect(() => {
    animate(count, numberOfKeyframes, {
      type: "tween",
      delay,
      duration,
      ease: "easeIn",
      onUpdate: setLatest,
      onComplete: () => {
        setDone(true);
        onDone();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <motion.span className={className} style={{ color }}>
        {displayText}
      </motion.span>
      {latest > 0 && !done && <CursorAnimation width={cursorSize?.width} height={cursorSize?.height} color={color} />}
    </div>
  );
}
