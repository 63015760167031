import React, {createContext, useEffect, useReducer} from "react"

const initState = {
    location_id: null
}

let locationReducer = (prevState, newState) => {
    return {...prevState, ...newState}
}

const getData = () => {
    const localData = localStorage.getItem("location")
    if (localData != null) {
        return JSON.parse(localData)
    } else {
        localStorage.setItem("location", JSON.stringify(initState))
        return initState
    }
}

export const LocationContextProvider = props => {
    const [location, setLocation] = useReducer(locationReducer, getData())

    useEffect(() => {
        //console.log(location['location_id'] === "")
        localStorage.setItem("location", JSON.stringify(location))
    }, [location])

    return <LocationContext.Provider value={{ location, setLocation }}>{props.children}</LocationContext.Provider>
}

export const LocationContext = createContext()
