import React, { useState, useContext, useRef, useEffect } from "react";
import useStyles from "./styles";
import { Collapse, Zoom } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import QrCode from "../QrCode/QrCode";
import { errorPopup, errorToast } from "../../Notifications/Notifications";
import Lottie from "react-lottie";
import confettiAnimation from "./confetti.json";
import useSound from "use-sound";
import succesSound from "./ok.mp3";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";
import LanguageContext from "../../../store/LanguageContext";
import { LocationContext } from "../../../store/LocationContext";
import { postQRCode } from "../../../services/OneTimeQRCodesServices";
import { UserContext } from "../../../store/UserContext";
import { Redirect } from "react-router-dom";

const QR_CODE_VISIBLE_DURATION = 20000;
let disabled = false;

const Offer = props => {
  const { location } = useContext(LocationContext);
  const classes = useStyles(props);
  const { offer, size } = props;
  const [play, setPlay] = useState(false);
  const [preloadedCode, setPreloadedCode] = useState(null);
  const [renewCode, setRenewCode] = useState(false);
  const [expiredCode, setExpiredCode] = useState(false);
  const [playSound] = useSound(succesSound);
  const [loadingCode, setLoadingCode] = useState(false);
  const [code, setCode] = useState(false);
  const { state, setState } = useContext(UserContext);
  const [redirect, setRedirect] = useState();

  const { viewQrCode, setViewQrCode } = props;
  const [blocked, setBlock] = props.blockTouch;

  const strings = useContext(LanguageContext);
  const closeTimer = useRef();
  const subscriptionRef = useRef(null);

  const handleClose = () => {
    setViewQrCode(false);
    setLoadingCode(false);
    disabled = false;
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
    }
    if (subscriptionRef.current) {
      props.cableApp.subscriptions.remove(subscriptionRef.current);
      subscriptionRef.current = null;
    }
    props.setFade(true);
  };

  /*
    Preloading QR-code to speed up loading time.
    Since the 'preloadedCode' variable is later on used by the 'postQR' function
    we need a way to initially handle exceptions, but also show the user later on.
    Therefore the promise returns a function which then returns the data or throws
    the error. I.e. in 'postQR':

      const data = await preloadedCode;
      const { code, expires_at } = data();

    Immediately handling the exception might have unintended consequences for the user
    and so is not used at this time.
  */
  useEffect(() => {
    const preFetchCode = async () => {
      if (!props.offer.point_system) {
        const data = await postQRCode(props.offer.id, location);
        setPreloadedCode(data);
      }
    };
    preFetchCode();
  }, [renewCode]);

  /*
  The preloading doesn't renew until next react rendering and so the next call to 'postQR'
  can't happen until next rendering either. Hence the usage of the 'useEffect' below.
  */
  useEffect(() => {
    if (expiredCode) {
      postQR();
      setExpiredCode(false);
    }
  }, [expiredCode]);

  const postQR = async () => {
    try {
      let data;
      if (props.offer.point_system) {
        data = await postQRCode(props.offer.id, location, props.points);
      } else {
        data = preloadedCode;
      }
      const { code, expires_at } = data;
      const expiration_time = new Date(expires_at);
      if (expires_at && expiration_time < Date.now()) {
        setRenewCode(!renewCode);
        setExpiredCode(true);
        return;
      }
      setCode(code);
      setRenewCode(!renewCode);
      setLoadingCode(false);
      setViewQrCode(true);
      setupSocketListener(code);
      props.setFade(false);
      closeTimer.current = setTimeout(() => {
        handleClose();
      }, QR_CODE_VISIBLE_DURATION);
    } catch (error) {
      setRenewCode(!renewCode);
      setViewQrCode(false);
      setLoadingCode(false);
      disabled = false;
      if (error.code === 401) {
        setState({ authenticated: false, user: null });
        setRedirect(true);
        errorToast({
          title: strings.sessionExpired,
        });
      } else if (error.code === 410) {
        props.setLoadOffers(loadOffers => !loadOffers);
        errorPopup({
          title: strings.qrOfferExpired,
          text: strings.qrOfferExpiredLong,
          timer: 5000,
        });
      } else {
        errorPopup({
          title: strings.qrOfferError,
          text: strings.tryAgainNote,
          timer: 5000,
        });
      }
    }
  };

  const handleImgClick = () => {
    // Avoid multiple clicks
    if (disabled) {
      return;
    }
    disabled = true;
    // Show loader immediately
    setLoadingCode(true);
    postQR();
  };

  const setupSocketListener = qrCode => {
    subscriptionRef.current = props.cableApp.subscriptions.create(
      {
        channel: "OneTimeQrCodeChannel",
        code: qrCode,
      },
      {
        connected: () => {
          console.log("Socket connected");
        },
        disconnected: () => {
          console.log("Socket disconnected");
          setViewQrCode(false);
        },
        received: data => {
          if (data.success === true) {
            playSound();
            setPlay(true);
            setTimeout(() => {
              setPlay(false);
            }, 3000);
          } else {
            props.setLoadOffers(loadOffers => !loadOffers);
            errorPopup({ title: strings[data.code] });
          }
          disabled = false;
          setLoadingCode(false);
          setViewQrCode(false);
          props.setFade(true);
        },
      },
    );
  };

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {play ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            height: "100vh",
            display: "flex",
            zIndex: 100,
            alignItems: "center",
            left: 0,
            right: 0,
          }}
        >
          <Lottie
            options={{
              animationData: confettiAnimation,
              loop: false,
              rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
            }}
            autoplay
            height="100vh"
            width="100vw"
            speed={1.5}
          />
        </div>
      ) : null}
      <div className={classes.imgContainer}>
        <div
          className={classes.card}
          style={{
            transform: !viewQrCode ? "rotateX(180deg)" : "rotateX(360deg)",
          }}
        >
          {code ? <QrCode code={code} /> : null}
        </div>
        <div
          className={classes.imgCard}
          onTouchStart={handleImgClick}
          style={{
            transform: viewQrCode ? "rotateX(180deg)" : "rotateX(0deg)",
            filter: loadingCode && !viewQrCode ? "brightness(50%)" : "brightness(100%)",
            backgroundImage: `url(${props.offer.image})`,
          }}
        >
          <ButtonBase style={{ height: "100%", width: "100%" }} />Z
        </div>
        {loadingCode && !viewQrCode ? (
          <div
            style={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <div style={{ margin: "auto", width: "auto" }}>
              <CircularProgress color="secondary" />
            </div>
          </div>
        ) : null}
      </div>
      <div style={{ width: size }}>
        <Collapse
          in={viewQrCode}
          timeout={600}
          mountOnEnter
          unmountOnExit
          style={{ transitionDelay: viewQrCode ? 0 : 500 }}
        >
          <div>
            <Zoom in={viewQrCode} timeout={600} mountOnEnter unmountOnExit>
              <Typography
                style={{
                  transform: props.size === "40vw" ? "scale(0.75)" : "scale(1)",
                }}
                className={classes.scanToGetStamp}
              >
                {props.offer.point_system ? null : strings.scanToGetStamp}
              </Typography>
            </Zoom>
          </div>
        </Collapse>
        {offer.point_system ? (
          <>
            <Typography className={classes.tabletDescriptionPointSystem}>
              {viewQrCode ? "Skanna för att samla poäng" : offer.tablet_description}
            </Typography>
          </>
        ) : (
          <Typography
            style={{
              transform: `scale(${viewQrCode ? (props.size === "40vw" ? 0.5 : 0.6) : 1})`,
            }}
            className={classes.tabletDescription}
          >
            {offer.tablet_description}
          </Typography>
        )}
      </div>
      <Collapse in={viewQrCode} timeout={600} mountOnEnter unmountOnExit>
        <div>
          <Zoom in={viewQrCode} timeout={600} mountOnEnter unmountOnExit>
            <div
              style={{
                position: "absolute",
                margin: "auto",
                bottom: -100,
                left: 0,
                right: 0,
                width: 80,
                height: 80,
              }}
            >
              <Fab
                style={{
                  transform: props.size === "40vw" ? "scale(0.75)" : "scale(1)",
                }}
                onTouchStart={handleClose}
                onClick={handleClose}
              >
                <CloseIcon />
              </Fab>
            </div>
          </Zoom>
        </div>
      </Collapse>
    </>
  );
};

export default Offer;
