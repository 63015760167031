import React, { useState } from "react";
import FilterItem from "./FilterItem";

const TagBox = ({ card, currentTags, mappedCards, setMappedCards }) => {
  const [selectedTag, setSelectedTag] = useState(currentTags.find(element => element.label === card.tag));

  React.useEffect(() => {
    setSelectedTag(currentTags.find(element => element.label === card.tag));
  }, [currentTags, card.tag]);

  const handleTagChange = tag => {
    setSelectedTag(tag);
    const mappedCardsNew = mappedCards.map(element => {
      if (element.id === card.id) {
        element.tag = tag.label;
        element.isEdited = true;
      }
      return element;
    });
    setMappedCards(mappedCardsNew);
  };

  return (
    <FilterItem
      currentTags={currentTags}
      card={card}
      selectedTag={selectedTag}
      handleTagChange={handleTagChange}
      tagKey="table"
    />
  );
};

export default TagBox;
