import React, { useContext } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import backgroundImage from "../../../assets/images/licenseLoyalty.png";
import CountGrid from "../../../components/Shared/CountGrid";
import LanguageContext from "../../../store/LanguageContext";
import { ReactComponent as MWhite } from "../../../assets/icons/m-white.svg";
import { ReactComponent as LineWhite } from "../../../assets/icons/line-white.svg";

export const LoyaltyLicensePage = () => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);

  return (
    <>
      <div className={classes.root}>
      </div>
      <div className={classes.mainContainer}>
        <CountGrid
          labelTotal={strings.loyaltyCardsPage.invited}
          total={"-"}
          labelActive={strings.loyaltyCardsPage.interactions}
          active={"-"}
          labelDone={strings.loyaltyCardsPage.activeLoyaltyCards}
          done={"-"}
        />
        <div className={classes.contentBox}>
          <Typography className={classes.contentText}>&#8226; {strings.loyaltyCardsPage.designedCards}</Typography>
          <Typography className={classes.contentText}>&#8226; {strings.loyaltyCardsPage.simpleInvitation}</Typography>
          <Typography className={classes.contentText}>&#8226; {strings.loyaltyCardsPage.manageCustomers}</Typography>
        </div>
        <div className={classes.cardBox}>
          <div className={classes.companyImageBox}>
            <div className={classes.companyImageEmpty}></div>
          </div>
          <div className={classes.companyDetailsBox}>
            <Typography className={classes.companyName}>
              {strings.loyaltyCardsPage.companyNameDefault}
            </Typography>
            <Typography className={classes.description}>{strings.loyaltyCardsPage.shortDescriptionDefault}</Typography>
          </div>
          <div className={classes.dateBox}>
            <div className={classes.validBox}>
              <Typography className={classes.verifiedTitle}>{strings.loyaltyCardsPage.verifiedBy}</Typography>
              <div className={classes.logoBox} >
                <div className={classes.logo}>
                  <MWhite />
                </div>
              </div>
            </div>
          </div>
          <LineWhite />
        </div>
        <Typography className={classes.contactText}>{strings.loyaltyCardsPage.accessInstruction}</Typography>
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: 4036,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    marginBottom: 50,
    backgroundImage: `url(${backgroundImage})`,
    filter: "blur(6px)",
    WebkitFilter: "blur(6px)",
    opacity: 0.3
  },

  mainContainer: {
    position: "absolute",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    left: 0,
    top: window.innerWidth > 1200 ? 250 : 100,
  },

  contentBox:{
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 150
  },

  contentText: {
    fontFamily: "DMSans",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 500,
    color: "black",
    marginBottom: 15
  },

  contactText:{
    fontFamily: "DMSans",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "black",
    marginTop: 50,
    padding: "0px 20px"
  },

  cardBox: {
    height: 205,
    maxHeight: 205,
    margin: "20px 18px",
    flex: "0 0 327px",
    borderRadius: 10,
    backgroundColor: "#E0E0E0",
    boxShadow: "2px 3px 10px -2px rgba(0,0,0,0.82)",
    marginTop: 50
  },

  companyImageBox: {
    display: "flex",
    flex: 1,
    marginLeft: 20,
    marginTop: 20,
    height: 60,
    alignItems: "center",
    justifyItems: "flex-start",
  },

  companyImageEmpty: {
    borderRadius: 16,
    backgroundColor: "#FBFBFB",
    height: 50,
    width: 80,
  },

  companyDetailsBox: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
  },

  companyName: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: "DMSans",
    textAlign: "start",
    backgroundImage: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },

  description: {
    color: "#FBFBFB",
    fontSize: 12,
    fontFamily: "DMMono",
    textAlign: "start",
  },

  dateBox: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginLeft: 20,
    marginRight: 20,
    marginBottom: -110,
  },

  validBox: {
    display: "flex",
    flexDirection: "column",
  },

  verifiedTitle: {
    color: "#FBFBFB",
    fontSize: 6,
    fontFamily: "DMSans",
    fontWeight: 700,
  },

  logoBox: {
    display: "flex",
    height: 40,
    width: 40,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0.10000000149011612px 0.10000000149011612px 0px #00000080 inset",
    background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%)"
  },

  logo: {
    display: "flex",
    borderRadius: 8,
    height: 38,
    width: 38,
    alignItems: "center",
    justifyContent: "center",
    background: "#E0E0E0"
  },

}));
