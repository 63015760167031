import fetchHelper from "../lib/fetchHelper";

export const getOfferProvider = async () => {
  return fetchHelper("/api/v1/offer_providers/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const connectStripe = async () => {
  return fetchHelper("/api/dashboard/offer_providers/onboard_stripe", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
