import fetchHelper from "../lib/fetchHelper";

export const getStore = async () => {
  return fetchHelper("/api/dashboard/offer_provider/store", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getSubscription = async (id, page) => {
  return fetchHelper(`/api/dashboard/product_subscriptions/${id}/subscriptions?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateStoreSettings = async formData => {
  return fetchHelper("/api/dashboard/offer_provider/store_settings/", {
    method: "PATCH",
    body: formData,
  });
};
