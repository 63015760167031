import { useContext } from "react";
import LanguageContext from "../store/LanguageContext";

export const sortingOrders = {
  ASC: "asc",
  DESC: "desc",
};

export const roles = {
  ADMIN: "ADMIN",
  TABLET: "TABLET"
};

export const Months = () => {
  const strings = useContext(LanguageContext);
  let months =[
    { value: 1, label: strings.months.january },
    { value: 2, label: strings.months.february },
    { value: 3, label: strings.months.march },
    { value: 4, label: strings.months.april },
    { value: 5, label: strings.months.may },
    { value: 6, label: strings.months.june },
    { value: 7, label: strings.months.july },
    { value: 8, label: strings.months.august },
    { value: 9, label: strings.months.september },
    { value: 10, label: strings.months.october },
    { value: 11, label: strings.months.november },
    { value: 12, label: strings.months.december },
  ];
  return months;
};


