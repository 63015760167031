import React from "react";
import useStyles from "./styles";
import Dialog from "@material-ui/core/Dialog";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const SimpleDialog = props => {
  const classes = useStyles();
  const { onClose, open, transition, transitionDuration, children } = props;
  const handleClose = () => {
    onClose();
  };

  let paperClasses = [classes.paper];

  if (props.red) {
    paperClasses.push(classes.paperRed);
  }

  if (props.purple) {
    paperClasses.push(classes.paperPurple);
  }

  const classNames = {
    root: classes.root,
    paper: paperClasses.join(" "),
  };

  return (
    <Dialog
      classes={classNames}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: "rgba(32, 60, 83, 0.8)" } }}
      aria-labelledby="simple-dialog"
      open={open}
      TransitionComponent={transition ? transition : Fade}
      transitionDuration={transitionDuration ? transitionDuration : 200}
      style={props.style}
    >
      <div className={classes.container}>
        <IconButton
          aria-label="close"
          style={{ color: props.closeIconColor }}
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </div>
    </Dialog>
  );
};

export default SimpleDialog;
