import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Zoom,
} from "@material-ui/core";
import React, { useContext } from "react";
import LanguageContext from "../../store/LanguageContext";
import { MSButton } from "../MSButton";

const RemoveDialog = ({ open, setOpen, title, content, removeItem }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);

  return (
    <Dialog
      transitionDuration={400}
      transition={Zoom}
      onClose={() => setOpen(false)}
      open={open}
      PaperProps={{
        style: { borderRadius: 8 },
      }}
      style={{ padding: 20 }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ marginTop: 10 }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <MSButton type="secondary" className={classes.buttons} onClick={() => setOpen(false)}>
          {strings.loyaltyCardsPage.cancel}
        </MSButton>
        <MSButton type="primary" className={classes.buttons} onClick={removeItem}>
          {strings.loyaltyCardsPage.remove}
        </MSButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px 20px 20px 20px",
  },

  buttons: {
    padding: "10px 20px",
  },
});

export default RemoveDialog;
