import React, { useContext, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { addNewTag } from "../../services/LoyaltyCardsServices";
import { MSButton } from "../MSButton";
import EmailInvitation from "./Emailnvitation";
import CSVInvitation from "./CSVInvitation";
import LanguageContext from "../../store/LanguageContext";
import InputErrorBox from "../Shared/InputErrorBox";
import InputConfirmationBox from "../Shared/InputConfirmationBox";
import { errorToast } from "../Notifications/Notifications";

const InviteUsers = ({ activeCard, handleInviteSent, currentTags, handleNewTagAdded }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [newTag, setNewTag] = useState("");
  const [isTagAdded, setIsTagAdded] = useState(false);
  const [isTagAddedSuccessfully, setIsTagAddedSuccessfully] = useState(false);
  const [newTagValidation, setNewTagValidation] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const handleTagChange = event => {
    setNewTag(event.target.value);
    setNewTagValidation(event.target.value.length >= 0);
  };

  const addNewTagHandler = async () => {
    if (newTag.length === 0) {
      setNewTagValidation(false);
      return;
    }
    if (currentTags.filter(tag => tag.label.toLowerCase() === newTag.toLowerCase()).length > 0) {
      setIsTagAdded(true);
      setIsTagAddedSuccessfully(false);
      setErrorMessage(strings.loyaltyCardsPage.tagExists);
      setTimeout(() => {
        setErrorMessage("");
        setIsTagAdded(false);
      }, 5000);
      return;
    }
    let formData = new FormData();
    formData.append("label", newTag);
    try {
      await addNewTag(formData);
      setNewTag("");
      setIsTagAddedSuccessfully(true);
      setIsTagAdded(true);
      setTimeout(() => {
        setIsTagAdded(false);
      }, 5000);
      handleNewTagAdded();
    } catch (error) {
      errorToast({
        title: "Error",
        text: error.message,
      });
      setIsTagAdded(true);
      setTimeout(() => {
        setIsTagAdded(false);
      }, 5000);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.inviteContainer}>
        <div className={classes.emailContainer}>
          <div className={classes.emailTitleBox}>
            <Typography className={classes.title}>{strings.loyaltyCardsPage.inviteEmailTitle}</Typography>
          </div>
          <EmailInvitation activeCard={activeCard} currentTags={currentTags} handleInviteSent={handleInviteSent} />
        </div>
        <div className={classes.csvContainer}>
          <div className={classes.csvTitleBox}>
            <Typography className={classes.title}>{strings.loyaltyCardsPage.inviteCSVTitle}</Typography>
          </div>
          <CSVInvitation activeCard={activeCard} currentTags={currentTags} handleInviteSent={handleInviteSent} />
        </div>
      </div>
      <div className={classes.tagTitleBox}>
        <Typography className={classes.title}>{strings.loyaltyCardsPage.addNewTag}</Typography>
      </div>
      <div className={classes.newTagContainer}>
        <div className={classes.tagBox}>
          <Typography className={classes.inputTitle}>{strings.loyaltyCardsPage.tag}</Typography>
          <input
            placeholder={"Ex. Gothia"}
            className={classes.tagInput}
            type="text"
            value={newTag}
            onChange={handleTagChange}
          />
          {!newTagValidation ? <InputErrorBox message={strings.loyaltyCardsPage.tagRequired} /> : null}
          <MSButton type="secondary" className={classes.addButtonBox} onClick={addNewTagHandler}>
            {strings.loyaltyCardsPage.add}
          </MSButton>
          {isTagAdded ? (
            isTagAddedSuccessfully ? (
              <InputConfirmationBox message={strings.loyaltyCardsPage.taggingSuccessful} />
            ) : (
              <InputErrorBox
                message={errorMessage.length > 0 ? errorMessage : strings.loyaltyCardsPage.taggingUnsuccessful}
              />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginLeft: 20,
    marginRight: 20,
    textAlign: "start",
  },

  inviteContainer: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    paddingTop: 10,
    paddingBottom: 30,
    flexDirection: "row",
    flexWrap: "wrap",
  },

  emailContainer: {
    display: "flex",
    flex: 0.5,
    flexGrow: 1,
    minWidth: 300,
    flexDirection: "column",
    backgroundImage: window.innerWidth > 1200 ? "linear-gradient(#C4C4C4 43%, rgba(255,255,255,0) 0%)" : "",
    backgroundPosition: "right",
    backgroundSize: "2px 12px",
    backgroundRepeat: "repeat-y",
    marginRight: window.innerWidth > 1200 ? 50 : 0,
  },

  tagInput: {
    width: "45%",
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    height: 47,
    fontFamily: "DMSans",
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 8,
    resize: "none",
    padding: "5px 10px",
    "&::placeholder": {
      color: "#33333380",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
  },

  inputTitle: {
    height: 35,
    fontFamily: "DMSans",
    fontSize: 20,
    fontWeight: 500,
    color: "#333333",
    marginRight: 10,
    paddingBottom: 5,
    alignSelf: "flex-start",
  },

  emailTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  tagTitleBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
  },

  title: {
    fontFamily: "DMSans",
    fontSize: 24,
    fontWeight: 700,
    color: "#333333",
  },

  csvContainer: {
    flex: 0.5,
    flexGrow: 1,
    minWidth: 300,
    display: "flex",
    flexDirection: "column",
  },

  csvTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  newTagContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginBottom: 40,
    marginRight: 50,
  },

  tagBox: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },

  addButtonBox: {
    display: "flex",
    alignItems: "center",
    boxShadow: "1px 2px 4px 0px #00000040",
    justifyContent: "center",
    border: "0.5px solid #C4C4C4",
    height: "50px",
    cursor: "pointer",
    width: "15%",
    marginTop: 30,
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 500,
    borderRadius: 38,
    marginBottom: 5,
    "&:hover": {
      backgroundColor: "#EDB5A3",
      color: "white",
    },
  },
});

export default InviteUsers;
