import fetchHelper from "../lib/fetchHelper";

export const getCompanyDetails = async () => {
  return fetchHelper("api/dashboard/offer_providers", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
