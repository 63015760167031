import React, { useState } from "react";
import { StampCardsStatisticsSection } from "../../components/StampCardsStatisticsSection";
import { StampCardsActiveOffersSection } from "../../components/StampCardsActiveOffersSection";
import { StampCardsCreateStampSection } from "../../components/StampCardsCreateStampSection";
import { StampCardsClaimsSection } from "../../components/StampCardsClaimsSection";
import { useStampCardPageStyles } from "./StampCardPageStyles";
import { StampLicensePage } from "./StampLicensePage/StampLicensePage";
import { PageWrapper } from "../../components/Shared/PageWrapper";
import { Spacing } from "../../components/Spacing";
import { Collapse } from "@material-ui/core";

export const StampCardPage = () => {
  const classes = useStampCardPageStyles();
  const [hasStampCardAccess, setHasStampCardAccess] = useState(true);
  const [selectedOffer, setSelectedOffer] = useState({});

  return hasStampCardAccess ? (
    <PageWrapper>
      <main className={classes.root}>
        <StampCardsStatisticsSection setHasStampCardAccess={setHasStampCardAccess} />
        <Spacing height={70} />
        <StampCardsActiveOffersSection
          setHasStampCardAccess={setHasStampCardAccess}
          setSelectedOffer={setSelectedOffer}
          selectedOffer={selectedOffer}
        />
        <Collapse in={"id" in selectedOffer}>
          <Spacing height={50} />
          <StampCardsCreateStampSection selectedOffer={selectedOffer} />
        </Collapse>
        <Spacing height={70} />
        <hr className={classes.hrLine} />
        <Spacing height={70} />
        <StampCardsClaimsSection setHasStampCardAccess={setHasStampCardAccess} />
      </main>
    </PageWrapper>
  ) : (
    <StampLicensePage />
  );
};
