import fetchHelper from "../lib/fetchHelper";

export const transactionWebhook = async formData => {
  return fetchHelper("api/webhooks/transaction", {
    method: "POST",
    body: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const checkoutWebhook = async formData => {
  return fetchHelper("api/webhooks/order", {
    method: "POST",
    body: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const redisWebhook = async formData => {
  return fetchHelper("api/webhooks/redis", {
    method: "POST",
    body: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
