import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { NavigationRoutes } from "../../routes/navigation";
import { useNavigationStyles } from "./MSNavigationStyles";
import LanguageContext from "../../store/LanguageContext";

export const MSNavigation = () => {
  const classes = useNavigationStyles();
  const strings = useContext(LanguageContext);

  const routes = [
    {
      path: NavigationRoutes.OVERVIEW,
      label: strings.navigationBar.overview,
    },
    {
      path: NavigationRoutes.STAMP_CARD,
      label: strings.navigationBar.stampCard,
    },
    {
      path: NavigationRoutes.LOYALTY_CARD,
      label: strings.navigationBar.loyaltyCard,
    },
    {
      path: NavigationRoutes.COUPONS,
      label: strings.navigationBar.coupons,
    },
    {
      path: NavigationRoutes.STORE,
      label: strings.navigationBar.store,
    },
    {
      path: NavigationRoutes.RETURNS,
      label: strings.navigationBar.returns,
    },
    /* {
      path: NavigationRoutes.GIFT_CARDS,
      label: "Presentkort",
    },
    {
      path: NavigationRoutes.DEALS,
      label: "Deals",
    }, */
  ];

  return (
    <nav className={classes.root}>
      {routes.map(({ label, path }) => (
        <NavLink key={path} to={path} className={classes.navLink} activeClassName="is-active">
          {label}
        </NavLink>
      ))}
    </nav>
  );
};
