import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

const Spinner = () => {
  const classes = useStyles();

  return (
    <div className={classes.loaderWrapper}>
      <div className={classes.loader}>
        <CircularProgress color="primary" />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  loaderWrapper: {
    position: "fixed",
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "rgba(255,255,255,0)",
    zIndex: 9999,
  },
  loader: {
    position: "fixed",
    left: "calc(50% - 20px)",
    top: "calc(50% - 20px)",
  },
});

export default Spinner;
