import React, { useContext, useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { MSTable } from "../MSTable";
import { MSButton } from "../MSButton";
import { deleteLoyaltyCard, getCardDetails, updateLoyaltyCard } from "../../services/LoyaltyCardsServices";
import LanguageContext from "../../store/LanguageContext";
import ExtendBox from "./ExtendBox";
import TableFilter from "./TableFilter";
import TagBox from "./TagBox";
import SaveIcon from "@material-ui/icons/Save";
import { errorToast } from "../Notifications/Notifications";
import CheckIcon from "@material-ui/icons/Check";
import CopyLinkButton from "./CopyLinkButton";

const CustomersTable = ({ activeCard, isInvited, currentTags, handleTableUpdate, isDataModified }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [cards, setCards] = useState(null);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [cardsFinal, setCardsFinal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [filterOptions, setFilterOptions] = useState({ tag_id: "", email: "" });

  const getPageNumber = (paginationData, removedCount) => {
    if ((paginationData.count - removedCount) % 10 === 0 && paginationData.count > 0) {
      if (paginationData.count - removedCount === 0 || paginationData.page === 1) {
        return 1;
      }
      return paginationData.page - 1;
    }
    return paginationData.page;
  };

  const updateData = React.useCallback(
    async (card, paginationData) => {
      setIsLoading(true);
      if (card.isRemoved) {
        try {
          await deleteLoyaltyCard(card.id);
          setPage(getPageNumber(paginationData, 1));
          handleTableUpdate();
        } catch (error) {
          errorToast({
            title: "Error",
            text: error.message,
          });
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
        return;
      }
      if (card.isEdited || card.isExtended) {
        let newTag = currentTags.find(el => el.label === card.tag);
        let formData = new FormData();
        formData.append("invite_email", card.email);
        formData.append("valid_from", new Date(card.startDate));
        formData.append("expires_at", new Date(card.endDate));
        formData.append("tag_id", newTag !== undefined ? newTag.id : null);
        try {
          await updateLoyaltyCard(card.id, formData);
          handleTableUpdate();
        } catch (error) {
          errorToast({
            title: "Error",
            text: error.message,
          });
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    },
    [currentTags, handleTableUpdate],
  );

  const saveAll = async () => {
    setIsSaving(true);
    await Promise.all(
      cards.map(async element => {
        if (element.isRemoved) {
          try {
            await deleteLoyaltyCard(element.id);
            setPage(getPageNumber(pagination, cards.filter(el => el.isRemoved === true).length));
            handleTableUpdate();
          } catch (error) {
            errorToast({
              title: "Error",
              text: error.message,
            });
          }
        }
        if (element.isEdited || (element.isExtended && !element.isRemoved)) {
          let newTag = currentTags.find(el => el.label === element.tag);
          let formData = new FormData();
          formData.append("invite_email", element.email);
          formData.append("valid_from", new Date(element.startDate));
          formData.append("expires_at", new Date(element.endDate));
          formData.append("tag_id", newTag !== undefined ? newTag.id : null);
          try {
            await updateLoyaltyCard(element.id, formData);
            handleTableUpdate();
          } catch (error) {
            errorToast({
              title: "Error",
              text: error.message,
            });
          }
        }
        return element;
      }),
    );
    setTimeout(() => {
      setIsSaving(false);
    }, 500);
  };

  const saveButton = React.useCallback(
    (card, paginationData) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            cursor: isLoading ? "wait" : card.isEdited || card.isRemoved || card.isExtended ? "pointer" : "auto",
          }}
          onClick={() => updateData(card, paginationData)}
        >
          <SaveIcon
            width={18}
            height={18}
            style={{ fill: card.isEdited || card.isRemoved || card.isExtended ? "#4EA54C" : "#CFCFCF" }}
          />
        </div>
      );
    },
    [isLoading, updateData],
  );

  const removeButton = React.useCallback(
    card => {
      const removeRow = () => {
        const mappedCardsNew = cards.map(element => {
          if (element.id === card.id) {
            element.isRemoved = !element.isRemoved;
          }
          return element;
        });
        setCards(mappedCardsNew);
      };
      return (
        <div className={classes.removeButtonContainer}>
          <MSButton
            type={card.isRemoved ? "primary" : "secondary"}
            className={classes.tableButtonBox}
            onClick={removeRow}
          >
            {!card.isRemoved ? strings.loyaltyCardsPage.remove : strings.loyaltyCardsPage.cancel}
          </MSButton>
        </div>
      );
    },
    [
      cards,
      classes.removeButtonContainer,
      classes.tableButtonBox,
      strings.loyaltyCardsPage.cancel,
      strings.loyaltyCardsPage.remove,
    ],
  );

  async function fetchData(pageNumber = 1) {
    if (!activeCard.id.toString().includes("empty_")) {
      try {
        let data = await getCardDetails(activeCard.id, pageNumber, filterOptions.tag_id, filterOptions.email);

        setPagination(data.pagination);
        const mappedCards = data.data.map(card => {
          const cardItem = {
            id: card.id,
            email: card.invite_email,
            tag: card.tag?.label,
            startDate: new Date(card.valid_from).toLocaleDateString(),
            endDateValue: card.expires_at,
            endDate: new Date(card.expires_at).toLocaleDateString(),
            inviteSentAt: card.invite_sent_at == null ? "" : new Date(card.invite_sent_at).toLocaleString(),
            invitedLink: card.deeplink,
            userRetrievedCard: card.user_retrieved_card,
            isEdited: false,
            isRemoved: false,
            isExtended: false,
          };
          return cardItem;
        });
        setCards(mappedCards);
      } catch (error) {
        errorToast({
          title: "Error",
          text: error.message,
        });
      }
    } else {
      setCards(null);
      pagination.count = 0;
      setPagination(pagination);
    }
  }

  const handleChangePage = (_, newPage) => {
    if (newPage !== null) {
      setPage(newPage);
      fetchData(newPage);
    }
  };

  useEffect(() => {
    setPage(1);
    fetchData();
    return () => {
      setCards([]);
    };
  }, [activeCard]);

  useEffect(() => {
    fetchData(page);
  }, [filterOptions, isInvited, isDataModified]);

  useEffect(() => {
    if (activeCard.id.toString().includes("empty_") === false && cards) {
      const mappedCardsFinal = cards.map(card => {
        const cardItem = {
          id: card.id,
          email: card.email,
          tag: <TagBox card={card} currentTags={currentTags} mappedCards={cards} setMappedCards={setCards} />,
          startDate: card.startDate,
          endDate: card.isExtended ? ">" + card.endDate : card.endDate,
          inviteSentAt: card.inviteSentAt,
          invitedLink: <CopyLinkButton link={card.invitedLink} />,
          userRetrievedCard: card.userRetrievedCard && (
            <CheckIcon style={{ opacity: card.isRemoved ? 0.3 : 1, color: "green" }} />
          ),
          isEdited: card.isEdited,
          isRemoved: card.isRemoved,
          isExtended: card.isExtended,
          extend: <ExtendBox card={card} mappedCards={cards} setMappedCards={setCards} />,
          save: saveButton(card, pagination),
          remove: removeButton(card),
        };
        return cardItem;
      });
      setCardsFinal(mappedCardsFinal);
    } else {
      setCardsFinal([]);
    }
  }, [cards, activeCard.id, currentTags, removeButton, saveButton]);

  const tableColumns = [
    { id: "id", label: strings.loyaltyCardsPage.id, align: "left", sortVisible: true },
    { id: "email", label: strings.loyaltyCardsPage.email, align: "left", sortVisible: true },
    { id: "tag", label: strings.loyaltyCardsPage.tag, align: "left", sortVisible: true },
    { id: "startDate", label: strings.loyaltyCardsPage.start, align: "left", sortVisible: true },
    { id: "endDate", label: strings.loyaltyCardsPage.end, align: "left", sortVisible: true },
    { id: "extend", label: strings.loyaltyCardsPage.extend, align: "left", sortVisible: false },
    { id: "inviteSentAt", label: strings.loyaltyCardsPage.invitedAt, align: "left", sortVisible: false },
    { id: "invitedLink", label: strings.loyaltyCardsPage.invitedLink, align: "left", sortVisible: false },
    { id: "userRetrievedCard", label: strings.loyaltyCardsPage.retrievedCard, align: "center", sortVisible: false },
    { id: "save", label: strings.loyaltyCardsPage.save, align: "left", sortVisible: false },
    { id: "remove", label: strings.loyaltyCardsPage.remove, align: "left", sortVisible: false },
    { id: "isEdited" },
    { id: "isRemoved" },
    { id: "isExtended" },
  ];

  return (
    <div className={classes.mainContainer}>
      {cardsFinal !== null && (
        <div className={classes.tableTitleBox}>
          <Typography className={classes.tableTitle}>{strings.loyaltyCardsPage.tableTitle}</Typography>
          <div className={classes.inviteBox}>
            <TableFilter currentTags={currentTags} setFilterOptions={setFilterOptions} />
          </div>
        </div>
      )}
      {cardsFinal !== null && pagination !== null && (
        <MSTable
          columns={tableColumns}
          rows={cardsFinal}
          isSaving={isSaving}
          saveAll={saveAll}
          pagination={pagination}
          rowsPerPage={10}
          customHandleChangePage={handleChangePage}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },

  tableTitleBox: {
    display: "flex",
    minHeight: 20,
    width: "100%",
    flexDirection: "row",
    marginBottom: 15,
    justifyContent: "space-between",
    alignItems: "center",
  },

  tableTitle: {
    fontFamily: "DMSans",
    fontSize: window.innerWidth > 1200 ? 28 : 20,
    fontWeight: 700,
    color: "#333333",
  },

  inviteBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  tableButtonBox: {
    height: 30,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
    boxShadow: "0px 1px 2px 0px #00000040",
    border: "0.5px solid #C4C4C4",
    borderRadius: 38,
    padding: "0px 10px",
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#EDB5A3",
      color: "white",
    },
  },

  removeButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    minWidth: 100,
  },
});

export default CustomersTable;
