import { makeStyles } from "@material-ui/core/styles";

export const useHeaderStyles = makeStyles(({ palette }) => ({
  root: {
    zIndex: 2,
    width: "100%",
    height: 60,
    background: palette.primary.main,
    display: "flex",
    paddingLeft: 30,
    paddingRight: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
  img: {
    height: 27,
  },
  loggedUser: {
    fontSize: 20,
    fontFamily: "DMSans",
    fontWeight: 500,
    color: "#FFFFFF",
    marginRight: 15,
  },
  logoutBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  logoutImg: {
    cursor: "pointer",
  },
}));
