import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import DemoStep from "../../components/Demo/DemoStep";
import { checkoutWebhook, redisWebhook, transactionWebhook } from "../../services/DemoServices";
import LanguageContext from "../../store/LanguageContext";

export const DemoPage = () => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [orderNumber, setOrderNumber] = useState(0);
  const [cardHash, setCardHash] = useState("hash01");
  const [disableTransaction, setDisableTransaction] = useState(true);

  // Clear redis data related to order_numbers before closing demo
  useEffect(() => {
    const handleTabClose = async event => {
      event.preventDefault();
      const data = {
        order_number: 100, // Arbritrary large number
      };
      try {
        await redisWebhook(JSON.stringify(data));
      } catch (error) {
        console.error(error);
      }
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const handleCardHashChange = event => {
    setCardHash(event.target.value);
  };

  const performTransaction = async () => {
    const data = {
      id: 1,
      order_number: orderNumber,
      checkout_system_id: "asd",
      timestamp: "ThisIsTime",
      amount: 65,
      card_hash: cardHash,
    };
    try {
      await transactionWebhook(JSON.stringify(data));
      setDisableTransaction(true);
    } catch (error) {
      console.error(error);
    }
  };

  const performCheckout = async () => {
    const data = {
      id: 1,
      order_number: orderNumber + 1,
      checkout_system_id: "asd",
      timestamp: "ThisIsTime",
      currency: "SEK",
      products: [
        {
          id: 1,
          name: "Choclate bar",
          price: 20,
          count: 2,
        },
        {
          id: 2,
          name: "Lollipop",
          price: 5,
          count: 1,
        },
        {
          id: 3,
          name: "Gummy bears",
          price: 20,
          count: 1,
        },
      ],
    };

    try {
      await checkoutWebhook(JSON.stringify(data));
      setOrderNumber(orderNumber + 1);
      setDisableTransaction(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.demoContainer}>
      <Container>
        <Typography className={classes.demoTitle}>Checkout demo</Typography>
        <Typography className={classes.cardInputTitle}>Enter card hash (must be unique per user)</Typography>
        <input
          placeholder={"hash01"}
          className={classes.cardInput}
          type="text"
          value={cardHash}
          onChange={handleCardHashChange}
        />
        <DemoStep
          title={`${strings.demoStep} 1`}
          body={strings.demoStepBody1}
          buttonText={strings.demoStepButton1}
          onClick={performCheckout}
        />
        <DemoStep
          title={`${strings.demoStep} 2`}
          body={strings.demoStepBody2}
          buttonText={strings.demoStepButton2}
          onClick={performTransaction}
          disabled={disableTransaction}
        />
      </Container>
    </div>
  );
};

const useStyles = makeStyles(({ palette, typography }) => ({
  demoContainer: {
    position: "absolute",
    inset: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    // justifyContent: "center",
    backgroundColor: palette.primary.main,
  },
  demoTitle: {
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 64,
    color: typography.white,
    margin: 32,
  },
  cardInputTitle: {
    fontFamily: "DMSans",
    fontSize: 16,
  },
  cardInput: {
    width: "100%",
    maxWidth: 500,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    height: 47,
    fontFamily: "DMSans",
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 8,
    resize: "none",
    padding: "5px 10px",
    "&::placeholder": {
      color: "#33333380",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
  },
}));
