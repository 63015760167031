import React, { useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { Button, TextField, Typography, Link, Container } from "@material-ui/core";

import useStyles from "./styles";
import { UserContext } from "../../store/UserContext";
import Fade from "@material-ui/core/Fade";
import { errorToast } from "../../components/Notifications/Notifications";
import LanguageContext from "../../store/LanguageContext";
import CompanyLayout from "../../CompanyLayout/CompanyLayout";
import { roles } from "../../lib/constants";
import { signInCompany } from "../../services/SignInServices";
import { CompanyContext } from "../../store/CompanyContext";

export const SignInCompany = () => {
  const classes = useStyles();
  const { state, setState } = useContext(UserContext);
  const { setCompany } = useContext(CompanyContext);
  const [redirect, setRedirect] = useState(false);
  const [fade, setFade] = useState(true);
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();

  const strings = useContext(LanguageContext);

  useEffect(() => {
    setCompany({ company: null });
  }, [setCompany]);

  const handleChange = (value, target) => {
    switch (target) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    let formData = new FormData();
    if (email) {
      formData.append("email", email);
    }
    if (password) {
      formData.append("password", password);
    }
    try {
      let result = await signInCompany(formData);
      setFade(false);
      setTimeout(() => {
        setState({ authenticated: true, user: result, role: roles.ADMIN });
        setRedirect(true);
      }, 600);
    } catch (error) {
      if (error.message === "This account does not have an associated company") {
        errorToast({
          title: strings.noAssociatedCompany,
          text: strings.contactAdministrator,
        });
      } else if(error.message === "Wrong email or password") {
        errorToast({
          title: strings.wrongUserCredentials,
          text: strings.tryAgain,
        });
      } else if(error.message === "No email provided") {
        errorToast({
          title: strings.noEmailProvided,
          text: strings.tryAgain,
        });
      } else {
        errorToast({
          title: strings.somethingWrong,
          text: strings.tryAgainNote,
        });
      }
    }
  };
  const signInCompanyPage = (
    <Fade in={fade} timeout={600} mountOnEnter unmountOnExit>
      <div style={{ marginTop: -50, width: "100%" }}>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <form className={classes.form} noValidate onSubmit={event => handleSubmit(event)}>
              <TextField
                onChange={event => handleChange(event.target.value, "email")}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                onChange={event => handleChange(event.target.value, "password")}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                style={{
                  backgroundColor: "#FFF",
                  color: "#CC7F66",
                  borderRadius: 60,
                  padding: "16px 24px",
                  //padding: "10px 20px 10px 20px"
                }}
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {strings.signIn}
              </Button>
            </form>
            <Typography style={{ display: "flex", flexDirection: "column" }}>
              <Link
                className={classes.link}
                onClick={() => window.location.replace("/offer_provider_admin_password_resets/new")}
              >
                {strings.forgotPassword}
              </Link>
              <Link className={classes.link} onClick={() => window.location.replace("/sign_in")}>
                {strings.companyPortalLoginPage.goToTablet}
              </Link>
            </Typography>
          </div>
        </Container>
      </div>
    </Fade>
  );

  return redirect  || (state.role === roles.ADMIN && state.authenticated) ? <Redirect to="/overview" /> : <CompanyLayout>{signInCompanyPage}</CompanyLayout>;
};
