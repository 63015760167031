import React, { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import StampsGraph from "./StampsGraph";
import StampCardsGraph from "./StampCardsGraph";
import LanguageContext from "../../store/LanguageContext";

const Statistics = props => {
  const strings = useContext(LanguageContext);

  const stampsLocation = props.location.value
    ? `${strings.stampCardPage.stamps} (${props.location.label})`
    : strings.stampCardPage.stamps;

  return (
    <Grid item xs={12}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontSize: 24,
              marginTop: 24,
              marginBottom: 24,
              textAlign: "left",
            }}
          >
            {stampsLocation}
          </Typography>
          <StampsGraph data={props.data}></StampsGraph>
        </Grid>
        {props.hasStampOffer && (
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontSize: 24,
                marginTop: 24,
                marginBottom: 24,
                textAlign: "left",
              }}
            >
              {strings.stampCardPage.claimedStampCards}
            </Typography>
            <StampCardsGraph data={props.data}></StampCardsGraph>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Statistics;
