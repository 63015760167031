import React, { useEffect, useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import LanguageContext from "../../store/LanguageContext";
import { errorToast } from "../Notifications/Notifications";
import { useStampCardsActiveOffersSectionStyles } from "./StampCardsActiveOffersSectionStyles";
import { getActiveOffers } from "../../services/OffersServices";
import Spinner from "../../components/Shared/Spinner";
import { StampCard } from "../StampCard";
import { Grid } from "@material-ui/core";
import { Spacing } from "../Spacing";

export const StampCardsActiveOffersSection = ({ setHasStampCardAccess, setSelectedOffer, selectedOffer }) => {
  const classes = useStampCardsActiveOffersSectionStyles();
  const strings = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(true);
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const ac = new AbortController();
    async function fetchData() {
      try {
        const result = await getActiveOffers();
        setOffers(result.data);
      } catch (error) {
        if (error.code === 403) {
          setHasStampCardAccess(false);
        } else {
          errorToast({
            title: "Error",
            text: error.message,
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
    return () => ac.abort();
  }, [setHasStampCardAccess]);

  return isLoading ? (
    <Spinner />
  ) : (
    <section>
      <Typography component="h1" variant="h5" className={classes.active}>
        {strings.stampCardPage.active}
      </Typography>
      <Spacing height={20} />
      <Grid container alignItems="center" spacing={4}>
        {offers.map((offer, index) => (
          <Grid item key={offer.id + index}>
            <StampCard
              onClick={() => setSelectedOffer(offer)}
              image={offer.image}
              title={offer.short_description}
              numberOfStamps={offer.nb_of_stamps_needed}
              selected={offer.id === selectedOffer.id}
              pointSystem={offer.point_system}
            />
          </Grid>
        ))}
      </Grid>
    </section>
  );
};
