import React, { createContext, useEffect, useReducer } from "react";

// If changing this format or behaviour, please make sure the device sign in flow remains compatible
// See app/views/sessions/devices/success.html.erb for details.

const initState = {
  authenticated: false,
  user: null,
  bearer_token: null,
};

let userReducer = (prevState, newState) => {
  let reducedState = { ...prevState, ...newState };
  return reducedState;
};

const getData = () => {
  const localData = localStorage.getItem("user");
  if (localData !== null && localData !== undefined) {
    return JSON.parse(localData);
  }
  localStorage.setItem("user", JSON.stringify(initState));
  return initState;
};

export const UserContextProvider = props => {
  const [state, setState] = useReducer(userReducer, getData());

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state));
  }, [state]);

  return <UserContext.Provider value={{ state, setState }}>{props.children}</UserContext.Provider>;
};

export const UserContext = createContext();
