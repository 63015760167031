import { makeStyles } from "@material-ui/core";
import React from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const NewCouponTemplate = ({ onEmptyCouponClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.couponBox} onClick={onEmptyCouponClick}>
      <div className={classes.content}>
        <AddCircleOutlineIcon className={classes.icon} />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  couponBox: {
    height: 180,
    margin: "20px 30px 20px 0px",
    flex: "0 0 490px",
    maxWidth: 290,
    borderRadius: 8,
    cursor: isCreated => (!isCreated ? "default" : "pointer"),
    backgroundColor: "#F0F0F0",
    boxShadow: "inset 2px 2px 2px rgba(0, 0, 0, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25)",
    fitler: "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))",
    "&::before": {
      content: "''",
      display: "block",
      height: 48,
      width: 24,
      marginRight: 290,
      marginTop: 66,
      backgroundColor: isCreated => (!isCreated ? "#F0F0F0" : "#fafafa"),
      borderRight: "2.5px solid #C4C4C4",
      borderTop: "2.5px solid #C4C4C4",
      borderBottom: "2.5px solid #C4C4C4",
      borderTopRightRadius: 50,
      borderBottomRightRadius: 50,
    },
    "&::after": {
      content: "''",
      display: "block",
      height: 48,
      width: 24,
      marginLeft: 266,
      marginTop: -115,
      backgroundColor: isCreated => (!isCreated ? "#F0F0F0" : "#fafafa"),
      borderLeft: "2.5px solid #C4C4C4",
      borderTop: "2.5px solid #C4C4C4",
      borderBottom: "2.5px solid #C4C4C4",
      borderTopLeftRadius: 50,
      borderBottomLeftRadius: 50,
    },
  },

  content: {
    display: "flex",
    flexDirection: "row",
    height: 180,
    width: 230,
    marginTop: -113,
    justifyContent: "center",
    alignItems: "center",
  },

  icon: {
    width: 80,
    height: 80,
    color: "#C4C4C4",
  },
});

export default NewCouponTemplate;
