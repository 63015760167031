import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { removeCoupon, removeEventCoupon } from "../../services/CouponsServices";
import LanguageContext from "../../store/LanguageContext";
import { MSButton } from "../MSButton";
import { errorToast } from "../Notifications/Notifications";
import { InputWraper } from "../Shared/InputWrapper";
import RemoveDialog from "../Shared/RemoveDialog";

const SaveSection = ({
  event,
  pushNotificationAccess,
  isSaveEnabled,
  createCoupon,
  isSaving,
  isRemoveVisible,
  activeCoupon,
  handleCouponsRemove,
  isEventCoupon = false,
}) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const getLabelNumber = () => {
    if (isEventCoupon) {
      if (pushNotificationAccess && event === "not_stamped_for_x_days") {
        return "3. ";
      }
      return "2. ";
    }
    if (pushNotificationAccess) {
      return "4. ";
    }
    return "3. ";
  };

  const removeSelectedCoupon = async () => {
    setIsRemoving(true);
    try {
      if (isEventCoupon) {
        await removeEventCoupon(activeCoupon.id);
      } else {
        await removeCoupon(activeCoupon.id);
      }
      setIsDialogOpen(false);
      handleCouponsRemove(activeCoupon);
    } catch (error) {
      if (error.code === 400) {
        return;
      }
      errorToast({
        title: "Error",
        text: error.message,
      });
    }
    setTimeout(() => {
      setIsRemoving(false);
    }, 1000);
  };

  return (
    <InputWraper title={getLabelNumber() + strings.couponsPage.saveHeader}>
      <div className={classes.content}>
        <MSButton
          type={"primary"}
          className={isSaveEnabled ? classes.enabled : classes.disabled}
          onClick={createCoupon}
          disabled={isSaving}
        >
          {isSaving ? <CircularProgress color="primary" size={20} /> : strings.couponsPage.saveHeader}
        </MSButton>
        {isRemoveVisible && (
          <>
            <MSButton type={"primary"} className={classes.enabled} onClick={() => setIsDialogOpen(true)}>
              {isRemoving ? <CircularProgress color="primary" size={20} /> : "Remove"}
            </MSButton>
            <RemoveDialog
              open={isDialogOpen}
              setOpen={setIsDialogOpen}
              title={strings.couponsPage.removeDialogTitle}
              content={strings.couponsPage.removeDialogContent}
              removeItem={removeSelectedCoupon}
            />
          </>
        )}
      </div>
    </InputWraper>
  );
};

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 30,
    flexWrap: "wrap",
  },

  enabled: {
    height: 50,
    maxHeight: 250,
    minWidth: 200,
    fontSize: 16,
    marginRight: 20,
    marginBottom: 10,
  },

  disabled: {
    height: 50,
    maxHeight: 250,
    minWidth: 200,
    backgroundColor: "#C4C4C480 !important",
    color: "#33333380 !important",
    pointerEvents: "none",
    fontSize: 16,
    marginRight: 20,
    marginBottom: 10,
  },
});

export default SaveSection;
