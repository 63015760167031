import fetchHelper from "../lib/fetchHelper";

export const getStatistics = async location => {
  return fetchHelper(`api/dashboard/stamp_cards/statistics?location=${location}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getClaims = async page => {
  return fetchHelper(`api/dashboard/stamp_cards/claims?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const exportStampsList = async id => {
  return fetchHelper(`api/dashboard/stamp_cards/export?export_id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const returnPoints = async (id, newPoints) => {
  return fetchHelper(`api/dashboard/receipts/${id}/return?new_points=${newPoints}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
