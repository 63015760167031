import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    //    backgroundColor: 'rgba(32, 60, 83, 0.8)',
  },
  paper: {
    borderRadius: 30,
    padding: 30,
    marginLeft: 10,
    marginRight: 10,
  },
  paperRed: {
    backgroundColor: "#F17877",
  },
  container: {
    marginTop: 10,
    margin: "auto",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
  imgContainer: {
    position: "relative",
    margin: "auto",
    width: props => props.size,
    display: "flex",
    alignItems: "center",
    height: props => props.size,
    transition: "0.6s",
    transformStyle: "preserve-3d",
    perspective: "1000px",
    borderRadius: 24,
  },
  card: {
    position: "absolute",
    backgroundColor: "white",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
    backfaceVisibility: "hidden",
    borderRadius: 24,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
    transition: "transform 0.6s ease-in-out",
    transformStyle: "preserve-3d",
  },
  imgCard: {
    position: "absolute",
    backgroundColor: "white",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
    backfaceVisibility: "hidden",
    borderRadius: 24,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
    transition: "transform 0.6s ease-in-out, filter 0.2s ease-out",
    transformStyle: "preserve-3d",
    backgroundSize: "auto 100%",
    backgroundPosition: "center",
  },
  scanToGetStamp: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    marginTop: 10,
    textAlign: "center",
    textShadow: "1px 1px 3px #DDDDDD",
  },
  tabletDescription: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: 1.2,
    fontSize: "3vw",
    marginTop: 10,
    textAlign: "center",
    transition: "transform 0.6s ease-in-out",
    willChange: "transform",
    textShadow: "1px 1px 3px #DDDDDD",
  },
  tabletDescriptionPointSystem: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 32,
    marginTop: 10,
    textAlign: "center",
    transition: "transform 0.6s ease-in-out",
    willChange: "transform",
  },
}));

export default useStyles;
