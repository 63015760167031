import React, { Suspense, useEffect, useMemo, useState } from "react";
import LocalizedStrings from "react-localization";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Switch, BrowserRouter as Router, Redirect, Route as PublicRoute } from "react-router-dom";
import { locale } from "./lib/locale";
import { theme } from "./assets/theme";
import LanguageContext from "./store/LanguageContext";
import { PrivateRoute } from "./routes/PrivateRoute";
import { SansNavigationRoutes, NavigationRoutes } from "./routes/navigation";
import { RootWrapper } from "./components/RootWrapper";
import Spinner from "./components/Shared/Spinner";
import CouponsPage from "./pages/CouponsPage";
import DealsPage from "./pages/DealsPage";
import GiftCardsPage from "./pages/GiftCardsPage";
import LoyaltyCardPage from "./pages/LoyaltyCardPage";
import OverviewPage from "./pages/OverviewPage";
import StampCardPage from "./pages/StampCardPage";
import SignInPage from "./pages/SignInPage";
import SignInCompanyPage from "./pages/SignInCompanyPage";
import DashboardPage from "./pages/DashboardPage";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import StorePage from "./pages/StorePage";
import DemoPage from "./pages/DemoPage";
import ReturnsPage from "./pages/ReturnsPage/ReturnsPage";
import LanguageSelectContext from "./store/LanguageSelectContext";

const App = () => {
  const [language, setLanguage] = useState();
  const strings = useMemo(() => {
    const str = new LocalizedStrings(locale);
    if (language) {
      str.setLanguage(language);
    }
    return str;
  }, [language]);

  const firebaseConfig = {
    apiKey: "AIzaSyDBTKXEyxwya3ozRSgKWL78kj-gClmnbxM",
    authDomain: "my-stamp-tablet.firebaseapp.com",
    projectId: "my-stamp-tablet",
    storageBucket: "my-stamp-tablet.appspot.com",
    messagingSenderId: "76401834645",
    appId: "1:76401834645:web:c9948142c701192310799c",
    measurementId: "G-961KJXYFZR",
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  useEffect(() => {
    async function setWakelock() {
      let wakeLock = await navigator.wakeLock.request("screen");
      return () => {
        wakeLock.release();
      };
    }
    setWakelock();
  }, []);

  return (
    <LanguageSelectContext.Provider value={setLanguage}>
      <LanguageContext.Provider value={strings}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Suspense fallback={<Spinner />}>
              <RootWrapper>
                <Switch>
                  <PublicRoute path={SansNavigationRoutes.SIGN_IN} exact component={SignInPage} />
                  <PublicRoute path={SansNavigationRoutes.SIGN_IN_COMPANY} exact component={SignInCompanyPage} />
                  <PublicRoute path={SansNavigationRoutes.DEMO} exact component={DemoPage} />
                  <PrivateRoute path={SansNavigationRoutes.ROOT} exact component={DashboardPage} />
                  <PrivateRoute path={SansNavigationRoutes.DASHBOARD} exact component={DashboardPage} />
                  <PrivateRoute path={NavigationRoutes.COUPONS} exact component={CouponsPage} />
                  <PrivateRoute path={NavigationRoutes.DEALS} exact component={DealsPage} />
                  <PrivateRoute path={NavigationRoutes.GIFT_CARDS} exact component={GiftCardsPage} />
                  <PrivateRoute path={NavigationRoutes.LOYALTY_CARD} exact component={LoyaltyCardPage} />
                  <PrivateRoute path={NavigationRoutes.OVERVIEW} exact component={OverviewPage} />
                  <PrivateRoute path={NavigationRoutes.STAMP_CARD} exact component={StampCardPage} />
                  <PrivateRoute path={NavigationRoutes.STORE} exact component={StorePage} />
                  <PrivateRoute path={NavigationRoutes.RETURNS} exact component={ReturnsPage} />
                  <Redirect to={SansNavigationRoutes.ROOT} />
                </Switch>
              </RootWrapper>
            </Suspense>
          </Router>
        </ThemeProvider>
      </LanguageContext.Provider>
    </LanguageSelectContext.Provider>
  );
};

export default App;
