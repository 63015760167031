import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette }) => ({
  root: {
    borderRadius: "16px",
  },

  th: {
    backgroundColor: palette.primary.main,
    color: "white",
    borderRight: "0.5px solid #E0E0E0",
    fontFamily: "DMSans",
    fontSize: 14,
    fontWeight: 700,
  },

  cell: {
    borderRight: "0.5px solid #E0E0E0",
    fontFamily: "DMSans",
    fontSize: 16,
  },

  row: {
    backgroundColor: "#F4F4F4",
  },

  evenRow: {
    backgroundColor: "#E8E8E8",
  },

  orderMark: {
    clip: "rect(0 0 0 0)",
    overflow: "hidden",
    width: 1,
  },

  filterArrowsIcon: {
    marginRight: 5,
    marginBottom: 3,
  },

  displayedRows: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    overflowX: "auto",
  },

  spacer: {
    flex: 0,
  },

  pagination: {
    flex: 1,
  },

  customRowContainer: {
    display: "flex",
    flex: 1,
    minWidth: 800,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 20,
  },

  paginationBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  labelNormal: {
    color: "black",
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 12,
    marginRight: 3,
  },

  labelBold: {
    color: "black",
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 12,
    marginRight: 3,
  },

  labelButton: {
    fontFamily: "DMSans",
    fontWeight: 400,
    marginRight: 20,
    fontSize: 12,
    cursor: "pointer",
  },

  saveAllButton: {
    minWidth: 140,
    minHeight: 46,
    textAlign: "center",
  },
}));
