import React, { useState, useEffect, useContext } from "react";
import { CompanyIntroSection } from "./CompanyIntroSection";
import { getCompanyDetails } from "../../services/OverviewServices";
import Spinner from "../../components/Shared/Spinner";
import { errorToast } from "../../components/Notifications/Notifications";
import { UserContext } from "../../store/UserContext";
import { CompanyContext } from "../../store/CompanyContext";
import { useOverviewPageStyles } from "./OverviewPageStyles";

export const OverviewPage = () => {
  const [companyData, setCompanyData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { setState } = useContext(UserContext);
  const { setCompany } = useContext(CompanyContext);
  const classes = useOverviewPageStyles();

  useEffect(() => {
    async function fetchData() {
      try {
        let result = await getCompanyDetails();
        setCompanyData(result);
        setCompany({ company: result });
        setIsLoading(false);
      } catch (error) {
        if (error.code === 401) {
          setState({ authenticated: false, user: null });
        }
        errorToast({
          title: "Error",
          text: error.message,
        });
        setIsLoading(false);
      }
    }
    fetchData();
  }, [setState, setCompany]);

  return (
    <main className={classes.root}>
      {!isLoading ? <CompanyIntroSection companyData={companyData} /> : <Spinner />}
    </main>
  );
};
