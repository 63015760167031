import { makeStyles } from "@material-ui/core";

export const useStampCardsCreateStampSectionStyles = makeStyles(() => ({
  pointsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
  },
  pointsInput: {
    width: 80,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    height: 59,
    color: "#333333",
    fontFamily: "DMSans",
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 8,
    resize: "none",
    padding: "5px 10px",
    "&::placeholder": {
      color: "#C4C4C4",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "&[type=number]": {
      MozAppearance: "textfield",
    },
  },
  inputLabelTitle: {
    fontFamily: "DMSans",
    fontWeight: 600,
    fontSize: 20,
    color: "#333333",
    textAlign: "start",
  },
  titleContainer: {
    marginBottom: 20,
  },
  qrCodeContainer: {
    display: "flex",
    width: 300,
    flexDirection: "column",
    gap: 10,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  copyIcon: {
    marginLeft: 10,
    color: "#757575",
    display: "flex",
    flexGrow: 0,
    flexShrink: 0,
  },
  qrCodeLinkBox: {
    color: "#757575",
    width: 300,
    height: 50,
    padding: 15,
    backgroundColor: "#e4e4e4",
    borderRadius: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(95%)",
    },
  },
  downloadButton: {
    width: "100%",
  },
  downloadAnchor: {
    textDecoration: "none",
  },
  qrCodeLink: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));
