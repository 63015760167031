import React from "react";
import { makeStyles } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const InputConfirmationBox = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.confirmationBox}>
      <CheckCircleOutlineIcon className={classes.confirmationIcon}></CheckCircleOutlineIcon>
      <span className={classes.confirmationText}>{message}</span>
    </div>
  );
};

const useStyles = makeStyles({
  confirmationBox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 5,
    alignItems: "center",
  },

  confirmationIcon: {
    fill: "green",
    width: 15,
    height: 15,
  },

  confirmationText: {
    marginLeft: 2,
    color: "green",
    textAlign: "start",
    fontFamily: "DMSans",
    fontWeight: 400,
  },
});

export default InputConfirmationBox;
