import { makeStyles } from "@material-ui/core";

export const useStampCardPageStyles = makeStyles(() => ({
  root: {
    width: "100%",
    flexGrow: 1,
    marginTop: 200,
    paddingLeft: 50,
    paddingRight: 50,
    minHeight: window.innerHeight,
  },
  hrLine: {
    marginLeft: 50,
    marginRight: 50,
    border: "none",
    height: "2px",
    background: "repeating-linear-gradient(90deg,#C4C4C4,#C4C4C4 6px,transparent 6px,transparent 12px)",
  },
}));
