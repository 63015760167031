import { makeStyles, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useContext } from "react";
import LanguageContext from "../../store/LanguageContext";
import DateFnsUtils from "@date-io/date-fns";
import CouponTemplate from "./CouponTemplate";
import ScrollContainer from "react-indiana-drag-scroll";
import CouponPreview from "./CouponPreview";
import NewCouponForm from "./NewCouponForm";
import { InputWraper } from "../Shared/InputWrapper";

const AboutSection = ({
  shortDescription,
  handleShortDescriptionChange,
  longDescription,
  handleLongDescriptionChange,
  specifications,
  handleSpecificationsChange,
  image,
  handleImageChange,
  dateTo,
  handleDateToChange,
  dateFrom,
  handleDateFromChange,
  scheduledAt,
  handleScheduledAtChange,
  totalRedemptions,
  handleTotalRedemptionsChange,
  datesValidation,
  shortDescriptionErrorMessage,
  longDescriptionErrorMessage,
  specificationsErrorMessage,
}) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);

  const couponTemplate = {
    id: 1,
    short_description: shortDescription,
    long_description: longDescription,
    specifications: specifications,
    valid_from: dateFrom,
    valid_to: dateTo,
    redemption_limit: totalRedemptions,
    publish_at: scheduledAt,
    coupon_image: image,
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <InputWraper title={strings.couponsPage.aboutTitle}>
        <div className={classes.mainSubcontainer}>
          <NewCouponForm
            handleImageChange={handleImageChange}
            image={image}
            setImage={handleImageChange}
            dateFrom={dateFrom}
            handleDateFromChange={handleDateFromChange}
            dateTo={dateTo}
            handleDateToChange={handleDateToChange}
            datesValidation={datesValidation}
            scheduledAt={scheduledAt}
            handleScheduledAtChange={handleScheduledAtChange}
            totalRedemptions={totalRedemptions}
            handleTotalRedemptionsChange={handleTotalRedemptionsChange}
            shortDescription={shortDescription}
            handleShortDescriptionChange={handleShortDescriptionChange}
            longDescription={longDescription}
            handleLongDescriptionChange={handleLongDescriptionChange}
            specifications={specifications}
            handleSpecificationsChange={handleSpecificationsChange}
            shortDescriptionErrorMessage={shortDescriptionErrorMessage}
            longDescriptionErrorMessage={longDescriptionErrorMessage}
            specificationsErrorMessage={specificationsErrorMessage}
          />
          <div className={classes.previewContainer}>
            <div className={classes.previewTitleBox}>
              <Typography className={classes.previewLabel}>{strings.couponsPage.preview}</Typography>
            </div>
            <CouponPreview coupon={couponTemplate} isCreated={false} />
            <div className={classes.couponBox}>
              <ScrollContainer horizontal={true} style={{ display: "flex" }}>
                <CouponTemplate item={couponTemplate} isCreated={false} />
              </ScrollContainer>
            </div>
          </div>
        </div>
      </InputWraper>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles({
  mainSubcontainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  previewContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 0.4,
    backgroundColor: "#F0F0F0",
    minWidth: 350,
    flexGrow: 1,
    borderBottomRightRadius: 16,
  },

  couponBox: {
    minWidth: 350,
    display: "flex",
    justifyContent: "center",
  },

  previewTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 30,
    justifyContent: "center",
  },

  previewLabel: {
    fontFamily: "DMSans",
    fontWeight: 500,
    fontSize: 24,
    color: "#000000",
  },
});

export default AboutSection;
