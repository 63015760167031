import { IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Dropdown from "../Shared/Dropdown";
import CloseIcon from "@material-ui/icons/Close";

const MultiSelect = ({ data, selectedItem, handleChange, removeItem }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.dropdownBox}>
        <Dropdown data={data} selectedItem={selectedItem} handleChange={handleChange} dropdownKey={"coupon"} />
      </div>
      {selectedItem.filter(el => el.value === -1).length === 0 &&
        selectedItem.map(el => {
          return (
            <div key={el.value} className={classes.selectedItem}>
              <Typography className={classes.selectedText}>{el.label}</Typography>
              <IconButton aria-label="close" size={"small"} onClick={() => removeItem(el)}>
                <CloseIcon />
              </IconButton>
            </div>
          );
        })}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    marginTop: 10,
    flexWrap: "wrap",
  },

  dropdownBox: {
    maxWidth: 256,
    width: "100%",
    marginRight: 30,
    marginBottom: 10,
  },

  selectedText: {
    fontSize: 18,
    fontFamily: "DMSans",
    fontWeight: 500,
    color: "#000000",
    marginRight: 10,
  },

  selectedItem: {
    display: "flex",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-between",
    minWidth: 200,
    minHeight: 47,
    alignItems: "center",
    padding: "0px 20px",
    marginRight: 15,
    marginBottom: 10,
    border: "0.5px solid #C4C4C4",
    borderRadius: 38,
    boxShadow: "1px 2px 4px 0px #00000040",
  },
});

export default MultiSelect;
