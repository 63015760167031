import React, { useState, useContext } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { MSButton } from "../MSButton";
import LanguageContext from "../../store/LanguageContext";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const ExtendBox = ({ card, mappedCards, setMappedCards }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [dateHelper, setDateHelper] = useState(new Date(card.endDateValue));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleDateChange = date => {
    const mappedCardsNew = mappedCards.map(element => {
      if (element.id === card.id) {
        element.endDate = date.toLocaleDateString();
        element.isExtended = true;
      }
      return element;
    });
    setDateHelper(date);
    setMappedCards(mappedCardsNew);
  };

  const addOneDay = () => {
    let date = new Date(dateHelper);
    date.setMonth(date.getMonth() + 1);
    setDateHelper(date);
    const mappedCardsNew = mappedCards.map(element => {
      if (element.id === card.id) {
        element.endDate = date.toLocaleDateString();
        element.isExtended = true;
      }
      return element;
    });
    setMappedCards(mappedCardsNew);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.extendBox}>
        <MSButton
          type="secondary"
          style={{ opacity: card.isRemoved ? 0.3 : 1, pointerEvents: card.isRemoved && "none" }}
          className={classes.tableButtonBox}
          onClick={addOneDay}
        >
          {strings.loyaltyCardsPage.oneMonth}
        </MSButton>
        <div
          className={classes.datePickerBox}
          style={{ opacity: card.isRemoved ? 0.3 : 1, pointerEvents: card.isRemoved && "none" }}
        >
          <KeyboardDatePicker
            labelFunc={() => strings.loyaltyCardsPage.selectDate}
            value={new Date()}
            open={isCalendarOpen}
            onOpen={() => setIsCalendarOpen(true)}
            onClose={() => setIsCalendarOpen(false)}
            onChange={date => handleDateChange(date)}
            minDate={new Date()}
            format="dd/MM/yyyy"
            views={["year", "month", "date"]}
            animateYearScrolling
            className={classes.datePickerContainer}
            TextFieldComponent={props => <TextField {...props} onClick={() => setIsCalendarOpen(true)} />}
            keyboardIcon={<ArrowDown height={7} width={11} />}
            InputProps={{
              disableUnderline: true,
              readOnly: true,
              className: classes.input,
            }}
          />
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles({
  extendBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },

  tableButtonBox: {
    display: "flex",
    alignItems: "center",
    height: 30,
    minWidth: 120,
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
    boxShadow: "0px 1px 2px 0px #00000040",
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#EDB5A3",
      color: "white",
    },
  },

  datePickerBox: {
    height: 30,
    minWidth: 120,
    maxWidth: 140,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
  },

  datePickerContainer: {
    height: 30,
    width: "100%",
    borderRadius: 38,
    boxShadow: "0px 1px 2px 0px #00000040",
    cursor: "pointer",
    border: "0.5px solid #C4C4C4",
    resize: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#EDB5A3",
      color: "white",
      fill: "white",
    },
    "& *": {
      cursor: "pointer",
    },
  },

  input: {
    height: 30,
    minWidth: 140,
    padding: "5px 0px 5px 15px",
    fontWeight: 700,
    fontSize: 14,
    "&:hover": {
      color: "white",
    },
  },
});

export default ExtendBox;
