import { motion } from "framer-motion";
import { makeStyles } from "@material-ui/core";

const cursorVariants = {
  blinking: {
    opacity: [0, 0, 1, 1],
    transition: {
      duration: 1,
      repeat: Infinity,
      repeatDelay: 0,
      ease: "linear",
      times: [0, 0.5, 0.5, 1],
    },
  },
};

const useStyles = makeStyles(() => ({
  root: {
    display: "inline-block",
    marginLeft: 10,
    backgroundColor: "#333333",
  },
}));

export default function CursorBlinker({ height = 120, width = 10, color }) {
  const classes = useStyles();
  return (
    <motion.div
      variants={cursorVariants}
      animate="blinking"
      className={classes.root}
      style={{
        height,
        width,
        backgroundColor: color,
      }}
    />
  );
}
