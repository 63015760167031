import { makeStyles } from "@material-ui/core";

export const useStampCardsStatisticsSectionStyles = makeStyles(() => ({
  locationSelect: {
    width: 350,
  },
  stampsPerLocation: {
    marginBottom: 24,
    fontWeight: 700,
  },
}));
