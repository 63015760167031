import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as PNGUpload } from "../../assets/icons/pngUpload.svg";
import ReactFileReader from "react-file-reader";
import CloseIcon from "@material-ui/icons/Close";
import { Button, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import LanguageContext from "../../store/LanguageContext";
import { updateStoreSettings } from "../../services/SubscriptionServices";
import InputErrorBox from "../Shared/InputErrorBox";
import InputConfirmationBox from "../Shared/InputConfirmationBox";
import { errorToast } from "../Notifications/Notifications";

const StoreSettings = ({ store, handleStore }) => {
  const classes = useStyles();
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState("");
  const [buttonTextColor, setButtonTextColor] = useState("");
  const [validBackgroundColor, setValidBackgroundColor] = useState(false);
  const [validTextColor, setValidTextColor] = useState(false);
  const [validButtonBackgroundColor, setValidButtonBackgroundColor] = useState(false);
  const [validButtonTextColor, setValidButtonTextColor] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const strings = useContext(LanguageContext);

  useEffect(() => {
    setBackgroundColor(store.store_background_color);
    setTextColor(store.store_text_color);
    setButtonBackgroundColor(store.button_background_color);
    setButtonTextColor(store.button_text_color);
    setImage(store.store_image);
  }, [store]);

  const handleImageChange = image => {
    setImage(image !== null ? image.base64 : null);
    setImageFile(image !== null ? image.fileList[0] : null);
  };

  const validateHexa = (hexa, valid, updateValid) => {
    const re = /^#[0-9a-f]{6}$/i;
    if (hexa === "" || re.test(hexa)) {
      if (valid !== true) {
        updateValid(true);
      }
      return true;
    }
    if (valid !== false) {
      updateValid(false);
    }
    return false;
  };

  const colorInput = (title, color, updateColor, valid, updateValid) => {
    return (
      <div className={classes.colorInputContainer}>
        <div className={classes.column}>
          <Typography className={classes.inputTitle}>{title}</Typography>
          <div className={classes.colorInputRow}>
            <input
              placeholder={"Ex. #EDB5A3"}
              className={classes.colorInput}
              type="text"
              value={color}
              onChange={event => updateColor(event.target.value)}
            />

            <div className={classes.colorBox} style={{ backgroundColor: color }} />
          </div>
          {!validateHexa(color, valid, updateValid) ? <InputErrorBox message={strings.storePage.wrongFormat} /> : null}
        </div>
      </div>
    );
  };

  const handleSave = async () => {
    setIsSaving(true);
    setIsSaved(false);
    let formData = new FormData();
    formData.append("store_background_color", backgroundColor);
    formData.append("store_text_color", textColor);
    formData.append("button_background_color", buttonBackgroundColor);
    formData.append("button_text_color", buttonTextColor);
    if (imageFile !== null) {
      formData.append("store_image", imageFile);
    }
    try {
      let result = await updateStoreSettings(formData);
      setIsSaving(false);
      setIsSaved(true);
      handleStore(result);
    } catch (error) {
      setIsSaving(false);
      errorToast({
        title: "Error",
        text: error.message,
      });
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.settingsRow}>
        <div className={classes.pngContainer}>
          <div className={classes.imageTitleRow}>
            <Typography className={classes.inputLabelTitle}>{strings.storePage.storeImage}</Typography>
            <Typography className={classes.inputLabelSubtitle}>{strings.couponsPage.png}</Typography>
          </div>
          <ReactFileReader handleFiles={handleImageChange} base64={true} fileTypes={"image/png"}>
            {image === null ? (
              <div className={classes.pngTemplate}>
                <div className={classes.pngIconBox}>
                  <PNGUpload className={classes.pngIcon} />
                </div>
              </div>
            ) : (
              <div className={classes.pngImageContainer}>
                <div className={classes.closeIconBox}>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={e => {
                      setImage(null);
                      e.stopPropagation();
                    }}
                  />
                </div>
                <div className={classes.selectedImageBox}>
                  <img src={image} alt={"coupon_image"} className={classes.selectedImage} />
                </div>
              </div>
            )}
          </ReactFileReader>
        </div>
        <div className={classes.colorContainer}>
          <Typography className={classes.inputLabelTitle}>{strings.storePage.colors}</Typography>
          <div className={classes.row}>
            {colorInput(
              strings.storePage.background,
              backgroundColor,
              setBackgroundColor,
              validBackgroundColor,
              setValidBackgroundColor,
            )}
            {colorInput(strings.storePage.text, textColor, setTextColor, validTextColor, setValidTextColor)}
          </div>
          <div className={classes.row}>
            {colorInput(
              strings.storePage.buttonBackground,
              buttonBackgroundColor,
              setButtonBackgroundColor,
              validButtonBackgroundColor,
              setValidButtonBackgroundColor,
            )}
            {colorInput(
              strings.storePage.buttonText,
              buttonTextColor,
              setButtonTextColor,
              validButtonTextColor,
              setValidButtonTextColor,
            )}
          </div>
        </div>
      </div>
      <div>
        <Button
          onClick={() => handleSave()}
          variant="contained"
          disabled={
            !(
              validBackgroundColor &&
              validTextColor &&
              validButtonBackgroundColor &&
              validButtonTextColor &&
              backgroundColor !== "" &&
              textColor !== "" &&
              buttonBackgroundColor !== "" &&
              buttonTextColor !== ""
            )
          }
          className={classes.button}
        >
          {isSaving ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            <Typography className={classes.buttonText}>{strings.storePage.saveSettings}</Typography>
          )}
        </Button>
        {isSaved && <InputConfirmationBox message={strings.storePage.success} />}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  buttonText: {
    fontFamily: "DMSans",
    fontSize: 20,
    color: "#333",
  },
  button: {
    minHeight: 50,
    minWidth: 250,
    backgroundColor: "#fff",
    marginTop: 40,
    borderRadius: 16,
    textTransform: "none",
  },
  colorInput: {
    width: "90%",
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    height: 47,
    fontFamily: "DMSans",
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 8,
    resize: "none",
    padding: "5px 10px",
    "&::placeholder": {
      color: "#33333380",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
  },
  inputTitle: {
    height: 35,
    fontFamily: "DMSans",
    fontSize: 20,
    fontWeight: 500,
    color: "#333333",
    marginRight: 10,
    paddingBottom: 5,
    alignSelf: "flex-start",
  },
  colorInputContainer: {
    paddingTop: 15,
    width: 300,
  },
  colorBox: {
    marginLeft: 16,
    marginRight: 50,
    minWidth: 50,
    minHeight: 50,
    width: 50,
    height: 50,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#333333",
    borderRadius: 8,
  },
  pngContainer: {
    display: "flex",
    flexDirection: "column",
  },
  colorContainer: {
    display: "flex",
    flexDirection: "column",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  colorInputRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  imageTitleRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
  settingsRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 80,
  },
  row: {
    flexWrap: "wrap",
    display: "flex",
    flexDirection: "row",
  },
  mainContainer: {
    marginTop: 16,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    textAlign: "start",
  },
  pngTemplate: {
    display: "flex",
    flexDirection: "column",
    minWidth: 400,
    maxWidth: 400,
    height: 200,
    backgroundColor: "#E0E0E0",
    borderRadius: 16,
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  pngImageContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 200,
    maxWidth: 400,
    height: 200,
    borderRadius: 16,
    justifyContent: "flex-start",
    cursor: "pointer",
    paddingBottom: 30,
    backgroundColor: "transparent",
    border: "0.5px solid #E0E0E0",
  },
  pngIconBox: {
    display: "flex",
    width: "100%",
    justifyItems: "center",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  pngIcon: {
    width: 90,
    height: 95,
  },
  closeIconBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: 26,
    paddingRight: 7,
  },
  closeIcon: {
    width: 35,
    height: 35,
    marginBottom: -15,
  },
  selectedImageBox: {
    display: "flex",
    width: "100%",
    justifyItems: "center",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    maxWidth: 300,
  },
  selectedImage: {
    display: "inline-block",
    maxWidth: 300,
    maxHeight: 140,
  },
  inputLabelTitle: {
    fontFamily: "DMSans",
    fontWeight: 600,
    fontSize: 20,
    color: "#333333",
    textAlign: "start",
  },
  inputLabelSubtitle: {
    fontFamily: "DMSans",
    fontSize: 20,
    color: "#333333",
    fontWeight: 400,
    marginLeft: 5,
  },
});

export default StoreSettings;
