import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { MSTable } from "../MSTable";
import { MSButton } from "../MSButton";
import LanguageContext from "../../store/LanguageContext";
import { getSubscription } from "../../services/SubscriptionServices";
import { errorToast } from "../Notifications/Notifications";

const SubscriptionTable = ({ selectedSubscription, handleTableUpdate }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [pagination, setPagination] = useState({ count: 0, last: 1, next: null, page: 1, prev: null });
  const [page, setPage] = useState(1);
  const [isSaving, setIsSaving] = useState(false);
  const [productSubscribers, setProductSubscribers] = useState(null);

  const getPageNumber = (paginationData, removedCount) => {
    if ((paginationData.count - removedCount) % 10 === 0 && paginationData.count > 0) {
      if (paginationData.count - removedCount === 0 || paginationData.page === 1) {
        return 1;
      }
      return paginationData.page - 1;
    }
    return paginationData.page;
  };

  const fetchSubscription = async (id, pageNumber = 1) => {
    try {
      if (id !== -1) {
        let result = await getSubscription(id, pageNumber);
        setPagination(result.pagination);
        const mappedSubscribers = result.data.map(sub => {
          const cardItem = {
            id: sub.id,
            email: sub.email,
            paymentStatus: sub.payment_status,
            startDate: new Date(sub.start_date).toLocaleDateString(),
            redemptionsToday: sub.redemptions_today,
            redemptionsMonth: sub.redemptions_30_days,
            remove: removeButton(sub, result.data),
            isRemoved: false,
          };
          return cardItem;
        });
        setProductSubscribers(mappedSubscribers);
      }
    } catch (error) {
      errorToast({
        title: "Error",
        text: error.message,
      });
    }
  };

  const saveAll = async () => {
    setIsSaving(true);
    await Promise.all(
      productSubscribers.map(async element => {
        if (element.isRemoved) {
          try {
            // await deleteLoyaltyCard(element.id);
            setPage(getPageNumber(pagination, productSubscribers.filter(el => el.isRemoved === true).length));
            handleTableUpdate();
          } catch (error) {
            errorToast({
              title: "Error",
              text: error.message,
            });
          }
        }
        return element;
      }),
    );
    setTimeout(() => {
      setIsSaving(false);
    }, 500);
  };

  const removeButton = React.useCallback(
    (sub, productSubscribers) => {
      const removeRow = () => {
        const filteredSubs = productSubscribers.map(element => {
          if (element.id === sub.id) {
            element.isRemoved = !element.isRemoved;
          }
          return element;
        });
        const mappedSubscribers = filteredSubs.map(element => {
          const subItem = {
            id: element.id,
            email: element.email,
            paymentStatus: element.payment_status,
            startDate: new Date(element.valid_from).toLocaleDateString(),
            redemptionsToday: element.redemptions_today,
            redemptionsMonth: element.redemptions_30_days,
            remove: removeButton(element, filteredSubs),
            isRemoved: element.isRemoved,
          };
          return subItem;
        });
        setProductSubscribers(mappedSubscribers);
      };
      return (
        <div className={classes.removeButtonContainer}>
          <MSButton type={"secondary"} className={classes.tableButtonBox} onClick={removeRow}>
            {!sub.isRemoved ? strings.loyaltyCardsPage.remove : strings.loyaltyCardsPage.cancel}
          </MSButton>
        </div>
      );
    },
    [productSubscribers],
  );

  const handleChangePage = (_, newPage) => {
    if (newPage !== null) {
      setPage(newPage);
      fetchSubscription(selectedSubscription.id, newPage);
    }
  };

  useEffect(() => {
    setPage(1);
    fetchSubscription(selectedSubscription.id);
    return () => {
      setProductSubscribers(null);
    };
  }, [selectedSubscription]);

  const tableColumns = [
    { id: "email", label: strings.storePage.email, align: "left", sortVisible: true },
    { id: "paymentStatus", label: strings.storePage.paymentStatus, align: "left", sortVisible: true },
    { id: "startDate", label: strings.storePage.startDate, align: "left", sortVisible: true },
    { id: "redemptionsToday", label: strings.storePage.redemptionsToday, align: "left", sortVisible: true },
    { id: "redemptionsMonth", label: strings.storePage.redemptionsMonth, align: "left", sortVisible: true },
    // { id: "remove", label: strings.storePage.remove, align: "left", sortVisible: false },
  ];

  return (
    <div className={classes.mainContainer}>
      {productSubscribers !== null && pagination !== null && (
        <MSTable
          columns={tableColumns}
          rows={productSubscribers}
          isSaving={isSaving}
          noSaveButton
          saveAll={saveAll}
          rowsPerPage={10}
          pagination={pagination}
          customHandleChangePage={handleChangePage}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },

  tableTitleBox: {
    display: "flex",
    minHeight: 20,
    width: "100%",
    flexDirection: "row",
    marginBottom: 15,
    justifyContent: "flex-end",
    alignItems: "center",
  },

  tableTitle: {
    fontFamily: "DMSans",
    fontSize: window.innerWidth > 1200 ? 28 : 20,
    fontWeight: 700,
    color: "#333333",
  },

  inviteBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  tableButtonBox: {
    height: 30,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
    boxShadow: "0px 1px 2px 0px #00000040",
    border: "0.5px solid #C4C4C4",
    borderRadius: 38,
    padding: "0px 10px",
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#EDB5A3",
      color: "white",
    },
  },

  removeButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    minWidth: 100,
  },
});

export default SubscriptionTable;
