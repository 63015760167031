import { makeStyles } from "@material-ui/core";
import TypewriterAnimation from "./TypewriterAnimation";
import { stagger, useAnimate } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import LanguageContext from "../../store/LanguageContext";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  title: {
    textAlign: "left",
    fontFamily: "WorkSans",
    fontWeight: 700,
    lineHeight: "110px",
    fontSize: 100,
    color: "#333333",
    marginBottom: 30,
  },
  text: {
    textAlign: "left",
    display: "inline-block",
    fontFamily: "WorkSans",
    fontWeight: 500,
    fontSize: 100,
    lineHeight: "100px",
    color: "#333333",
  },
}));

const duration = 0.6;
export default function TextAnimation({ onDone, offerProvider }) {
  const [scope, animate] = useAnimate();
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const language = strings && "getLanguage" in strings ? (strings?.getLanguage().includes("en") ? "en" : "sv") : "sv";
  const texts = offerProvider?.tablet_typewrite_text
    ? offerProvider?.tablet_typewrite_text.split("\r\n")
    : language === "sv" // The language package can't handle arrays with different lengths so need to set the texts manually
    ? ["Hej", "Glöm", "inte att", "skanna", "din", "QR-kod"]
    : ["Hey", "Don't", "forget", "to scan", "your", "QR code"];
  const title = texts?.shift();

  const [done, setDone] = useState([title, ...texts].map(() => false));

  const handleSetDone = index => {
    setDone(prev => {
      const newDone = [...prev];
      newDone[index] = true;
      return newDone;
    });
  };

  const exitAnimation = async () => {
    setTimeout(() => {
      onDone();
    }, (texts.length + 1) * 75 + 500);
    await animate("span", { opacity: 0 }, { duration: 0.5, delay: stagger(0.05, { startDelay: 1, from: "last" }) });
  };

  useEffect(() => {
    if (done.every(Boolean)) {
      exitAnimation();
    }
  }, [done, setDone]);

  return (
    <div ref={scope} className={classes.root}>
      <TypewriterAnimation
        text={title}
        duration={duration}
        delay={0.25}
        className={classes.title}
        onDone={() => handleSetDone(0)}
        color={offerProvider?.tablet_custom_text_color ?? "#333333"}
        cursorSize={{
          height: 100,
          width: 16,
        }}
      />
      {texts.map((text, index) => (
        <TypewriterAnimation
          key={text}
          delay={duration * (index + 1) + 0.25}
          className={classes.text}
          duration={duration}
          onDone={() => handleSetDone(index + 1)}
          text={text}
          color={offerProvider?.tablet_custom_text_color ?? "#333333"}
          cursorSize={{
            height: 88,
            width: 8,
          }}
        />
      ))}
    </div>
  );
}
