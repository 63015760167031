import React, { useContext } from "react";
import { useHeaderStyles } from "./HeaderStyles";
import logo from "../../assets/images/mystamp_logo.png";
import { Typography } from "@material-ui/core";
import { UserContext } from "../../store/UserContext";
import logout from "../../assets/icons/logout.svg";

export const Header = () => {
  const classes = useHeaderStyles();
  const { state, setState } = useContext(UserContext);

  const signOut = () =>{
    setState({ authenticated: false, user: null, role: null });
  };

  return (
    <header className={classes.root}>
      <img src={logo} className={classes.img} alt="Logo" />
      <div className={classes.logoutBox}>
        <Typography className={classes.loggedUser}>{state.user?.email}</Typography>
        <img src={logout} width={24} height={24} className={classes.logoutImg} alt="Logout" onClick={signOut}/>
      </div>
    </header>
  );
};
