import { makeStyles } from "@material-ui/core";

export const useNavigationStyles = makeStyles(({ palette }) => ({
  root: {
    zIndex: 2,
    position: "absolute",
    display: "flex",
    maxWidth: "100%",
    overflowX: "auto",
    top: "80px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: palette.white,
    padding: "8px 0",
    border: "1px solid rgba(196, 196, 196, 0.25)",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: "34px",
    minHeight: "66px",
  },
  navLink: {
    backgroundColor: palette.secondary["500"],
    color: palette.black,
    textDecoration: "none",
    padding: "16px 24px",
    borderRadius: "inherit",
    margin: "0 8px",
    fontSize: "20px",

    "&.is-active": {
      backgroundColor: palette.primary.main,
      color: palette.white,
    },
  },
}));
