import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  container: {
    left: 0,
    right: 0,
    padding: 40,
  },
  rootContainer: {
    position: "absolute",
    top: "12%",
    right: 0,
    left: 0,
    zIndex: 10,
  },
  logoutFab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.03,
    position: "absolute",
    right: 10,
    top: 10,
    fontSize: 14,
    height: 50,
    width: 50,
    border: "1px solid rgba(0,0,0)",
    borderRadius: 10,
    userSelect: "none",
    zIndex: 10,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  whatDoYouWantTodo: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    marginTop: 10,
    marginBottom: 20,
    textAlign: "center",
  },
  welcomeTo: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 32,
    letterSpacing: 0.02,
    paddingTop: 144,
    marginBottom: 0,
    textAlign: "center",
    color: "#FFFFFF",
  },
  companyText: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 32,
    letterSpacing: 0.02,
    paddingTop: 97,
    marginBottom: 0,
    textAlign: "center",
    color: "#FFFFFF",
  },
}));
