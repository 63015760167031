import { Fade, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import LanguageContext from "../../store/LanguageContext";
import QrCode from "../Offers/QrCode/QrCode";
import Order from "./Order/Order";
import useStyles from "./styles";

const Checkout = ({ order, code, totalPrice, startedCardIntegration }) => {
  const strings = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <div className={classes.checkoutContainer}>
      {order && code && <Order order={order} code={code} totalPrice={totalPrice} />}
      <div>
        <Typography className={classes.scanText}>{strings.newPoints}</Typography>
        <Typography className={classes.pointsText}>{totalPrice}</Typography>
      </div>
      <div className={classes.outerFadeContainer}>
        <div className={classes.fadeContainer}>
          <Fade in={startedCardIntegration} timeout={400}>
            <div className={classes.qrContainer}>
              <div className={classes.scanTextContainer}>
                <Typography className={classes.scanText}>{strings.startedCardIntegration}</Typography>
              </div>
            </div>
          </Fade>
        </div>
        <div className={classes.fadeContainer}>
          <Fade in={!startedCardIntegration} timeout={400}>
            <div className={classes.qrContainer}>
              <div className={classes.scanTextContainer}>
                <Typography className={classes.scanText}>{strings.pointsCallToAction}</Typography>
              </div>
              <div className={classes.qrCode}>
                <QrCode code={code} />
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
