const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?!.*\.{2})^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

export const toggleFullScreen = () => {
  const doc = window.document;
  const docEl = document.documentElement;

  const requestFullScreen =
    docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
  const cancelFullScreen =
    doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

  if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
};

export const validateEmail = data => {
  let isValid = true;
  if (!emailRegex.test(data.toLowerCase())) {
    isValid = false;
  }
  return isValid;
};

export const validateEmails = data => {
  let parts = data.split(",");
  let isValid = true;
  parts.forEach(element => {
    element = element.trim();
    if (!emailRegex.test(element.toLowerCase())) {
      isValid = false;
    }
  });
  return isValid;
};

export const mapData = (data, dropdownKey) => {
  const getValue = element => {
    switch (dropdownKey) {
      case "tag":
        return element.label;
      case "loyalty":
        return element.short_description;
      case "location":
        return element.city_category.name + ": " + element.street_address;
      default:
        break;
    }
  };

  let mappedData = data.map(element => {
    const item = {
      value: element.id,
      label: getValue(element),
    };
    return item;
  });

  if (dropdownKey !== "tag"){
    mappedData.unshift({ value: -1, label: "All" });
  }
  return mappedData;
};


export const mapDataOfSelectedCoupon = (selectedData, data, dropdownKey, stampsLocationsSelected) => {
  if (dropdownKey === "location" && stampsLocationsSelected && selectedData.length === 0) {
    return data.filter(el => el.value === -1);
  }
  if (selectedData.length === data.length - 1 && dropdownKey !== "tag") {
    return data.filter(el => el.value === -1);
  }

  const getValue = (element)=>{
    switch (dropdownKey) {
      case "tag":
        return element.tag_id;
      case "loyalty":
        return element.loyalty_card_program_id;
      case "location":
        return element.location_id;
      default:
        break;
    }
  };

  const getLabel = element => {
    switch (dropdownKey) {
      case "tag":
        return element.label;
      case "loyalty":
        return element.short_description;
      case "location":
        return element.name;
      default:
        break;
    }
  };

  const newDataArray = selectedData.map(element => {
    const item = {
      value: getValue(element),
      label: getLabel(element),
    };
    return item;
  });
  return newDataArray;
};


export const mapSpecificationsData = (specifications, totalRedemptions, contentText) => {
  return parseInt(totalRedemptions) > 0 ? contentText + totalRedemptions + "\n" + specifications : specifications;
};

export const generateDropdownData = (allUsersSelected, selectedItems, data, parameterName) => {
  let newData = [];

  if (!allUsersSelected) {
    if (parameterName === "tag_id") {
      selectedItems.forEach(element => {
        newData.push(element.value);
      });
      return newData;
    }
    if (selectedItems.filter(el => el.value === -1).length > 0) {
      if (parameterName === "loyalty_card_program_id") {
        data
          .filter(el => el.value !== -1)
          .forEach(element => {
            newData.push(element.value);
          });
        return newData;
      }
      return [];
    }
    selectedItems.forEach(element => {
      newData.push(element.value);
    });
    return newData;
  }

  if (parameterName === "tag_id") {
    data.forEach(element => {
      newData.push(element.value);
    });
    return newData;
  }
  if (parameterName === "location_id") {
    return [];
  }
  data
    .filter(el => el.value !== -1)
    .forEach(element => {
      newData.push(element.value);
    });
  return newData;
};

export const mapDropdownList = (list, selectedData, setSelectedData, defaultItem) => {
  if (selectedData?.length === 0 && list.length > 0) {
    setSelectedData(list);
    return;
  }
  if (list.filter(el => el.value === -1).length === 0) {
    setSelectedData(list);
    return;
  }
  if (selectedData !== null && selectedData[0].value === -1 && list.length > 1) {
    setSelectedData(list.filter(el => el.value !== -1));
    return;
  }
  setSelectedData([defaultItem]);
};
