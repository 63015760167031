import fetchHelper from "../lib/fetchHelper";

export const postQRCode = async (id, location, points = 0) => {
  let url = "/api/v1/one_time_qr_codes?offer_id=" + id;
  if (points !== 0) {
    url += "&points=" + points;
  }
  if (location["location_id"] !== "none") {
    url += "&location_id=" + location["location_id"];
  }
  return fetchHelper(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getActiveOffers = async () => {
  return fetchHelper("/api/dashboard/offers/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postQRCodeBackOffice = async (offer_id, location_id, points = 0) => {
  let url = "/api/dashboard/one_time_qr_codes?offer_id=" + offer_id;
  if (points !== 0) {
    url += "&points=" + points;
  }

  if (location_id) {
    url += "&location_id=" + location_id;
  }
  return fetchHelper(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postQRCodeCheckoutIntegration = async (id, order_number, points = 0) => {
  let url =
    "/api/v1/one_time_qr_codes?offer_id=" + id + "&integrate_checkout=" + true + "&order_number=" + order_number;

  if (points !== 0) {
    url += "&points=" + points;
  }

  return fetchHelper(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
