import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import CardDetails from "../../components/LoyaltyCards/CardDetails";
import ActiveCards from "../../components/LoyaltyCards/ActiveCards";
import InviteUsers from "../../components/LoyaltyCards/InviteUsers";
import { Typography } from "@material-ui/core";
//import { ReactComponent as Info } from "../../assets/icons/info.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow_up.svg";
import CustomersTable from "../../components/LoyaltyCards/CustomersTable";
import { getActiveCards, getTags } from "../../services/LoyaltyCardsServices";
import LanguageContext from "../../store/LanguageContext";
import { Collapse } from "@material-ui/core";
import Spinner from "../../components/Shared/Spinner";
import { LoyaltyLicensePage } from "./LoyaltyLicensePage/LoyaltyLicensePage";
import { errorToast } from "../../components/Notifications/Notifications";
import { UserContext } from "../../store/UserContext";
import { StatsGrid } from "../../components/StatsGrid";
import { StatsGridStat } from "../../components/StatsGridStat";
import { PageWrapper } from "../../components/Shared/PageWrapper";

export const LoyaltyCardsPage = () => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(true);
  const [activeCards, setActiveCards] = useState([]);
  //const [isEditOpen, setIsEditOpen] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [isInvited, setIsInvited] = useState(false);
  const [currentTags, setCurrentTags] = useState(null);
  const [isNewTagAdded, setIsNewTagAdded] = useState(false);
  const [isLoyaltyCardAvailable, setIsLoyaltyCardAvailable] = useState(true);
  const [isDataModified, setIsDataModified] = useState(false);
  const { setState } = useContext(UserContext);

  const handleActiveCardChange = card => {
    setActiveCard(card);
    setIsInviteOpen(false);
    //setIsEditOpen(true);
  };

  const handleInviteSent = () => {
    setIsInvited(!isInvited);
  };

  const handleUpdate = () => {
    setIsDataModified(!isDataModified);
  };

  const handleNewTagAdded = () => {
    setIsNewTagAdded(!isNewTagAdded);
  };

  async function fetchTags() {
    try {
      let tags = await getTags();
      setCurrentTags(tags);
    } catch (error) {
      errorToast({
        title: "Error",
        text: error.message,
      });
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        let result = await getActiveCards();
        setActiveCards(result);
        fetchTags();
        setActiveCard(activeCard === null ? (result.length > 0 ? result[0] : null) : activeCard);
        setIsLoading(false);
      } catch (error) {
        if (error.code === 401) {
          setState({ authenticated: false, user: null });
          return;
        }
        if (error.code === 403) {
          setIsLoyaltyCardAvailable(false);
          setIsLoading(false);
          return;
        }
        errorToast({
          title: "Error",
          text: error.message,
        });
        setIsLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataModified, isInvited, setState]);

  useEffect(() => {
    fetchTags();
  }, [isNewTagAdded]);

  return (
    <PageWrapper>
      <div align="center" className={classes.mainContainer}>
        {isLoading && <Spinner />}
        {isLoyaltyCardAvailable ? (
          <>
            <StatsGrid valueFontSize={56} labelFontSize={18}>
              <StatsGridStat value={activeCard !== null ? activeCard.invited_count : "0"} label={strings.loyaltyCardsPage.invited} />
              <StatsGridStat value={activeCard !== null ? activeCard.interaction_count : "0"} label={strings.loyaltyCardsPage.interactions} />
              <StatsGridStat value={activeCard !== null ? activeCard.active_loyalty_cards_count : "0"} label={strings.loyaltyCardsPage.activeLoyaltyCards} />
            </StatsGrid>
            <Typography className={classes.activeTitle}>{strings.loyaltyCardsPage.activeTitle}</Typography>
            <ActiveCards cards={activeCards} activeCard={activeCard} onActiveChange={handleActiveCardChange} />
            {/*   <div className={classes.newCardTitleBox}>
        <Typography className={classes.newCardTitle}>Skapa nytt lojalitetskort</Typography>
        <Info width={28} height={28} style={{ marginRight: 10 }} />
        {isEditOpen ? (
          <ArrowUp
            height={14}
            width={23}
            onClick={() => {
              setIsEditOpen(!isEditOpen);
            }}
          />
        ) : (
          <ArrowDown
            height={14}
            width={23}
            onClick={() => {
              setIsEditOpen(!isEditOpen);
            }}
          />
        )}
      </div>
      {isEditOpen && <CardDetails />} */}
            <div className={classes.inviteUsersBox}>
              <div
                className={classes.inviteTitleBox}
                style={{ cursor: activeCard !== null && !activeCard.id.toString().includes("empty_") ? "pointer" : "default" }}
                onClick={activeCard !== null && !activeCard.id.toString().includes("empty_") ? () => setIsInviteOpen(!isInviteOpen) : () => { }}
              >
                <Typography className={classes.newCardTitle}>{strings.loyaltyCardsPage.inviteUsersTitle}</Typography>
                {isInviteOpen ? <ArrowUp height={14} width={23} /> : <ArrowDown height={14} width={23} />}
              </div>
            </div>
            <Collapse in={isInviteOpen}>
              <InviteUsers
                activeCard={activeCard}
                handleInviteSent={handleInviteSent}
                currentTags={currentTags}
                handleNewTagAdded={handleNewTagAdded}
              />
            </Collapse>
            <hr className={classes.hrLine} />
            <div className={classes.customersContainer}>
              {activeCard !== null && (
                <CustomersTable
                  activeCard={activeCard}
                  isInvited={isInvited}
                  currentTags={currentTags}
                  handleTableUpdate={handleUpdate}
                  isDataModified={isDataModified}
                />
              )}
            </div>
          </>
        ) : (
          <LoyaltyLicensePage />
        )}
      </div>
    </PageWrapper>
  );
};

const useStyles = makeStyles(() => ({
  mainContainer: {
    height: "100%",
    minHeight: window.innerHeight,
    marginTop: 200,
    paddingLeft: 50,
    paddingRight: 50
  },

  activeTitle: {
    display: "flex",
    flex: 1,
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 28,
    color: "#000000",
    margin: "50px 0px -10px 0px",
  },

  newCardTitleBox: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 30,
  },

  newCardTitle: {
    fontFamily: "DMSans",
    fontSize: 28,
    fontWeight: 700,
    color: "#333333",
    marginRight: 10,
  },

  inviteUsersBox: {
    display: "flex",
    flex: 1,
    marginTop: 10,
    alignItems: "center",
  },

  inviteTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  hrLine: {
    border: "none",
    height: "2px",
    background: "repeating-linear-gradient(90deg,#C4C4C4,#C4C4C4 6px,transparent 6px,transparent 12px)",
    marginBottom: 30
  },

  customersContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },

}));
