import { makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { motion, useAnimate } from "framer-motion";
import { ReactComponent as DashedLine } from "../../assets/icons/dashed-line.svg";
import mystampLogoImg from "../../assets/images/mystamp_logo_black.png";
import phoneImg from "../../assets/images/phone.png";
import LanguageContext from "../../store/LanguageContext";
import { pointsDashboardDefaultColor } from "./PointsDashboard";
import { getColorFilter } from "../../lib/color-helpers";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    willChange: "transform",
  },
  phone: {
    width: 520,
    height: 957,
    position: "absolute",
    zIndex: 5,
    objectFit: "contain",
    willChange: "transform",
    top: "55%",
    left: "50%",
    opacity: 1,
    "& svg": {
      position: "relative",
      width: "100%",
      height: "unset",
    },
  },
  animation: {
    position: "absolute",
    width: 240,
    height: 240,
    top: "50%",
    left: "50%",
    zIndex: 1000,
    transform: "translate(-50%, -50%)",
    "& video": {
      position: "relative",
      zIndex: 1000,
      width: "100%",
      height: "100%",
    },
    "& div": {
      zIndex: 500,
      width: 150,
      height: 150,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
    },
  },
  background: {
    position: "absolute",
    backgroundColor: "white",
    borderRadius: "60px",
    zIndex: 1,
  },
  offerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "135px 25px 40px",
    height: "100%",
    width: "100%",
    "& > *": {
      zIndex: 20,
    },
  },
  logoImg: {
    height: 50,
    padding: "0 40px",
    maxWidth: "100%",
    marginBottom: "auto",
  },
  qrCodeFrame: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "45%",
    left: "50%",
    height: 410,
    width: 410,
    "& svg": {
      borderRadius: "2px",
    },
  },
  offerTitle: {
    fontFamily: "WorkSans",
    color: "#838383CC",
    fontSize: 25,
    fontWeight: 500,
    lineHeight: "30px",
    marginBottom: "60px",
  },
  offerText: {
    fontFamily: "WorkSans",
    fontSize: 58,
    fontWeight: 800,
    lineHeight: "75px",
    marginBottom: "4px",
  },
  offerDescription: {
    fontFamily: "WorkSans",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "24px",
    marginBottom: "auto",
  },
  offerFooter: {
    width: "65%",
    overflow: "hidden",
  },
  offerFooterText: {
    marginTop: "20px",
    fontFamily: "WorkSans",
    color: "#838383CC",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "16px",
    marginBottom: "12px",
  },
}));

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const initialDelay = 0.5;

const PhoneAnimation = ({ when = false, onDone, offerProvider, onScanningDone }) => {
  const animationRef = useRef();
  const classes = useStyles();
  const [phoneRef, animatePhone] = useAnimate();
  const [backgroundRef, animateBackground] = useAnimate();

  const strings = useContext(LanguageContext);
  const [phoneFramePosition, setPhoneFramePosition] = useState();

  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [showOffer, setShowOffer] = useState(false);

  async function animation() {
    await animatePhone(
      phoneRef.current,
      { transform: "translate(-50%, -50%) rotate(0deg)" },
      { type: "spring", duration: 0.75, delay: initialDelay },
    );

    setTimeout(() => {
      animationRef.current.playbackRate = 2;
      animationRef.current.play();
      setShowSuccessAnimation(true);
    }, 500);

    // Need to wait for the previous animation to finish before the phone frame is in the correct position
    await sleep(100);
    // Fraction of whitespace around the phone frame in the image which are needed to calculate the correct background position
    const widthFactor = 0.1558,
      topHeightFactor = 0.0552,
      bottomHeightFactor = 0.1459;
    const phoneRect = phoneRef.current.getBoundingClientRect();
    setPhoneFramePosition({
      x: phoneRect.x + phoneRect.width * widthFactor + 10,
      y: phoneRect.y + phoneRect.height * topHeightFactor + 20,
      width: phoneRect.width * (1 - widthFactor * 2) - 20,
      height: phoneRect.height * (1 - topHeightFactor - bottomHeightFactor) - 40,
    });

    setTimeout(() => {
      setShowOffer(true);
    }, 1500);

    setTimeout(() => {
      onScanningDone();
    }, 1200);

    await animateBackground(
      backgroundRef.current,
      {
        clipPath: `circle(${phoneRect.height * (1 - bottomHeightFactor + bottomHeightFactor) + 200}px at 50% 50%)`,
      },
      {
        delay: 1,
        type: "spring",
        velocity: 50,
        stiffness: 300,
        damping: 40,
        mass: 6,
      },
    );
    setTimeout(() => {
      onDone();
    }, 7000);
  }

  useEffect(() => {
    if (when) {
      animation();
    }
  }, [when]);

  return (
    <motion.div
      className={classes.root}
      initial={{
        scale: 1,
      }}
      animate={
        showOffer
          ? {
              scale: [1, 1.05, 1.05, 1],
            }
          : undefined
      }
      transition={{
        delay: 0.5,
        duration: 1.4,
        times: [0, 0.2, 0.4, 0.8],
        ease: "easeInOut",
        repeat: Infinity,
      }}
    >
      <motion.img
        ref={phoneRef}
        src={phoneImg}
        className={classes.phone}
        initial={{
          transform: "translate(86%, 40%) rotate(-30deg)",
        }}
      />

      <motion.div
        ref={backgroundRef}
        className={classes.background}
        initial={{
          clipPath: "circle(0 at 50% 50%)",
        }}
        style={{
          left: phoneFramePosition?.x,
          top: phoneFramePosition?.y,
          width: phoneFramePosition?.width,
          height: phoneFramePosition?.height,
        }}
      >
        <motion.div
          className={classes.offerContainer}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: showOffer ? 1 : 0,
          }}
          transition={{
            delay: 0.5,
            duration: 0.5,
          }}
        >
          <img
            src={offerProvider?.tablet_logo_url}
            className={classes.logoImg}
            alt="Logo"
            style={{ visibility: offerProvider?.tablet_logo_url ? "visible" : "hidden" }}
          />
          <Typography className={classes.offerTitle}>{strings.pointsDashboard.offer.title}</Typography>
          <div
            className={classes.offerText}
            style={{ color: offerProvider?.tablet_brand_color ?? pointsDashboardDefaultColor }}
          >
            {offerProvider?.tablet_offer_heading}
          </div>
          <Typography
            className={classes.offerDescription}
            style={{ color: offerProvider?.tablet_brand_color ?? pointsDashboardDefaultColor }}
          >
            {offerProvider?.tablet_offer_subtitle}
          </Typography>
          <div className={classes.offerFooter}>
            <DashedLine />
            <Typography className={classes.offerFooterText}>
              {strings.pointsDashboard.offer.footer_disclaimer}
            </Typography>
            <img src={mystampLogoImg} height={30} />
          </div>
        </motion.div>
      </motion.div>
      <div className={classes.animation}>
        <motion.video
          src={"/checkmark-transparent.webm"}
          ref={animationRef}
          playsInline
          muted
          onEnded={() => setShowSuccessAnimation(false)}
          style={{
            filter: getColorFilter(offerProvider.tablet_brand_color ?? pointsDashboardDefaultColor),
          }}
          initial={{
            opacity: 1,
          }}
          animate={{
            opacity: showSuccessAnimation ? 1 : 0,
          }}
          transition={{
            duration: 0.2,
          }}
        />
        <motion.div
          initial={{
            clipPath: "circle(0 at 50% 50%)",
            opacity: 0,
          }}
          animate={
            showSuccessAnimation
              ? {
                  clipPath: "circle(65px at 50% 50%)",
                  opacity: 1,
                }
              : {
                  opacity: 0,
                }
          }
          transition={{
            duration: showSuccessAnimation ? 0.5 : 0,
          }}
        />
      </div>
    </motion.div>
  );
};

export default PhoneAnimation;
