import fetchHelper from "../lib/fetchHelper";

export const getCouponsInfo = async () => {
  return fetchHelper("api/dashboard/coupon_campaigns/statistics", {
    method: "GET",
  });
};

export const getCoupons = async counter => {
  return fetchHelper(`api/dashboard/coupon_campaigns?page=${counter}`, {
    method: "GET",
  });
};

export const getEventsCouponsInfo = async () => {
  return fetchHelper("api/dashboard/coupon_events/statistics", {
    method: "GET",
  });
};

export const getEventsCoupons = async counter => {
  return fetchHelper(`api/dashboard/coupon_events?page=${counter}`, {
    method: "GET",
  });
};

export const getPushNotificationAccess = async () => {
  return fetchHelper("/api/dashboard/offer_providers/push_notification_access", {
    method: "GET",
  });
};

export const getUsedMonthlyNotifications = async () => {
  return fetchHelper("/api/dashboard/offer_providers/used_monthly_notifications", {
    method: "GET",
  });
};

export const getNotificationBalances = async () => {
  return fetchHelper("/api/dashboard/offer_providers/notification_balances", {
    method: "GET",
  });
};

export const getAudienceCount = async query => {
  return fetchHelper(`/api/dashboard/offer_providers/audience_count?${query}`, {
    method: "GET",
  });
};

export const getLocations = async () => {
  return fetchHelper("api/dashboard/locations", {
    method: "GET",
  });
};

export const addNewCoupon = async formData => {
  return fetchHelper("api/dashboard/coupon_campaigns", {
    method: "POST",
    body: formData,
  });
};

export const updateCoupon = async (id, formData) => {
  return fetchHelper(`api/dashboard/coupon_campaigns/${id}`, {
    method: "PATCH",
    body: formData,
  });
};

export const removeCoupon = async id => {
  return fetchHelper(`api/dashboard/coupon_campaigns/${id}`, {
    method: "DELETE",
  });
};

export const addNewEventCoupon = async formData => {
  return fetchHelper("api/dashboard/coupon_events", {
    method: "POST",
    body: formData,
  });
};

export const updateEventCoupon = async (id, formData) => {
  return fetchHelper(`api/dashboard/coupon_events/${id}`, {
    method: "PATCH",
    body: formData,
  });
};

export const removeEventCoupon = async id => {
  return fetchHelper(`api/dashboard/coupon_events/${id}`, {
    method: "DELETE",
  });
};
