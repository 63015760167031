import React, { useState } from "react";
import Lottie from "react-lottie";
import animationData from "./confetti.json";
import useSound from "use-sound";
import pop from "./pop.mp3";
import logo from "../../assets/images/mystamp_logo.png";

const Logo = () => {
  const [stopped, setStopped] = useState(false);
  const [play] = useSound(pop);

  const defaultOptions = {
    autoPlay: false,
    loop: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handle = () => {
    setStopped(true);
    play();
    setTimeout(() => {
      setStopped(false);
    }, 2000);
  };
  return (
    <>
      <div
        style={{
          width: "fit-content",
          paddingTop: 0,
          margin: "auto",
          userSelect: "none",
        }}
      >
        {stopped ? (
          <Lottie
            style={{
              position: "absolute",
              top: -150,
              left: 0,
              right: 0,
              zIndex: 0,
            }}
            options={defaultOptions}
            height={600}
            width={600}
            isStopped={!stopped}
            isClickToPauseDisabled={true}
          />
        ) : null}
        <div onClick={handle}>
          <img
            src={logo}
            alt={"Logo"}
            style={{
              top: 199,
              userSelect: "none",
              position: "absolute",
              zIndex: 1,
              left: 0,
              right: 0,
              margin: "auto",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Logo;
