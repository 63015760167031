import React, { useContext, useState } from "react";
import { Line } from "react-chartjs-2";
import { ToggleButtonGroup } from "@material-ui/lab";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Dropdown from "../Shared/Dropdown";
import LanguageContext from "../../store/LanguageContext";
import { Months } from "../../lib/constants";

const options = {
  indexAxis: "x",
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  type: "line",
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      ticks: {
        precision: 0,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "right",
    },
    title: {
      display: true,
    },
  },
};

const useStyles = makeStyles(theme => ({
  chartPaper: {
    backgroundColor: "#E4E4E4",
    padding: "24px",
    borderRadius: "16px",
  },
  toggleContainer: {
    display: "flex",
    flexDirection: "row",
    minWidth: 330,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  buttonSelected: {
    backgroundColor: "#EDB5A3!important",
    color: "#FFFFFF!important",
  },
  buttonRoot: {
    backgroundColor: "#FFF",
    fontWeight: "500",
    borderRadius: "60px!important",
    border: "0px solid",
    margin: "4px 8px",
    padding: "0px 25px",
    alignItems: "center",
    textTransform: "none",
    flex: "none",
    color: "#000",
  },
  dropdownsContainer: {
    display: "flex",
    flexDirection: "row",
    justifySelf: "flex-end",
  },
}));

const StampsGraph = props => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);

  let months = Months();
  const currentYear = new Date().getFullYear();
  const years = [
    { value: 1, label: currentYear },
    { value: 2, label: currentYear - 1 },
    { value: 3, label: currentYear - 2 },
  ];
  const totalYears = [
    { value: 1, label: strings.all },
    { value: 2, label: currentYear },
    { value: 3, label: currentYear - 1 },
    { value: 4, label: currentYear - 2 },
  ];
  const [filterStamps, setFilterStamps] = useState("day");
  const [filterMonth, setFilterMonth] = useState(months.find(el => el.value === new Date().getMonth() + 1));
  const [filterYear, setFilterYear] = useState(years[0]);
  const [filterYearTotal, setFilterYearTotal] = useState(totalYears[0]);

  const handleFilterStamps = (event, newState) => {
    setFilterStamps(newState);
    setFilterYear(years[0]);
    setFilterMonth(months.find(el => el.value === new Date().getMonth() + 1));
    setFilterYearTotal(totalYears[0]);
  };
  const handleMonthChange = (value) => {
    setFilterMonth(value);
  };
  const handleYearChange = value => {
    filterStamps === "total" ? setFilterYearTotal(value) : setFilterYear(value);
  };
  const filterDayLabels = (labels, values, filteredLabels, filteredValues) => {
    labels.forEach((element, index) => {
      let date = new Date(element);
      if (date.getMonth() + 1 === filterMonth.value && date.getFullYear() === filterYear.label) {
        filteredLabels.push(date.getDate());
        filteredValues.push(values[index]);
      }
    });
  };
  const filterTotalLabels = (labels, values, filteredLabels, filteredValues) => {
    labels.forEach((element, index) => {
      let date = new Date(element);
      if (date.getFullYear() === filterYearTotal.label) {
        filteredLabels.push(date.toLocaleDateString());
        filteredValues.push(values[index]);
      }
    });
  };

  let stampLabels;
  let stampData;
  if (filterStamps === "day") {
    let labels = Object.keys(props.data.stamps_per_day);
    let values = Object.values(props.data.stamps_per_day);
    let filteredLabels = [];
    let filteredValues = [];
    filterDayLabels(labels, values, filteredLabels, filteredValues);
    stampLabels = filteredLabels;
    stampData = filteredValues;
  } else if (filterStamps === "month") {
    stampLabels = Object.keys(props.data.stamps_per_month);
    stampData = Object.values(props.data.stamps_per_month);
  } else {
    let labels = Object.keys(props.data.stamps_total);
    let values = Object.values(props.data.stamps_total);
    let filteredLabels = [];
    let filteredValues = [];
    if(filterYearTotal.value !== 1){
      filterTotalLabels(labels, values, filteredLabels, filteredValues);
    }
    else{
      filteredLabels = labels;
      filteredValues = values;
    }
    stampLabels = filteredLabels;
    stampData = filteredValues;
  }

  let data_stamps = {
    labels: stampLabels,
    datasets: [
      {
        label: strings.stampCardPage.stampsNumber,
        data: stampData,
        backgroundColor: ["#EDB5A3"],
        borderColor: "#EDB5A3",
        fill: false,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
    ],
  };

  return (
    <Paper elevation={0} className={classes.chartPaper}>
      <div className={classes.toggleContainer}>
        <ToggleButtonGroup
          value={filterStamps}
          exclusive
          onChange={handleFilterStamps}
          style={{
            backgroundColor: "#FFF",
            borderRadius: 60,
            border: "1px solid rgba(196, 196, 196, 0.25)",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
            display: "flex",
            alignItems: "start",
            width: "fit-content",
            height: 32,
            overflowX: "auto",
            flexWrap: "nowrap",
            overflowY: "hidden",
            maxWidth: "100%",
            marginRight: filterStamps === "day" ? "10px" : 0,
            marginBottom: 5,
          }}
        >
          <ToggleButton
            value="day"
            classes={{
              selected: classes.buttonSelected,
              root: classes.buttonRoot,
            }}
          >
            {strings.stampCardPage.day}
          </ToggleButton>
          <ToggleButton
            value="month"
            classes={{
              selected: classes.buttonSelected,
              root: classes.buttonRoot,
            }}
          >
            {strings.stampCardPage.month}
          </ToggleButton>
          <ToggleButton
            value="total"
            classes={{
              selected: classes.buttonSelected,
              root: classes.buttonRoot,
            }}
          >
            {strings.stampCardPage.total}
          </ToggleButton>
        </ToggleButtonGroup>
        <div className={classes.dropdownsContainer}>
          {filterStamps === "day" && (
            <Dropdown
              data={months}
              selectedItem={filterMonth}
              handleChange={handleMonthChange}
              dropdownKey={"filter"}
            />
          )}
          {(filterStamps === "day" || filterStamps === "total") && (
            <div style={{ marginLeft: 10 }}>
              <Dropdown
                data={filterStamps === "total" ? totalYears : years}
                selectedItem={filterStamps === "total" ? filterYearTotal : filterYear}
                handleChange={handleYearChange}
                dropdownKey={"graph"}
              />
            </div>
          )}
        </div>
      </div>
      <Line data={data_stamps} options={options} />
    </Paper>
  );
};

export default StampsGraph;
