import { makeStyles, Typography, Collapse } from "@material-ui/core";
import React, { useContext, useState } from "react";
import LanguageContext from "../../store/LanguageContext";
import { MSButton } from "../../components/MSButton";
import ConfirmationContainer from "./ConfirmationContainer";
import MultiSelect from "./MultiSelect";
import { mapDropdownList, validateEmails } from "../../lib/helper";
import { InputWraper } from "../Shared/InputWrapper";

const InviteSection = ({
  tags,
  cards,
  locations,
  selectedStampLocations,
  setSelectedStampLocations,
  selectedLoyaltyCard,
  setSelectedLoyaltyCard,
  selectedTags,
  setSelectedTags,
  stampCardUsersSelected,
  setStampCardUsersSelected,
  allUsersSelected,
  setAllUsersSelected,
  loyaltyCardUsersSelected,
  setLoyaltyCardUsersSelected,
  isLoyaltyAvailable,
}) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [emails, setEmails] = useState("");
  const [emailsValidation, setEmailsValidation] = useState(true);
  const [file, setFile] = useState(null);

  const handleStampsChange = list => {
    mapDropdownList(list, selectedStampLocations, setSelectedStampLocations, locations[0]);
  };

  const handleLoyaltyChange = list => {
    mapDropdownList(list, selectedLoyaltyCard, setSelectedLoyaltyCard, cards[0]);
  };

  const handleTagsChange = list => {
    mapDropdownList(list, selectedTags, setSelectedTags, tags[0]);
  };

  const handleEmailsChange = event => {
    setEmails(event.target.value);
    event.target.value.length > 0 ? setEmailsValidation(validateEmails(event.target.value)) : setEmailsValidation(true);
  };

  const removeSelectedStamp = element => {
    setSelectedStampLocations(selectedStampLocations.filter(el => el.value !== element.value));
    // If selectedStampLocations is empty after the removal select the "All" option
    if (selectedStampLocations.filter(el => el.value !== element.value)) {
      setSelectedStampLocations(locations.filter(el => el.value === -1));
    }
  };

  const removeSelectedLoyaltyCard = element => {
    setSelectedLoyaltyCard(selectedLoyaltyCard.filter(el => el.value !== element.value));
    // If selectedLoyaltyCard is empty after the removal select the "All" option
    if (selectedLoyaltyCard.filter(el => el.value !== element.value)) {
      setSelectedLoyaltyCard(cards.filter(el => el.value === -1));
    }
  };

  const removeSelectedTag = element => {
    setSelectedTags(selectedTags.filter(el => el.value !== element.value));
  };

  const handleFiles = files => {
    setFile(files !== null ? files[0] : null);
  };

  const allUsersHandler = () => {
    setAllUsersSelected(!allUsersSelected);
    if (!allUsersSelected === true) {
      setStampCardUsersSelected(false);
      setLoyaltyCardUsersSelected(false);
      setSelectedLoyaltyCard([]);
      setSelectedTags([]);
      setSelectedStampLocations([]);
    }
  };

  const locationsHandler = () => {
    setStampCardUsersSelected(!stampCardUsersSelected);
    setSelectedStampLocations(locations.filter(el => el.value === -1));
    if (!stampCardUsersSelected === true) {
      setAllUsersSelected(false);
    }
  };

  const loyaltyCardsHandler = () => {
    setLoyaltyCardUsersSelected(!loyaltyCardUsersSelected);
    setSelectedLoyaltyCard(cards.filter(el => el.value === -1));
    setSelectedTags([]);
    if (!loyaltyCardUsersSelected === true) {
      setAllUsersSelected(false);
    }
  };

  return (
    <InputWraper title={"2. " + strings.loyaltyCardsPage.inviteUsersTitle}>
      <div className={classes.mainSubcontainer}>
        <Typography className={classes.title}>{strings.couponsPage.inviteViaList}</Typography>
        <div className={classes.buttonsRow}>
          <MSButton
            className={allUsersSelected ? classes.buttonSelected : classes.button}
            onClick={allUsersHandler}
            type={allUsersSelected ? "primary" : "secondary"}
          >
            {strings.couponsPage.allUsers}
          </MSButton>
          <MSButton
            className={stampCardUsersSelected ? classes.buttonSelected : classes.button}
            onClick={locationsHandler}
            type={stampCardUsersSelected ? "primary" : "secondary"}
          >
            {strings.couponsPage.stampCardUsers}
          </MSButton>
          <MSButton
            className={loyaltyCardUsersSelected ? classes.buttonSelected : classes.button}
            onClick={loyaltyCardsHandler}
            style={{ opacity: !isLoyaltyAvailable ? 0.5 : 1 }}
            disabled={!isLoyaltyAvailable}
            type={loyaltyCardUsersSelected ? "primary" : "secondary"}
          >
            {strings.couponsPage.loyaltyCardUsers}
          </MSButton>
        </div>
        <Collapse in={stampCardUsersSelected}>
          <Typography className={classes.dropdownTitle}>{strings.couponsPage.chooseStores}</Typography>
          <MultiSelect
            data={locations}
            selectedItem={selectedStampLocations}
            handleChange={handleStampsChange}
            removeItem={removeSelectedStamp}
          />
        </Collapse>
        <Collapse in={loyaltyCardUsersSelected}>
          <Typography className={classes.dropdownTitle}>{strings.couponsPage.chooseLoyaltyCards}</Typography>
          <MultiSelect
            data={cards}
            selectedItem={selectedLoyaltyCard}
            handleChange={handleLoyaltyChange}
            removeItem={removeSelectedLoyaltyCard}
          />
          <Typography className={classes.dropdownTitle}>{strings.couponsPage.chooseTags}</Typography>
          <MultiSelect
            data={tags}
            selectedItem={selectedTags}
            handleChange={handleTagsChange}
            removeItem={removeSelectedTag}
          />
        </Collapse>
        <ConfirmationContainer
          emails={emails}
          handleEmailsChange={handleEmailsChange}
          emailsValidation={emailsValidation}
          selectedStampLocations={selectedStampLocations}
          selectedLoyaltyCard={selectedLoyaltyCard}
          selectedTags={selectedTags}
          allUsersSelected={allUsersSelected}
          stampCardUsersSelected={stampCardUsersSelected}
          loyaltyCardUsersSelected={loyaltyCardUsersSelected}
          file={file}
          handleFiles={handleFiles}
          isLoyaltyAvailable={isLoyaltyAvailable}
        />
      </div>
    </InputWraper>
  );
};

const useStyles = makeStyles({
  mainSubcontainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 350,
    marginLeft: 30,
    paddingTop: 30,
  },

  title: {
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 20,
    color: "#333333",
    textAlign: "start",
  },

  dropdownTitle: {
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 20,
    color: "#333333",
    textAlign: "start",
    marginTop: 30,
  },

  buttonsRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
    flexWrap: "wrap",
  },

  buttonSelected: {
    height: 50,
    marginRight: 10,
    boxShadow: "none",
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 16,
    marginBottom: 5,
  },

  button: {
    height: 50,
    marginRight: 10,
    boxShadow: "none",
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 16,
    backgroundColor: "#EFEFEF !important",
    color: "#00000080 !important",
    border: "none",
    marginBottom: 5,
  },
});

export default InviteSection;
