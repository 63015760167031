import React, { useContext } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import LanguageContext from "../../store/LanguageContext";
import PerfectScrollbar from "react-perfect-scrollbar";
import SubscriptionCard from "./SubscriptionCard";

const CreatedSubscriptions = ({ productSubscriptions, handleSelected, selectedSubscription }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);

  return (
    <div className={classes.mainContainer}>
      <Typography className={classes.title}>{strings.storePage.yourCreatedSubs}</Typography>
      <Typography className={classes.subtitle}>{strings.storePage.clickOnSub}</Typography>
      <PerfectScrollbar className={classes.activeSubscriptionsContainer} onXReachEnd={() => null}>
        {productSubscriptions.map(item => {
          return (
            <SubscriptionCard
              key={item.id}
              selected={selectedSubscription.id === item.id}
              productSubscription={item}
              handleSelected={() => handleSelected(item)}
            />
          );
        })}
      </PerfectScrollbar>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontFamily: "DMSans",
    fontSize: 28,
    fontWeight: 700,
    color: "#333333",
    marginTop: 24,
  },
  activeSubscriptionsContainer: {
    display: "flex",
    overflowX: "auto",
  },
  mainContainer: {
    marginTop: 16,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    textAlign: "start",
  },
  subtitle: {
    fontFamily: "DMSans",
    fontSize: 20,
    color: "#333333",
    fontWeight: 400,
  },
});

export default CreatedSubscriptions;
