import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core";

const Copyright = ({isFooter}) => {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={isFooter ? classes.footer : classes.layout}>
      {"Copyright © "}
      <Link color="inherit" href="https://mystamp.se/">
        MyStamp
      </Link>{" "}
      {new Date().getFullYear()}
      {""}
    </Typography>
  );
};

const useStyles = makeStyles({
  footer: {
    color: "white",
    width: "100%",
    textAlign: "right",
  },
  layout: {
    color: "#2C2C2C",
    width: "100%",
    textAlign: "center",
  },
});

export default Copyright;
