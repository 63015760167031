import React, { useContext } from "react";
import { MenuItem, Select } from "@material-ui/core";
import LanguageContext from "../../store/LanguageContext";

const SelectLocation = ({selectedLocation, locations, handleLocationChange}) => {
  const strings = useContext(LanguageContext);

  return (
    <Select
      value={selectedLocation !== "none" ? selectedLocation : "none"}
      style={{ marginBottom: 20, width: "100%" }}
      onChange={handleLocationChange}
    >
      <MenuItem className="location" key="null_location" value={"none"}>
        {strings.none}
      </MenuItem>
      {locations ? (
        locations.map(element => (
          <MenuItem className="location" key={element.id} value={element.id}>
            {element.city_category.name + ": " + element.street_address}
          </MenuItem>
        ))
      ) : (
        <div></div>
      )}
    </Select>
  );
};

export default SelectLocation;
