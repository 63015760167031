import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import LanguageContext from "../../store/LanguageContext";
import { exportStampsList, getClaims } from "../../services/StampCardsServices";
import { errorToast } from "../Notifications/Notifications";
import { useStampCardsClaimsSectionStyles } from "./StampCardsClaimsSectionStyles";
import { MSTable } from "../MSTable";
import Spinner from "../../components/Shared/Spinner";
import { MSButton } from "../MSButton";
import consumer from "../../lib/actionCable";
import { v4 as uuidv4 } from "uuid";
import { getActiveOffers } from "../../services/OffersServices";
import { CircularProgress } from "@material-ui/core";

export const StampCardsClaimsSection = ({ setHasStampCardAccess }) => {
  const [claims, setClaims] = useState({ data: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [offers, setOffers] = useState([]);
  const [page, setPage] = useState(1);

  const classes = useStampCardsClaimsSectionStyles();
  const subscriptionRef = useRef(null);
  const strings = useContext(LanguageContext);

  useEffect(() => {
    const ac = new AbortController();
    async function fetchData() {
      try {
        const claimsRes = await getClaims(page);
        const offersRes = await getActiveOffers();
        setOffers(offersRes.data);
        setClaims(claimsRes);
      } catch (error) {
        if (error.code === 403) {
          setHasStampCardAccess(false);
        } else {
          errorToast({
            title: "Error",
            text: error.message,
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
    return () => ac.abort();
  }, [page, setHasStampCardAccess]);

  const hasStampOffer = useMemo(() => offers.some(offer => !offer.point_system), [offers]);

  const handleChangePage = (_, newPage) => {
    if (newPage) {
      setPage(newPage);
    }
  };

  const exportList = async () => {
    setIsSaving(true);
    let id = uuidv4();
    await setupSocketListener(id);
    setTimeout(() => {
      setIsSaving(false);
    }, 500);
  };

  const setupSocketListener = async id => {
    subscriptionRef.current = consumer.subscriptions.create(
      {
        channel: "ExportStampCardsChannel",
        export_id: id,
      },
      {
        connected: async () => {
          console.log("Socket connected");
          try {
            await exportStampsList(id);
          } catch (error) {
            errorToast({
              title: "Error",
              text: error.message,
            });
            consumer.disconnect();
          }
        },
        disconnected: () => {
          console.log("Socket disconnected");
        },
        received: data => {
          const url = window.URL.createObjectURL(new Blob([data.csv_file.content]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data.csv_file.file_name);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          consumer.disconnect();
        },
      },
    );
  };

  const columns = [
    { id: "offer_name", label: strings.stampCardPage.offer, align: "left", sortVisible: false },
    { id: "claimed_at", label: strings.stampCardPage.date, align: "left", sortVisible: false },
  ];

  const rows = claims.data.map((claim, index) => ({
    ...claim,
    claimed_at: new Date(claim.claimed_at).toLocaleString(),
    index,
  }));

  const pagination = claims.pagination;

  return (
    <section>
      {!isLoading && claims.pagination ? (
        hasStampOffer && (
          <div>
            <div className={classes.tableTitleContainer}>
              <Typography component="h1" variant="h5" className={classes.claimedStampCards}>
                {strings.stampCardPage.claimedStampCards}
              </Typography>
              <MSButton type="primary" disabled={isSaving} className={classes.exportButton} onClick={exportList}>
                {isSaving ? <CircularProgress color="primary" size={20} /> : strings.stampCardPage.exportList}
              </MSButton>
            </div>
            <MSTable
              columns={columns}
              rows={rows}
              pagination={pagination}
              rowsPerPage={10}
              customOrder={"asc"}
              customOrderBy={"index"}
              customHandleChangePage={handleChangePage}
              noSaveButton
              customString={strings.stampCardPage.showingClaims}
            />
          </div>
        )
      ) : (
        <Spinner />
      )}
    </section>
  );
};
