import { makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import ReactFileReader from "react-file-reader";
import { ReactComponent as PNGUpload } from "../../assets/icons/pngUpload.svg";
import CloseIcon from "@material-ui/icons/Close";
import LanguageContext from "../../store/LanguageContext";
import InputErrorBox from "../Shared/InputErrorBox";
import DateTimePicker from "../Shared/DateTimePicker";

const NewCouponForm = ({
  handleImageChange,
  image,
  setImage,
  dateFrom,
  handleDateFromChange,
  dateTo,
  handleDateToChange,
  datesValidation,
  scheduledAt,
  handleScheduledAtChange,
  totalRedemptions,
  handleTotalRedemptionsChange,
  shortDescription,
  handleShortDescriptionChange,
  longDescription,
  handleLongDescriptionChange,
  specifications,
  handleSpecificationsChange,
  shortDescriptionErrorMessage,
  longDescriptionErrorMessage,
  specificationsErrorMessage,
}) => {

  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [isDateFromOpen, setIsDateFromOpen] = useState(false);
  const [isDateToOpen, setIsDateToOpen] = useState(false);
  const [isScheduledAtOpen, setIsScheduledAtOpen] = useState(false);

  return (
    <div className={classes.leftContainer}>
      <div className={classes.titleBox}>
        <Typography className={classes.inputLabelTitle}>{strings.couponsPage.addImage}</Typography>
        <Typography className={classes.inputLabelSubtitle}>{strings.couponsPage.png}</Typography>
      </div>
      <div className={classes.pngContainer}>
        <ReactFileReader handleFiles={handleImageChange} base64={true} fileTypes={"image/png"}>
          {image === null ? (
            <div className={classes.pngTemplate}>
              <div className={classes.pngIconBox}>
                <PNGUpload className={classes.pngIcon} />
              </div>
            </div>
          ) : (
            <div className={classes.pngImageContainer}>
              <div className={classes.closeIconBox}>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={e => {
                    setImage(null);
                    e.stopPropagation();
                  }}
                />
              </div>
              <div className={classes.selectedImageBox}>
                <img src={image} alt={"coupon_image"} className={classes.selectedImage} />
              </div>
            </div>
          )}
        </ReactFileReader>
      </div>
      <div className={classes.titleBox}>
        <Typography className={classes.inputLabelTitle}>{strings.couponsPage.validityPeriod}</Typography>
      </div>
      <div className={classes.datesContainer}>
        <div className={classes.dateBox}>
          <Typography className={classes.dateInputLabel}>{strings.loyaltyCardsPage.dateFrom}</Typography>
          <DateTimePicker
            value={dateFrom}
            isDatePickerOpen={isDateFromOpen}
            setIsDatePickerOpen={setIsDateFromOpen}
            handleDateChange={handleDateFromChange}
          />
        </div>
        <div className={classes.dateBox}>
          <Typography className={classes.dateInputLabel}>{strings.loyaltyCardsPage.dateTo}</Typography>
          <DateTimePicker
            value={dateTo}
            isDatePickerOpen={isDateToOpen}
            setIsDatePickerOpen={setIsDateToOpen}
            handleDateChange={handleDateToChange}
          />
        </div>
      </div>
      {!datesValidation ? <InputErrorBox message={strings.loyaltyCardsPage.dateFromValidation} /> : null}
      <div className={classes.dateBox} style={{ marginTop: 25 }}>
        <Typography className={classes.dateInputLabel}>{strings.couponsPage.scheduledAt}</Typography>
        <DateTimePicker
          value={scheduledAt}
          isDatePickerOpen={isScheduledAtOpen}
          setIsDatePickerOpen={setIsScheduledAtOpen}
          handleDateChange={handleScheduledAtChange}
          includeTime={true}
        />
      </div>
      <div className={classes.titleBox}>
        <Typography className={classes.inputLabelTitle}>{strings.couponsPage.totalRedemptions}</Typography>
      </div>
      <input
        placeholder={"Ex. 120"}
        className={classes.totalRedemptionsInput}
        type="number"
        min={1}
        value={totalRedemptions}
        onKeyDown={evt => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
        onChange={handleTotalRedemptionsChange}
      />
      <div className={classes.titleBox}>
        <Typography className={classes.inputLabelTitle}>
          {strings.loyaltyCardsPage.shortDescriptionDefault}*
          <Typography component={"span"} className={classes.inputLabelSubtitle}>
            {strings.couponsPage.max72}
          </Typography>
        </Typography>
      </div>
      <textarea
        placeholder={strings.couponsPage.shortDescriptonExample}
        className={classes.descriptionInput}
        type="text"
        value={shortDescription}
        onChange={handleShortDescriptionChange}
      />
      {shortDescriptionErrorMessage.length > 0 ? <InputErrorBox message={shortDescriptionErrorMessage} /> : null}
      <div className={classes.titleBox}>
        <Typography className={classes.inputLabelTitle}>
          {strings.couponsPage.longDescription}*
          <Typography component={"span"} className={classes.inputLabelSubtitle}>
            {strings.couponsPage.max125}
          </Typography>
        </Typography>
      </div>
      <textarea
        placeholder={strings.couponsPage.longDescriptonExample}
        className={classes.descriptionInput}
        type="text"
        value={longDescription}
        onChange={handleLongDescriptionChange}
      />
      {longDescriptionErrorMessage.length > 0 ? <InputErrorBox message={longDescriptionErrorMessage} /> : null}
      <div className={classes.titleBox}>
        <Typography className={classes.inputLabelTitle}>
          {strings.couponsPage.specifications}
          <Typography component={"span"} className={classes.inputLabelSubtitle}>
            {strings.couponsPage.max80}
          </Typography>
        </Typography>
      </div>
      <textarea
        placeholder={strings.couponsPage.specificationsExample}
        className={classes.descriptionInput}
        type="text"
        value={specifications}
        onChange={handleSpecificationsChange}
      />
      {specificationsErrorMessage.length > 0 ? <InputErrorBox message={specificationsErrorMessage} /> : null}
    </div>
  );
};

const useStyles = makeStyles({
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 0.6,
    paddingLeft: 30,
    paddingBottom: 40,
    flexGrow: 1,
  },

  titleBox: {
    display: "flex",
    maxWidth: 600,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 30,
    textAlign: "start",
    marginBottom: 10,
    paddingRight: 50,
  },

  pngContainer: {
    maxWidth: 400,
    marginRight: 20,
  },

  pngTemplate: {
    display: "flex",
    flexDirection: "column",
    minWidth: 200,
    maxWidth: 400,
    height: 200,
    backgroundColor: "#E0E0E0",
    borderRadius: 16,
    justifyContent: "flex-start",
    cursor: "pointer",
  },

  pngImageContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 200,
    maxWidth: 400,
    height: 200,
    borderRadius: 16,
    justifyContent: "flex-start",
    cursor: "pointer",
    paddingBottom: 30,
    backgroundColor: "transparent",
    border: "0.5px solid #E0E0E0",
  },

  selectedImageBox: {
    display: "flex",
    width: "100%",
    justifyItems: "center",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    maxWidth: 300,
  },

  selectedImage: {
    display: "inline-block",
    maxWidth: 300,
    maxHeight: 140,
  },

  closeIconBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: 26,
    paddingRight: 7,
  },

  closeIcon: {
    width: 35,
    height: 35,
    marginBottom: -15,
  },

  pngIconBox: {
    display: "flex",
    width: "100%",
    justifyItems: "center",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  pngIcon: {
    width: 90,
    height: 95,
  },

  datesContainer: {
    display: "flex",
    width: "100%",
    minWidth: 300,
    maxWidth: 600,
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },

  dateBox: {
    display: "flex",
    minWidth: 170,
    maxWidth: 250,
    flexDirection: "column",
    justifyContent: "flex-start",
    flex: 1,
    marginRight: 20,
    marginBottom: 10,
  },

  descriptionInput: {
    display: "flex",
    width: "90%",
    minHeight: 144,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    borderRadius: 8,
    resize: "none",
    padding: "15px",
    color: "#333333",
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 5,
    "&:focus": {
      outlineWidth: 0,
    },
    "&::placeholder": {
      color: "#C4C4C4",
      fontFamily: "DMSans",
      fontSize: 18,
      fontWeight: 400,
    },
  },

  totalRedemptionsInput: {
    width: 80,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    height: 59,
    color: "#333333",
    fontFamily: "DMSans",
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 8,
    resize: "none",
    padding: "5px 10px",
    "&::placeholder": {
      color: "#C4C4C4",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "&[type=number]": {
      MozAppearance: "textfield",
    },
  },

  inputLabelTitle: {
    fontFamily: "DMSans",
    fontWeight: 600,
    fontSize: 20,
    color: "#333333",
    textAlign: "start",
  },

  dateInputLabel: {
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 20,
    color: "#333333",
    textAlign: "start",
  },

  inputLabelSubtitle: {
    fontFamily: "DMSans",
    fontSize: 20,
    color: "#333333",
    fontWeight: 400,
    marginLeft: 5,
  },
});

export default NewCouponForm;
