export default {
  input: {
    "&:-webkit-autofill": {
      transition: "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
    },
    "&:-webkit-autofill:focus": {
      transition: "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
    },
    "&:-webkit-autofill:hover": {
      transition: "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
    },
  },
}
