import { makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { motion } from "framer-motion";
import LanguageContext from "../../store/LanguageContext";
import mystampLogoImg from "../../assets/images/mystamp_logo_black.png";
import QRCode from "qrcode.react";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 10,
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  card: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    boxShadow: "0px 13.32px 53.29px 0px #1A1A1A29",
    borderRadius: "20px",
    padding: 40,
    paddingBottom: 45,
    top: "50%",
    width: "min(575px, 80vw)",
    minHeight: "min(569px, 80vh)",
    willChange: "transform",
  },
  imageContainer: {
    backgroundColor: "white",
    width: 198,
    height: 102,
    borderRadius: "24px",
    position: "absolute",
    padding: "16px 24px",
    top: -51,
    left: "50%",
    transform: "translateX(-50%)",
    boxShadow:
      "0px -2px 3px 0px #C4C4C41A, 0px -6px 6px 0px #C4C4C417, 0px -14px 9px 0px #C4C4C40D, 0px -25px 10px 0px #C4C4C403, 0px -39px 11px 0px #C4C4C400",
  },
  logoImg: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  headerContainer: {
    width: "100%",
    borderBottom: "1px solid #00000029",
    paddingBottom: 40,
    marginBottom: 44,
    marginTop: 50,
  },
  title: {
    fontFamily: "WorkSans",
    fontSize: 44,
    lineHeight: "46px",
    fontWeight: 600,
    color: "#333333",
    textAlign: "center",
    marginBottom: 10,
  },
  totalPaid: {
    fontFamily: "WorkSans",
    fontSize: 24,
    fontWeight: 400,
    color: "#00000099",
    lineHeight: "36px",
    textAlign: "center",
  },
  toClaim: {
    fontFamily: "WorkSans",
    fontSize: 32,
    fontWeight: 400,
    color: "#00000099",
    lineHeight: "36px",
    marginBottom: 16,
    textAlign: "center",
  },
  points: {
    fontFamily: "WorkSans",
    fontSize: 55,
    fontWeight: 600,
    textAlign: "center",
    lineHeight: "55px",
  },
}));

const PurchaseCard = ({ offerProvider, code, currency, points }) => {
  const strings = useContext(LanguageContext);
  const classes = useStyles();

  const language =
    strings && "getLanguage" in strings ? (strings?.getLanguage().includes("en") ? "en-GB" : "sv-SE") : "sv-SE";
  const displayPrice = price => {
    return new Intl.NumberFormat(language, {
      style: "currency",
      currency,
      maximumFractionDigits: 0,
    }).format(price);
  };
  const displayPoints = points => {
    return new Intl.NumberFormat(language, {
      maximumFractionDigits: 0,
    }).format(points);
  };

  return (
    <div className={classes.root}>
      <motion.div
        className={classes.card}
        initial={{
          transform: "translateY(200%)",
        }}
        animate={{
          transform: "translateY(-50%)",
        }}
        transition={{
          type: "spring",
          velocity: 75,
          stiffness: 280,
          damping: 50,
          mass: 6,
        }}
      >
        <div className={classes.imageContainer}>
          <img
            src={offerProvider?.tablet_logo_url}
            className={classes.logoImg}
            alt="Logo"
            style={{
              visibility: offerProvider?.tablet_logo_url ? "visible" : "hidden",
            }}
          />
        </div>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>{strings.pointsDashboard.purchase_card.title}</Typography>
          <Typography className={classes.totalPaid}>
            {strings.pointsDashboard.purchase_card.total_paid} {displayPrice((points ?? 0) / 10)}
          </Typography>
        </div>
        <Typography className={classes.toClaim}>{strings.pointsDashboard.purchase_card.to_claim}</Typography>
        <Typography className={classes.points}>
          {displayPoints(points)} {strings.pointsDashboard.purchase_card.points}
        </Typography>

        {offerProvider?.show_qr_after_point_receipt && code && (
          <QRCode
            value={code}
            style={{ height: 160, margin: "40px 0 120px" }}
            size={160}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={false}
            renderAs={"png"}
          />
        )}
        <img style={{ marginTop: "auto" }} src={mystampLogoImg} height={36} />
      </motion.div>
    </div>
  );
};

export default PurchaseCard;
