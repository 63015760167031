import { responsiveFontSizes, unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";
import DM_Sans from "./fonts/DMSans-Regular.ttf";
import DM_Mono from "./fonts/DMMono-Regular.ttf";
import WorkSans_Bold from "./fonts/WorkSans-Bold.ttf";
import WorkSans_Medium from "./fonts/WorkSans-Medium.ttf";
import WorkSans_ExtraBold from "./fonts/WorkSans-ExtraBold.ttf";
import WorkSans_Regular from "./fonts/WorkSans-Regular.ttf";
import WorkSans_SemiBold from "./fonts/WorkSans-SemiBold.ttf";

const dmSans = {
  fontFamily: "DMSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('DMSans'),
    local('DMSans-Regular'),
    url(${DM_Sans}) format('truetype')
  `,
};

const dmMono = {
  fontFamily: "DMMono",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('DMMono'),
    local('DMMono-Regular'),
    url(${DM_Mono}) format('truetype')
  `,
};

const workSansBold = {
  fontFamily: "WorkSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
    url(${WorkSans_Bold}) format('truetype')
  `,
};
const workSansSemiBold = {
  fontFamily: "WorkSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `
    url(${WorkSans_SemiBold}) format('truetype')
  `,
};
const workSansMedium = {
  fontFamily: "WorkSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
    url(${WorkSans_Medium}) format('truetype')
  `,
};
const workSansExtraBold = {
  fontFamily: "WorkSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 800,
  src: `
    url(${WorkSans_ExtraBold}) format('truetype')
  `,
};
const workSansRegular = {
  fontFamily: "WorkSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    url(${WorkSans_Regular}) format('truetype')
  `,
};

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      type: "light",
      black: "#000000",
      white: "#FFFFFF",
      primary: {
        500: "#EDB5A3",
        A400: "#EDB5A3",
      },
      secondary: {
        400: "#E8E8E8",
        500: "#EFEFEF",
        A400: "#EFEFEF",
      },
    },
    typography: {
      gray: "#A9A9A9",
      white: "#fff",
      htmlFontSize: 16,
      fontFamily: ["DMSans", "DMMono", "WorkSans", "Roboto", "Arial", "sans-serif"].join(","),
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            dmSans,
            dmMono,
            workSansBold,
            workSansSemiBold,
            workSansMedium,
            workSansExtraBold,
            workSansRegular,
          ],
        },
      },
    },
  }),
);
