import { makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { getBackground, getLineImage, getLogoBackground, getMImage, getTextColor } from "../../lib/cardThemesHelper";
import LanguageContext from "../../store/LanguageContext";
import ScrollContainer from "react-indiana-drag-scroll";

const ActiveCards = ({ cards, activeCard, onActiveChange }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const cardDesigns = ["Rosa", "Rosa & svart", "Silver", "Guld", "Egen design"];
  const [mappedCards, setMappedCards] = useState([]);

  useEffect(() => {
    const mappedCardsData = cards.map(card => {
      const cardItem = {
        id: card.id,
        company_name: card.company_name,
        company_image: card.company_logo,
        description: card.short_description,
        theme: cardDesigns[card.card_design],
        frontImage: card.front_image,
        invited_count: card.invited_count,
        interaction_count: card.interaction_count,
        active_loyalty_cards_count: card.active_loyalty_cards_count,
      };
      return cardItem;
    });
    if (mappedCardsData.length < 4) {
      for (let i = mappedCardsData.length; i < 4; i++) {
        const emptyCard = {
          id: "empty_" + i,
          company_name: strings.loyaltyCardsPage.companyNameDefault,
          company_image: null,
          description: strings.loyaltyCardsPage.shortDescriptionDefault,
          theme: "none",
          frontImage: null,
          invited_count: 0,
          interaction_count: 0,
          active_loyalty_cards_count: 0,
        };
        mappedCardsData.push(emptyCard);
      }
    }
    setMappedCards(mappedCardsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  return (
    <ScrollContainer horizontal={true} className={classes.activeCardsContainer}>
      {mappedCards.map(item => {
        return (
          <div
            key={item.id}
            onClick={() => {
              onActiveChange(item);
            }}
            className={classes.cardBox}
            style={{
              backgroundImage: getBackground(item.theme, item.frontImage),
              backgroundSize: "cover",
              boxShadow:
                activeCard !== null && activeCard.id === item.id
                  ? "2px 3px 10px 6px rgba(0,0,0,0.82)"
                  : "2px 3px 10px -2px rgba(0,0,0,0.82)",
            }}
          >
            {item.frontImage === null && (
              <>
                <div className={classes.companyImageBox}>
                  {item.theme !== "none" ? (
                    <div
                      style={{
                        backgroundImage: `url(${item.company_image})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                      className={classes.companyImage}
                    ></div>
                  ) : (
                    <div className={classes.companyImageEmpty}></div>
                  )}
                </div>
                <div className={classes.companyDetailsBox}>
                  <Typography
                    className={classes.companyName}
                    style={{
                      backgroundImage: getTextColor(item.theme),
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    {item.company_name}
                  </Typography>
                  <Typography className={classes.description}>{item.description}</Typography>
                </div>
                <div className={classes.dateBox}>
                  <div className={classes.validBox}>
                    <Typography className={classes.verifiedTitle}>{strings.loyaltyCardsPage.verifiedBy}</Typography>
                    <div className={classes.logoBox} style={{ background: getTextColor(item.theme) }}>
                      <div className={classes.logo} style={{ background: getLogoBackground(item.theme) }}>
                        {getMImage(item.theme)}
                      </div>
                    </div>
                  </div>
                </div>
                {getLineImage(item.theme)}
              </>
            )}
          </div>
        );
      })}
    </ScrollContainer>
  );
};

const useStyles = makeStyles({
  activeCardsContainer: {
    display: "flex",
    overflowX: "auto",
  },

  cardBox: {
    height: 205,
    margin: "20px 18px",
    flex: "0 0 327px",
    borderRadius: 10,
    cursor: "pointer",
  },

  companyImageBox: {
    display: "flex",
    flex: 1,
    marginLeft: 20,
    marginTop: 20,
    height: 60,
    alignItems: "center",
    justifyItems: "flex-start",
  },

  companyImage: {
    backgroundPosition: "center",
    height: 50,
    width: 80,
  },

  companyImageEmpty: {
    borderRadius: 16,
    backgroundColor: "#FBFBFB",
    height: 50,
    width: 80,
  },

  companyDetailsBox: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
  },

  companyName: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: "DMSans",
    textAlign: "start",
  },

  description: {
    color: "#FBFBFB",
    fontSize: 12,
    fontFamily: "DMMono",
    textAlign: "start",
  },

  dateBox: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginLeft: 20,
    marginRight: 20,
    marginBottom: -110,
  },

  validBox: {
    display: "flex",
    flexDirection: "column",
  },

  verifiedTitle: {
    color: "#FBFBFB",
    fontSize: 6,
    fontFamily: "DMSans",
    fontWeight: 700,
  },

  logoBox: {
    display: "flex",
    height: 40,
    width: 40,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0.10000000149011612px 0.10000000149011612px 0px #00000080 inset",
  },

  logo: {
    display: "flex",
    borderRadius: 8,
    height: 38,
    width: 38,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default ActiveCards;
