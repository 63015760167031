import Swal from "sweetalert2";
import "./notification_styles.css";

const defaultToast = {
  toast: true,
  showConfirmButton: false,
  grow: "row",
  timerProgressBar: true,
  position: "bottom",
  timer: 3000,
  title: null,
  text: null,
  html: null,
  confirmButtonText: "Uppfattat!",
  customClass: "notification-class",
};

const defaultPopup = {
  toast: false,
  position: "center",
  grow: null,
  allowOutsideClick: false,
};

export const errorPopup = (options, confirmAction) => {
  Swal.fire({
    ...defaultToast,
    ...defaultPopup,
    icon: "error",
    ...options,
  }).then(result => {
    if (result.isConfirmed && confirmAction !== undefined) {
      confirmAction();
    }
  });
};

export const successPopup = options => {
  Swal.fire({
    ...defaultToast,
    ...defaultPopup,
    icon: "success",
    ...options,
  });
};

export const successToast = options => {
  Swal.fire({
    ...defaultToast,
    icon: "success",
    ...options,
  });
};

export const errorToast = options => {
  Swal.fire({
    ...defaultToast,
    icon: "error",
    ...options,
  });
};

export const infoToast = options => {
  Swal.fire({
    ...defaultToast,
    icon: "success",
    ...options,
  });
};
