import React from "react";
import { Typography } from "@material-ui/core";
import { useStatsGridStatStyles } from "./StatsGridStatStyles";

export const StatsGridStat = props => {
  const classes = useStatsGridStatStyles(props);

  return (
    <div className={classes.root}>
      <Typography className={classes.valueTypography}>{props.value}</Typography>
      <Typography className={classes.labelTypography}>{props.label}</Typography>
    </div>
  );
};
