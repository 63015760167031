import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Table, TableContainer, TableBody, TablePagination, TableCell, TableRow } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "./MSTableStyles";
import { sortingOrders } from "../../lib/constants";
import { EnhancedTableHead } from "./EnhancedTableHead";
import { MSButton } from "../MSButton";
import LanguageContext from "../../store/LanguageContext";
import CircularProgress from "@material-ui/core/CircularProgress";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === sortingOrders.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const MSTable = ({
  columns,
  rows,
  saveAll,
  isSaving,
  pagination,
  rowsPerPage = 10,
  customOrder,
  customOrderBy,
  customHandleChangePage,
  noSaveButton,
  customString,
}) => {
  const [order, setOrder] = useState(customOrderBy || sortingOrders.ASC);
  const [orderBy, setOrderBy] = useState(customOrder || columns[0]?.id);
  const strings = useContext(LanguageContext);
  const classes = useStyles();

  const getRow = () => {
    return (
      <div className={classes.customRowContainer}>
        <div className={classes.paginationBox}>
          <Pagination
            count={pagination.last}
            page={pagination.page}
            color="primary"
            hideNextButton
            hidePrevButton
            onClick={e => customHandleChangePage(null, Number(e.target.innerText))}
          />
          <div
            style={{ marginLeft: 30 }}
            className={classes.labelButton}
            onClick={() => customHandleChangePage(null, pagination.prev)}
          >
            {strings.loyaltyCardsPage.previous}
          </div>
          <div className={classes.labelButton} onClick={() => customHandleChangePage(null, pagination.next)}>
            {strings.loyaltyCardsPage.next}
          </div>
          <div className={classes.labelButton} onClick={() => customHandleChangePage(null, pagination.last)}>
            {strings.loyaltyCardsPage.last}
          </div>
        </div>
        {!noSaveButton && (
          <MSButton disabled={isSaving} type={"secondary"} className={classes.saveAllButton} onClick={saveAll}>
            {isSaving ? <CircularProgress color="primary" size={20} /> : strings.loyaltyCardsPage.saveAll}
          </MSButton>
        )}
      </div>
    );
  };

  const getLabel = (from, to, count) => {
    if (pagination) {
      from = (pagination.page - 1) * rowsPerPage + 1;
      to = pagination.page * rowsPerPage;
      if (to > pagination.count) {
        to = pagination.count;
      }
    }
    return (
      <span className={classes.paginationBox}>
        <span className={classes.labelNormal}>
          {customString ? customString : strings.loyaltyCardsPage.displayUsers}
        </span>
        <span className={classes.labelBold}>
          {from} - {to}
        </span>
        <span className={classes.labelNormal}>{strings.loyaltyCardsPage.of}</span>
        <span className={classes.labelBold}>{count}</span>
        <span className={classes.labelNormal} style={{ marginRight: 40 }}>
          {strings.loyaltyCardsPage.total}
        </span>
      </span>
    );
  };

  const checkProps = id => {
    if (id !== "id" && id !== "isEdited" && id !== "isRemoved" && id !== "isExtended") {
      return true;
    }
    return false;
  };

  const filterColumns = columns => {
    return columns.filter(
      el => el.id !== "id" && el.id !== "isEdited" && el.id !== "isRemoved" && el.id !== "isExtended",
    );
  };

  const getCellStyle = (row, id) => {
    const style = {
      color: row["isRemoved"] && id !== "extend" ? "#00000040" : "#000000",
      textDecoration: row["isRemoved"] && id !== "extend" && id !== "remove" && id !== "tag" && "line-through",
    };
    return style;
  };

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === sortingOrders.ASC;
    setOrder(isAsc ? sortingOrders.DESC : sortingOrders.ASC);
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer className={classes.root}>
        <Table>
          <EnhancedTableHead
            headCells={filterColumns(columns)}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            classes={classes}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map((row, idx) => {
              return (
                <TableRow tabIndex={-1} key={row.id} className={idx % 2 ? classes.evenRow : classes.row}>
                  {columns.map(
                    ({ id, align }) =>
                      checkProps(id) && (
                        <TableCell key={id} align={align} className={classes.cell} style={getCellStyle(row, id)}>
                          {row[id]}
                        </TableCell>
                      ),
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        classes={{ root: classes.pagination, toolbar: classes.displayedRows, spacer: classes.spacer }}
        rowsPerPageOptions={[]}
        component="div"
        count={pagination.count}
        backIconButtonProps={{ style: { display: "none" } }}
        nextIconButtonProps={{ style: { display: "none" } }}
        ActionsComponent={
          pagination.count > 0
            ? () => getRow()
            : () => {
                return null;
              }
        }
        labelDisplayedRows={
          pagination.count > 0
            ? ({ from, to, count }) => getLabel(from, to, count)
            : () => {
                return null;
              }
        }
        rowsPerPage={rowsPerPage}
        page={pagination.page - 1}
        onPageChange={customHandleChangePage}
      />
    </>
  );
};

MSTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
};

export { MSTable };
