import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(10),
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    display: "flex",
    width: "fit-content",
    alignSelf: "center",
    color: "#0000008a",
    cursor: "pointer",
    paddingBottom: 5,
  },
  welcomeTo: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 32,
    letterSpacing: 0.02,
    marginTop: -135,
    marginBottom: 97,
    textAlign: "center",
    color: "#FFFFFF",
  },
  tabletText: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 32,
    letterSpacing: 0.02,
    textAlign: "center",
    color: "#FFFFFF",
  },
}));

export default useStyles;
