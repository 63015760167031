import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  orderRow: {
    display: "flex",
    flexDirection: "row",
  },
  orderOne: {
    display: "flex",
    flex: 2,
    justifyContent: "flex-start",
  },
  orderTwo: {
    flex: 1,
  },
  orderThree: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default useStyles;
