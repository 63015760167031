import { makeStyles } from "@material-ui/core";
import React from "react";
import CouponTemplate from "./CouponTemplate";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import NewCouponTemplate from "./NewCouponTemplate";

const ActiveCoupons = ({ coupons, activeCoupon, onActiveChange, loadMoreData, onEmptyCouponClick }) => {
  const classes = useStyles();

  const onReachEnd = () => {
    loadMoreData();
  };

  return (
    <div className={classes.mainContainer}>
      <NewCouponTemplate onEmptyCouponClick={onEmptyCouponClick} />
      <PerfectScrollbar className={classes.activeCouponsContainer} onXReachEnd={onReachEnd}>
        {coupons.map((item, index) => {
          return (
            <CouponTemplate
              key={item.id}
              item={item}
              index={index}
              activeCoupon={activeCoupon}
              onActiveChange={onActiveChange}
              isCreated={true}
            />
          );
        })}
      </PerfectScrollbar>
    </div>
  );
};

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    marginBottom: 50,
  },
  activeCouponsContainer: {
    display: "flex",
    overflowX: "auto",
  },
});

export default ActiveCoupons;
