import { makeStyles, Slide, Snackbar } from "@material-ui/core";
import React, { useContext, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import LanguageContext from "../../store/LanguageContext";

const ConnectionDetector = () => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [connection, setConnection] = useState(navigator.onLine);
  window.addEventListener(
    "online",
    () => {
      setConnection(true);
    },
    false,
  );
  window.addEventListener(
    "offline",
    () => {
      setConnection(false);
    },
    false,
  );
  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  return (
    <Snackbar
      open={!connection}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={SlideTransition}
      autoHideDuration={5000}
      transitionDuration={1000}
    >
      <Alert variant="filled" severity="warning" className={classes.alert}>
        {strings.noInternet}
      </Alert>
    </Snackbar>
  );
};

const useStyles = makeStyles({
  alert: {
    display: "flex",
    justifyContent: "center",
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
    backgroundColor: "white",
    color: "#2C2C2C",
    fontFamily: "DMSans",
  },
});

export default ConnectionDetector;
