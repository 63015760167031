import React, { useEffect, useState, useContext, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import LanguageContext from "../../store/LanguageContext";
import { getStatistics } from "../../services/StampCardsServices";
import { errorToast } from "../../components/Notifications/Notifications";
import { StatsGrid } from "../../components/StatsGrid";
import { StatsGridStat } from "../../components/StatsGridStat";
import Statistics from "../../components/CompanyDashboard/Statistics";
import { useStampCardsStatisticsSectionStyles } from "./StampCardsStatisticsSectionStyles";
import Spinner from "../../components/Shared/Spinner";
import Dropdown from "../Shared/Dropdown";
import { Spacing } from "../Spacing";
import { getActiveOffers } from "../../services/OffersServices";

export const StampCardsStatisticsSection = ({ setHasStampCardAccess }) => {
  const [stampCardStatistics, setStampCardStatistics] = useState({ locations: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [offers, setOffers] = useState([]);

  const classes = useStampCardsStatisticsSectionStyles();

  const strings = useContext(LanguageContext);

  const allLocationsOption = {
    label: strings.stampCardPage.allLocations,
    value: "",
  };

  const [location, setLocation] = useState(allLocationsOption);

  useEffect(() => {
    const ac = new AbortController();
    async function fetchData() {
      try {
        const statisticsRes = await getStatistics(location.value);
        const offersRes = await getActiveOffers();
        setOffers(offersRes.data);
        setStampCardStatistics(statisticsRes);
      } catch (error) {
        if (error.code === 403) {
          setHasStampCardAccess(false);
        } else {
          errorToast({
            title: "Error",
            text: error.message,
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
    return () => ac.abort();
  }, [location, setHasStampCardAccess]);

  const locationOptions = [
    allLocationsOption,
    ...stampCardStatistics.locations.map(location => ({
      label: `${location.city_category.name}, ${location.street_address}`,
      value: location.id,
    })),
  ];

  const hasStampOffer = useMemo(() => offers.some(offer => !offer.point_system), [offers]);

  const handleChange = item => {
    setLocation(item);
  };

  const stampsLocation = location.value
    ? `${strings.stampCardPage.stamps}\n(${location.label})`
    : strings.stampCardPage.stamps;

  return (
    <section>
      {!isLoading && stampCardStatistics.categories ? (
        <div>
          <StatsGrid valueFontSize={56} labelFontSize={18}>
            <StatsGridStat value={stampCardStatistics.stamps_count} label={stampsLocation} />
            <StatsGridStat value={stampCardStatistics.stamp_cards_count} label={strings.stampCardPage.activeStamps} />
            <StatsGridStat value={stampCardStatistics.claims_count} label={strings.stampCardPage.claimedStampCards} />
          </StatsGrid>
          <Spacing height={50} />
          <Typography component="h1" variant="h5" className={classes.stampsPerLocation}>
            {strings.stampCardPage.stampsPerLocation}
          </Typography>
          <div className={classes.locationSelect}>
            <Dropdown data={locationOptions} selectedItem={location} handleChange={handleChange} />
          </div>
          <Statistics hasStampOffer={hasStampOffer} data={stampCardStatistics} location={location} />
        </div>
      ) : (
        <Spinner />
      )}
    </section>
  );
};
