import { /* IconButton,  */ makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import LanguageContext from "../../store/LanguageContext";
//import { MSButton } from "../MSButton";
//import InputErrorBox from "../Shared/InputErrorBox";
//import ReactFileReader from "react-file-reader";
//import CloseIcon from "@material-ui/icons/Close";

const ConfirmationContainer = ({
  emails,
  emailsValidation,
  selectedStampLocations,
  selectedLoyaltyCard,
  selectedTags,
  allUsersSelected,
  stampCardUsersSelected,
  loyaltyCardUsersSelected,
  file,
  isLoyaltyAvailable,
}) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const emailsCheck = emails.length > 0 && emailsValidation;
  const allLoyaltyCards = selectedLoyaltyCard !== null && selectedLoyaltyCard[0]?.value === -1;
  const allStamps = selectedStampLocations !== null && selectedStampLocations[0]?.value === -1;

  const selectedStampsString =
    selectedStampLocations !== null
      ? selectedStampLocations.map((el, index) => {
        return el.label + (index === selectedStampLocations.length - 1 ? "" : ", ");
      })
      : "";

  const selectedLoyaltyCardsString =
    selectedLoyaltyCard !== null
      ? selectedLoyaltyCard.map((el, index) => {
        return el.label + (index === selectedLoyaltyCard.length - 1 ? "" : ", ");
      })
      : "";

  const selectedTagsStrings =
    selectedTags !== null
      ? selectedTags.map((el, index) => {
        return (
          el.label +
            (index === selectedTags.length - 1 ? "" : selectedTags.length === 2 ? " " + strings.and + " " : ", ")
        );
      })
      : "";

  return (
    <div className={classes.container}>
      <div className={classes.csvInvitiation}>
        {/*    <Typography className={classes.title}>{strings.couponsPage.inviteViaCSV}</Typography>
        <Typography className={classes.descriptionBold}>
          {strings.couponsPage.enterEmails}
          <Typography component={"span"} className={classes.descriptionNormal}>
            {strings.couponsPage.separateWithCommas}
          </Typography>
        </Typography>
        <input className={classes.emailInput} type="text" value={emails} onChange={handleEmailsChange} />
        {!emailsValidation ? <InputErrorBox message={strings.loyaltyCardsPage.invalidEmail} /> : null}
        <div className={classes.csvBox}>
          <ReactFileReader handleFiles={handleFiles} fileTypes={".csv"}>
            <MSButton type="secondary" className={classes.inviteButtonBox}>
              {strings.loyaltyCardsPage.selectCSV}
            </MSButton>
          </ReactFileReader>
          {file !== null && (
            <>
              <Typography className={classes.csvFile}>{file.name}</Typography>
              <IconButton aria-label="close" size={"small"} onClick={() => handleFiles(null)}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        </div> */}
      </div>
      <div className={classes.inviteInfo}>
        <Typography className={classes.title}>{strings.couponsPage.inviteMessagesTitle}</Typography>
        <ul className={classes.bulletListWrapper}>
          {allUsersSelected && (
            <li className={classes.bulletTextNormal}>
              {isLoyaltyAvailable ? strings.couponsPage.allUsersMessage_1 : strings.couponsPage.allUsersMessage_2}
            </li>
          )}
          {stampCardUsersSelected && !allUsersSelected && allStamps && (
            <li className={classes.bulletTextNormal}>
              <span className={classes.bulletTextBold}>{strings.couponsPage.allStampsMessage_1}</span>
              <span>{strings.couponsPage.allStampsMessage_2}</span>
              <span className={classes.bulletTextBold}>{strings.couponsPage.allStampsMessage_3}</span>
            </li>
          )}
          {stampCardUsersSelected && !allStamps && selectedStampsString.length > 0 && (
            <li className={classes.bulletTextNormal}>
              <span>{strings.couponsPage.selectedStampMessage_1}</span>
              <span className={classes.bulletTextBold}>
                {strings.couponsPage.selectedStampMessage_2}
                {selectedStampsString}
              </span>
            </li>
          )}
          {loyaltyCardUsersSelected && !allUsersSelected && allLoyaltyCards && selectedTagsStrings.length === 0 && (
            <li className={classes.bulletTextNormal}>
              <span className={classes.bulletTextBold}>{strings.couponsPage.allStampsMessage_1}</span>
              <span>{strings.couponsPage.allLoyaltyCardsMessage_1}</span>
              <span className={classes.bulletTextBold}>{strings.couponsPage.allLoyaltyCardsMessage_2}</span>
            </li>
          )}
          {loyaltyCardUsersSelected &&
            !allLoyaltyCards &&
            selectedLoyaltyCardsString.length > 0 &&
            selectedTagsStrings.length === 0 && (
            <li className={classes.bulletTextNormal}>
              <span className={classes.bulletTextBold}>{strings.couponsPage.selectedLoyaltyCardsMessage_1}</span>
              <span>{strings.couponsPage.allLoyaltyCardsMessage_1}</span>
              <span className={classes.bulletTextBold}>
                {strings.couponsPage.selectedLoyaltyCardsMessage_2}
                {selectedLoyaltyCardsString}
              </span>
            </li>
          )}
          {loyaltyCardUsersSelected &&
            !allLoyaltyCards &&
            selectedLoyaltyCardsString.length > 0 &&
            selectedTagsStrings.length > 0 && (
            <li className={classes.bulletTextNormal}>
              <span>
                {strings.couponsPage.selectedLoyaltyCardsMessage_1}
                {strings.couponsPage.allLoyaltyCardsMessage_1}
              </span>
              <span className={classes.bulletTextBold}>
                {strings.couponsPage.selectedLoyaltyCardsMessage_2}
                {selectedLoyaltyCardsString}
              </span>
              <span>{strings.couponsPage.loyaltyWithTagsMessage_1}</span>
              <span className={classes.bulletTextBold}>{selectedTagsStrings}</span>
            </li>
          )}
          {((loyaltyCardUsersSelected && selectedLoyaltyCardsString.length === 0) || allLoyaltyCards) &&
            selectedTagsStrings.length > 0 && (
            <li className={classes.bulletTextNormal}>
              <span>
                {strings.couponsPage.selectedLoyaltyCardsMessage_1}
                {strings.couponsPage.allLoyaltyCardsMessage_1}
              </span>
              <span className={classes.bulletTextBold}>{strings.couponsPage.allLoyaltyCardsMessage_2}</span>
              <span>{strings.couponsPage.loyaltyWithTagsMessage_1}</span>
              <span className={classes.bulletTextBold}>{selectedTagsStrings}</span>
            </li>
          )}
          {emailsCheck && (
            <li className={classes.bulletTextNormal}>
              <span className={classes.bulletTextBold}>{emails.split(",").length}</span>
              <span>{strings.couponsPage.emailsMessage_1}</span>
              <span className={classes.bulletTextBold}>{strings.couponsPage.emailsMessage_2}</span>
            </li>
          )}
          {file !== null && (
            <li className={classes.bulletTextNormal}>
              <span>{strings.couponsPage.csvMessage_1}</span>
              <span className={classes.bulletTextBold}>{strings.couponsPage.csvMessage_2}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 30,
  },

  csvInvitiation: {
    flex: 0.5,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    minHeight: 300,
    minWidth: 350,
    paddingRight: 30,
    flexGrow: 1,
  },

  inviteInfo: {
    flex: 0.5,
    width: "100%",
    minHeight: 300,
    minWidth: 350,
    backgroundColor: "#F0F0F0",
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    flexGrow: 1,
    paddingLeft: 50,
    paddingRight: 30,
  },

  title: {
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 20,
    color: "#333333",
    textAlign: "start",
    marginTop: 20,
  },

  descriptionBold: {
    fontFamily: "DMSans",
    fontWeight: 600,
    fontSize: 20,
    color: "#333333",
    textAlign: "start",
    marginTop: 20,
  },

  descriptionNormal: {
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 20,
    color: "#333333",
    textAlign: "start",
    marginTop: 20,
  },

  emailInput: {
    width: "80%",
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    height: 58,
    fontFamily: "DMSans",
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 8,
    resize: "none",
    marginTop: 10,
    padding: "5px 10px",
    "&::placeholder": {
      color: "#33333380",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
  },

  bulletListWrapper: {
    width: "100%",
    margin: "20px 0 0 0",
    padding: "0px 0px 0px 20px",
  },

  bulletTextBold: {
    fontWeight: 700,
  },

  bulletTextNormal: {
    fontSize: 18,
    fontFamily: "DMSans",
    fontWeight: 400,
    color: "#000000",
    textAlign: "start",
    letterSpacing: "-0.078px",
  },

  csvBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    height: 50,
    alignItems: "center",
    marginTop: 30,
  },

  inviteButtonBox: {
    display: "flex",
    height: 50,
    borderRadius: 38,
    padding: "5px 25px !important",
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 500,
  },

  csvFile: {
    marginLeft: 20,
    marginRight: 10,
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 700,
  },
});

export default ConfirmationContainer;
