import { Collapse, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow_up.svg";
import CouponPreview from "./CouponPreview";
import ScrollContainer from "react-indiana-drag-scroll";
import CouponTemplate from "./CouponTemplate";
import LanguageContext from "../../store/LanguageContext";

const PublishedCouponSection = ({ publishedCoupon }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [previewOpen, setPreviewOpen] = useState(true);
  let publishDate = new Date(publishedCoupon.publish_at);
  //const [couponsCount, setCouponsCount] = useState(2);
  //const [link] = useState("http://hjdkfkhjgfsfg.mystamp.com");
  //const [QR] = useState("http://sdfjjkddja.mystamp.com");

  /*  const handleCouponsCountChange = event => {
    setCouponsCount(event.target.value);
  }; */

  return (
    <div className={classes.container}>
      <div className={classes.titleBox}>
        {publishedCoupon.invited_count > 0 ? (
          <Typography className={classes.title}>{strings.couponsPage.publishedCouponText_v1}</Typography>
        ) : (
          <Typography className={classes.title}>
            {strings.couponsPage.scheduledAtPast_v1}
            {publishDate.toLocaleDateString()}
            {strings.couponsPage.scheduledAtPast_v2}
          </Typography>
        )}
      </div>
      {/*<hr className={classes.hrLine} />
       <div className={classes.shareBox}>
        <Typography className={classes.subTitle}>{strings.couponsPage.share}</Typography>
        <Typography className={classes.descriptionTitle}>{strings.couponsPage.couponsPerPerson}</Typography>
        <input
          className={classes.countInput}
          type="number"
          min={1}
          value={couponsCount}
          onKeyDown={evt => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
          onChange={handleCouponsCountChange}
        />
        <div className={classes.inputContainer}>
          <div className={classes.linkBox}>
            <Typography className={classes.linkTitle}>{strings.couponsPage.link}</Typography>
            <input className={classes.input} type="text" value={link} readOnly  />
          </div>
          <div className={classes.linkBox}>
            <Typography className={classes.linkTitle}>{strings.couponsPage.qr}</Typography>
            <input className={classes.input} type="text" value={QR} readOnly  />
          </div>
          <div className={classes.buttonBox}>
            <MSButton type="primary" className={classes.button}>
              {strings.couponsPage.saveAsPNG}
            </MSButton>
          </div>
        </div>
      </div>
      <hr className={classes.hrLine} /> */}
      <div className={classes.previewBox}>
        <div className={classes.previewTitleBox}>
          <div className={classes.previewTitle} onClick={() => setPreviewOpen(!previewOpen)}>
            <Typography className={classes.subTitle}>{strings.couponsPage.preview}</Typography>
            {previewOpen ? <ArrowUp height={14} width={23} /> : <ArrowDown height={14} width={23} />}
          </div>
        </div>
        <Collapse in={previewOpen} timeout={500} style={{ width: "100%" }}>
          <div className={classes.createdCouponContainer}>
            <CouponPreview
              coupon={publishedCoupon}
              totalRedemptions={publishedCoupon.redemption_limit !== null ? publishedCoupon.redemption_limit : 0}
              isCreated={true}
            />
            <div className={classes.couponBox}>
              <ScrollContainer horizontal={true} style={{ display: "flex" }}>
                <CouponTemplate item={publishedCoupon} isCreated={true} />
              </ScrollContainer>
            </div>
          </div>
        </Collapse>
        <hr className={classes.hrLine} />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginBottom: 30,
    padding: "0px 10px",
  },

  titleBox: {
    display: "flex",
    minHeight: 150,
    width: "50%",
    alignSelf: "center",
    paddingBottom: 50,
    justifyContent: "center",
  },

  title: {
    fontFamily: "DMSans",
    fontSize: 38,
    fontWeight: 400,
  },

  hrLine: {
    width: "100%",
    border: "none",
    height: "2px",
    background: "repeating-linear-gradient(90deg,#C4C4C4,#C4C4C4 6px,transparent 6px,transparent 12px)",
    marginBottom: 30,
  },

  shareBox: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "flex-start",
    marginBottom: 30,
  },

  previewBox: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "flex-start",
    marginBottom: 30,
  },

  subTitle: {
    fontFamily: "DMSans",
    fontSize: 28,
    fontWeight: 700,
    marginRight: 10,
  },

  descriptionTitle: {
    fontFamily: "DMSans",
    fontSize: 24,
    fontWeight: 500,
    marginTop: 15,
  },

  countInput: {
    width: 74,
    height: 50,
    textAlign: "center",
    marginTop: 15,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    fontFamily: "DMSans",
    fontSize: 20,
    fontWeight: 400,
    borderRadius: 8,
    resize: "none",
    padding: "5px 10px",
    "&::placeholder": {
      color: "#C4C4C4",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "&[type=number]": {
      MozAppearance: "textfield",
    },
  },

  inputContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },

  linkBox: {
    display: "flex",
    flex: 0.4,
    minWidth: 350,
    marginRight: 5,
    flexDirection: "column",
    alignItems: "flex-start",
  },

  buttonBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },

  button: {
    width: 200,
    height: 58,
    marginTop: 15,
    fontSize: 20,
    fontWeight: 700,
  },

  linkTitle: {
    fontFamily: "DMSans",
    fontSize: 24,
    fontWeight: 700,
    marginTop: 20,
  },

  input: {
    width: "100%",
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    height: 58,
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 400,
    borderRadius: 8,
    marginTop: 15,
    resize: "none",
    padding: "5px 10px",
    "&::placeholder": {
      color: "#33333380",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
  },

  previewTitleBox: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    cursor: "pointer",
  },

  previewTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  createdCouponContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    width: "100%",
    justifyContent: "center",
    minWidth: 350,
    flexWrap: "wrap",
  },

  couponBox: {
    minWidth: 350,
    display: "flex",
    justifyContent: "center",
  },
});

export default PublishedCouponSection;
