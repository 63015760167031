import React, { createContext, useEffect, useReducer } from "react";

const initState = {
  company: null,
};

let userReducer = (prevState, newState) => {
  let reducedState = { ...prevState, ...newState };
  return reducedState;
};

const getData = () => {
  const localData = localStorage.getItem("company");
  if (localData !== null && localData !== undefined) {
    return JSON.parse(localData);
  }
  localStorage.setItem("company", JSON.stringify(initState));
  return initState;
};

export const CompanyContextProvider = props => {
  const [company, setCompany] = useReducer(userReducer, getData());

  useEffect(() => {
    localStorage.setItem("company", JSON.stringify(company));
  }, [company]);

  return <CompanyContext.Provider value={{ company, setCompany }}>{props.children}</CompanyContext.Provider>;
};

export const CompanyContext = createContext();
