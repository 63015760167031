import React, { useContext } from "react";
import LanguageContext from "../../../store/LanguageContext";
import OrderRow from "../OrderRow/OrderRow";
import useStyles from "./styles";

const Order = ({ order, code, totalPrice }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);

  const currencySign = currency => {
    switch (currency) {
      case "SEK":
        return "kr";
      case "EUR":
        return "€";
      default:
        return "";
    }
  };

  return (
    <div className={classes.orderContainer}>
      <div className={classes.orderTable}>
        {order.products.map(product => (
          <OrderRow
            key={product.id}
            one={product.name}
            two={product.count > 1 ? `${product.count} x ${product.price}` : ""}
            three={product.price}
          />
        ))}
      </div>
      <div className={classes.orderTable}>
        <OrderRow bold one={strings.offer.total} three={`${totalPrice} ${currencySign(order.currency)}`} />
      </div>
    </div>
  );
};

export default Order;
