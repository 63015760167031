import React from "react";
import useStyles from "./styles";

const OrderRow = ({ one = "", two = "", three = "", bold = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.orderRow} style={{ fontWeight: bold ? 700 : 500 }}>
      <div className={classes.orderOne}>{one}</div>
      <div className={classes.orderTwo}>{two}</div>
      <div className={classes.orderThree}>{three}</div>
    </div>
  );
};

export default OrderRow;
