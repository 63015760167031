import React, { useState, useContext } from "react";
import { MSButton } from "../MSButton";
import { makeStyles, Tooltip } from "@material-ui/core";
import { errorToast } from "../Notifications/Notifications";
import LanguageContext from "../../store/LanguageContext";

const CopyLinkButton = ({ link = "" }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const onTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const onTooltipOpen = () => {
    navigator.permissions.query({ name: "clipboard-write" }).then(result => {
      if (result.state == "granted" || result.state == "prompt") {
        navigator.clipboard.writeText(link);
        setIsTooltipOpen(true);
      } else {
        errorToast({
          title: "Error",
          text: strings.stampCardPage.copiedLinkError,
        });
      }
    });
  };

  return (
    <div className={classes.container}>
      <Tooltip open={isTooltipOpen} title={strings.stampCardPage.copiedLink} leaveDelay={1000} onClose={onTooltipClose}>
        <div>
          <MSButton type="secondary" onClick={onTooltipOpen} className={classes.tableButtonBox}>
            {strings.stampCardPage.copyLink}
          </MSButton>
        </div>
      </Tooltip>
    </div>
  );
};
export default CopyLinkButton;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },

  tableButtonBox: {
    height: 30,
    minWidth: 120,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
    boxShadow: "0px 1px 2px 0px #00000040",
    border: "0.5px solid #C4C4C4",
    borderRadius: 38,
    padding: "0px 10px",
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#EDB5A3",
      color: "white",
    },
  },
});
