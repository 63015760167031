import { makeStyles } from "@material-ui/core";

export const useStampCardStampStyles = makeStyles(theme => ({
  root: {
    backgroundColor: ({ stamp }) => (stamp ? theme.palette.primary.main : theme.palette.secondary.main),
    height: 16,
    width: 16,
    borderRadius: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: ({ stamp }) => (stamp ? "" : "inset 0 0px 1px #ffffff"),
  },
  image: {
    height: 8,
    width: 8,
  },
  text: {
    fontSize: 8,
    fontWeight: 700,
    color: theme.palette.secondary.dark,
  },
}));
