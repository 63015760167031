import React, { useContext, useState } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { inviteUserViaEmail } from "../../services/LoyaltyCardsServices";
import { validateEmail } from "../../lib/helper";
import { MSButton } from "../MSButton";
import LanguageContext from "../../store/LanguageContext";
import InputErrorBox from "../Shared/InputErrorBox";
import InputConfirmationBox from "../Shared/InputConfirmationBox";
import FilterItem from "./FilterItem";
import { errorToast } from "../Notifications/Notifications";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const EmailInvitation = ({ activeCard, currentTags, handleInviteSent }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  let today = new Date();
  let dateToHelper = new Date(today.setMonth(today.getMonth() + 1));
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(dateToHelper);
  const [email, setEmail] = useState("");
  const [emailValidation, setEmailValidation] = useState(true);
  const [datesValidation, setDatesValidation] = useState(true);
  const [isInvited, setIsInvited] = useState(false);
  const [selectedTag, setSelectedTag] = React.useState(null);
  const [isInvitedByEmailOk, setIsInvitedByEmailOk] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDateFromOpen, setIsDateFromOpen] = useState(false);
  const [isDateToOpen, setIsDateToOpen] = useState(false);

  const handleTagChange = tag => {
    setSelectedTag(tag);
  };

  const inviteUser = async () => {
    if (email.length === 0) {
      setEmailValidation(false);
      return;
    }
    if (dateFrom >= dateTo) {
      setDatesValidation(false);
      return;
    }
    let formData = new FormData();
    formData.append("invite_email", email);
    formData.append("valid_from", dateFrom);
    formData.append("expires_at", dateTo);
    formData.append("tag_id", selectedTag !== null ? selectedTag.value : null);
    try {
      await inviteUserViaEmail(activeCard.id, formData);
      setEmail("");
      setSelectedTag(null);
      setDateFrom(new Date());
      setDateTo(dateToHelper);
      setIsInvitedByEmailOk(true);
      setIsInvited(true);
      handleInviteSent();
      setTimeout(() => {
        setIsInvited(false);
      }, 5000);

    } catch (error) {
      if (error.code === 400) {
        setIsInvited(true);
        setErrorMessage(error.message);
        setTimeout(() => {
          setIsInvited(false);
          setErrorMessage("");
        }, 5000);
        return;
      }
      errorToast({
        title: "Error",
        text: error.message,
      });
    }
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
    event.target.value.length > 0 ? setEmailValidation(validateEmail(event.target.value)) : setEmailValidation(true);
  };

  const handleDateFromChange = date => {
    setDateFrom(date);
    setDatesValidation(dateTo > date);
  };

  const handleDateToChange = date => {
    setDateTo(date);
    setDatesValidation(date > dateFrom);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.mainCointainer}>
        <Typography className={classes.inputTitle}>{strings.loyaltyCardsPage.email}</Typography>
        <input
          placeholder={"Ex. user@name.com"}
          className={classes.emailInput}
          type="text"
          value={email}
          onChange={handleEmailChange}
        />
        {!emailValidation ? (
          <InputErrorBox
            message={
              email.length === 0 ? strings.loyaltyCardsPage.emailRequired : strings.loyaltyCardsPage.invalidEmail
            }
          />
        ) : null}
        <div className={classes.datesContainer}>
          <div className={classes.dateBox}>
            <Typography className={classes.inputTitle}>{strings.loyaltyCardsPage.dateFrom}</Typography>
            <KeyboardDatePicker
              value={dateFrom}
              open={isDateFromOpen}
              onOpen={() => setIsDateFromOpen(true)}
              onClose={() => setIsDateFromOpen(false)}
              onChange={date => handleDateFromChange(date)}
              minDate={new Date()}
              format="dd/MM/yyyy"
              views={["year", "month", "date"]}
              animateYearScrolling
              className={classes.datePickerContainer}
              TextFieldComponent={props => <TextField {...props} onClick={() => setIsDateFromOpen(true)} />}
              keyboardIcon={<ArrowDown height={7} width={11} />}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
                fullWidth: true,
              }}
            />
          </div>
          <div className={classes.dateBox}>
            <Typography className={classes.inputTitle}>{strings.loyaltyCardsPage.dateTo}</Typography>
            <KeyboardDatePicker
              value={dateTo}
              open={isDateToOpen}
              onOpen={() => setIsDateToOpen(true)}
              onClose={() => setIsDateToOpen(false)}
              onChange={date => handleDateToChange(date)}
              minDate={new Date()}
              format="dd/MM/yyyy"
              views={["year", "month", "date"]}
              animateYearScrolling
              className={classes.datePickerContainer}
              TextFieldComponent={props => <TextField {...props} onClick={() => setIsDateToOpen(true)} />}
              keyboardIcon={<ArrowDown height={7} width={11} />}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
                fullWidth: true,
              }}
            />
          </div>
        </div>
        {!datesValidation ? <InputErrorBox message={strings.loyaltyCardsPage.dateFromValidation} /> : null}
        <Typography className={classes.tagsInputTitle}>{strings.loyaltyCardsPage.tagUsers}</Typography>
        <FilterItem
          currentTags={currentTags}
          selectedTag={selectedTag}
          handleTagChange={handleTagChange}
          tagKey="email"
          handleTagRemove={handleInviteSent}
        />
        <MSButton type="secondary" className={classes.inviteButtonBox} onClick={inviteUser}>
          {strings.loyaltyCardsPage.invite}
        </MSButton>
        {isInvited ? (
          isInvitedByEmailOk ? (
            <InputConfirmationBox message={strings.loyaltyCardsPage.invitationSuccessful} />
          ) : (
            <InputErrorBox
              message={errorMessage.length > 0 ? errorMessage : strings.loyaltyCardsPage.invitationUnsuccessful}
            />
          )
        ) : null}
      </div>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles({
  tagsInputTitle: {
    height: 35,
    fontFamily: "DMSans",
    fontSize: 20,
    fontWeight: 500,
    color: "#333333",
    marginRight: 10,
    paddingBottom: 5,
    alignSelf: "flex-start",
    marginTop: 20,
  },

  inputTitle: {
    height: 35,
    fontFamily: "DMSans",
    fontSize: 20,
    fontWeight: 500,
    color: "#333333",
    marginRight: 10,
    paddingBottom: 5,
    alignSelf: "flex-start",
  },

  mainCointainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
    paddingBottom: 20,
    textAlign: "start",
  },

  emailInput: {
    width: "90%",
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    height: 47,
    fontFamily: "DMSans",
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 8,
    resize: "none",
    padding: "5px 10px",
    "&::placeholder": {
      color: "#33333380",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
  },

  datesContainer: {
    display: "flex",
    width: "90%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },

  dateBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "45%",
  },

  datePickerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 47,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    borderRadius: 8,
    border: "0.5px solid #C4C4C4",
    resize: "none",
    padding: "5px 5px 5px 10px",
    backgroundColor: "#FFFFFF",
    "& *": {
      cursor: "pointer",
    },
  },

  tags: {
    width: "90%",
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    height: 47,
    borderRadius: 8,
    padding: "5px 10px",
    alignContent: "center",
    backgroundColor: "#FFFFFF",
  },

  inviteButtonBox: {
    display: "flex",
    alignItems: "center",
    boxShadow: "1px 2px 4px 0px #00000040",
    justifyContent: "center",
    border: "0.5px solid #C4C4C4",
    cursor: "pointer",
    height: "50px",
    width: "30%",
    borderRadius: 38,
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 500,
    color: "#333333",
    marginTop: 30,
    marginBottom: 5,
    "&:hover": {
      backgroundColor: "#EDB5A3",
      color: "white",
    },
  },
});

export default EmailInvitation;
