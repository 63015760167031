import React, { useState, useContext } from "react";
import { Slide, Fade } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Offer from "./Offer/Offer";
import { UserContext } from "../../store/UserContext";
import { Redirect } from "react-router-dom";

const Offers = ({ getOfferProviderData, setOffers, redirect, offers, setLoadOffers, consumer, setFade }) => {
  const [activeIndex, setActiveIndex] = useState(false);
  const [blocked, setBlock] = useState(false);
  const { setState } = useContext(UserContext);

  const toggleOffer = (index, value) => {
    let tempOffers = [...offers];
    for (let i = 0; i < offers.length; i++) {
      if (i === index) {
        tempOffers[i].viewQrCode = value;
        tempOffers[i].slide = true;
        tempOffers[i].transform = getTransform(index, offers.length, value);
        setActiveIndex(value ? index : false);
        setState({ selected: value });
      } else {
        tempOffers[i].viewQrCode = false;
        tempOffers[i].slide = !value;
      }
    }
    setOffers(tempOffers);
  };

  const gridSize = index => {
    if (offers.length === 4) {
      return 6;
    } else if (offers.length === 2 || offers.length === 1) {
      return 12;
    } else if (offers.length === 3 && index === 0) {
      return 12;
    }
    return 6;
  };

  const getTransform = (index, length, isActive) => {
    let el = window.document.getElementById(`transform-div-${index}`);
    if (el !== null) {
      const { x, y } = el.getBoundingClientRect();
      const offsetX = x - window.innerWidth / 3.3;
      const offsetY = y - window.innerHeight / 3.3;
      return `translate(${
        isActive
          ? `${gridSize(index) === 12 ? 0 : -offsetX}px, ${gridSize(index) === 12 ? -offsetY - 50 : -offsetY}px`
          : "0"
      }) scale(${isActive ? (gridSize(index) === 12 && !(index === 1) ? 1.2 : 1.6) : 1})`;
    }
  };

  const itemIsActive = index => activeIndex === index;

  const getDirection = index => {
    const length = offers.length;
    if (length === 1) {
      return "up";
    }
    if (length === 2) {
      return index === 0 ? "down" : "up";
    }
    if (length === 3) {
      return index === 0 ? "down" : "up";
    }
    if (length === 4) {
      return index % 2 === 0 ? "right" : "left";
    }
  };

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <Grid container alignItems="center" justifyContent="center" style={{ height: "100%" }}>
      <div
        style={{ position: "absolute", top: 0, left: 0, width: 50, height: 50 }}
        onTouchStart={getOfferProviderData}
      />
      {offers.map((offer, i) => (
        <Grid
          item
          lg={gridSize(i)}
          key={i}
          style={{
            zIndex: itemIsActive(i) ? 10 : 1,
            padding: "0 30px 30px 30px",
          }}
        >
          <div
            id={`transform-div-${i}`}
            style={{
              willChange: "transform",
              transition: "all 600ms ease-in-out",
              transform: offer.transform,
            }}
          >
            <Fade in={offer.slide} timeout={600}>
              <div>
                <Slide in={offer.slide} direction={getDirection(i)} timeout={600}>
                  <div>
                    <Offer
                      cableApp={consumer}
                      offer={offer}
                      blockTouch={[blocked, setBlock]}
                      size={getSize(offers.length, i)}
                      viewQrCode={offer.viewQrCode}
                      setFade={setFade}
                      setViewQrCode={value => {
                        toggleOffer(i, value);
                      }}
                      setLoadOffers={setLoadOffers}
                    />
                  </div>
                </Slide>
              </div>
            </Fade>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

const getSize = (length, index) => {
  if (length === 1) {
    return "65vw";
  }
  return index === 0 && length !== 4 ? "54vw" : "36vw";
};

export default Offers;
