import React, { useState, useContext } from "react";
import useStyles from "./styles";
import Logo from "./Logo/Logo";
import { UserContext } from "../store/UserContext";
import Dialog from "../components/Dialog/Dialog";
import Button from "@material-ui/core/Button";
import Zoom from "@material-ui/core/Zoom";
import Typography from "@material-ui/core/Typography";
import { toggleFullScreen } from "../lib/helper";
import LanguageContext from "../store/LanguageContext";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { LocationContext } from "../store/LocationContext";
import logo from "../assets/images/mystamp_logo.png";
import theme from "./theme/theme";
import { ThemeProvider } from "@material-ui/core";
import ConnectionDetector from "../components/Shared/ConnectionDetector";
import SelectLocation from "../components/Dashboard/SelectLocation";
import { roles } from "../lib/constants";
import LanguageSelectContext from "../store/LanguageSelectContext";

const Layout = ({ isSignIn, children, isPointUI = false }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const changeLanguage = useContext(LanguageSelectContext);
  const { state, setState } = useContext(UserContext);
  const { location, setLocation } = useContext(LocationContext);
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState();

  const holdStart = () => {
    setTimer(
      setTimeout(() => {
        setOpen(true);
      }, 1000),
    );
  };
  const holdEnd = () => {
    clearTimeout(timer);
    setTimer(null);
  };

  const handleMenuClick = action => {
    switch (action) {
      case "logout":
        setState({ authenticated: false, user: null });
        setLocation("");
        break;
      default:
        break;
    }
    setOpen(false);
  };

  const handleLocationChange = event => {
    setLocation({ location_id: event.target.value });
  };

  function PoweredBy() {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 195,
          bottom: 28,
          margin: "auto",
        }}
      >
        <Typography
          style={{
            fontFamily: "MetroPolis !important",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 21,
            textAlign: "center",
            color: "#d3d3d3",
            letterSpacing: 5,
          }}
        >
          Powered by
        </Typography>
      </div>
    );
  }

  function MyStampLogo() {
    return (
      <div
        style={{
          position: "absolute",
          left: 150,
          right: 0,
          bottom: 70,
          margin: "auto",
        }}
      >
        <img
          src={logo}
          alt={"myStampLogo"}
          style={{
            userSelect: "none",
            position: "absolute",
            zIndex: 1,
            left: 0,
            right: 0,
            margin: "auto",
            height: 50,
          }}
        />
      </div>
    );
  }

  function CompanyLogo(props) {
    return props.showLogo ? (
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 170,
          margin: "auto",
        }}
      >
        <img
          src={state.user.tablet_logo_url}
          alt="logo"
          style={{
            userSelect: "none",
            position: "absolute",
            zIndex: 0,
            left: 0,
            right: 0,
            margin: "auto",
            height: 90,
          }}
        />
      </div>
    ) : (
      <div></div>
    );
  }

  let showBackgroundVideo = state.authenticated;
  let showBackgroundImage = state.authenticated;

  if (state.user && state.user.tablet_background_image_url) {
    showBackgroundImage = showBackgroundImage && true;
    showBackgroundVideo = false;
  } else if (state.user && state.user.tablet_background_video_url) {
    showBackgroundVideo = showBackgroundVideo && true;
    showBackgroundImage = true;
  } else {
    showBackgroundVideo = false;
    showBackgroundImage = false;
  }

  let showLogo = state.authenticated;

  if (state.user && state.user.tablet_logo_url) {
    showLogo = showLogo && false;
  } else {
    showLogo = false;
  }

  // TODO(Rasmus) Break backgrounds to components
  return (
    <ThemeProvider theme={theme}>
      <div
        align="center"
        style={{
          position: "absolute",
          top: 0,
          minHeight: 85,
          right: 0,
          left: 0,
          height: "100%",
          overflow: "hidden",
        }}
      >
        {!showBackgroundVideo || isPointUI ? (
          !isPointUI && showBackgroundImage ? (
            <div
              alt="background image"
              style={{
                backgroundColor: "#EDB5A3",
                width: "100%",
                height: "100%",
                backgroundImage: `url(${state.user.tablet_background_image_url})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                onTouchStart={holdStart}
                onTouchEnd={holdEnd}
                onMouseDown={holdStart}
                onMouseUp={holdEnd}
                className={classes.logoutFab}
              >
                Hold
              </div>
              <div>{children}</div>
              <CompanyLogo showLogo={showLogo} />
              {/* <PoweredBy />
              <MyStampLogo /> */}
            </div>
          ) : (
            <div
              style={{
                backgroundColor: isPointUI ? "#FFFFFF" : "#EDB5A3",
                width: "100%",
                height: isPointUI ? "100%" : "50%",
              }}
            >
              <div
                onTouchStart={holdStart}
                onTouchEnd={holdEnd}
                onMouseDown={holdStart}
                onMouseUp={holdEnd}
                className={classes.logoutFab}
              >
                Hold
              </div>
              {!isPointUI && <Logo isSignIn={isSignIn} />}
              <div
                style={{
                  transform: `rotate(180deg) translateX(${state.selected ? 400 : 0}px)`,
                  position: "absolute",
                  right: -400,
                  left: 0,
                  top: "48%",
                  zIndex: -1,
                  transition: "all 0.6s ease-in-out",
                  willChange: "transform",
                }}
              >
                <svg viewBox="0 0 800 336">
                  <path
                    d="M668.036 1.58413C737.179 15.8809 814 134.249 814 134.249V336H-240V134.249C-240 134.249 -163.645 -8.40817 -94.0357 1.58364C-10.8469 13.5247 9.55609 134.768 93.2715 134.249C170.216 133.772 191.785 22.8894 268.7 20.1072C350.175 17.16 375.665 132.016 457.155 134.249C549.003 136.766 577.356 -17.1657 668.036 1.58413Z"
                    fill="#EDB5A3"
                  />
                </svg>
              </div>
              <div>{children}</div>
            </div>
          )
        ) : (
          <div>
            <video
              id="background-video"
              loop
              autoPlay
              muted
              controls={false}
              playsInline
              style={{
                zIndex: -10,
                WebkitTransform: "translateX(-50%) translateY(-50%)",
                transform: "translateX(-50%) translateY(-50%)",
                minWidth: "100%",
                minHeight: "100%",
                objectFit: "cover",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <source src={state.user.tablet_background_video_url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div
              onTouchStart={holdStart}
              onTouchEnd={holdEnd}
              onMouseDown={holdStart}
              onMouseUp={holdEnd}
              className={classes.logoutFab}
            >
              Hold
            </div>
            <div>{children}</div>
            <CompanyLogo showLogo={showLogo} />
            <PoweredBy />
            <MyStampLogo />
          </div>
        )}

        <Dialog
          transitionDuration={400}
          transition={Zoom}
          onClose={() => setOpen(false)}
          // Copied from SignIn component
          open={
            open ||
            ((typeof state.role === "undefined" || state.role === roles.TABLET) &&
              state.authenticated &&
              location.location_id === null)
          }
          closeIconColor={"#000000"}
          style={{ padding: 50 }}
        >
          <div style={{ alignItems: "center", textAlign: "center", margin: "auto" }}>
            <Typography className={classes.whatDoYouWantToDo}>{strings.whatDoYouWantToDo}</Typography>
            {state.authenticated ? (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel>{strings.location}</InputLabel>
                  <SelectLocation
                    selectedLocation={location.location_id}
                    locations={state.user?.locations}
                    handleLocationChange={handleLocationChange}
                  />
                </FormControl>
              </Box>
            ) : null}

            <br />
            <Button
              onClick={toggleFullScreen}
              variant="contained"
              style={{ background: "#edb5a3", color: "#FFF", borderRadius: 16, marginBottom: 20 }}
            >
              Fullscreen
            </Button>
            <div>
              <Button
                onClick={() => changeLanguage("en")}
                variant="contained"
                style={{ background: "#edb5a3", color: "#FFF", borderRadius: 16, marginBottom: 20, marginRight: 5 }}
              >
                English
              </Button>
              <Button
                onClick={() => changeLanguage("sv")}
                variant="contained"
                style={{ background: "#edb5a3", color: "#FFF", borderRadius: 16, marginBottom: 20, marginLeft: 5 }}
              >
                Svenska
              </Button>
            </div>
            <br />
            {state.authenticated ? (
              <Button
                onClick={() => handleMenuClick("logout")}
                variant="contained"
                style={{
                  background: "#edb5a3",
                  color: "#FFF",
                  borderRadius: 16,
                }}
              >
                {strings.signOut}
              </Button>
            ) : null}
            <br />
          </div>
        </Dialog>
        <ConnectionDetector />
      </div>
    </ThemeProvider>
  );
};

export default Layout;
