import { makeStyles } from "@material-ui/core";

export const useStatsGridStatStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    flexBasis: 0,
  },
  valueTypography: {
    fontFamily: "DMSans",
    fontStyle: "Normal",
    fontWeight: 700,
    fontSize: ({ valueFontSize = 56 }) => valueFontSize,
    textAlign: "center",
    color: "#EDB5A3",
  },
  labelTypography: {
    fontFamily: "DMMono",
    fontStyle: "Regular",
    fontSize: ({ labelFontSize = 18 }) => labelFontSize,
    textAlign: "center",
    whiteSpace: "pre-line",
  },
}));
