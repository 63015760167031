import React, { useContext, useState, useEffect, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import LanguageContext from "../../store/LanguageContext";
import { useStampCardsCreateStampSectionStyles } from "./StampCardsCreateStampSectionStyles";
import { MSButton } from "../MSButton";
import { Collapse, Tooltip } from "@material-ui/core";
import { postQRCodeBackOffice } from "../../services/OneTimeQRCodesServices";
import QRCode from "qrcode.react";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import AddIcon from "@material-ui/icons/Add";
import { errorToast } from "../Notifications/Notifications";

export const StampCardsCreateStampSection = ({ selectedOffer }) => {
  const classes = useStampCardsCreateStampSectionStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [qrCode, setQrCode] = useState({});
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [points, setPoints] = useState(0);
  const strings = useContext(LanguageContext);

  const downloadAnchor = useRef(null);

  useEffect(() => {
    setIsOpen(false);
  }, [selectedOffer]);

  const onTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const onTooltipOpen = () => {
    navigator.permissions.query({ name: "clipboard-write" }).then(result => {
      if (result.state == "granted" || result.state == "prompt") {
        navigator.clipboard.writeText(qrCode.code);
        setIsTooltipOpen(true);
      } else {
        errorToast({
          title: "Error",
          text: strings.stampCardPage.copiedLinkError,
        });
      }
    });
  };

  const handleClick = async () => {
    try {
      const data = await postQRCodeBackOffice(selectedOffer.id, null, points);
      setQrCode(data);
      setIsOpen(true);
    } catch (error) {
      errorToast({
        title: "Error",
        text: error.message,
      });
    }
  };

  const downloadQRCode = () => {
    const canvas = document.querySelector(".code > canvas");
    downloadAnchor.current.href = canvas.toDataURL();
    downloadAnchor.current.download = `stamp_${qrCode.id}.png`;
  };

  const renderPointsInput = () => {
    return (
      <div className={classes.pointsContainer}>
        <Typography className={classes.inputLabelTitle}>{strings.couponsPage.points}</Typography>
        <input
          placeholder={"Ex. 120"}
          className={classes.pointsInput}
          type="number"
          value={points}
          onChange={event => setPoints(event.target.value)}
        />
      </div>
    );
  };

  return (
    <section>
      {selectedOffer.point_system && renderPointsInput()}
      <MSButton className={classes.titleContainer} type="primary" onClick={() => handleClick()} endIcon={<AddIcon />}>
        {strings.stampCardPage.createStamp} {selectedOffer.short_description}
      </MSButton>
      <Collapse in={isOpen}>
        {qrCode.code && (
          <div className={classes.container}>
            <Tooltip
              open={isTooltipOpen}
              title={strings.stampCardPage.copiedLink}
              leaveDelay={1000}
              onClose={onTooltipClose}
            >
              <div className={classes.qrCodeLinkBox} onClick={onTooltipOpen}>
                <Typography className={classes.qrCodeLink}>{qrCode.code}</Typography>
                <div className={classes.copyIcon}>
                  <FileCopyOutlinedIcon />
                </div>
              </div>
            </Tooltip>
            <div className={classes.qrCodeContainer}>
              <div className="code">
                <QRCode
                  value={qrCode.code}
                  style={{ height: 300 }}
                  size={300}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  includeMargin={false}
                  renderAs={"png"}
                />
              </div>
              <a onClick={downloadQRCode} ref={downloadAnchor} className={classes.downloadAnchor}>
                <MSButton type="secondary" className={classes.downloadButton}>
                  {strings.stampCardPage.downloadQRImage}
                </MSButton>
              </a>
            </div>
          </div>
        )}
      </Collapse>
    </section>
  );
};
