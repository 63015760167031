import React, { useContext, useEffect, useState } from "react";
import { addNewEventCoupon, updateEventCoupon, getPushNotificationAccess } from "../../services/CouponsServices";
import LanguageContext from "../../store/LanguageContext";
import { errorToast } from "../Notifications/Notifications";
import { CompanyContext } from "../../store/CompanyContext";
import SaveSection from "./SaveSection";
import EventAboutSection from "./EventAboutSection";
import NotificationsSection from "./NotificationsSection";

const NewEventCouponSection = ({ activeCoupon, handleCouponsUpdate, handleCouponsRemove, usedEvents }) => {
  //about section
  const strings = useContext(LanguageContext);
  const { company } = useContext(CompanyContext);
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [shortDescriptionErrorMessage, setShortDescriptionErrorMessage] = useState("");
  const [longDescriptionErrorMessage, setLongDescriptionErrorMessage] = useState("");
  const [specificationsErrorMessage, setSpecificationsErrorMessage] = useState("");
  const [validityPeriod, setValidityPeriod] = useState("");
  const [validityPeriodInDays, setValidityPeriodInDays] = useState("");
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [eventOptionOne, setEventOptionOne] = useState("");
  const [eventOptionTwo, setEventOptionTwo] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedValidityPeriod, setSelectedValidityPeriod] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [pushNotificationAccess, setPushNotificationAccess] = useState(false);
  const [sendPushNotification, setSendPushNotification] = useState(false);
  const [pushNotificationTitle, setPushNotificationTitle] = useState(company.company.name ?? "");
  const [pushNotificationBody, setPushNotificationBody] = useState("");
  const [invitedCount, setInvitedCount] = useState(0);
  const [hasPointsEvents, setHasPointsEvents] = useState(false);
  const [isEventOptionAvailable, setIsEventOptionAvailable] = useState(true);

  //save section
  const [isSaveEnabled, setIsSaveEnable] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isRemoveVisible, setIsRemoveVisible] = useState(false);

  const createCoupon = async () => {
    setIsSaving(true);
    let formData = new FormData();
    formData.append("short_description", shortDescription);
    formData.append("long_description", longDescription);
    formData.append("specifications", specifications);
    formData.append("valid_period_in_days", validityPeriodInDays);
    formData.append("active", isActive);

    if (imageFile !== null) {
      formData.append("image", imageFile);
    }

    if (sendPushNotification) {
      formData.append("send_push_notification", sendPushNotification);
      formData.append("push_notification_title", pushNotificationTitle);
      formData.append("push_notification_body", pushNotificationBody);
    }

    switch (selectedEvent.value) {
      case "first_stamp":
        formData.append("first_stamp", true);
        break;
      case "not_stamped_for_x_days":
        formData.append("not_stamped_for_x_days", eventOptionOne);
        break;
      case "x_stamps_per_period":
        formData.append("x_stamps_per_period", eventOptionOne);
        formData.append("x_days_in_period", eventOptionTwo);
        break;
      case "x_stamps_per_stamp_card":
        formData.append("x_stamps_per_stamp_card", eventOptionOne);
        break;
    }
    try {
      if (activeCoupon !== null) {
        let result = await updateEventCoupon(activeCoupon.id, formData);
        handleCouponsUpdate(result, true);
      } else {
        let result = await addNewEventCoupon(formData);
        handleCouponsUpdate(result);
      }
    } catch (error) {
      if (error.code === 400) {
        return;
      }
      errorToast({
        title: "Error",
        text: error.message,
      });
    }
    setTimeout(() => {
      setIsSaving(false);
    }, 1000);
  };

  let eventOptions = [
    {
      label: strings.couponsPage.eventOptionLabel_11,
      value: "first_stamp",
    },
    {
      label:
        strings.couponsPage.eventOptionLabel_21 +
        (eventOptionOne ? eventOptionOne : "-") +
        strings.couponsPage.eventOptionLabel_22,
      value: "not_stamped_for_x_days",
      text1: strings.couponsPage.eventOptionsText_2,
      text2: strings.couponsPage.eventOptionLabel_22,
    },
    {
      label:
        strings.couponsPage.eventOptionLabel_31 +
        (eventOptionOne ? eventOptionOne : "-") +
        strings.couponsPage.eventOptionLabel_32 +
        (eventOptionTwo ? eventOptionTwo : "-") +
        strings.couponsPage.eventOptionLabel_33,
      value: "x_stamps_per_period",
      text1: strings.couponsPage.eventOptionsText_3,
      text2: strings.couponsPage.eventOptionLabel_32,
      text3: strings.couponsPage.eventOptionLabel_33,
    },
    {
      label:
        strings.couponsPage.eventOptionLabel_41 +
        (eventOptionOne ? eventOptionOne : "-") +
        strings.couponsPage.eventOptionLabel_42,
      value: "x_stamps_per_stamp_card",
      text1: strings.couponsPage.eventOptionsText_4,
      text2: strings.couponsPage.eventOptionLabel_42,
    },
  ];

  const getActiveEventType = () => {
    if (activeCoupon) {
      if (activeCoupon["first_stamp"]) {
        return "first_stamp";
      } else if (activeCoupon["not_stamped_for_x_days"]) {
        return "not_stamped_for_x_days";
      } else if (activeCoupon["x_stamps_per_period"]) {
        return "x_stamps_per_period";
      }
      return "x_stamps_per_stamp_card";
    }
    return "";
  };

  useEffect(() => {
    if (selectedEvent) {
      const option = eventOptions.find(option => option.value === selectedEvent.value);
      if (option && option.label !== selectedEvent.label) {
        setSelectedEvent(option);
      }
    }
  }, [eventOptionOne, eventOptionTwo]);

  const handlePushNotificationTitle = event => {
    setPushNotificationTitle(event.target.value);
  };

  const handlePushNotificationBody = event => {
    setPushNotificationBody(event.target.value);
  };

  const handleSendPushNotification = toggle => {
    setSendPushNotification(toggle);
  };

  const handleIsActiveChange = toggle => {
    setIsActive(toggle);
  };

  const handleSelectedValidityPeriodChange = item => {
    setSelectedValidityPeriod(item);
  };

  const handleSelectedEventsChange = item => {
    setSelectedEvent(item);
  };

  const handleImageChange = image => {
    setImage(image !== null ? image.base64 : null);
    setImageFile(image !== null ? image.fileList[0] : null);
  };

  const handleShortDescriptionChange = event => {
    event.target.value.length <= 72 && setShortDescription(event.target.value);
    if (event.target.value.length === 73) {
      setShortDescriptionErrorMessage(strings.couponsPage.maximumNumberOfCharacters);
      setTimeout(() => {
        setShortDescriptionErrorMessage("");
      }, 3000);
    }
  };

  const handleLongDescriptionChange = event => {
    event.target.value.length <= 125 && setLongDescription(event.target.value);
    if (event.target.value.length === 126) {
      setLongDescriptionErrorMessage(strings.couponsPage.maximumNumberOfCharacters);
      setTimeout(() => {
        setLongDescriptionErrorMessage("");
      }, 3000);
    }
  };

  const handleSpecificationsChange = event => {
    event.target.value.length <= 80 && setSpecifications(event.target.value);
    if (event.target.value.length === 81) {
      setSpecificationsErrorMessage(strings.couponsPage.maximumNumberOfCharacters);
      setTimeout(() => {
        setSpecificationsErrorMessage("");
      }, 3000);
    }
  };

  const handleValidityPeriod = event => {
    if (event.target.value !== "0") {
      setValidityPeriod(event.target.value);
    }
  };

  useEffect(() => {
    const getMultiplier = period => {
      switch (period) {
        case "days":
          return 1;
        case "weeks":
          return 7;
      }
    };

    if (selectedValidityPeriod) {
      const multiplier = getMultiplier(selectedValidityPeriod.value);
      setValidityPeriodInDays(parseInt(validityPeriod) * multiplier);
    }
  }, [validityPeriod, selectedValidityPeriod]);

  const handleEventOptionOne = event => {
    if (event.target.value !== "0") {
      setEventOptionOne(event.target.value);
    }
  };

  const handleEventOptionTwo = event => {
    if (event.target.value !== "0") {
      setEventOptionTwo(event.target.value);
    }
  };

  useEffect(() => {
    let couponNotNull = activeCoupon !== null;

    if (couponNotNull) {
      const option = eventOptions.find(option => option.value === getActiveEventType());
      setSelectedEvent(option);
      const eventType = getActiveEventType();
      setEventOptionOne(eventType !== "first_stamp" ? activeCoupon[eventType] : "1");
      setEventOptionTwo(activeCoupon["x_stamps_per_period"] ?? "");
    } else {
      setSelectedEvent(null);
      setEventOptionOne("");
      setEventOptionTwo("");
    }
    setHasPointsEvents(couponNotNull? activeCoupon.hasPointsEvents : false);
    setIsActive(couponNotNull ? activeCoupon.active : false);
    setInvitedCount(couponNotNull ? activeCoupon.invited_count : 0);
    setImage(couponNotNull ? activeCoupon.coupon_image : null);
    setValidityPeriod(
      couponNotNull ? (activeCoupon.valid_period_in_days ? activeCoupon.valid_period_in_days : "") : "",
    );
    setSelectedValidityPeriod(couponNotNull ? { label: "days", value: "days" } : null);
    setShortDescription(couponNotNull ? activeCoupon.short_description : "");
    setLongDescription(couponNotNull ? activeCoupon.long_description : "");
    setSpecifications(couponNotNull ? activeCoupon.specifications : "");
    setSendPushNotification(couponNotNull ? activeCoupon.send_push_notification : false);
    setPushNotificationTitle(couponNotNull ? activeCoupon.push_notification_title ?? "" : company.company.name ?? "");
    setPushNotificationBody(couponNotNull ? activeCoupon.push_notification_body : "");

    setIsRemoveVisible(couponNotNull && activeCoupon.invited_count <= 0);
  }, [activeCoupon]);

  useEffect(() => {
    const eventOptionAvailable = () => {
      if (activeCoupon !== null && activeCoupon.active) {
        return true;
      }
      if (selectedEvent && isActive) {
        return !usedEvents.includes(selectedEvent.value);
      }
      return true;
    };

    const eventOptionsSelected = () => {
      if (selectedEvent) {
        switch (selectedEvent.value) {
          case "first_stamp":
            return true;
          case "not_stamped_for_x_days":
            return eventOptionOne;
          case "x_stamps_per_period":
            return eventOptionOne && eventOptionTwo;
          case "x_stamps_per_stamp_card":
            return eventOptionOne;
        }
      }
      return false;
    };

    let isSaveButtonEnabled =
      eventOptionAvailable() &&
      eventOptionsSelected() &&
      selectedEvent !== null &&
      parseInt(validityPeriod) > 0 &&
      selectedValidityPeriod !== null &&
      shortDescription.length > 0 &&
      longDescription.length > 0;
    setIsSaveEnable(isSaveButtonEnabled);
    setIsEventOptionAvailable(eventOptionAvailable());
  }, [shortDescription, longDescription, selectedValidityPeriod, validityPeriod, selectedEvent, isActive]);

  useEffect(() => {
    const ac = new AbortController();
    const fetchgetpushNotificationAccess = async () => {
      try {
        const pushNotificationAccess = await getPushNotificationAccess();
        setPushNotificationAccess(pushNotificationAccess["push_notification_access"]);
      } catch (error) {
        errorToast({
          title: "Error",
          text: error.message,
        });
      }
    };
    fetchgetpushNotificationAccess();
    return () => ac.abort();
  }, []);

  return (
    <>
      <EventAboutSection
        shortDescription={shortDescription}
        handleShortDescriptionChange={handleShortDescriptionChange}
        longDescription={longDescription}
        handleLongDescriptionChange={handleLongDescriptionChange}
        specifications={specifications}
        handleSpecificationsChange={handleSpecificationsChange}
        image={image}
        handleImageChange={handleImageChange}
        validityPeriod={validityPeriod}
        validityPeriodInDays={validityPeriodInDays}
        handleValidityPeriod={handleValidityPeriod}
        shortDescriptionErrorMessage={shortDescriptionErrorMessage}
        longDescriptionErrorMessage={longDescriptionErrorMessage}
        specificationsErrorMessage={specificationsErrorMessage}
        eventOptionOne={eventOptionOne}
        handleEventOptionOne={handleEventOptionOne}
        eventOptionTwo={eventOptionTwo}
        handleEventOptionTwo={handleEventOptionTwo}
        selectedEvent={selectedEvent}
        handleSelectedEventsChange={handleSelectedEventsChange}
        selectedValidityPeriod={selectedValidityPeriod}
        handleSelectedValidityPeriodChange={handleSelectedValidityPeriodChange}
        eventOptions={eventOptions}
        isActive={isActive}
        handleIsActiveChange={handleIsActiveChange}
        invitedCount={invitedCount}
        isEventOptionAvailable={isEventOptionAvailable}
        hasPointsEvents={hasPointsEvents}
      />
      {pushNotificationAccess && selectedEvent?.value === "not_stamped_for_x_days" && (
        <NotificationsSection
          sendPushNotification={sendPushNotification}
          handleSendPushNotification={handleSendPushNotification}
          pushNotificationTitle={pushNotificationTitle}
          handlePushNotificationTitle={handlePushNotificationTitle}
          pushNotificationBody={pushNotificationBody}
          handlePushNotificationBody={handlePushNotificationBody}
          isEventCoupon
        />
      )}
      <SaveSection
        pushNotificationAccess={pushNotificationAccess}
        event={selectedEvent?.value}
        isSaveEnabled={isSaveEnabled}
        createCoupon={createCoupon}
        isSaving={isSaving}
        isRemoveVisible={isRemoveVisible}
        activeCoupon={activeCoupon}
        handleCouponsUpdate={handleCouponsUpdate}
        handleCouponsRemove={handleCouponsRemove}
        isEventCoupon
      />
    </>
  );
};
export default NewEventCouponSection;
