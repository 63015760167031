import { makeStyles } from "@material-ui/core";

export const useStampCardsClaimsSectionStyles = makeStyles(() => ({
  claimedStampCards: {
    marginRight: 20,
  },
  tableTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 24,
  },
  exportButton: {
    minWidth: 140,
    minHeight: 52,
    fontSize: 18,
    fontWeight: "500 !important",
    padding: "10px 25px !important",
    letterSpacing: 0.5
  },
}));
