import React, { useContext, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import LanguageContext from "../../../store/LanguageContext";
import backgroundImage from "../../../assets/images/licenseStamp.png";

export const StampLicensePage = () => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [commingSoon] = useState(false);

  return (
    <>
      <div className={classes.root}></div>
      {!commingSoon ? (
        <div className={classes.mainContainer}>
          <div className={classes.contentBox}>
            {/* <Typography className={classes.contentText}>&#8226; {strings.stampCardPage.createStamps}</Typography> */}
            <Typography className={classes.contentText}>&#8226; {strings.stampCardPage.seeStatistics}</Typography>
            <Typography className={classes.contentText}>&#8226; {strings.stampCardPage.claims}</Typography>
          </div>
          <Typography className={classes.contactText}>{strings.loyaltyCardsPage.accessInstruction}</Typography>
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <div className={classes.contentBox}>
            <Typography className={classes.commingSoonText}>{strings.couponsPage.comingSoon}</Typography>
          </div>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: 3503,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    backgroundImage: `url(${backgroundImage})`,
    marginTop: 50,
    marginBottom: 50,
    filter: "blur(6px)",
    WebkitFilter: "blur(6px)",
    opacity: 0.3,
  },

  mainContainer: {
    position: "absolute",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    left: 0,
    top: window.innerWidth > 1200 ? 150 : 100,
  },

  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 150,
  },

  contentText: {
    fontFamily: "DMMono",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 500,
    color: "black",
    marginBottom: 15,
  },

  contactText: {
    fontFamily: "DMSans",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "black",
    marginTop: 50,
    padding: "0px 20px",
  },

  commingSoonText: {
    fontFamily: "DMMono",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 500,
    color: "black",
    marginBottom: 15,
  },
}));
