import { makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import LanguageContext from "../../store/LanguageContext";
import logo from "../../assets/images/mystamp_m_logo.png";

const NotificationTemplate = ({ title, message }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);

  return (
    <ScrollContainer horizontal={true} className={classes.collapse}>
      <div className={classes.notificationTemplate}>
        <div className={classes.logoBox}>
          <img src={logo} alt={"coupon_image"} className={classes.logo} />
        </div>
        <div className={classes.templateData}>
          <Typography className={classes.notificationTitle} style={{ color: title.length > 0 ? "#000" : "#C4C4C4" }}>
            {title.length === 0 ? strings.couponsPage.notificationTitle : title}
          </Typography>
          <Typography
            className={classes.notificationMessage}
            style={{ color: message.length > 0 ? "#000" : "#C4C4C4" }}
          >
            {message.length === 0 ? strings.couponsPage.notificationMessage : message}
          </Typography>
        </div>
      </div>
    </ScrollContainer>
  );
};

const useStyles = makeStyles({
  notificationTemplate: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #C4C4C440",
    height: 120,
    width: 550,
    boxShadow: "0px 4px 4px 0px #00000040",
    marginTop: 30,
  },

  collapse: {
    padding: 5,
  },

  logoBox: {
    display: "flex",
    flex: 0.2,
    flexShrink: 0,
    alignItems: "flex-start",
    justifyContent: "center",
    margin: 20,
  },

  logo: {
    borderRadius: "50%",
    width: 50,
    height: 50,
  },

  templateData: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "flex",
    flex: 0.8,
    flexDirection: "column",
    padding: "20px 20px 20px 0px",
    alignItems: "flex-start",
  },

  notificationTitle: {
    width: "calc(100%)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 20,
    textAlign: "start",
  },

  notificationMessage: {
    width: "calc(100%)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 18,
    marginTop: 5,
    textAlign: "start",
  },
});

export default NotificationTemplate;
