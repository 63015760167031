import React from "react";
import { ReactComponent as MSilver } from "../assets/icons/m-silver.svg";
import { ReactComponent as MGold } from "../assets/icons/m-gold.svg";
import { ReactComponent as MPink } from "../assets/icons/m-pink.svg";
import { ReactComponent as MPinkBlack } from "../assets/icons/m-pink-black.svg";
import { ReactComponent as MWhite } from "../assets/icons/m-white.svg";
import { ReactComponent as LineSilver } from "../assets/icons/line-silver.svg";
import { ReactComponent as LineGold } from "../assets/icons/line-gold.svg";
import { ReactComponent as LinePink } from "../assets/icons/line-pink.svg";
import { ReactComponent as LinePinkBlack } from "../assets/icons/line-pink-black.svg";
import { ReactComponent as LineWhite } from "../assets/icons/line-white.svg";

export const getLineImage = theme => {
  switch (theme) {
    case "Rosa":
      return <LinePink />;
    case "Rosa & svart":
      return <LinePinkBlack />;
    case "Silver":
      return <LineSilver />;
    case "Guld":
      return <LineGold />;
    case "none":
      return <LineWhite />;
    default:
      break;
  }
};

export const getMImage = theme => {
  switch (theme) {
    case "Rosa":
      return <MPink />;
    case "Rosa & svart":
      return <MPinkBlack />;
    case "Silver":
      return <MSilver />;
    case "Guld":
      return <MGold />;
    case "none":
      return <MWhite />;
    default:
      break;
  }
};

export const getTextColor = theme => {
  switch (theme) {
    case "Rosa":
    case "none":
      return "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%)";
    case "Rosa & svart":
      return "linear-gradient(90.34deg, #EDB5A4 7.98%, #FCD6CA 31.34%, rgba(207, 145, 126, 0.921986) 46.45%, rgba(255, 221, 210, 0.995666) 73.94%, #EDB5A4 95.92%)";
    case "Silver":
      return "linear-gradient(90deg, #FFFFFF -0.06%, #EBEBEB 3.14%, #DEDEDE 4%, #A5A5A5 8.04%, #CCCCCC 11.04%, #E2E2E2 12.64%, #A8A8A8 16.32%, #AFAFAF 23.54%, #F1F1F1 32.35%, #F8F8F8 35.66%, #FFFFFF 44.02%, #EBEBEB 68.83%, #E6E6E6 69.29%, #B6B6B6 74.31%, #949494 78.73%, #7F7F7F 82.35%, #777777 84.73%, #7B7B7B 86.96%, #868686 89.02%, #999999 91.02%, #B3B3B3 92.98%, #D5D5D5 94.91%, #EBEBEB 95.97%, #F2F2F2 97.12%, #FFFFFF 100.03%)";
    case "Guld":
      return "linear-gradient(90deg, #B57E10 0%, #E5C35C 22.21%, #F9DF7B 32.79%, #FFF3A6 52.38%, #F9DF7B 72.96%, #B57E10 99.97%)";
    default:
      break;
  }
};

export const getBackground = (theme, frontImage) => {
  switch (theme) {
    case "Rosa":
      return "linear-gradient(91deg, #EDB5A4 8.63%, #E8AB98 27.02%, #ECB6A5 40.82%, #FFD5C8 66.1%, #ECB5A4 96.91%)";
    case "Rosa & svart":
      return "linear-gradient(91deg, #282828 0%, #282828 50%, #282828 100%)";
    case "Silver":
      return "linear-gradient(91deg, #282828 0%, #282828 50%, #282828 100%)";
    case "Guld":
      return "linear-gradient(91deg, #282828 0%, #282828 50%, #282828 100%)";
    case "Egen design":
      return `url(${frontImage})`;
    case "none":
      return "linear-gradient(91deg, #E0E0E0 0%, #E0E0E0 50%, #E0E0E0 100%)";
    default:
      break;
  }
};

export const getLogoBackground = theme => {
  switch (theme) {
    case "Rosa":
      return "linear-gradient(91deg, #FFD5C8 0.63%,#ECB6A5 10.02%, #ECB5A4 40.91%)";
    case "Rosa & svart":
      return "#282828";
    case "Silver":
      return "#282828";
    case "Guld":
      return "#282828";
    case "none":
      return "#E0E0E0";
    default:
      break;
  }
};
