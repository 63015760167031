import React, { useState, useContext } from "react";

import useStyles from "./styles";
import Logo from "./Logo/Logo";
import { UserContext } from "../store/UserContext";
import Dialog from "../components/Dialog/Dialog";
import Button from "@material-ui/core/Button";
import Zoom from "@material-ui/core/Zoom";
import Typography from "@material-ui/core/Typography";
import { toggleFullScreen } from "../lib/helper";
import LanguageContext from "../store/LanguageContext";
import ConnectionDetector from "../components/Shared/ConnectionDetector";
import Copyright from "../pages/SignInCompanyPage/Copyright/Copyright";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme/theme";

const CompanyLayout = ({ children }) => {
  const classes = useStyles();
  const { state, setState } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState();

  const strings = useContext(LanguageContext);

  const holdStart = () => {
    setTimer(
      setTimeout(() => {
        setOpen(true);
      }, 1000),
    );
  };
  const holdEnd = () => {
    clearTimeout(timer);
    setTimer(null);
  };

  const handleMenuClick = action => {
    switch (action) {
      case "logout":
        setState({ authenticated: false, user: null });
        break;
      default:
        break;
    }
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        align="center"
        style={{
          position: "absolute",
          top: 0,
          minHeight: 85,
          right: 0,
          left: 0,
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingBottom: 20,
          }}
        >
          <div
            style={{
              background: "linear-gradient(#EDB5A3, #E1957D)",
              width: "100%",
              height: "321",
            }}
          >
            <div
              onTouchStart={holdStart}
              onTouchEnd={holdEnd}
              onMouseDown={holdStart}
              onMouseUp={holdEnd}
              className={classes.logoutFab}
            >
              Hold
            </div>
            <Typography component="h1" variant="h5" className={classes.welcomeTo}>
              {strings.companyPortalLoginPage.welcomeTo}
            </Typography>
            <Logo />
            <Typography component="h1" variant="h5" className={classes.companyText}>
              {strings.companyPortalLoginPage.companyPortal}
            </Typography>
            <div>{children}</div>
            <div
              style={{
                background: "#E1957D",
                height: 1,
              }}
            >
              <svg viewBox="0 0 1440 320">
                <path
                  fill="#E1957D"
                  fillOpacity="1"
                  d="M 0 0 C 380 37 235 324 582 165 C 847 61 906 189 1021 182 C 1230 170 1333 22 1441 23 L 1440 0 L 1405.7 0 C 1371.4 0 1303 0 1234 0 C 1165.7 0 1097 0 1029 0 C 960 0 891 0 823 0 C 754.3 0 686 0 617 0 C 548.6 0 480 0 411 0 C 342.9 0 274 0 206 0 C 137.1 0 69 0 34 0 L -2 0 Z"
                ></path>
              </svg>
            </div>
          </div>
          <Copyright />
        </div>

        <Dialog
          transitionDuration={400}
          transition={Zoom}
          onClose={() => setOpen(false)}
          open={open}
          style={{ padding: 50 }}
        >
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              margin: "auto",
            }}
          >
            <Typography className={classes.whatDoYouWantTodo}>{strings.whatDoYouWantToDo}</Typography>
            <br />
            {state.authenticated ? (
              <Button
                onClick={() => handleMenuClick("logout")}
                variant="contained"
                style={{
                  background: "#edb5a3",
                  color: "#FFF",
                  borderRadius: 16,
                  marginBottom: 20,
                }}
              >
                {strings.signOut}
              </Button>
            ) : null}
            <br />
            <Button
              onClick={() => handleMenuClick("pengar")}
              variant="contained"
              style={{
                background: "#edb5a3",
                color: "#FFF",
                borderRadius: 16,
              }}
            >
              {strings.contactUs}
            </Button>
            <br />
            <br />
            <Button
              onClick={toggleFullScreen}
              variant="contained"
              style={{ background: "#edb5a3", color: "#FFF", borderRadius: 16 }}
            >
              Fullscreen
            </Button>
          </div>
        </Dialog>
        <ConnectionDetector />
      </div>
    </ThemeProvider>
  );
};

export default CompanyLayout;
