import { IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Select, { components } from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import LanguageContext from "../../store/LanguageContext";
import ScrollContainer from "react-indiana-drag-scroll";
import { customComponentsStyles, customStyles } from "../../lib/dropdownCustomStyles";

const Dropdown = ({ data, selectedItem, handleChange, setOpen, item, dropdownKey, disabled = false }) => {
  const classes = useStyles();
  const strings = React.useContext(LanguageContext);
  const customStyle = dropdownKey !== undefined ? customStyles[dropdownKey] : customStyles["default"];

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <IconButton aria-label="close" size={"small"} className={classes.dropdownIcon}>
            <ArrowDown width={11} height={11} />
          </IconButton>
        </components.DropdownIndicator>
      )
    );
  };

  const Option = props => {
    return (
      components.Option && (
        <components.Option {...props}>
          <div className={classes.optionBox}>
            <Typography>{props.data.label}</Typography>
            {customStyle.delete && (
              <IconButton size={"small"} className={classes.dropdownIcon} onClick={() => setOpen(true)}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </components.Option>
      )
    );
  };

  const ValueContainer = props => {
    const childInput = props.children[1];
    return (
      components.ValueContainer &&
      (props.isMulti ? (
        <components.ValueContainer {...props}>
          {props.hasValue ? (
            <ScrollContainer horizontal={true} className={classes.scrollBox}>
              {props.getValue().map((el, index) => {
                return (
                  <Typography key={el.value} className={classes.multiValue}>
                    {index > 0 ? ", " + el.label : el.label}
                  </Typography>
                );
              })}
            </ScrollContainer>
          ) : (
            <Typography className={classes.placeholder}>{strings.loyaltyCardsPage.selectPlaceholder}</Typography>
          )}
          {childInput}
        </components.ValueContainer>
      ) : (
        <components.ValueContainer {...props}></components.ValueContainer>
      ))
    );
  };

  return (
    <Select
      menuPosition={customStyle.menuPosition}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          text: "black",
          primary25: "#EDB5A3",
          primary50: "#e5977e",
          primary: "#EDB5A3",
        },
      })}
      styles={customComponentsStyles(customStyle)}
      options={data}
      isMulti={customStyle.multi}
      isClearable={false}
      isSearchable={customStyle.search}
      menuShouldBlockScroll={true}
      value={selectedItem || null}
      isDisabled={item?.isRemoved || disabled}
      onChange={item => handleChange(item)}
      placeholder={strings.loyaltyCardsPage.selectPlaceholder}
      components={{
        DropdownIndicator: DropdownIndicator,
        IndicatorSeparator: () => null,
        Option: Option,
        ValueContainer: ValueContainer,
      }}
    />
  );
};

const useStyles = makeStyles({
  optionBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },

  scrollBox: {
    display: "flex",
    flex: "inherit",
    flexDirection: "row",
    alignItems: "center",
  },

  placeholder: {
    color: "#33333380",
    marginLeft: "2px",
    marginRight: "2px",
    fontFamily: "DMSans",
    fontSize: "16px",
    fontWeight: 400,
  },

  multiValue: {
    display: "flex",
    minWidth: "fit-content",
  },

  dropdownIcon: {
    height: 25,
    width: 25,
    backgroundColor: "transparent",
    borderRadius: "38px",
  },
});

export default Dropdown;
