import { makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { CompanyContext } from "../../store/CompanyContext";
import LanguageContext from "../../store/LanguageContext";

const CouponTemplate = ({ item, activeCoupon, onActiveChange, isCreated, index }) => {
  const classes = useStyles(isCreated);
  const { company } = useContext(CompanyContext);
  const strings = useContext(LanguageContext);
  const options = { year: "2-digit", month: "numeric", day: "numeric", hour12: false };

  let dateValidFrom;
  let dateValidTo;
  let formattedDateValidFrom;
  let formattedDateValidTo;

  if (item.valid_from) {
    dateValidFrom = new Date(item.valid_from);
    dateValidTo = new Date(item.valid_to);
    formattedDateValidFrom = dateValidFrom.toLocaleDateString(undefined, options);
    formattedDateValidTo = dateValidTo.toLocaleDateString(undefined, options);
  }

  const renderValidityText = () => {
    if (item.valid_from) {
      return (
        <Typography className={classes.itemDate}>
          {strings.couponsPage.valid}
          {formattedDateValidFrom}
          {strings.couponsPage.to}
          {formattedDateValidTo}
        </Typography>
      );
    }
    return (
      <Typography className={classes.itemDate}>
        {strings.couponsPage.valid}
        {strings.couponsPage.for}
        {item.valid_period_in_days ? item.valid_period_in_days : "-"} {strings.couponsPage.days}
      </Typography>
    );
  };

  return (
    <div
      onClick={
        onActiveChange !== undefined
          ? () => {
              onActiveChange(item);
            }
          : () => {}
      }
      style={{ marginLeft: index === 0 && 0 }}
      className={activeCoupon?.id === item.id && isCreated ? classes.couponBoxActive : classes.couponBoxInactive}
    >
      <div className={classes.content} style={{ marginTop: isCreated && activeCoupon?.id === item.id ? -116 : -113 }}>
        <div className={classes.imageBox}>
          {isCreated ? (
            <img
              src={item.coupon_image !== null ? item.coupon_image : company.company.logo_url}
              alt={"coupon_image"}
              className={classes.image}
            />
          ) : item.coupon_image !== null ? (
            <img
              src={item.coupon_image !== null ? item.coupon_image : company.company.logo_url}
              alt={"coupon_image"}
              className={classes.image}
            />
          ) : (
            <div className={classes.emptyBox} />
          )}
        </div>
        <div
          className={classes.descriptionBox}
          style={{
            backgroundImage:
              activeCoupon?.id === item.id && isCreated
                ? "linear-gradient(black 43%, rgba(255,255,255,0) 0%)"
                : "linear-gradient(#C4C4C4 43%, rgba(255,255,255,0) 0%)",
          }}
        >
          <Typography className={classes.itemTitle}>{company.company.name}</Typography>

          <Typography
            className={
              isCreated
                ? classes.itemDescriptionInserted
                : item.short_description?.length > 0
                ? classes.itemDescriptionInserted
                : classes.itemDescription
            }
          >
            {isCreated
              ? item.short_description
              : item.short_description?.length > 0
              ? item.short_description
              : strings.couponsPage.shortDescriptonExample}
          </Typography>
          {renderValidityText()}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  couponBoxInactive: {
    height: 180,
    margin: "20px 15px",
    flex: "0 0 490px",
    maxWidth: 490,
    borderRadius: 8,
    cursor: isCreated => (!isCreated ? "default" : "pointer"),
    backgroundColor: "#FFFFFF",
    boxShadow: "inset 2px 2px 2px rgba(0, 0, 0, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25)",
    fitler: "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))",
    "&::before": {
      content: "''",
      display: "block",
      height: 48,
      width: 24,
      marginRight: 490,
      marginTop: 66,
      backgroundColor: isCreated => (!isCreated ? "#F0F0F0" : "#fafafa"),
      borderRight: "2.5px solid #C4C4C4",
      borderTop: "2.5px solid #C4C4C4",
      borderBottom: "2.5px solid #C4C4C4",
      borderTopRightRadius: 50,
      borderBottomRightRadius: 50,
    },
    "&::after": {
      content: "''",
      display: "block",
      height: 48,
      width: 24,
      marginLeft: 466,
      marginTop: -115,
      backgroundColor: isCreated => (!isCreated ? "#F0F0F0" : "#fafafa"),
      borderLeft: "2.5px solid #C4C4C4",
      borderTop: "2.5px solid #C4C4C4",
      borderBottom: "2.5px solid #C4C4C4",
      borderTopLeftRadius: 50,
      borderBottomLeftRadius: 50,
    },
  },

  couponBoxActive: {
    height: 180,
    margin: "20px 15px",
    flex: "0 0 490px",
    maxWidth: 490,
    borderRadius: 8,
    cursor: "pointer",
    border: "5px solid black",
    backgroundColor: "#FFFFFF",
    "&::before": {
      content: "''",
      display: "block",
      height: 54,
      width: 30,
      marginLeft: -5,
      marginRight: 493,
      marginTop: 58,
      backgroundColor: "#fafafa",
      borderRight: "5px solid black",
      borderTop: "5px solid black",
      borderBottom: "5px solid black",
      borderTopRightRadius: 50,
      borderBottomRightRadius: 50,
    },
    "&::after": {
      content: "''",
      display: "block",
      height: 54,
      width: 30,
      marginLeft: 455,
      marginTop: -118,
      backgroundColor: "#fafafa",
      borderLeft: "5px solid black",
      borderTop: "5px solid black",
      borderBottom: "5px solid black",
      borderTopLeftRadius: 50,
      borderBottomLeftRadius: 50,
    },
  },

  emptyBox: {
    width: 100,
    height: 43,
    backgroundColor: "#E0E0E0",
    borderRadius: 16,
  },

  image: {
    maxWidth: 120,
    maxHeight: 150,
    backgroundSize: "contain !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
  },

  content: {
    display: "flex",
    height: 180,
    width: 430,
    backgroundColor: "transparent",
    justifyContent: "space-between",
    alignItems: "center",
  },

  imageBox: {
    display: "flex",
    width: 145,
    height: 135,
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 10,
    marginRight: 3,
  },

  descriptionBox: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    height: "75%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    textAlign: "start",
    paddingRight: 10,
    paddingLeft: 25,
    backgroundImage: "linear-gradient(#C4C4C4 43%, rgba(255,255,255,0) 0%)",
    backgroundPosition: "left",
    backgroundSize: "2px 16px",
    backgroundRepeat: "repeat-y",
  },

  itemTitle: {
    fontFamily: "DMSans",
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 5,
    color: "black",
    maxHeight: 30,
  },

  itemDescription: {
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 5,
    color: "#C4C4C4",
    lineHeight: "20px",
  },

  itemDescriptionInserted: {
    width: "100%",
    wordWrap: "break-word",
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 5,
    color: "black",
    lineHeight: "20px",
  },

  itemDate: {
    fontFamily: "DMSans",
    color: "black",
    fontSize: 13.5,
    fontWeight: 400,
  },
});

export default CouponTemplate;
