import { makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import LanguageContext from "../../../store/LanguageContext";
import { CompanyTag } from "../CompanyTag/CompanyTag";

export const CompanyInfo = ({ companyData }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);

  return (
    <div className={classes.root}>
      <Typography className={classes.name}>{companyData.name}</Typography>
      <Typography className={classes.city}>{companyData.locations[0].city_category.name}</Typography>
      <div className={classes.tags}>
        {companyData.categories.sort((a, b) => a.position - b.position).map(tag => (
          <CompanyTag tag={tag} key={tag.id}/>
        ))}
      </div>
      <div className={classes.descriptionBox}>
        <Typography className={classes.description}>{companyData.description}</Typography>
      </div>
      <div className={classes.stats}>
        <div className={classes.statsBox}>
          <Typography className={classes.number}>{companyData.user_count}</Typography>
          <Typography className={classes.text}>{strings.overviewPage.myUsers}</Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(({palette}) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 1440,
  },
  name: {
    fontFamily: "DMSans",
    fontStyle: "normal",
    fontSize: 48,
    fontWeight: 500,
    marginTop: 32,
  },
  city: {
    fontFamily: "DMSans",
    fontStyle: "normal",
    fontSize: 20,
    fontWeight: 400,
    marginBottom: 16,
    lineHeight: 0.85,
  },
  tags: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    width: 500,
  },
  descriptionBox:{
    display: "flex",
    flex: 1,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    marginTop: 30,
  },
  description: {
    maxWidth: "60%",
    fontFamily: "DMSans",
    fontStyle: "normal",
    fontSize: 16,
    fontWeight: 400,
  },
  stats: {
    marginTop: 32,
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
  },
  statsBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  number: {
    fontFamily: "DMSans",
    fontStyle: "normal",
    fontSize: 80,
    fontWeight: 700,
    color: palette.primary.main,
  },
  text: {
    fontFamily: "DMMono",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 20,
    marginTop: -20
  },
}));
