import * as React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import LanguageContext from "../../store/LanguageContext";
import { removeTag } from "../../services/LoyaltyCardsServices";
import { errorToast } from "../Notifications/Notifications";
import InputConfirmationBox from "../Shared/InputConfirmationBox";
import Dropdown from "../Shared/Dropdown";
import RemoveDialog from "../Shared/RemoveDialog";

export default function FilterItem({ currentTags, card, selectedTag, handleTagChange, tagKey, handleTagRemove }) {
  const classes = useStyles();
  const strings = React.useContext(LanguageContext);
  const [mappedTags, setMappedTags] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [isTagRemoved, setIsTagRemoved] = React.useState(false);

  const deleteTag = async () => {
    try {
      await removeTag(selectedTag.value);
      setTimeout(() => {
        handleTagChange(null);
      }, 500);
      setOpen(false);
      handleTagRemove();
      setIsTagRemoved(true);
      setTimeout(() => {
        setIsTagRemoved(false);
      }, 5000);
    } catch (error) {
      errorToast({
        title: "Error",
        text: error.message,
      });
    }
  };

  React.useEffect(() => {
    if (currentTags !== null) {
      const mappedData = currentTags.map(tag => {
        const tagItem = {
          value: tag.id,
          label: tag.label,
        };
        return tagItem;
      });
      setMappedTags(mappedData);
    }
  }, [currentTags]);

  return (
    <div className={tagKey === "filter" ? classes.mainContainer : classes.mainContainerInvite}>
      {tagKey === "filter" && (
        <Typography className={classes.filterTitle}>{strings.loyaltyCardsPage.tagTitle}</Typography>
      )}
      <Dropdown
        data={mappedTags}
        selectedItem={selectedTag}
        handleChange={handleTagChange}
        setOpen={setOpen}
        item={card}
        dropdownKey={tagKey === "csv" || tagKey === "email" ? "invite" : tagKey}
      />
      {isTagRemoved && (tagKey === "csv" || tagKey === "email") && (
        <InputConfirmationBox message={strings.loyaltyCardsPage.tagRemovedMessage} />
      )}
      <RemoveDialog
        open={open}
        setOpen={setOpen}
        title={
          strings.loyaltyCardsPage.remove +
          ' "' +
          selectedTag?.label +
          '" ' +
          strings.loyaltyCardsPage.tag.toLowerCase()
        }
        content={strings.loyaltyCardsPage.removeDialogContent}
        removeItem={deleteTag}
      />
    </div>
  );
}

const useStyles = makeStyles({
  mainContainer: {
    width: "100%",
    padding: "0px 15px",
  },

  mainContainerInvite: {
    width: "90%",
  },

  filterTitle: {
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 10,
    marginBottom: 5,
  },
});
