import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  orderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  totalText: {
    fontWeight: "bold",
  },
  orderTable: {
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "column",
    padding: 18,
    borderRadius: 20,
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 500,
    backgroundColor: "#fff",
    marginBottom: 6,
  },
  orderTableLeftCol: {
    textAlign: "left",
  },
  orderTableRightCol: {
    textAlign: "right",
  },
}));

export default useStyles;
