import React, { useState, useContext, useEffect, useRef } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Fade } from "@material-ui/core";
import Offers from "../components/Offers/Offers";
import LanguageContext from "../store/LanguageContext";
import consumer from "../lib/actionCable";
import Checkout from "../components/Checkout/Checkout";
import { UserContext } from "../store/UserContext";
import { errorPopup, errorToast } from "../components/Notifications/Notifications";
import { getOfferProvider } from "../services/OfferProvidersServices";
import { postQRCodeCheckoutIntegration } from "../services/OneTimeQRCodesServices";
import Lottie from "react-lottie";
import confettiAnimation from "./confetti.json";
import useSound from "use-sound";
import succesSound from "./ok.mp3";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";

const Dashboard = () => {
  const classes = useStyles();
  const [paddingTop, setPaddingTop] = useState(246);
  const [fade, setFade] = useState(true);
  const { state, setState } = useContext(UserContext);
  const [offers, setOffers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loadOffers, setLoadOffers] = useState(true);
  const [redirect, setRedirect] = useState();
  const [integratedCheckout, setIntegratedCheckout] = useState(false);
  const [code, setCode] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [order, setOrder] = useState();
  const [showOrder, setShowOrder] = useState(false);
  const [startedCardIntegration, setStartedCardIntegration] = useState(false);
  const [playSound] = useSound(succesSound);
  const [play, setPlay] = useState(false);
  const [success, setSuccess] = useState(false);
  const strings = useContext(LanguageContext);
  const timerRef = useRef(null);

  useEffect(() => {
    getOfferProviderData();
  }, [loadOffers]);

  useEffect(() => {
    const postCheckoutIntegrationQR = async activeOffer => {
      // Use offer id to simplify integration with existing code
      const data = await postQRCodeCheckoutIntegration(activeOffer.id, orderNumber, totalPrice);
      const { code } = data;
      setCode(code);
      setShowOrder(true);
    };
    const activeOffer = offers.filter(offer => offer.active);
    if (orderNumber && activeOffer.length > 0 && integratedCheckout) {
      postCheckoutIntegrationQR(activeOffer[0]);
    }
  }, [orderNumber, offers, totalPrice]);

  const getOfferProviderData = async () => {
    try {
      let result = await getOfferProvider();
      setIntegratedCheckout(result.integrated_checkout);
      const tempOffers = result.offers.map(offer => ({
        ...offer,
        viewQrCode: false,
        slide: true,
      }));
      setOffers(tempOffers);
      setLoaded(true);
      if (result.integrated_checkout) {
        setPaddingTop(0);
      } else if (result.offers.length === 4) {
        setPaddingTop(246);
      } else if (result.offers.length === 1) {
        setPaddingTop(348);
      } else {
        setPaddingTop(212);
      }
      setupSocketListener();
    } catch (error) {
      if (error.code === 401) {
        setState({ authenticated: false, user: null });
        setRedirect(true);
        errorToast({
          title: strings.sessionExpired,
        });
        return;
      }
      errorPopup(
        {
          title: strings.getOffersError,
          text: strings.checkInternet,
          showConfirmButton: true,
          timer: 5000,
          confirmButtonText: strings.tryAgainButton,
        },
        getOfferProviderData,
      );
    }
  };

  useEffect(() => {
    if (success && showOrder) {
      playSound();
      setPlay(true);
      timerRef.current = setTimeout(() => {
        setPlay(false);
        setShowOrder(false);
        setStartedCardIntegration(false);
        setSuccess(false);
      }, 4500);
    } else {
      setSuccess(false);
      setStartedCardIntegration(false);
    }
  }, [success, showOrder]);

  const setupSocketListener = () => {
    consumer.subscriptions.create(
      {
        channel: "OfferProviderChannel",
        offer_provider_id: state.user.id,
      },
      {
        connected: () => {
          console.log("Socket connected");
        },
        disconnected: () => {
          console.log("Socket disconnected");
        },
        received: data => {
          if (data.action === "REFRESH") {
            window.location.reload();
          } else if (data.action === "UPDATE") {
            const tempOffers = data.user.offers.map(offer => ({
              ...offer,
              viewQrCode: false,
              slide: true,
            }));
            setOffers(tempOffers);
            setState({ user: data.user });
          } else if (data.action === "ORDER") {
            clearTimeout(timerRef.current);
            const priceSum = data.order.products.reduce(
              (previousValue, currentValue) => previousValue + currentValue.price * currentValue.count,
              0,
            );
            setOrder(data.order);
            setTotalPrice(priceSum);
            setOrderNumber(data.order.order_number);
          } else if (data.action === "SUCCESS") {
            setSuccess(true);
          } else if (data.action === "STARTED_CARD_INTEGRATION") {
            setStartedCardIntegration(true);
          } else if (data.action === "DELAY") {
            timerRef.current = setTimeout(() => {
              setShowOrder(false);
              setStartedCardIntegration(false);
            }, 4500);
          }
        },
      },
    );
  };

  const renderOffersOrCheckout = () => {
    if (integratedCheckout) {
      return (
        <div
          style={{
            height: "100%",
          }}
        >
          {play && (
            <div className={classes.animationContainer}>
              <Lottie
                options={{
                  animationData: confettiAnimation,
                  loop: false,
                  rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
                }}
                autoplay
                height="100vh"
                width="100vw"
                speed={1.5}
              />
            </div>
          )}
          <Fade in={!showOrder} timeout={400} mountOnEnter unmountOnExit>
            <div className={classes.callToActionContainer}>
              <div className={classes.whiteContainer}>
                <Typography className={classes.callToActionTitle}>{strings.pointsCallToAction1}</Typography>
                <div className={classes.verticalSpace} />
                <Typography className={classes.callToActionBody}>{strings.pointsCallToAction2}</Typography>
                <img style={{ paddingTop: 25 }} width={"90%"} src={state.user.tablet_logo_url} alt="logo" />
              </div>
            </div>
          </Fade>
          <Fade in={showOrder} timeout={400} mountOnEnter unmountOnExit>
            <div className={classes.checkoutContainer}>
              <Checkout
                startedCardIntegration={startedCardIntegration}
                order={order}
                code={code}
                totalPrice={totalPrice}
              />
            </div>
          </Fade>
        </div>
      );
    }
    return (
      <Offers
        fade={fade}
        getOfferProviderData={getOfferProviderData}
        setOffers={setOffers}
        redirect={redirect}
        offers={offers}
        setLoadOffers={setLoadOffers}
        consumer={consumer}
        setFade={setFade}
      />
    );
  };

  return (
    <>
      {loaded && !integratedCheckout && offers.length > 0 && (
        <Fade in={fade} timeout={400} mountOnEnter unmountOnExit>
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 144,
              margin: "auto",
            }}
          >
            {offers[0].point_system ? (
              <div className={classes.titleContainer}>
                <Typography className={classes.title}>Klicka här innan ditt köp för att samla poäng</Typography>
                <ArrowDownwardRoundedIcon style={{ height: 200, width: 300, color: "#fff" }} />
              </div>
            ) : (
              <Typography className={classes.selectOffer}>{strings.selectOffer}</Typography>
            )}
          </div>
        </Fade>
      )}
      <Container
        maxWidth={false}
        align="center"
        component="main"
        style={{ position: "absolute", top: paddingTop, padding: 0, bottom: 0 }}
      >
        <div style={{ height: "100%" }}>{loaded && renderOffersOrCheckout()}</div>
      </Container>
    </>
  );
};

const useStyles = makeStyles(() => ({
  verticalSpace: {
    height: 10,
  },
  callToActionTitle: {
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 36,
    textAlign: "center",
  },
  callToActionBody: {
    fontFamily: "DMSans",
    fontSize: 28,
    textAlign: "center",
  },
  selectOffer: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 24,
    textAlign: "center",
    textShadow: "1px 1px 3px #DDDDDD",
  },
  titleContainer: {
    display: "flex",
    margin: "0 20px 0 20px",
    flexDirection: "column",
    alignItems: "center",
    gap: 10,
  },
  title: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 42,
    lineHeight: 1,
    textAlign: "center",
    color: "#FFF",
  },
  checkoutContainer: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 144,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  animationContainer: {
    position: "fixed",
    top: 0,
    height: "100vh",
    display: "flex",
    zIndex: 100,
    alignItems: "center",
    left: 0,
    right: 0,
  },
  whiteContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    paddingTop: 40,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 20,
  },
  callToActionContainer: {
    display: "flex",
    height: "90%",
    alignItems: "center",
    justifyContent: "center",
    margin: 64,
  },
}));

export default Dashboard;
