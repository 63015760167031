import React, { useContext } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { MSButton } from "../MSButton";
import Popover from "@material-ui/core/Popover";
import LanguageContext from "../../store/LanguageContext";
import FilterItem from "./FilterItem";

const TableFilter = ({ currentTags, setFilterOptions }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [selectedTag, setSelectedTag] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleTagChange = tag => {
    setSelectedTag(tag);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterCards = () => {
    setFilterOptions({ tag_id: selectedTag ? selectedTag.value : "", email: email.toLowerCase() });
    setAnchorEl(null);
  };

  const clearFilters = () => {
    setFilterOptions({ tag_id: "", email: "" });
    setEmail("");
    setSelectedTag("");
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <div className={classes.filterBox} onClick={handleClick}>
        <Typography className={classes.inviteButtonText}>{strings.loyaltyCardsPage.filter}</Typography>
        <Filter width={18} height={18} className={classes.filterIcon} />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 16, marginTop: 10, minWidth: 275 } }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ width: "100%", borderRadius: 16 }}>
          <div className={classes.filterTitleBox}>
            <Typography className={classes.filterTitleText}>{strings.loyaltyCardsPage.filter}</Typography>
          </div>
          <div className={classes.filterContentBox}>
            <div className={classes.emailBox}>
              <Typography className={classes.emailText}>{strings.loyaltyCardsPage.emailTitle}</Typography>
              <input className={classes.input} value={email} onChange={handleEmailChange} />
            </div>
            {currentTags && (
              <FilterItem
                currentTags={currentTags}
                selectedTag={selectedTag}
                handleTagChange={handleTagChange}
                tagKey="filter"
              />
            )}
            <div className={classes.buttonsRow}>
              <MSButton type="secondary" style={{ height: 30, marginRight: 10 }} onClick={() => clearFilters()}>
                {strings.loyaltyCardsPage.resetButton}
              </MSButton>
              <MSButton type="primary" style={{ height: 30 }} onClick={filterCards}>
                {strings.loyaltyCardsPage.applyButton}
              </MSButton>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

const useStyles = makeStyles({
  filterBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 1px 2px 0px #00000040",
    border: "0.5px solid #C4C4C4",
    minHeight: 30,
    cursor: "pointer",
    marginLeft: 40,
    padding: "0px 20px",
    "&:hover": {
      backgroundColor: "#EDB5A3",
      color: "white",
      fill: "white",
    },
  },

  filterTitleBox: {
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "center",
    backgroundColor: "#EDB5A3",
    height: 40,
    paddingLeft: 20,
  },

  filterTitleText: {
    color: "#FFFFFF",
    fontFamily: "DMSans",
    fontWeight: 500,
    fontSize: 15,
  },

  filterIcon: {
    marginLeft: 10,
  },

  filterContentBox: {
    display: "flex",
    height: "auto",
    flexDirection: "column",
  },

  emailBox: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 15px",
  },

  emailText: {
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 10,
    marginBottom: 5,
  },

  buttonsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "10px 15px",
  },

  inviteButtonText: {
    display: "flex",
    fontFamily: "DMSans",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 700,
    minWidth: 55,
  },

  input: {
    width: "100%",
    height: 32,
    border: "1px solid #C4C4C4",
    borderRadius: 8,
    fontFamily: "DMSans",
    fontSize: 12,
    fontWeight: 500,
    padding: "0px 5px",
    "&:focus": {
      outlineWidth: 0,
    },
  },
});

export default TableFilter;
