import React from "react"
import useStyles from "./styles"
import QRCode from "qrcode.react"

const QrCode = props => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.qrCodeContainer}>
        <QRCode
          style={{ width: "100%", height: "100%" }}
          value={props.code}
          size={560}
          bgColor={"#ffffff"}
          fgColor={"#000000"}
          level={"L"}
          includeMargin={false}
          renderAs={"svg"}
          // imageSettings={{
          //   src: "mystamp_m_logo.png",
          //   x: null,
          //   y: null,
          //   borderRadius: 10,
          //   height: 80,
          //   width: 80,
          //   excavate: true,
          // }}
        />
      </div>
    </>
  )
}

export default QrCode
