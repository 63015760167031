import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const Metric = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.typographyContainer}>
      <Typography className={classes.valueTypography}>{props.value}</Typography>
      <Typography className={classes.labelTypography}>{props.label}</Typography>
    </div>
  );
};

const useStyles = makeStyles({
  typographyContainer: {
    flexGrow: 1,
    flexBasis: 0,
    margin: props => (props.gridKey === "activeCoupon" ? "60px auto 60px" : "100px auto 0"),
    padding: "10px",
  },

  valueTypography: {
    fontFamily: "DMSans",
    fontStyle: "Normal",
    fontWeight: 700,
    fontSize: props => (props.gridKey === "activeCoupon" ? 44 : 56),
    textAlign: "center",
    lineHeight: "18px",
    color: "#EDB5A3",
    marginBottom: 32,
  },

  labelTypography: {
    fontFamily: "DMMono",
    fontStyle: "Regular",
    fontSize: 18,
    textAlign: "center",
    lineHeight: "30px",
  },
});

export default Metric;
