import { makeStyles } from "@material-ui/core";

export const useStorePageStyles = makeStyles(() => ({
  mainContainer: {
    height: "100%",
    minHeight: window.innerHeight,
    marginTop: 200,
    paddingLeft: 50,
    paddingRight: 50,
  },
  inviteUsersBox: {
    display: "flex",
    flex: 1,
    marginTop: 10,
    alignItems: "center",
  },
  inviteTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: "DMSans",
    fontSize: 28,
    fontWeight: 700,
    color: "#333333",
    marginRight: 10,
  },
  subtitle: {
    fontFamily: "DMSans",
    fontSize: 20,
    color: "#333333",
    fontWeight: 400,
  },
  buttonText: {
    fontFamily: "DMSans",
    fontSize: 20,
    color: "#fff",
    fontWeight: 700,
  },
  stripe_connect: {
    display: "inline-block",
    marginBottom: "1px",
    backgroundImage: "linear-gradient(#28A0E5, #015E94)",
    WebkitFontSmoothing: "antialiased",
    border: "0",
    padding: "1px",
    height: "30px",
    textDecoration: "none",
    MozBorderRadius: "4px",
    WebkitBorderRadius: "4px",
    borderRadius: "4px",
    MozBoxShadow: "0 1px 0 rgba(0,0,0,0.2)",
    WebkitBoxShadow: "0 1px 0 rgba(0, 0, 0, 0.2)",
    boxShadow: "0 1px 0 rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    MsUserSelect: "none",
    userSelect: "none",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    backdropFilter: "blur(10px)",
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  modal: {
    marginTop: 250,
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: 650,
    padding: 40,
    backgroundColor: "#fff",
    border: "1px solid rgba(196, 196, 196, 0.25)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 20,
  },
  button: {
    background: "#edb5a3",
    color: "#FFF",
    marginTop: 40,
    borderRadius: 16,
    textTransform: "none",
  },
}));
