import React, { useContext } from "react";
import LanguageContext from "../../store/LanguageContext";
import { CompanyContext } from "../../store/CompanyContext";
import InputErrorBox from "../Shared/InputErrorBox";
import { Collapse, List, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core";

const NotificationsInfoList = ({
  isChecked,
  notificationBalance,
  notificationMailingsPerMonth,
  usedMonthlyNotifications,
}) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const { company } = useContext(CompanyContext);
  const monthlyNotificationsLeft = notificationMailingsPerMonth - usedMonthlyNotifications;
  const balanceWarningThreshold = 100;
  const subscrtionWarningThreshold = 2;

  const showInsufficientFunds = () => {
    return (
      (notificationBalance ? notificationBalance <= 0 : false) ||
      (usedMonthlyNotifications ? monthlyNotificationsLeft <= 0 : false)
    );
  };

  const showWarningMessage = () => {
    return (
      !showInsufficientFunds() &&
      ((notificationBalance ? notificationBalance < balanceWarningThreshold : false) ||
        (monthlyNotificationsLeft ? monthlyNotificationsLeft < subscrtionWarningThreshold : false))
    );
  };

  const showContactEmail = () => {
    if (company.company) {
      if (company.company.locations[0].city_category.country === "SE") {
        return "kontakt@mystamp.se";
      }
      return "info@mystamp.nl";
    }
  };

  return (
    <List className={classes.list}>
      {typeof notificationBalance === "number" && (
        <ListItem className={classes.listItem}>
          <ListItemText className={classes.textNormal}>
            <span>{strings.couponsPage.notificationsMessage_1}</span>
            <span className={classes.textBold}> {notificationBalance} </span>
            <span>{strings.couponsPage.notificationsMessage_2}</span>
          </ListItemText>
        </ListItem>
      )}
      {typeof usedMonthlyNotifications === "number" && (
        <ListItem className={classes.listItem}>
          <ListItemText className={classes.textNormal}>
            <span>{strings.couponsPage.notificationsMessage_1}</span>
            <span className={classes.textBold}> {monthlyNotificationsLeft} </span>
            <span>
              {strings.couponsPage.notificationsMessage_3}
              {notificationMailingsPerMonth}
              {strings.couponsPage.notificationsMessage_2}
              {strings.couponsPage.notificationsMessage_4}
            </span>
          </ListItemText>
        </ListItem>
      )}
      <div className={classes.errorText}>
        {showInsufficientFunds() && isChecked && (
          <InputErrorBox message={strings.couponsPage.insufficientNotificationFunds} />
        )}
      </div>
      <Collapse in={!isChecked}>
        {showInsufficientFunds() && (
          <ListItem className={classes.listItem}>
            <ListItemText className={classes.textNormal}>
              <span style={{ lineHeight: "10px" }}>
                {strings.couponsPage.notificationsMessage_5}
                <Typography>
                  {"\n"}
                  {showContactEmail()}
                </Typography>
              </span>
            </ListItemText>
          </ListItem>
        )}
        {showWarningMessage() && (
          <ListItem className={classes.listItem}>
            <ListItemText className={classes.textNormal}>
              <span>{strings.couponsPage.notificationsMessage_6}</span>
              <Typography>
                {"\n"}
                {showContactEmail()}
              </Typography>
            </ListItemText>
          </ListItem>
        )}
      </Collapse>
    </List>
  );
};

const useStyles = makeStyles({
  errorText: {
    marginBottom: 10,
  },
  list: {
    marginBottom: 10,
    paddingLeft: 5,
  },

  listItem: {
    paddingLeft: 0,
    marginBottom: 10,
  },

  textNormal: {
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 20,
    color: "#333333",
  },

  textBold: {
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 24,
    color: "#EDB5A3",
  },
});

export default NotificationsInfoList;
