import { makeStyles, Typography, Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useContext } from "react";
import ReactFileReader from "react-file-reader";
import { ReactComponent as PNGUpload } from "../../assets/icons/pngUpload.svg";
import { ReactComponent as Checked } from "../../assets/icons/check-square.svg";
import { ReactComponent as Unhecked } from "../../assets/icons/square.svg";
import CloseIcon from "@material-ui/icons/Close";
import LanguageContext from "../../store/LanguageContext";
import InputErrorBox from "../Shared/InputErrorBox";
import Dropdown from "../Shared/Dropdown";

const NewEventCouponForm = ({
  handleImageChange,
  image,
  setImage,
  validityPeriod,
  handleValidityPeriod,
  shortDescription,
  handleShortDescriptionChange,
  longDescription,
  handleLongDescriptionChange,
  specifications,
  handleSpecificationsChange,
  shortDescriptionErrorMessage,
  longDescriptionErrorMessage,
  specificationsErrorMessage,
  eventOptionOne,
  handleEventOptionOne,
  eventOptionTwo,
  handleEventOptionTwo,
  selectedEvent,
  handleSelectedEventsChange,
  selectedValidityPeriod,
  handleSelectedValidityPeriodChange,
  eventOptions,
  isActive,
  handleIsActiveChange,
  invitedCount,
  isEventOptionAvailable,
  hasPointsEvents,
}) => {
  const classes = useStyles(invitedCount);
  const strings = useContext(LanguageContext);

  const validityPeriods = [
    {
      label: strings.couponsPage.days,
      value: "days",
    },
    {
      label: strings.couponsPage.weeks,
      value: "weeks",
    },
  ];

  const eventSpecificSection = () => {
    if (!selectedEvent || selectedEvent.value === "first_stamp") {
      return null;
    }

    const enableOptionTwo = selectedEvent.value === "x_stamps_per_period";
    return (
      <>
        <div className={classes.titleBox}>
          <Typography className={classes.inputLabelTitle}>{strings.couponsPage.validityOptions}</Typography>
        </div>
        <div className={classes.eventValueContainer}>
          <Typography component={"span"} className={classes.inputLabelSubtitle}>
            {selectedEvent.text1}
          </Typography>
          <input
            disabled={invitedCount > 0}
            placeholder={"Ex. 5"}
            className={classes.eventValueInput}
            type="number"
            min={1}
            value={eventOptionOne}
            onKeyDown={evt => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
            onChange={handleEventOptionOne}
          />
          <Typography component={"span"} className={classes.inputLabelSubtitle}>
            {selectedEvent.text2}
          </Typography>
          {enableOptionTwo && (
            <>
              <input
                disabled={invitedCount > 0}
                placeholder={"Ex. 5"}
                className={classes.eventValueInput}
                type="number"
                min={1}
                value={eventOptionTwo}
                onKeyDown={evt => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onChange={handleEventOptionTwo}
              />
              <Typography component={"span"} className={classes.inputLabelSubtitle}>
                {selectedEvent.text3}
              </Typography>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={classes.leftContainer}>
      <div className={classes.titleBox}>
        <Typography className={classes.inputLabelTitle}>{strings.couponsPage.addImage}</Typography>
        <Typography className={classes.inputLabelSubtitle}>{strings.couponsPage.png}</Typography>
      </div>
      <div className={classes.pngContainer}>
        <ReactFileReader
          disabled={invitedCount > 0}
          handleFiles={handleImageChange}
          base64={true}
          fileTypes={"image/png"}
        >
          {image === null ? (
            <div className={classes.pngTemplate}>
              <div className={classes.pngIconBox}>
                <PNGUpload className={classes.pngIcon} />
              </div>
            </div>
          ) : (
            <div className={classes.pngImageContainer}>
              <div className={classes.closeIconBox}>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={e => {
                    setImage(null);
                    e.stopPropagation();
                  }}
                />
              </div>
              <div className={classes.selectedImageBox}>
                <img src={image} alt={"coupon_image"} className={classes.selectedImage} />
              </div>
            </div>
          )}
        </ReactFileReader>
      </div>
      <div className={classes.titleBox}>
        <div>
          <Typography className={classes.inputLabelTitle}>{strings.couponsPage.validityPeriod}</Typography>
          <Typography className={classes.inputLabelTitleHelp}>{strings.couponsPage.validityPeriodHelp}</Typography>
        </div>
      </div>
      <div className={classes.eventValueContainer}>
        <input
          disabled={invitedCount > 0}
          placeholder={"Ex. 5"}
          className={classes.eventValueInput}
          type="number"
          min={1}
          value={validityPeriod}
          onKeyDown={evt => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
          onChange={handleValidityPeriod}
        />
        <div className={classes.validityPeriodsSelectContainer}>
          <Dropdown
            disabled={invitedCount > 0}
            data={validityPeriods}
            selectedItem={selectedValidityPeriod}
            handleChange={handleSelectedValidityPeriodChange}
          />
        </div>
      </div>
      {!hasPointsEvents && (
        <>
          <div className={classes.titleBox}>
            <Typography className={classes.inputLabelTitle}>{strings.couponsPage.eventTitle}</Typography>
          </div>
          <div className={classes.eventValueContainer}>
            <div className={classes.eventSelectContainer}>
              <Dropdown
                disabled={invitedCount > 0}
                data={eventOptions}
                selectedItem={selectedEvent}
                handleChange={handleSelectedEventsChange}
              />
            </div>
          </div>
        </>
      )}
      {!hasPointsEvents && selectedEvent && eventSpecificSection()}
      <div className={classes.titleBox}>
        <Typography className={classes.inputLabelTitle}>
          {strings.loyaltyCardsPage.shortDescriptionDefault}*
          <Typography component={"span"} className={classes.inputLabelSubtitle}>
            {strings.couponsPage.max72}
          </Typography>
        </Typography>
      </div>
      <textarea
        disabled={invitedCount > 0}
        placeholder={strings.couponsPage.shortDescriptonExample}
        className={classes.descriptionInput}
        type="text"
        value={shortDescription}
        onChange={handleShortDescriptionChange}
      />
      {shortDescriptionErrorMessage.length > 0 ? <InputErrorBox message={shortDescriptionErrorMessage} /> : null}
      <div className={classes.titleBox}>
        <Typography className={classes.inputLabelTitle}>
          {strings.couponsPage.longDescription}*
          <Typography component={"span"} className={classes.inputLabelSubtitle}>
            {strings.couponsPage.max125}
          </Typography>
        </Typography>
      </div>
      <textarea
        disabled={invitedCount > 0}
        placeholder={strings.couponsPage.longDescriptonExample}
        className={classes.descriptionInput}
        type="text"
        value={longDescription}
        onChange={handleLongDescriptionChange}
      />
      {longDescriptionErrorMessage.length > 0 ? <InputErrorBox message={longDescriptionErrorMessage} /> : null}
      <div className={classes.titleBox}>
        <Typography className={classes.inputLabelTitle}>
          {strings.couponsPage.specifications}
          <Typography component={"span"} className={classes.inputLabelSubtitle}>
            {strings.couponsPage.max80}
          </Typography>
        </Typography>
      </div>
      <textarea
        disabled={invitedCount > 0}
        placeholder={strings.couponsPage.specificationsExample}
        className={classes.descriptionInput}
        type="text"
        value={specifications}
        onChange={handleSpecificationsChange}
      />
      {specificationsErrorMessage.length > 0 ? <InputErrorBox message={specificationsErrorMessage} /> : null}
      <div className={classes.titleBox}>
        <div className={classes.activeContainer}>
          <Typography className={classes.inputLabelTitle} style={{ color: "#333333" }}>
            {strings.couponsPage.activate}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={!isActive}
                value={isActive}
                onClick={() => handleIsActiveChange(!isActive)}
                icon={<Checked style={{ color: "#000000" }} />}
                checkedIcon={<Unhecked style={{ color: "#000000" }} />}
              />
            }
            label={strings.couponsPage.activateText}
            className={classes.dateTitle}
          />
        </div>
      </div>
      {!isEventOptionAvailable ? <InputErrorBox message={strings.couponsPage.activeValidation} /> : null}
    </div>
  );
};

const useStyles = makeStyles({
  activeContainer: {
    display: "flex",
    flexDirection: "column",
  },
  dateTitle: {
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 18,
    color: "#333333",
    textAlign: "start",
  },
  validityPeriodsSelectContainer: {
    width: "50%",
  },
  eventSelectContainer: {
    width: "100%",
  },
  eventValueContainer: {
    width: "90%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 16,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 0.6,
    paddingLeft: 30,
    paddingBottom: 40,
    flexGrow: 1,
  },

  titleBox: {
    display: "flex",
    maxWidth: 600,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 30,
    textAlign: "start",
    marginBottom: 10,
    paddingRight: 50,
  },

  pngContainer: {
    maxWidth: 400,
    marginRight: 20,
  },

  pngTemplate: {
    display: "flex",
    flexDirection: "column",
    minWidth: 200,
    maxWidth: 400,
    height: 200,
    backgroundColor: "#E0E0E0",
    borderRadius: 16,
    justifyContent: "flex-start",
    cursor: invitedCount => (invitedCount <= 0 ? "pointer" : "not-allowed"),
  },

  pngImageContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 200,
    maxWidth: 400,
    height: 200,
    borderRadius: 16,
    justifyContent: "flex-start",
    cursor: invitedCount => (invitedCount <= 0 ? "pointer" : "not-allowed"),
    paddingBottom: 30,
    backgroundColor: "transparent",
    border: "0.5px solid #E0E0E0",
  },

  selectedImageBox: {
    display: "flex",
    width: "100%",
    justifyItems: "center",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    maxWidth: 300,
  },

  selectedImage: {
    display: "inline-block",
    maxWidth: 300,
    maxHeight: 140,
  },

  closeIconBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: 26,
    paddingRight: 7,
  },

  closeIcon: {
    width: 35,
    height: 35,
    marginBottom: -15,
  },

  pngIconBox: {
    display: "flex",
    width: "100%",
    justifyItems: "center",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  pngIcon: {
    width: 90,
    height: 95,
  },

  descriptionInput: {
    display: "flex",
    width: "90%",
    minHeight: 144,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    borderRadius: 8,
    resize: "none",
    padding: "15px",
    color: invitedCount => (invitedCount <= 0 ? "#333333" : "#C4C4C4"),
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 5,
    "&:focus": {
      outlineWidth: 0,
    },
    "&::placeholder": {
      color: "#C4C4C4",
      fontFamily: "DMSans",
      fontSize: 18,
      fontWeight: 400,
    },
  },

  eventValueInput: {
    width: 70,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    minHeight: 47,
    height: "100%",
    color: invitedCount => (invitedCount <= 0 ? "#333333" : "#C4C4C4"),
    fontFamily: "DMSans",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 8,
    resize: "none",
    padding: "5px 10px",
    "&::placeholder": {
      color: "#C4C4C4",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "&[type=number]": {
      MozAppearance: "textfield",
    },
  },
  inputLabelTitleHelp: {
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 16,
    color: invitedCount => (invitedCount <= 0 ? "#333333" : "#C4C4C4"),
  },
  inputLabelTitle: {
    fontFamily: "DMSans",
    fontWeight: 600,
    fontSize: 20,
    color: invitedCount => (invitedCount <= 0 ? "#333333" : "#C4C4C4"),
    textAlign: "start",
  },

  inputLabelSubtitle: {
    fontFamily: "DMSans",
    fontSize: 20,
    color: invitedCount => (invitedCount <= 0 ? "#333333" : "#C4C4C4"),
    fontWeight: 400,
    marginLeft: 5,
  },
});

export default NewEventCouponForm;
