import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  qrCodeContainer: {
    padding: 20,
    backgroundColor: "#FFF",
    borderRadius: 16,
  },
}));

export default useStyles;
