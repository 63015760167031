import React from "react";
import { useLocation } from "react-router-dom";
import { Header }  from "../Header";
import { MSNavigation } from "../MSNavigation";
import { NavigationRoutes  } from "../../routes/navigation";
import { Footer } from "../Footer";

export const RootWrapper = ({ children }) => {
  const location = useLocation();

  if (Object.values(NavigationRoutes).includes(location.pathname)) {
    return (
      <>
        <Header />
        <MSNavigation />
        {children}
        <Footer />
      </>
    );
  }

  return children;
};
