import { makeStyles } from "@material-ui/core";

const commonBtnStyles = {
  padding: "10px 15px",
  fontWeight: 700,
  textTransform: "none",
};

const buttonStyles = {
  primary: ({ palette, typography }) => ({
    root: {
      ...commonBtnStyles,
      backgroundColor: palette.primary.main,
      color: typography.white,
      borderRadius: "38px",

      "&:hover": {
        backgroundColor: palette.primary.main,
      },
    },
  }),
  secondary: ({ palette }) => ({
    root: {
      ...commonBtnStyles,
      backgroundColor: palette.primary.secondary,
      borderRadius: "38px",

      "&:hover": {
        backgroundColor: palette.primary.secondary,
      },
    },
  }),
  white: ({ palette, typography }) => ({
    root: {
      ...commonBtnStyles,
      backgroundColor: palette.white,
      borderRadius: "38px",
      color: typography.gray,
    },
  }),
  text: () => ({
    root: {
      ...commonBtnStyles,
    },
  }),
};

export const useButtonStyles = type => {
  const makeClasses = makeStyles(buttonStyles[type]);

  return makeClasses();
};
