import React, { useContext } from "react";
import LanguageContext from "../../store/LanguageContext";
import { makeStyles, Typography } from "@material-ui/core";

const SubscriptionCard = ({ productSubscription, selected = false, handleSelected }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);

  const convertPrice = price => {
    return price / 100;
  };

  return (
    <div className={classes.container} onClick={() => handleSelected()}>
      <div className={classes.cardContainer} style={{ filter: selected ? "brightness(1)" : "brightness(0.75)" }}>
        <div className={classes.content}>
          <div>
            <Typography className={classes.title}>{productSubscription.name}</Typography>
            {/* <Typography className={classes.subTitle}>Card subtitle</Typography> */}
          </div>
          <Typography className={classes.price}>
            {convertPrice(productSubscription.price_per_month)} {strings.storePage.krPerMonth}
          </Typography>
        </div>
        <div className={classes.image}>
          <img src={productSubscription.image} alt={"coupon_image"} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  price: {
    fontFamily: "DMSans",
    fontSize: 14,
    color: "#333333",
  },
  subTitle: {
    fontFamily: "DMSans",
    fontSize: 12,
    color: "#333333",
  },
  title: {
    fontFamily: "DMSans",
    fontSize: 16,
    fontWeight: "bold",
    color: "#333333",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 2,
    padding: "8px 16px",
  },
  image: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    display: "flex",
    flex: 1,
    objectFit: "cover",
    overflow: "hidden",
  },
  container: {
    padding: "16px 6px",
    filter: "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12))",
  },
  cardContainer: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    width: 343,
    height: 112,
    backgroundColor: "#fff",
    borderRadius: 12,
  },
});

export default SubscriptionCard;
