import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

import { useButtonStyles } from "./MSButtonStyles";

const buttonTypes = ["primary", "secondary", "white", "text"];

const typeToVariant = {
  primary: "contained",
  secondary: "outlined",
  white: "contained",
  text: "text",
};

const MSButton = ({ type, className, ...rest }) => {
  const classes = useButtonStyles(type);

  return <Button variant={typeToVariant[type]} classes={classes} className={className} {...rest} />;
};

MSButton.propTypes = {
  type: PropTypes.oneOf(buttonTypes),
};

export { MSButton };
