import fetchHelper from "../lib/fetchHelper";

export const addNewTag = async formData => {
  return fetchHelper("api/dashboard/tags", {
    method: "POST",
    body: formData,
  });
};

export const getActiveCards = async () => {
  return fetchHelper("api/dashboard/loyalty_card_programs/", {
    method: "GET",
  });
};

export const getCardDetails = async (id, page, tag_id, invite_email) => {
  return fetchHelper(
    `api/dashboard/loyalty_cards?loyalty_card_program=${id}&page=${page}&tag_id=${tag_id}&invite_email=${invite_email}`,
    {
      method: "GET",
    },
  );
};

export const inviteUserViaEmail = async (id, formData) => {
  return fetchHelper(`api/dashboard/loyalty_card_programs/${id}/invite`, {
    method: "POST",
    body: formData,
  });
};

export const inviteUserViaCSV = async (id, formData) => {
  return fetchHelper(`api/dashboard/loyalty_card_programs/${id}/bulk_invite`, {
    method: "POST",
    body: formData,
  });
};

export const getTags = async () => {
  return fetchHelper("/api/dashboard/tags", {
    method: "GET",
  });
};

export const removeTag = async id => {
  return fetchHelper(`/api/dashboard/tags/${id}`, {
    method: "DELETE",
  });
};

export const updateLoyaltyCard = async (id, formData) => {
  return fetchHelper(`api/dashboard/loyalty_cards/${id}`, {
    method: "PATCH",
    body: formData,
  });
};

export const deleteLoyaltyCard = async id => {
  return fetchHelper(`api/dashboard/loyalty_cards/${id}`, {
    method: "DELETE",
  });
};
