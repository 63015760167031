import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  checkoutContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    paddingLeft: 64,
    paddingRight: 64,
    paddingTop: 32,
    paddingBottom: 96,
  },
  qrContainer: {
    display: "flex",
    padding: 18,
    borderRadius: 20,
    fontFamily: "DMSans",
    fontSize: 18,
    backgroundColor: "#fff",
    width: "100%",
    height: 250,
  },
  scanTextContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  scanText: {
    fontSize: 24,
    textAlign: "center",
  },
  qrCode: {
    display: "flex",
    flex: 1,
  },
  pointsText: {
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 64,
    color: "#fff",
  },
  fadeContainer: {
    height: 250,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  outerFadeContainer: {
    height: 250,
    position: "relative",
    width: "100%",
  },
}));

export default useStyles;
