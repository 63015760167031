export const customStyles = {
  table: {
    shadow: "0px 1px 2px 0px #00000040",
    maxWidth: 150,
    width: 150,
    cursor: "pointer",
    height: 32,
    fontSize: 16,
    fontWeight: 400,
    menuPosition: "fixed",
    minWidth: null,
    overflowX: null,
    multi: false,
    search: true,
    delete: false,
  },
  filter: {
    shadow: "none",
    maxWidth: null,
    width: "100%",
    cursor: "default",
    height: 32,
    fontSize: 12,
    fontWeight: 500,
    menuPosition: "fixed",
    minWidth: 150,
    overflowX: null,
    multi: false,
    search: true,
    delete: false,
  },
  coupon: {
    shadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    maxWidth: null,
    width: "100%",
    cursor: "pointer",
    height: 47,
    fontSize: 16,
    fontWeight: 400,
    menuPosition: "absolute",
    minWidth: null,
    overflowX: null,
    multi: true,
    search: false,
    delete: false,
  },
  invite: {
    shadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    maxWidth: null,
    width: "100%",
    cursor: "default",
    height: 47,
    fontSize: 16,
    fontWeight: 400,
    menuPosition: "absolute",
    minWidth: null,
    overflowX: null,
    multi: false,
    search: true,
    delete: true,
  },
  graph: {
    shadow: "none",
    maxWidth: null,
    width: "100%",
    cursor: "default",
    height: 32,
    fontSize: 12,
    fontWeight: 500,
    menuPosition: "fixed",
    minWidth: 100,
    overflowX: "none",
    multi: false,
    search: false,
    delete: false,
  },
  default: {
    shadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    maxWidth: null,
    width: "100%",
    cursor: "default",
    height: 47,
    fontSize: 16,
    fontWeight: 400,
    menuPosition: "absolute",
    minWidth: null,
    overflowX: null,
    multi: false,
    search: true,
    delete: false,
  },
};

export const customComponentsStyles = customStyle => {
  return {
    control: base => ({
      ...base,
      maxWidth: customStyle.maxWidth,
      width: customStyle.width,
      textAlign: "left",
      cursor: customStyle.cursor,
      minHeight: null,
      height: customStyle.height,
      boxShadow: customStyle.shadow,
      border: "1px solid #C4C4C4",
      minWidth: customStyle.minWidth,
      borderRadius: 8,
      "&:hover": {
        border: "1px solid #C4C4C4",
      },
    }),

    option: base => ({
      ...base,
      textAlign: "left",
      fontSize: customStyle.fontSize,
      fontWeight: customStyle.fontWeight,
      height: customStyle.height,
      overflowX: customStyle.overflowX,
    }),

    placeholder: base => ({
      ...base,
      fontFamily: "DMSans",
      fontSize: customStyle.fontSize,
      fontWeight: customStyle.fontWeight,
      color: "#33333380",
    }),

    indicatorsContainer: base => ({
      ...base,
      height: customStyle.height,
    }),

    valueContainer: base => ({
      ...base,
      display: "flex",
      fontFamily: "DMSans",
      fontSize: customStyle.fontSize,
      fontWeight: customStyle.fontWeight,
      height: customStyle.height,
      padding: "0px 8px",
    }),

    input: base => ({
      ...base,
      fontFamily: "DMSans",
      fontSize: customStyle.fontSize,
      fontWeight: customStyle.fontWeight,
    }),

    multiValue: base => ({
      ...base,
      backgroundColor: "transparent",
      width: "100%",
      minWidth: null,
    }),

    multiValueLabel: base => ({
      ...base,
      fontSize: 18,
      fontWeight: 400,
      fontFamily: "DMSans",
    }),

    multiValueRemove: base => ({
      ...base,
      display: "none",
    }),
  };
};
