import RequestException from "./requestException";

const fetchHelper = async (url, config) => {
  const response = await fetch(url, config);
  let data = {};
  try {
    data = await response.json();

    if (response.status.toString().startsWith("2")) {
      return data;
    }
  } catch (error) {
    if (response.status === 204 || response.status === 202) {
      return data;
    }
    throw new RequestException(error.message, response.status);
  }
  throw new RequestException(data.message, response.status);
};

export default fetchHelper;
