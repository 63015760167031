import { alpha, makeStyles } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import consumer from "../../lib/actionCable";
import { LocationContext } from "../../store/LocationContext";
import { UserContext } from "../../store/UserContext";
import TextAnimation from "./TextAnimation";
import ReceiptAnimation from "./ReceiptAnimation";
import PhoneAnimation from "./PhoneAnimation";
import PurchaseCard from "./PurchaseCard";

export const pointsDashboardDefaultColor = "#EDB5A3";
const PointsDashboard = ({ offerProvider }) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [code, setCode] = useState("");
  const [points, setPoints] = useState(0);
  const [currency, setCurrency] = useState("SEK");
  const { location } = useContext(LocationContext);
  const { state } = useContext(UserContext);

  useEffect(() => {
    // If ip is not from Sweden, set currency to Euro
    const fetchCountryFromIp = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const { country } = await response.json();
        if (country && country !== "SE") {
          setCurrency("EUR");
        }
      } catch (e) {
        console.error("Failed to fetch country from ip", e);
      }
    };

    fetchCountryFromIp();
  }, []);

  useEffect(() => {
    const setupSocketListener = () => {
      consumer.subscriptions.create(
        {
          channel: "LocationReceiptsChannel",
          location_id: location.location_id,
        },
        {
          connected: () => {
            console.log("Receipt socket connected");
          },
          disconnected: () => {
            console.log("Receipt socket disconnected");
          },
          received: data => {
            setSuccess(true);
            setCode(data.qr_url);
            setPoints(data.points);
          },
        },
      );
    };
    if (location.location_id) {
      setupSocketListener();
    }
  }, [location.location_id]);

  useEffect(() => {
    const setupSocketListener = () => {
      consumer.subscriptions.create(
        {
          channel: "OfferProviderChannel",
          offer_provider_id: state.user.id,
        },
        {
          connected: () => {
            console.log("Offer provider socket connected");
          },
          disconnected: () => {
            console.log("Offer provider socket disconnected");
          },
          received: data => {
            if (data.action === "REFRESH") {
              window.location.reload();
            }
          },
        },
      );
    };
    if (state.user?.id) {
      setupSocketListener();
    }
  }, [state.user?.id]);

  const restartAnimation = () => {
    setIteration(prev => (prev + 1) % 10);
    setReceiptAnimationDone(false);
    setTextAnimationDone(false);
    setScanningAnimationDone(false);
    setPhoneAnimationDone(false);
  };

  // Hide success message after 5 seconds or 10 seconds if show_qr_after_point_receipt is true
  useEffect(() => {
    const timer = setTimeout(
      () => {
        if (success) {
          setSuccess(false);
          restartAnimation();
        }
      },
      offerProvider?.show_qr_after_point_receipt ? 20000 : 10000,
    );
    return () => clearTimeout(timer);
  }, [success, offerProvider?.show_qr_after_point_receipt]);

  const [textAnimationDone, setTextAnimationDone] = useState(false);
  const [receiptAnimationDone, setReceiptAnimationDone] = useState(false);
  const [scanningAnimationDone, setScanningAnimationDone] = useState(false);
  const [phoneAnimationDone, setPhoneAnimationDone] = useState(false);

  const [iteration, setIteration] = useState(0);
  useEffect(() => {
    if (textAnimationDone && receiptAnimationDone && phoneAnimationDone && !success) {
      restartAnimation();
    }
  }, [textAnimationDone, receiptAnimationDone, phoneAnimationDone, success]);

  const showBackgroundVideo = Boolean(offerProvider?.tablet_background_video_url);
  const showBackgroundImage = Boolean(!showBackgroundVideo && offerProvider?.tablet_background_image_url);
  return (
    <AnimatePresence>
      <div
        key={iteration}
        className={classes.root}
        style={{
          backgroundColor: offerProvider?.tablet_brand_color
            ? alpha(offerProvider?.tablet_brand_color, 0.25)
            : pointsDashboardDefaultColor,
        }}
      >
        {success ? (
          <motion.div
            className={classes.container}
            key={"purchase-container"}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <PurchaseCard offerProvider={offerProvider} code={code} currency={currency} points={points} />
          </motion.div>
        ) : (
          <motion.div
            key={"animation-container"}
            className={classes.container}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <TextAnimation onDone={() => setTextAnimationDone(true)} offerProvider={offerProvider} />
            <ReceiptAnimation when={textAnimationDone} onDone={() => setReceiptAnimationDone(true)} />
            <PhoneAnimation
              when={receiptAnimationDone}
              onDone={() => setPhoneAnimationDone(true)}
              onScanningDone={() => setScanningAnimationDone(true)}
              offerProvider={offerProvider}
            />
          </motion.div>
        )}
        {showBackgroundVideo && (
          <motion.video
            id="background-video"
            initial={{
              opacity: 0,
            }}
            animate={scanningAnimationDone || success ? { opacity: 1 } : { opacity: 0 }}
            transition={{
              delay: 0,
            }}
            loop
            autoPlay
            muted
            controls={false}
            playsInline
            src={offerProvider?.tablet_background_video_url}
            className={classes.background}
          />
        )}
        {showBackgroundImage && (
          <motion.img
            id="background-image"
            initial={{
              opacity: 0,
            }}
            animate={scanningAnimationDone || success ? { opacity: 1 } : { opacity: 0 }}
            transition={{
              delay: 0,
            }}
            src={offerProvider?.tablet_background_image_url}
            className={classes.background}
          />
        )}
      </div>
    </AnimatePresence>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: "50px",
    paddingRight: "10px",
    height: "min(1200px, 100vh)",
  },
  background: {
    zIndex: 0,
    top: 0,
    left: 0,
    objectFit: "cover",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "absolute",
  },
}));
export default PointsDashboard;
