import React, { useState, useContext } from "react";
import LanguageContext from "../../store/LanguageContext";
import { PageWrapper } from "../../components/Shared/PageWrapper";
import { InputWraper } from "../../components/Shared/InputWrapper";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { MSButton } from "../../components/MSButton";
import { returnPoints } from "../../services/StampCardsServices";
import { errorToast } from "../../components/Notifications/Notifications";

const ReturnsPage = () => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [receiptId, setReceiptId] = useState("");
  const [points, setPoints] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const getStatusMessage = feedback => {
    if (feedback) {
      const numberOfAlreadyClaimed = feedback.filter(x => x === "coupon_already_claimed").length;
      const numberOfCouponsRemoved = feedback.filter(x => x === "coupon_and_point_level_deleted").length;
      return `${strings.returnsPage.status_1} ${numberOfAlreadyClaimed} ${strings.returnsPage.status_2} ${numberOfCouponsRemoved} ${strings.returnsPage.status_3}`;
    }
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      const response = await returnPoints(receiptId, points);
      const statusMessage = getStatusMessage(response.feedback);
      setMessage(statusMessage);
    } catch (error) {
      setMessage("");
      errorToast({
        title: "Error",
        text: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageWrapper>
      <div className={classes.mainContainer}>
        <Typography className={classes.title}>{strings.returnsPage.title}</Typography>
        <InputWraper title={strings.returnsPage.description}>
          <div className={classes.innerContainer}>
            <Typography className={classes.inputLabelTitle}>{strings.returnsPage.input_label_1}</Typography>
            <input
              placeholder={strings.returnsPage.input_help_1}
              className={classes.input}
              type="text"
              value={receiptId}
              onChange={e => setReceiptId(e.target.value)}
            />
            <Typography className={classes.inputLabelTitle}>{strings.returnsPage.input_label_2}</Typography>
            <input
              placeholder={strings.returnsPage.input_help_2}
              className={classes.input}
              type="number"
              min={0}
              value={points}
              onChange={e => setPoints(e.target.value)}
            />
            <div className={classes.submitContainer}>
              <MSButton type="primary" onClick={() => submit()} disabled={isLoading}>
                {strings.returnsPage.submit}
              </MSButton>
              <Typography className={classes.inputLabelBoody}>{message}</Typography>
            </div>
          </div>
        </InputWraper>
      </div>
    </PageWrapper>
  );
};

const useStyles = makeStyles(() => ({
  mainContainer: {
    minHeight: window.innerHeight,
    height: "100%",
    marginTop: 200,
    marginLeft: 50,
    marginRight: 50,
  },
  innerContainer: {
    padding: 30,
    gap: 20,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontFamily: "DMSans",
    fontSize: 28,
    fontWeight: 700,
    color: "#333333",
  },
  inputLabelTitle: {
    fontFamily: "DMSans",
    fontWeight: 600,
    fontSize: 20,
    color: "#333333",
    textAlign: "start",
  },
  inputLabelBoody: {
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 16,
    color: "#333333",
  },
  submitContainer: {
    display: "flex",
    gap: 20,
    alignItems: "center",
  },
  input: {
    width: 700,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    minHeight: 47,
    height: "100%",
    color: "#333333",
    fontFamily: "DMSans",
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 8,
    resize: "none",
    padding: "5px 10px",
    "&::placeholder": {
      color: "#C4C4C4",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "&[type=number]": {
      MozAppearance: "textfield",
    },
  },
}));

export default ReturnsPage;
