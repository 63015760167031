import React, { useContext, useEffect, useState } from "react";
import { useStorePageStyles } from "./StorePageStyles";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow_up.svg";
import Button from "@material-ui/core/Button";
import LanguageContext from "../../store/LanguageContext";
import { Collapse, Typography } from "@material-ui/core";
import { PageWrapper } from "../../components/Shared/PageWrapper";
import { StatsGrid } from "../../components/StatsGrid";
import { StatsGridStat } from "../../components/StatsGridStat";
import { connectStripe } from "../../services/OfferProvidersServices";
import StoreSettings from "../../components/Store/StoreSettings";
import SubscriptionTable from "../../components/Store/SubscriptionTable";
import CreatedSubscriptions from "../../components/Store/CreatedSubscriptions";
import { getStore } from "../../services/SubscriptionServices";
import { errorToast } from "../../components/Notifications/Notifications";
import { StoreLicensePage } from "./StoreLicensePage/StoreLicensePage";


export const StorePage = () => {
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [hasStoreAccess, setHasStoreAccess] = useState(true);
  const [store, setStore] = useState({
    created_subscriptions: 0,
    active_subscriptions: 0,
    button_background_color: "",
    button_text_color: "",
    has_stripe_account: false,
    product_subscriptions: [],
    store_background_color: "",
    store_image: null,
    store_text_color: "",
  });
  const [selectedSubscription, setSelectedSubscription] = useState({
    id: -1,
    name: "",
    image: "",
    price_per_month: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [isDataModified, setIsDataModified] = useState(false);
  const classes = useStorePageStyles();
  const strings = useContext(LanguageContext);

  const connectStripeCall = async () => {
    try {
      let result = await connectStripe();
      window.location = result.link;
    } catch (error) {
      if (error.code === 401) {
        errorToast({
          title: "Error",
          text: error.message,
        });
      }
    }
  };

  const fetchStore = async () => {
    try {
      const result = await getStore();
      handleStore(result);
      if (!result.has_stripe_account) {
        setShowModal(true);
      }
      if (result?.product_subscriptions.length > 0) {
        handleSelected(result?.product_subscriptions[0]);
      }
    } catch (error) {
      if (error.code === 403) {
        setHasStoreAccess(false);
      } else {
        errorToast({
          title: "Error",
          text: error.message,
        });
      }
    }
  };

  const handleStore = store => {
    setStore(store);
  };

  const handleSelected = productSubscription => {
    setSelectedSubscription(productSubscription);
  };

  useEffect(() => {
    fetchStore();
  }, [isDataModified]);

  return hasStoreAccess ? (
    <PageWrapper>
      {showModal && (
        <div className={classes.overlay}>
          <div className={classes.modal}>
            <Typography className={classes.title}>{strings.storePage.paymentSolutionTitle}</Typography>
            <Typography className={classes.subtitle}>{strings.storePage.paymentSolutionSubtitle}</Typography>
            <div>
              <Button onClick={connectStripeCall} variant="contained" className={classes.button}>
                <Typography className={classes.buttonText}>{strings.storePage.connectWithStripe}</Typography>
              </Button>
            </div>
          </div>
        </div>
      )}
      <div align="center" className={classes.mainContainer}>
        <>
          <StatsGrid valueFontSize={56} labelFontSize={18}>
            <StatsGridStat value={store.created_subscriptions} label={strings.storePage.createdSubscriptions} />
            <StatsGridStat value={store.active_subscriptions} label={strings.storePage.activeSubscribers} />
          </StatsGrid>
          <div className={classes.inviteUsersBox}>
            <div
              className={classes.inviteTitleBox}
              style={{
                cursor: "pointer",
              }}
              onClick={() => setIsInviteOpen(!isInviteOpen)}
            >
              <Typography className={classes.title}>{strings.storePage.settings}</Typography>
              {isInviteOpen ? <ArrowUp height={14} width={23} /> : <ArrowDown height={14} width={23} />}
            </div>
          </div>
          <Collapse in={isInviteOpen}>
            <StoreSettings store={store} handleStore={handleStore} />
          </Collapse>
          <CreatedSubscriptions
            productSubscriptions={store.product_subscriptions}
            handleSelected={handleSelected}
            selectedSubscription={selectedSubscription}
          />
          <SubscriptionTable
            selectedSubscription={selectedSubscription}
            handleTableUpdate={() => setIsDataModified(!isDataModified)}
            isDataModified={() => null}
          />
        </>
      </div>
    </PageWrapper> 
  ) : (<StoreLicensePage/>);
};
