import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CouponsLicensePage } from "./CouponsLicensePage/CouponsLicensePage";
import { Collapse, Typography } from "@material-ui/core";
import LanguageContext from "../../store/LanguageContext";
import Spinner from "../../components/Shared/Spinner";
import ActiveCoupons from "../../components/Coupons/ActiveCoupons";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow_up.svg";
import PublishedCouponSection from "../../components/Coupons/PublishedCouponSection";
import { getCoupons, getCouponsInfo, getEventsCoupons, getEventsCouponsInfo } from "../../services/CouponsServices";
import { errorToast } from "../../components/Notifications/Notifications";
import { UserContext } from "../../store/UserContext";
import NewCouponSection from "../../components/Coupons/NewCouponSection";
import { StatsGrid } from "../../components/StatsGrid";
import { StatsGridStat } from "../../components/StatsGridStat";
import { PageWrapper } from "../../components/Shared/PageWrapper";
import { MSButton } from "../../components/MSButton";
import NewEventCouponSection from "../../components/Coupons/NewEventCouponSection";
import ActiveEventCoupons from "../../components/Coupons/ActiveEventCoupons";

const SHOULD_BE_PUBLISHED_WITHIN_X_MINUTES = 3;
const CAMPAIGN = "campaign";
const EVENT = "event";

export const CouponsPage = () => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(true);
  const [newCouponOpen, setNewCouponOpen] = useState(false);
  const [publishedCoupon, setPublishedCoupon] = useState(null);
  const [isCouponsPageAvailable, setIsCouponsPageAvailable] = useState(true);
  const { setState } = useContext(UserContext);
  const [activeCouponType, setActiveCouponType] = useState(CAMPAIGN);
  const [refetch, setRefetch] = useState(true);
  const [tempCoupon, setTempCoupon] = useState();

  // Campaign
  const [campaignCoupons, setCampaignCoupons] = useState([]);
  const [campaignCouponInfo, setCampaignCouponInfo] = useState(null);
  const [activeCampaignCoupon, setActiveCampaignCoupon] = useState(null);
  const [campaignMaxPage, setCampaignMaxPage] = useState(-1);
  const [campaignPage, setCampaignPage] = useState(1);

  // Event
  const [eventCoupons, setEventCoupons] = useState([]);
  const [eventCouponInfo, setEventCouponInfo] = useState(null);
  const [activeEventCoupon, setActiveEventCoupon] = useState(null);
  const [eventMaxPage, setEventMaxPage] = useState(-1);
  const [eventPage, setEventPage] = useState(1);
  const [usedEvents, setUsedEvents] = useState([]);

  const handleActiveCouponChange = coupon => {
    if (activeCouponType === CAMPAIGN) {
      setActiveCampaignCoupon(coupon.id === activeCampaignCoupon?.id ? null : coupon);
      if (coupon.id !== activeCampaignCoupon?.id) {
        const couponPublishDate = new Date(coupon.publish_at);
        const today = new Date();
        const timeDifferenceInMinutes = (today - couponPublishDate) / 1000 / 60;
        setPublishedCoupon(
          coupon.invited_count > 0 || timeDifferenceInMinutes >= SHOULD_BE_PUBLISHED_WITHIN_X_MINUTES ? coupon : null,
        );
      } else {
        // setPublishedCoupon(null);
      }
    } else {
      setActiveEventCoupon(coupon.id === activeEventCoupon?.id ? null : coupon);
      if (coupon.id !== activeEventCoupon?.id) {
        const couponPublishDate = new Date(coupon.publish_at);
        const today = new Date();
        const timeDifferenceInMinutes = (today - couponPublishDate) / 1000 / 60;
        setPublishedCoupon(
          coupon.invited_count > 0 || timeDifferenceInMinutes >= SHOULD_BE_PUBLISHED_WITHIN_X_MINUTES ? coupon : null,
        );
      } else {
        // setPublishedCoupon(null);
      }
    }
    setNewCouponOpen(true);
  };

  useEffect(() => {
    if (publishedCoupon) {
      setTempCoupon(publishedCoupon);
    }
  }, [publishedCoupon]);

  const handleCouponsUpdate = (newCoupon, isUpdate) => {
    let newCoupons;
    if (isUpdate) {
      if (activeCouponType === CAMPAIGN) {
        newCoupons = campaignCoupons.map(el => {
          if (el.id === newCoupon.id) {
            el = newCoupon;
          }
          return el;
        });
        setCampaignCoupons(newCoupons);
      } else if (activeCouponType === EVENT) {
        newCoupons = eventCoupons.map(el => {
          if (el.id === newCoupon.id) {
            el = newCoupon;
          }
          return el;
        });
        addUsedEventTypes(newCoupons);
        setEventCoupons(newCoupons);
      }
      window.scrollTo({
        top: 200,
        left: 0,
        behavior: "smooth",
      });
      return;
    }

    if (activeCouponType === CAMPAIGN) {
      newCoupons = campaignCoupons;
      setCampaignCoupons([]);
      setCampaignMaxPage(-1);
      setCampaignPage(1);
    } else if (activeCouponType === EVENT) {
      newCoupons = eventCoupons;
      setEventCoupons([]);
      setEventMaxPage(-1);
      setEventPage(1);
    }
    setRefetch(true);
    handleActiveCouponChange(newCoupon);
    // setActiveCampaignCoupon(null);
    // setActiveEventCoupon(null);
    window.scrollTo({
      top: 200,
      left: 0,
      behavior: "smooth",
    });
  };

  const onEmptyCouponClick = () => {
    setActiveCampaignCoupon(null);
    setActiveEventCoupon(null);
    setNewCouponOpen(true);
    setPublishedCoupon(null);
  };

  const handleCouponsRemove = removedCoupon => {
    if (activeCouponType === CAMPAIGN) {
      setCampaignCoupons(campaignCoupons.filter(el => el.id !== removedCoupon.id));
      setActiveCampaignCoupon(null);
    } else if (activeCouponType === EVENT) {
      setEventCoupons(eventCoupons.filter(el => el.id !== removedCoupon.id));
      removeUsedEventTypes(removedCoupon);
      setActiveEventCoupon(null);
    }
    window.scrollTo({
      top: 200,
      left: 0,
      behavior: "smooth",
    });
  };

  const removeUsedEventTypes = coupon => {
    let type = "";
    if (coupon.active) {
      if (coupon["first_stamp"]) {
        type = "first_stamp";
      } else if (coupon["not_stamped_for_x_days"]) {
        type = "not_stamped_for_x_days";
      } else if (coupon["x_stamps_per_period"]) {
        type = "x_stamps_per_period";
      } else {
        type = "x_stamps_per_stamp_card";
      }
    }
    setUsedEvents(usedEvents.filter(event => event !== type));
  };

  const addUsedEventTypes = coupons => {
    const usedEventTypes = [];
    coupons.forEach(coupon => {
      if (coupon.active) {
        if (coupon["first_stamp"]) {
          usedEventTypes.push("first_stamp");
        } else if (coupon["not_stamped_for_x_days"]) {
          usedEventTypes.push("not_stamped_for_x_days");
        } else if (coupon["x_stamps_per_period"]) {
          usedEventTypes.push("x_stamps_per_period");
        } else {
          usedEventTypes.push("x_stamps_per_stamp_card");
        }
      }
    });
    setUsedEvents(usedEventTypes);
  };

  async function fetchCoupons(counter = 1) {
    setIsLoading(true);
    try {
      if (activeCouponType === CAMPAIGN && campaignCoupons.length < counter * 10) {
        let result = await getCoupons(counter);
        setCampaignCoupons(campaignCoupons.concat(result.data));
        setCampaignMaxPage(result.pagination.last);
        setCampaignPage(counter);
      } else if (activeCouponType === EVENT && eventCoupons.length < counter * 10) {
        let result = await getEventsCoupons(counter);
        addUsedEventTypes(result.data);
        setEventCoupons(eventCoupons.concat(result.data));
        setEventMaxPage(result.pagination.last);
        setEventPage(counter);
      }
    } catch (error) {
      if (error.code === 401) {
        setState({ authenticated: false, user: null });
        setIsLoading(false);
        return;
      }
      if (error.code === 403) {
        setIsCouponsPageAvailable(false);
        setIsLoading(false);
        return;
      }
      errorToast({
        title: "Error",
        text: error.message,
      });
    }
    setIsLoading(false);
    return () => {
      setCampaignCoupons([]);
      setEventCoupons([]);
    };
  }

  async function fetchCouponsInfo() {
    setIsLoading(true);
    try {
      if (activeCouponType === CAMPAIGN) {
        let result = await getCouponsInfo();
        setCampaignCouponInfo(result);
        setEventCouponInfo(null);
      } else if (activeCouponType === EVENT) {
        let result = await getEventsCouponsInfo();
        setEventCouponInfo(result);
        setCampaignCouponInfo(null);
      }
      setIsLoading(false);
    } catch (error) {
      if (error.code === 403) {
        setIsCouponsPageAvailable(false);
        setIsLoading(false);
        return;
      }
      errorToast({
        title: "Error",
        text: error.message,
      });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const ac = new AbortController();
    if (refetch) {
      if (activeCouponType === CAMPAIGN && campaignCoupons.length === 0) {
        fetchCoupons();
      } else if (activeCouponType === EVENT && eventCoupons.length === 0) {
        fetchCoupons();
      }
    }
    fetchCouponsInfo();
    setRefetch(false);
    return () => ac.abort();
  }, [refetch]);

  function loadMoreData() {
    if (activeCouponType === CAMPAIGN) {
      if (campaignMaxPage !== -1 && campaignMaxPage >= campaignPage + 1) {
        fetchCoupons(campaignPage + 1);
      }
    } else {
      if (eventMaxPage !== -1 && eventMaxPage >= eventPage + 1) {
        fetchCoupons(eventPage + 1);
      }
    }
  }

  const toggleCouponFilter = async option => {
    setActiveCouponType(option);
    setActiveCampaignCoupon(null);
    setActiveEventCoupon(null);
    setRefetch(true);
  };

  const showTabButtons = () => {
    const campaignButton = (
      <MSButton
        onClick={() => toggleCouponFilter(CAMPAIGN)}
        className={classes.tabButton}
        type={activeCouponType === CAMPAIGN ? "primary" : "white"}
        style={{ borderRadius: 20 }}
      >
        {strings.couponsPage.campaignCoupon}
      </MSButton>
    );

    const eventButton = (
      <MSButton
        onClick={() => toggleCouponFilter(EVENT)}
        className={classes.tabButton}
        type={activeCouponType === EVENT ? "primary" : "white"}
        style={{ borderRadius: 20 }}
      >
        {strings.couponsPage.eventCoupon}
      </MSButton>
    );

    if (activeCampaignCoupon !== null && activeCouponType === CAMPAIGN) {
      return campaignButton;
    } else if (activeEventCoupon !== null && activeCouponType === EVENT) {
      return eventButton;
    }
    return (
      <>
        {campaignButton}
        {eventButton}
      </>
    );
  };

  const showCouponTab = () => {
    const campaignTab = (
      <NewCouponSection
        activeCoupon={activeCampaignCoupon}
        handleCouponsUpdate={handleCouponsUpdate}
        handleCouponsRemove={handleCouponsRemove}
      />
    );

    const eventTab = (
      <NewEventCouponSection
        activeCoupon={activeEventCoupon}
        handleCouponsUpdate={handleCouponsUpdate}
        handleCouponsRemove={handleCouponsRemove}
        usedEvents={usedEvents}
      />
    );

    if (activeCampaignCoupon !== null && activeCouponType === CAMPAIGN) {
      return campaignTab;
    } else if (activeEventCoupon !== null && activeCouponType === EVENT) {
      return eventTab;
    }
    return activeCouponType === CAMPAIGN ? campaignTab : eventTab;
  };

  const showStats = () => {
    let invitedCount = "0";
    let claimedCount = "0";
    let activeCount = "0";
    let addMargin = false;

    if (campaignCouponInfo !== null) {
      invitedCount = campaignCouponInfo.invited_count;
      claimedCount = campaignCouponInfo.claimed_count;
      activeCount = campaignCouponInfo.active_count;
      addMargin = campaignCouponInfo.length > 0;
    } else if (eventCouponInfo !== null) {
      invitedCount = eventCouponInfo.invited_count;
      claimedCount = eventCouponInfo.claimed_count;
      activeCount = eventCouponInfo.active_count;
      addMargin = eventCouponInfo.length > 0;
    }

    return (
      <StatsGrid valueFontSize={56} labelFontSize={18} marginValue={addMargin > 0 ? "0px" : "0px 0px 50px 0px"}>
        <StatsGridStat value={invitedCount} label={strings.loyaltyCardsPage.invited} />
        <StatsGridStat value={claimedCount} label={strings.couponsPage.redeemedCoupons} />
        <StatsGridStat value={activeCount} label={strings.couponsPage.activeCoupons} />
      </StatsGrid>
    );
  };

  return (
    <PageWrapper>
      {isLoading && <Spinner />}
      {isCouponsPageAvailable ? (
        <div align="center" className={classes.mainContainer}>
          {showStats()}

          <Typography className={classes.couponsTitle}>{strings.couponsPage.yourCoupons}</Typography>
          <Typography className={classes.couponsSubtitle}>{strings.couponsPage.clickOnCoupons}</Typography>
          <div className={classes.couponFilterContainer}>
            <Typography className={classes.couponsFilterLabel}>{strings.couponsPage.show}</Typography>
            <div className={classes.couponFilterOptionsContainer}>
              <MSButton
                type="text"
                className={classes.couponsFilterOption}
                style={{ fontWeight: activeCouponType === CAMPAIGN ? 700 : 400 }}
                onClick={() => toggleCouponFilter(CAMPAIGN)}
              >
                {strings.couponsPage.campaignCoupons}
              </MSButton>
              <MSButton
                type="text"
                className={classes.couponsFilterOption}
                style={{ fontWeight: activeCouponType === EVENT ? 700 : 400 }}
                onClick={() => toggleCouponFilter(EVENT)}
              >
                {strings.couponsPage.eventCoupons}
              </MSButton>
            </div>
          </div>
          {activeCouponType === EVENT && (
            <ActiveEventCoupons
              coupons={eventCoupons}
              activeCoupon={activeEventCoupon}
              onActiveChange={handleActiveCouponChange}
              loadMoreData={loadMoreData}
              onEmptyCouponClick={onEmptyCouponClick}
            />
          )}
          {activeCouponType === CAMPAIGN && (
            <ActiveCoupons
              coupons={campaignCoupons}
              activeCoupon={activeCampaignCoupon}
              onActiveChange={handleActiveCouponChange}
              loadMoreData={loadMoreData}
              onEmptyCouponClick={onEmptyCouponClick}
            />
          )}
          <Collapse
            timeout={500}
            in={
              (activeCouponType === CAMPAIGN && activeCampaignCoupon !== null) ||
              (activeCouponType === EVENT && activeEventCoupon !== null)
            }
          >
            <StatsGrid valueFontSize={44} labelFontSize={18} marginValue={"0px 0px 50px 0px"}>
              <StatsGridStat
                value={tempCoupon ? tempCoupon.invited_count : "0"}
                label={strings.loyaltyCardsPage.invited}
              />
              <StatsGridStat
                value={tempCoupon ? tempCoupon.claimed_count : "0"}
                label={strings.couponsPage.redeemedCoupons}
              />
            </StatsGrid>
          </Collapse>

          {publishedCoupon !== null && activeCouponType === CAMPAIGN ? (
            <PublishedCouponSection publishedCoupon={publishedCoupon} />
          ) : (
            <>
              <div className={classes.newCouponTitleBox}>
                <div className={classes.couponTitleBox} onClick={() => setNewCouponOpen(!newCouponOpen)}>
                  <Typography className={classes.newCouponTitle}>
                    {activeCampaignCoupon !== null || activeEventCoupon !== null
                      ? strings.couponsPage.editCoupon
                      : strings.couponsPage.addNewCoupon}
                  </Typography>
                  {newCouponOpen ? <ArrowUp height={14} width={23} /> : <ArrowDown height={14} width={23} />}
                </div>
              </div>

              <Collapse in={newCouponOpen} timeout={500}>
                {
                  <div style={{ position: "relative" }}>
                    <div className={classes.couponContainer}>
                      <div className={classes.tabButtonBox}>{showTabButtons()}</div>
                      <div className={classes.tabSpacing} />
                    </div>
                    <div style={{ zIndex: 1, position: "relative", marginTop: 80 }}>{showCouponTab()}</div>
                  </div>
                }
              </Collapse>
              {!newCouponOpen && <hr className={classes.hrLine} />}
            </>
          )}
        </div>
      ) : (
        <CouponsLicensePage />
      )}
    </PageWrapper>
  );
};

const useStyles = makeStyles(() => ({
  couponFilterContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
    gap: 16,
  },
  couponsFilterLabel: {
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 16,
    color: "#000000",
  },
  couponsFilterOption: {
    fontFamily: "DMSans",
    fontSize: 16,
    color: "#000000",
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  couponFilterOptionsContainer: {
    display: "flex",
    gap: 24,
  },
  couponContainer: {
    position: "absolute",
    right: 0,
    left: 0,
    top: -60,
    display: "flex",
    justifyContent: "stretch",
    alignItems: "flex-start",
  },
  tabButtonBox: {
    display: "flex",
    flex: 2,
  },
  tabSpacing: {
    display: "flex",
    flex: 1,
  },
  tabButton: {
    flex: 1,
    fontSize: 24,
    height: 90,
    marginRight: 20,
    display: "inline-flex",
    alignItems: "flex-start",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  mainContainer: {
    minHeight: window.innerHeight,
    height: "100%",
    marginTop: 200,
    marginLeft: 50,
    marginRight: 50,
  },

  couponsTitle: {
    display: "flex",
    flex: 1,
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 28,
    color: "#000000",
    marginTop: 50,
  },

  couponsSubtitle: {
    display: "flex",
    flex: 1,
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 24,
    color: "#000000",
  },

  newCouponTitleBox: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    cursor: "pointer",
  },

  couponTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  newCouponTitle: {
    fontFamily: "DMSans",
    fontSize: 28,
    fontWeight: 700,
    color: "#333333",
    marginRight: 10,
  },
  hrLine: {
    border: "none",
    height: "2px",
    background: "repeating-linear-gradient(90deg,#C4C4C4,#C4C4C4 6px,transparent 6px,transparent 12px)",
    marginBottom: 30,
  },
}));
