import React, { useContext, useEffect, useState } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { inviteUserViaCSV } from "../../services/LoyaltyCardsServices";
import { MSButton } from "../MSButton";
import ReactFileReader from "react-file-reader";
import LanguageContext from "../../store/LanguageContext";
import InputErrorBox from "../Shared/InputErrorBox";
import InputConfirmationBox from "../Shared/InputConfirmationBox";
import FilterItem from "./FilterItem";
import { errorToast } from "../Notifications/Notifications";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const CSVInvitation = ({ activeCard, currentTags, handleInviteSent }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  let today = new Date();
  let dateToHelper = new Date(today.setMonth(today.getMonth() + 1));
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(dateToHelper);
  const [file, setFile] = useState(null);
  const [emails, setEmails] = useState("");
  const [invitesValidation, setInvitesValidation] = useState(true);
  const [datesValidation, setDatesValidation] = useState(true);
  const [isInvited, setIsInvited] = useState(false);
  const [selectedTag, setSelectedTag] = React.useState(null);
  const [isInvitedByCSVOk, setIsInvitedByCSVOk] = useState(false);
  const [infoMessage, setInfoMessages] = useState("");
  const [isDateFromOpen, setIsDateFromOpen] = useState(false);
  const [isDateToOpen, setIsDateToOpen] = useState(false);

  useEffect(() => {
    if (activeCard && activeCard.default_date) {
      setDateTo(new Date(activeCard.default_date));
    }
  }, [activeCard]);

  const handleFiles = files => {
    setFile(files[0]);
    setInvitesValidation(true);
  };

  const handleTagChange = tag => {
    setSelectedTag(tag);
  };

  const handleDateFromChange = date => {
    setDateFrom(date);
    setDatesValidation(dateTo > date);
  };

  const handleDateToChange = date => {
    setDateTo(date);
    setDatesValidation(date > dateFrom);
  };

  const handleEmailChange = event => {
    setEmails(event.target.value);
    if (event.target.value.length > 0) {
      setInvitesValidation(true);
    }
  };

  const inviteUser = async () => {
    if ((file === null || file.length === 0) && emails.length === 0) {
      setInvitesValidation(false);
      return;
    }
    if (dateFrom >= dateTo) {
      setDatesValidation(false);
      return;
    }
    let formData = new FormData();
    formData.append("valid_from", dateFrom);
    formData.append("expires_at", dateTo);
    file != null && formData.append("csv_input_file", file);
    emails.length > 0 && formData.append("invite_from_text", emails);
    selectedTag !== null && formData.append("tag_id", selectedTag.value);
    try {
      let result = await inviteUserViaCSV(activeCard.id, formData);
      setEmails("");
      setDateFrom(new Date());
      setDateTo(dateToHelper);
      setSelectedTag(null);
      setFile(null);
      setIsInvitedByCSVOk(true);
      setInfoMessages(result.message);
      setIsInvited(true);
      handleInviteSent();
      setTimeout(() => {
        setIsInvited(false);
      }, 5000);
    } catch (error) {
      errorToast({
        title: "Error",
        text: error.message,
      });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.mainContainer}>
        <Typography className={classes.inputTitle}>{strings.loyaltyCardsPage.emails}</Typography>
        <div className={classes.bulkBox}>
          <textarea
            placeholder={strings.loyaltyCardsPage.emailsDescriptonExample}
            className={classes.emailsInput}
            type="text"
            value={emails}
            onChange={handleEmailChange}
          />
          <div className={classes.alternativeBox}>
            <div>
              <Typography className={classes.alternativeTitle}>{strings.loyaltyCardsPage.or}</Typography>
            </div>
            <div className={classes.csvBox}>
              <ReactFileReader handleFiles={handleFiles} fileTypes={".csv"}>
                <MSButton type="secondary" className={classes.importButtonBox}>
                  {strings.loyaltyCardsPage.selectCSV}
                </MSButton>
              </ReactFileReader>
              <Typography className={classes.csvFile}>{file !== null ? file.name : ""}</Typography>
            </div>
          </div>
        </div>
        {!invitesValidation ? <InputErrorBox message={strings.loyaltyCardsPage.emailsRequired} /> : null}
        <div className={classes.columnContainer}>
          <div className={classes.datesBox}>
            <div className={classes.datePickerBox}>
              <Typography className={classes.inputTitle}>{strings.loyaltyCardsPage.dateFrom}</Typography>
              <KeyboardDatePicker
                value={dateFrom}
                open={isDateFromOpen}
                onOpen={() => setIsDateFromOpen(true)}
                onClose={() => setIsDateFromOpen(false)}
                onChange={date => handleDateFromChange(date)}
                minDate={new Date()}
                format="dd/MM/yyyy"
                views={["year", "month", "date"]}
                animateYearScrolling
                className={classes.datePickerContainer}
                TextFieldComponent={props => <TextField {...props} onClick={() => setIsDateFromOpen(true)} />}
                keyboardIcon={<ArrowDown height={7} width={11} />}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                  fullWidth: true,
                }}
              />
            </div>
            <div className={classes.datePickerBox}>
              <Typography className={classes.inputTitle}>{strings.loyaltyCardsPage.dateTo}</Typography>
              <KeyboardDatePicker
                value={dateTo}
                open={isDateToOpen}
                onOpen={() => setIsDateToOpen(true)}
                onClose={() => setIsDateToOpen(false)}
                onChange={date => handleDateToChange(date)}
                minDate={new Date()}
                format="dd/MM/yyyy"
                views={["year", "month", "date"]}
                animateYearScrolling
                className={classes.datePickerContainer}
                TextFieldComponent={props => <TextField {...props} onClick={() => setIsDateToOpen(true)} />}
                keyboardIcon={<ArrowDown height={7} width={11} />}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                  fullWidth: true,
                }}
              />
            </div>
          </div>
          {!datesValidation ? <InputErrorBox message={strings.loyaltyCardsPage.dateFromValidation} /> : null}
        </div>
        <Typography className={classes.inputTitleTags}>{strings.loyaltyCardsPage.tagUsers}</Typography>
        <FilterItem
          currentTags={currentTags}
          selectedTag={selectedTag}
          handleTagChange={handleTagChange}
          tagKey="csv"
          handleTagRemove={handleInviteSent}
        />
        <MSButton type="secondary" className={classes.inviteButtonBox} onClick={inviteUser}>
          {strings.loyaltyCardsPage.invite}
        </MSButton>
        {isInvited ? (
          isInvitedByCSVOk ? (
            <InputConfirmationBox
              message={infoMessage.length > 0 ? infoMessage : strings.loyaltyCardsPage.invitationSuccessful}
            />
          ) : (
            <InputErrorBox message={strings.loyaltyCardsPage.invitationUnsuccessful} />
          )
        ) : null}
      </div>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles({
  mainContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
  },

  bulkBox: {
    borderLeft: "1px solid #C4C4C4",
    paddingLeft: 25,
    paddingTop: 10,
    paddingBottom: 10,
  },

  emailsInput: {
    display: "flex",
    width: "90%",
    minHeight: 144,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    border: "0.5px solid #C4C4C4",
    borderRadius: 8,
    resize: "none",
    padding: "15px",
    color: "#333333",
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 400,
    "&:focus": {
      outlineWidth: 0,
    },
    "&::placeholder": {
      color: "#C4C4C4",
      fontFamily: "DMSans",
      fontSize: 18,
      fontWeight: 400,
    },
  },

  emailsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "90%",
    height: 35,
    alignItems: "center",
  },

  csvBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "90%",
    height: 47,
    alignItems: "center",
  },

  importButtonBox: {
    display: "flex",
    alignItems: "center",
    boxShadow: "1px 2px 4px 0px #00000040",
    cursor: "pointer",
    justifyContent: "center",
    border: "0.5px solid #C4C4C4",
    height: "50px",
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 500,
    maxWidth: "200px",
    minWidth: "160px",
    borderRadius: 38,
    "&:hover": {
      backgroundColor: "#EDB5A3",
      color: "white",
    },
  },

  columnContainer: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },

  datesBox: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  datePickerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "45%",
    textAlign: "start",
  },

  datePickerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 47,
    boxShadow: "inset 2px 2px 5px 0px rgba(0,0,0,0.25)",
    borderRadius: 8,
    border: "0.5px solid #C4C4C4",
    resize: "none",
    padding: "5px 5px 5px 10px",
    backgroundColor: "#FFFFFF",
    "& *": {
      cursor: "pointer",
    },
  },

  alternativeBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    marginTop: 15,
  },

  alternativeTitle: {
    height: 35,
    fontFamily: "DMSans",
    fontSize: 20,
    fontWeight: 500,
    color: "#333333",
    marginRight: 10,

    alignSelf: "flex-start",
  },

  inputTitle: {
    height: 35,
    fontFamily: "DMSans",
    fontSize: 20,
    fontWeight: 500,
    color: "#333333",
    marginRight: 10,
    paddingBottom: 5,
    alignSelf: "flex-start",
  },

  inputTitleTags: {
    height: 35,
    fontFamily: "DMSans",
    fontSize: 20,
    fontWeight: 500,
    color: "#333333",
    paddingBottom: 5,
    alignSelf: "flex-start",
    marginTop: 20,
  },

  inviteButtonBox: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "1px 2px 4px 0px #00000040",
    justifyContent: "center",
    border: "0.5px solid #C4C4C4",
    height: "50px",
    width: "30%",
    borderRadius: 38,
    marginTop: 30,
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 5,
  },

  csvFile: {
    marginLeft: 10,
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 700,
  },
});

export default CSVInvitation;
