import React, { memo, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { SansNavigationRoutes, NavigationRoutes } from "./navigation";
import { UserContext } from "../store/UserContext";
import { roles } from "../lib/constants";

export const PrivateRoute = memo(({ component: Component, ...rest }) => {
  const { state } = useContext(UserContext);
  const adminRoute = Object.entries(NavigationRoutes).some(keyValue => keyValue[1] === rest.path);
  const properPermission = adminRoute ? state.role === roles.ADMIN : (typeof state.role === 'undefined' || state.role === roles.TABLET);

  return (
    <Route
      {...rest}
      render={props =>
        state.authenticated && properPermission ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: adminRoute ? SansNavigationRoutes.SIGN_IN_COMPANY : SansNavigationRoutes.SIGN_IN }} />
        )
      }
    />
  );
});
