import React, { useContext } from "react";
import { useCompanyLogoStyles } from "./CompanyLogoStyles";
import { CompanyContext } from "../../store/CompanyContext";

export const CompanyLogo = props => {
  const classes = useCompanyLogoStyles(props);

  const { company } = useContext(CompanyContext);

  return <img className={classes.image} src={company.company.logo_url} alt={"Company Logo"} />;
};
