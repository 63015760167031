import { Checkbox, Collapse, FormControlLabel, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as Checked } from "../../assets/icons/check-square.svg";
import { ReactComponent as Unhecked } from "../../assets/icons/square.svg";
import LanguageContext from "../../store/LanguageContext";
import { InputWraper } from "../Shared/InputWrapper";
import InputErrorBox from "../Shared/InputErrorBox";
import NotificationsInfoList from "./NotificationsInfoList";
import NotificationTemplate from "./NotificationTemplate";
import { getNotificationBalances, getUsedMonthlyNotifications } from "../../services/CouponsServices";

const NotificationsSection = ({
  scheduledAt,
  sendPushNotification,
  handleSendPushNotification,
  pushNotificationTitle,
  handlePushNotificationTitle,
  pushNotificationBody,
  handlePushNotificationBody,
  audienceCount,
  isEventCoupon = false,
}) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const [notificationBalance, setNotificationBalance] = useState(null);
  const [notificationMailingsPerMonth, setNotificationMailingsPerMonth] = useState(null);
  const [usedMonthlyNotifications, setUsedMonthlyNotifications] = useState(null);

  const countyCode = strings ? strings._interfaceLanguage : "sv-SE";
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  let hour;
  if (!isEventCoupon) {
    hour = scheduledAt.getHours();
  }
  const sectionNumber = isEventCoupon ? "2. " : "3. ";

  useEffect(() => {
    const asyncCall = async () => {
      const balance = await getNotificationBalances();
      setNotificationBalance(balance["notification_balance"]);
      setNotificationMailingsPerMonth(balance["notification_mailings_per_month"]);
      if (typeof balance["notification_mailings_per_month"] === "number") {
        const usedMonthlyNotifications = await getUsedMonthlyNotifications();
        setUsedMonthlyNotifications(usedMonthlyNotifications["used_monthly_notifications"]);
      }
    };
    asyncCall();
  }, []);

  const checkboxLabel = () => {
    if (isEventCoupon) {
      return "Send push notification to the user on event";
    } else if (sendPushNotification) {
      return (
        strings.couponsPage.sendNotificationsToInvited + " (" + audienceCount + " " + strings.couponsPage.users + ")"
      );
    }

    return strings.couponsPage.sendNotificationsToInvited;
  };

  return (
    <InputWraper title={sectionNumber + strings.couponsPage.pushNotificationsHeader}>
      <div className={classes.mainSubcontainer}>
        <div className={classes.inputContainer}>
          <Typography className={classes.title}>{strings.couponsPage.sendNotifications}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={!sendPushNotification}
                value={sendPushNotification}
                onClick={() => handleSendPushNotification(!sendPushNotification)}
                icon={<Checked style={{ color: "#000000" }} />}
                checkedIcon={<Unhecked style={{ color: "#000000" }} />}
              />
            }
            label={checkboxLabel()}
            className={classes.dateTitle}
          />
          <div className={classes.collapse}>
            <Collapse in={sendPushNotification}>
              <div className={classes.inputBox}>
                <Typography className={classes.inputTitle}>{strings.couponsPage.notificationTitle}*</Typography>
                {/* TODO: Add maximum number of characters */}
                <input
                  className={classes.input}
                  type="text"
                  value={pushNotificationTitle}
                  onChange={handlePushNotificationTitle}
                />
              </div>
              <div className={classes.inputBox}>
                <Typography className={classes.inputTitle}>{strings.couponsPage.notificationMessage}*</Typography>
                {/* TODO: Add maximum number of characters */}
                <input
                  className={classes.input}
                  type="text"
                  placeholder="Message"
                  value={pushNotificationBody}
                  onChange={handlePushNotificationBody}
                />
              </div>
              {!isEventCoupon && (
                <div className={classes.inputBox}>
                  <Typography className={classes.dateInputTitle}>{strings.couponsPage.scheduledAt}</Typography>
                  <Typography className={classes.textNormal}>
                    {scheduledAt.toLocaleTimeString(countyCode, options).split(" ").slice(1).join(" ")}
                  </Typography>
                  {(hour < 8 || hour >= 20) && <InputErrorBox message={strings.couponsPage.notificationError} />}
                </div>
              )}
            </Collapse>
          </div>
        </div>
        <div className={classes.templateContainer}>
          <Typography className={classes.title} style={{ paddingLeft: 5 }}>
            {strings.stampCardPage.balance}
          </Typography>
          <NotificationsInfoList
            isChecked={sendPushNotification}
            notificationBalance={notificationBalance}
            notificationMailingsPerMonth={notificationMailingsPerMonth}
            usedMonthlyNotifications={usedMonthlyNotifications}
          />
          <Collapse in={sendPushNotification}>
            <div className={classes.collapse}>
              <Typography className={classes.title} style={{ paddingLeft: 5 }}>
                {strings.couponsPage.preview}
              </Typography>
              <Typography className={classes.textNormal} style={{ paddingLeft: 5 }}>
                {strings.couponsPage.previewHelp}
              </Typography>
              <div className={classes.templateBox}>
                <NotificationTemplate title={pushNotificationTitle} message={pushNotificationBody} />
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </InputWraper>
  );
};

const useStyles = makeStyles({
  mainSubcontainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 30,
    marginTop: 30,
  },

  collapse: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%",
  },

  inputBox: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },

  inputContainer: {
    flex: 0.5,
    minWidth: 400,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-start",
  },

  inputTitle: {
    fontFamily: "DMSans",
    fontWeight: 600,
    fontSize: 18,
    color: "#333333",
    textAlign: "start",
    marginTop: 20,
  },

  textNormal: {
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 20,
    textAlign: "start",
    color: "#333333",
  },

  dateInputTitle: {
    fontFamily: "DMSans",
    fontWeight: 600,
    fontSize: 18,
    color: "#333333",
    textAlign: "start",
    marginTop: 30,
  },

  input: {
    display: "flex",
    width: "80%",
    height: 58,
    border: "0.5px solid #C4C4C4",
    boxShadow: "2px 2px 5px 0px #00000040 inset",
    fontFamily: "DMSans",
    fontSize: 18,
    fontWeight: 400,
    borderRadius: 8,
    resize: "none",
    marginTop: 15,
    padding: "5px 10px",
    "&::placeholder": {
      color: "#33333380",
      fontFamily: "DMSans",
      fontSize: 16,
      fontWeight: 400,
    },
    "&:focus": {
      outlineWidth: 0,
    },
  },

  dateTitle: {
    fontFamily: "DMSans",
    fontWeight: 400,
    fontSize: 18,
    color: "#333333",
    textAlign: "start",
    marginTop: 15,
  },

  templateContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%",
    minHeight: 300,
    minWidth: 350,
    flexGrow: 1,
    paddingRight: 20,
  },

  title: {
    fontFamily: "DMSans",
    fontWeight: 700,
    fontSize: 20,
    color: "#333333",
    textAlign: "start",
  },

  templateBox: {
    minWidth: 350,
    display: "flex",
    paddingRight: 30,
  },
});

export default NotificationsSection;
