import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    //    backgroundColor: 'rgba(32, 60, 83, 0.8)',
  },
  paper: {
    borderRadius: 30,
    padding: "20px 75px 50px 75px",
    marginLeft: 10,
    marginRight: 10,
  },
  paperRed: {
    backgroundColor: "#F17877",
  },
  paperPurple: {
    backgroundColor: "#edb5a3",
  },
  container: {
    marginTop: 10,
    margin: "auto",
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
    color: "white",
  },
}))

export default useStyles
