function RequestException(message, code, data) {
  const error = new Error(message);

  error.code = code;
  if (data) {
    error.data = data;
  }
  return error;
}

RequestException.prototype = Object.create(Error.prototype);

export default RequestException;
