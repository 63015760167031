import React from "react";
import App from "./App";
import { UserContextProvider } from "./store/UserContext";
import { LocationContextProvider } from "./store/LocationContext";
import { CompanyContextProvider } from "./store/CompanyContext";
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <UserContextProvider>
      <LocationContextProvider>
        <CompanyContextProvider>
          <App />
        </CompanyContextProvider>
      </LocationContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
);
