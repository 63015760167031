import React from "react";
import PropTypes from "prop-types";
import { TableHead, TableCell, TableRow, TableSortLabel } from "@material-ui/core";
import { ReactComponent as FilterArrows } from "../../assets/icons/filterArrows.svg";
import { sortingOrders } from "../../lib/constants";

const EnhancedTableHead = ({ classes, headCells, order, orderBy, onRequestSort }) => {

  const createSortHandler = (property) => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            align={headCell.align}
            className={classes.th}
          >
            {headCell.sortVisible && <TableSortLabel
              active={orderBy === headCell.id}
              IconComponent={() => <FilterArrows className={classes.filterArrowsIcon} />}
              direction={orderBy === headCell.id ? order : sortingOrders.ASC}
              onClick={()=>{createSortHandler(headCell.id);}}
            >
              {orderBy === headCell.id ? <span className={classes.orderMark} /> : null}
            </TableSortLabel>}
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object,
  headCells: PropTypes.array,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
};

export { EnhancedTableHead };
