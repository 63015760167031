import { motion, useAnimate } from "framer-motion";
import receiptTopEnImg from "../../assets/images/receipt_top_en.png";
import receiptBottomEnImg from "../../assets/images/receipt_bottom_en.png";
import receiptTopSvImg from "../../assets/images/receipt_top_sv.png";
import receiptBottomSvImg from "../../assets/images/receipt_bottom_sv.png";
import { makeStyles } from "@material-ui/core";
import { useContext, useEffect } from "react";
import LanguageContext from "../../store/LanguageContext";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    overflow: "hidden",
  },
  receipt: {
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "290px",
    height: "auto",
    filter: "drop-shadow(12px 12px 32px rgba(98, 98, 98, 0.25))",
    willChange: "transform",
  },
  receiptTop: {
    position: "relative",
    width: "100%",
    willChange: "transform",
  },
  receiptBottom: {
    position: "relative",
    width: "100%",
    bottom: 0,
    willChange: "transform",
  },
}));

const initialDelay = 0.5;

const ReceiptAnimation = ({ when = false, onDone }) => {
  const classes = useStyles();
  const strings = useContext(LanguageContext);
  const language = strings && "getLanguage" in strings ? (strings?.getLanguage().includes("en") ? "en" : "sv") : "sv";
  const [receiptTop, animateReceiptTop] = useAnimate();
  const [receiptBottom, animateReceiptBottom] = useAnimate();

  async function animation() {
    await animateReceiptTop(
      receiptTop.current,
      { transform: "translate(40%, -75px) rotate(30deg)" },
      { duration: 0.25, delay: initialDelay + 1.5, ease: "easeInOut" },
    );
    animateReceiptBottom(
      receiptBottom.current,
      {
        transform: "translateY(-200px)",
      },
      {
        delay: 0.1,
        type: "spring",
        velocity: 75,
        stiffness: 280,
        damping: 50,
        mass: 6,
      },
    );
    onDone();
    await animateReceiptTop(
      receiptTop.current,
      { transform: "translate(100%, -50vh) rotate(45deg)" },
      { duration: 0.3, ease: "easeInOut", delay: -0.05 },
    );
    await animateReceiptTop(
      receiptTop.current,
      { transform: "translate(200%, -150vh) rotate(55deg)" },
      { duration: 0.3, ease: "easeInOut", delay: -0.05 },
    );
  }

  useEffect(() => {
    if (when) {
      animation();
    }
  }, [when]);

  if (!when) return null;

  return (
    <div className={classes.root}>
      <motion.div
        className={classes.receipt}
        initial={{
          top: "150%",
        }}
        animate={{
          top: "50%",
        }}
        transition={{
          delay: initialDelay,
          type: "spring",
          velocity: 75,
          stiffness: 280,
          damping: 50,
          mass: 6,
        }}
      >
        <motion.img
          ref={receiptTop}
          initial={{
            top: 0,
            rotate: 0,
          }}
          className={classes.receiptTop}
          src={language === "sv" ? receiptTopSvImg : receiptTopEnImg}
        />
        <motion.img
          ref={receiptBottom}
          src={language === "sv" ? receiptBottomSvImg : receiptBottomEnImg}
          className={classes.receiptBottom}
          id={"receiptBottom"}
        />
      </motion.div>
    </div>
  );
};

export default ReceiptAnimation;
