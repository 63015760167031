import React from "react";
import { CompanyLogo } from "../CompanyLogo/CompanyLogo";
import { StampCardStamp } from "./StampCardStamp";
import { useStampCardStyles } from "./StampCardStyles";
import Typography from "@material-ui/core/Typography";
import mystampIcon from "../../assets/images/mystamp_icon.png";
import giftIcon from "../../assets/images/ion_gift_disabled.png";

export const StampCard = props => {
  const classes = useStampCardStyles(props);
  const numberOfStamps = props.numberOfStamps;

  const stamps = Array(numberOfStamps);

  if (numberOfStamps == 2) {
    stamps.push({ text: (numberOfStamps - 1).toString(), stamp: true });
  } else if (numberOfStamps > 2) {
    Array.from(Array(numberOfStamps - 2)).map(() => stamps.push({ icon: mystampIcon, stamp: true }));
    stamps.push({ text: (numberOfStamps - 1).toString(), stamp: false });
  }
  stamps.push({ icon: giftIcon, stamp: false });

  const renderStamps = () => {
    if (props.pointSystem) {
      return null;
    }

    return stamps.map((stamp, index) => (
      <div className={classes.stampContainer} key={index}>
        <StampCardStamp image={stamp.icon} text={stamp.text} stamp={stamp.stamp} />
      </div>
    ));
  };

  return (
    <div className={classes.root} onClick={props.onClick}>
      <div className={classes.shadow}>
        <div className={classes.container}>
          <CompanyLogo maxWidth={84} maxHeight={70} />
          <div className={classes.stampsAndTitleContainer}>
            <Typography className={classes.title}>{props.title}</Typography>
            <div className={classes.stampsContainer}>{renderStamps()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
