import React from "react";
import { makeStyles } from "@material-ui/core";

export const CompanyLogo = ({ logoURL }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ backgroundImage: `url(${logoURL})`}} className={classes.image} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    paddingTop: 150,
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 1440,
    alignSelf: "center"
  },
  image:{
    height: 115,
    width: 200,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  }
}));

