import { Typography } from "@material-ui/core";
import React from "react";
import { useStampCardStampStyles } from "./StampCardStampStyles";

export const StampCardStamp = props => {
  const classes = useStampCardStampStyles(props);

  return (
    <div className={classes.root}>
      {props.image ? (
        <img src={props.image} className={classes.image} alt={"My stamp M"} />
      ) : (
        <Typography className={classes.text}>{props.text}</Typography>
      )}
    </div>
  );
};
