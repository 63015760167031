import { makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { CompanyContext } from "../../store/CompanyContext";
import LanguageContext from "../../store/LanguageContext";

const CouponPreview = ({ coupon, isCreated }) => {
  const classes = useStyles(isCreated);
  const strings = useContext(LanguageContext);
  const { company } = useContext(CompanyContext);
  const options = { year: "2-digit", month: "numeric", day: "numeric", hour12: false };

  let dateValidFrom;
  let dateValidTo;
  let formattedDateValidFrom;
  let formattedDateValidTo;

  if (coupon.valid_from) {
    dateValidFrom = new Date(coupon.valid_from);
    dateValidTo = new Date(coupon.valid_to);
    formattedDateValidFrom = dateValidFrom.toLocaleDateString(undefined, options);
    formattedDateValidTo = dateValidTo.toLocaleDateString(undefined, options);
  }

  const renderValidityText = () => {
    if (coupon.valid_from) {
      return (
        <Typography className={classes.footerText}>
          {strings.couponsPage.valid}
          {formattedDateValidFrom}
          {strings.couponsPage.to}
          {formattedDateValidTo}
        </Typography>
      );
    }
    return (
      <Typography className={classes.footerText}>
        {strings.couponsPage.valid}
        {strings.couponsPage.for}
        {coupon.valid_period_in_days ? coupon.valid_period_in_days : "-"} {strings.couponsPage.days}
      </Typography>
    );
  };

  return (
    <>
      <div className={classes.couponTemplate}>
        <div className={classes.content}>
          <div className={classes.infoContainer}>
            <div className={classes.imagesBox}>
              {company.company?.logo_url !== null && (
                <div style={{ backgroundImage: `url(${company.company?.logo_url})` }} className={classes.logoImage} />
              )}
              {coupon.coupon_image === null ? (
                !isCreated && <div className={classes.imageTemplate} />
              ) : (
                <div className={classes.imageBox}>
                  <img src={coupon.coupon_image} alt={"coupon_logo"} className={classes.image}></img>
                </div>
              )}
            </div>
            <Typography
              className={classes.contentText}
              style={{ color: coupon.long_description.length > 0 ? "black" : "#C4C4C4" }}
            >
              {coupon.long_description.length > 0
                ? coupon.long_description
                : strings.couponsPage.longDescriptionCouponExample}
            </Typography>
            <ul className={classes.bulletListWrapper}>
              {coupon.specifications.length > 0 || parseInt(coupon.redemption_limit) > 0 || isCreated ? (
                [
                  parseInt(coupon.redemption_limit) > 0 && (
                    <li className={classes.bulletTextInserted} key={coupon.redemption_limit}>
                      {strings.couponsPage.totalRedemptionsMessage_1}
                      {coupon.redemption_limit}
                      {strings.couponsPage.totalRedemptionsMessage_2}
                    </li>
                  ),
                  coupon.specifications.length > 0 &&
                    coupon.specifications.split("\n").map((element, index) => {
                      return (
                        element !== " " && (
                          <li key={index} className={classes.bulletTextInserted}>
                            {element}
                          </li>
                        )
                      );
                    }),
                ]
              ) : (
                <>
                  <li className={classes.bulletText}>{strings.couponsPage.bullet1}</li>
                  <li className={classes.bulletText}>{strings.couponsPage.bullet2}</li>
                  <li className={classes.bulletText}>{strings.couponsPage.bullet3}</li>
                </>
              )}
            </ul>
          </div>
          <div className={classes.bottomBox}>
            <hr className={classes.hrLine} />
            <div className={classes.pickButton}>
              <Typography className={classes.pickText}>{strings.couponsPage.pickUp}</Typography>
            </div>
            {renderValidityText()}
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles({
  couponTemplate: {
    display: "flex",
    width: 327,
    height: 618,
    backgroundColor: "#FBFBFB",
    borderRadius: 8,
    marginTop: 20,
    marginBottom: 20,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    boxShadow: "inset 2px 2px 2px rgba(0, 0, 0, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25)",
    fitler: "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))",
    "&::before": {
      content: "''",
      display: "block",
      height: 32,
      width: 16,
      marginRight: 295,
      marginTop: 360,
      backgroundColor: isCreated => (!isCreated ? "#F0F0F0" : "#fafafa"),
      borderRight: "2px solid #C4C4C4",
      borderTop: "2px solid #C4C4C4",
      borderBottom: "2px solid #C4C4C4",
      borderTopRightRadius: 50,
      borderBottomRightRadius: 50,
    },
    "&::after": {
      content: "''",
      display: "block",
      height: 32,
      width: 16,
      marginTop: 360,
      backgroundColor: isCreated => (!isCreated ? "#F0F0F0" : "#fafafa"),
      borderLeft: "2px solid #C4C4C4",
      borderTop: "2px solid #C4C4C4",
      borderBottom: "2px solid #C4C4C4",
      borderTopLeftRadius: 50,
      borderBottomLeftRadius: 50,
    },
  },

  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: 600,
    maxWidth: 297,
    marginLeft: -297,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
  },

  infoContainer: {
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  imagesBox: {
    minHeight: 190,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  imageTemplate: {
    width: 263,
    height: 130,
    backgroundColor: "#E0E0E0",
    borderRadius: 8,
    marginTop: 20,
  },

  imageBox: {
    display: "flex",
    width: 263,
    height: 130,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },

  image: {
    display: "inline-block",
    maxWidth: 253,
    maxHeight: 120,
  },

  logoImage: {
    width: 109,
    height: 49,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },

  contentText: {
    wordWrap: "break-word",
    width: "100%",
    fontSize: 16,
    fontFamily: "DMSans",
    fontWeight: 400,
    color: "#C4C4C4",
    textAlign: "center",
    marginTop: 20,
    lineHeight: "20px",
  },

  bulletListWrapper: {
    width: "100%",
    margin: "20px 0 0 0",
    padding: "0px 0px 0px 20px",
  },

  bulletText: {
    fontSize: 14,
    fontFamily: "DMSans",
    fontWeight: 400,
    color: "#C4C4C4",
    textAlign: "start",
  },

  bulletTextInserted: {
    wordWrap: "break-word",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "DMSans !important",
    fontWeight: 400,
    color: "#5E5E5E",
  },

  bottomBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    alignSelf: "flex-end",
    paddingTop: 20,
  },

  hrLine: {
    width: "80%",
    border: "none",
    height: "3px",
    background: "repeating-linear-gradient(90deg,#C4C4C4,#C4C4C4 13px,transparent 5px,transparent 18px)",
  },

  pickButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: isCreated => (isCreated === false ? "#C4C4C4" : "#EDB5A3"),
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 38,
    height: 50,
    width: 170,
    marginTop: 20,
  },

  pickText: {
    color: "#FFFFFF",
    fontFamily: "DMSans",
    fontSize: 24,
    fontWeight: 700,
    textTransform: "uppercase",
  },

  footerText: {
    fontFamily: "DMSans",
    color: "black",
    fontSize: 12,
    fontWeight: 400,
    marginTop: 10,
  },
});

export default CouponPreview;
