import React from "react";
import { Grid } from "@material-ui/core";

export const StatsGrid = props => {
  const { valueFontSize, labelFontSize, marginValue } = props;
  return (
    <Grid container justifyContent="center" style={{ margin: marginValue }}>
      {props.children.map((component, index) => (
        <Grid item xs={4} md={2} key={index}>
          {React.cloneElement(component, { valueFontSize, labelFontSize })}
        </Grid>
      ))}
    </Grid>
  );
};
